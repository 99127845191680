import { RestClient } from "../RestClient";
interface IData {
  id: string;
}
export class DeleteEventClient {
  private static _client: RestClient;
  static fetchDeleteEvent(data: IData) {
    return DeleteEventClient.getClient().deleteCall(
      `/api/msdv2/events/store`,
      data
    );
  }

  static getClient() {
    if (DeleteEventClient._client == null) {
      DeleteEventClient._client = new RestClient();
    }
    return DeleteEventClient._client;
  }
}