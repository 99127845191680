import KPIMini from "components/msd/KPIMini/KPIMini";
import PassengersCabinChannelMix from "components/msd/PassengersCabinChannelMix/PassengersCabinChannelMix";
import PointOfSaleBreakdown from "components/msd/PointOfSaleBreakdown/PointOfSaleBreakdown";
import ProductMatrix from "components/msd/ProductMatrix/ProductMatrix";
import { useState } from "react";
import Layout from "components/common/Layout/Layout";

export default function PRODUCTOVERVIEW() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [passengersCabinChannelMixData, setPassengersCabinChannelMixData] =
    useState({
      passengersMix: [],
      cabinMix: [],
      channelMix: [],
      layout: {},
    });
  const [storyText, setStoryText] = useState<any>({
    productMatrix: {},
    pos: {},
    passenger: {},
    cabin: {},
    channel: {},
  });
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Product Overview"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <ProductMatrix
        filterIsOpen={filterIsOpen}
        setPassengersCabinChannelMixData={setPassengersCabinChannelMixData}
        storyText={storyText}
        setStoryText={setStoryText}
      />
      <PointOfSaleBreakdown
        filterIsOpen={filterIsOpen}
        storyText={storyText}
        setStoryText={setStoryText}
      />
      <PassengersCabinChannelMix
        storyText={storyText}
        chartData={passengersCabinChannelMixData}
      />
    </Layout>
  );
}
