import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { LoginClient } from "lib/api/login/Login";
import { CircularProgress } from "@mui/material";
import { UserClient } from "lib/api/user/User";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "../Tooltip";
import LazyLoad from "react-lazyload";
import images from "constans/images";
import styles from "./styles.module.css";

export default function Login() {
  const [cookies, setCookie] = useCookies(["token"]);
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [visible, setVisible] = useState(true);
  const [isFull, setIsFull] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [progress, setProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (cookies.token) {
      UserClient.fetchUser({ token: cookies.token })
        .then(() => {
          window.location.href = "/home"; // redirect to home page
        })
        .catch(() => {
          setCookie("token", "");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputError(false); // reset input error
    if (mail.length > 0 && password.length > 0) {
      // if mail and password are not empty
      setIsFull(true); // set isFull to true if mail and password are not empty
      setInputError(false); // set inputError to false if mail and password are not empty
    } else {
      setIsFull(false); // set isFull to false if mail and password are empty
    }
  }, [mail, password]);

  const handleSubmit = (e) => {
    e.preventDefault(); // prevent default behavior of submit button
    if (mail.length > 0 && password.length > 0) {
      // if mail and password are not empty
      setProgress(true); // set progress to true
      LoginClient.fetchLogin({ email: mail, password: password })
        .then((response: any) => {
          UserClient.fetchUser({ token: response.jwt })
            .then((res: any) => {
              const defaultSelectFilter = {
                ...res.defaultFilterSelection,
              };
              localStorage.setItem(
                "user",
                JSON.stringify({
                  ...res,
                  defaultFilterSelection: defaultSelectFilter,
                })
              ); // save user to local storage
              setCookie("token", response.jwt); // save token to cookie
              window.location.href = "/home"; // redirect to home page if token is available
            })
            .catch(() => {
              localStorage.removeItem("user"); // remove user from local storage
              setCookie("token", "");
            });
        })
        .catch((error) => {
          setErrorMessage(
            error?.originalError?.response?.data?.message ?? "Error"
          ); // set error message
          setInputError(true); // set inputError to true
        })
        .finally(() => {
          setProgress(false); // set progress to false
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.image_wrapper}>
        <img
          src={theme === "dark" ? images.world : images.world_light}
          alt=""
          loading="lazy"
          className={styles.images_world}
        />
      </div>
      <div className={styles.login_card} data-theme={theme}>
        <div className={styles.logo_wrapper}>
          <LazyLoad height={200} offset={100}>
            <img
              src={theme === "dark" ? images.logo : images.logo_light}
              alt=""
              loading="lazy"
            />
          </LazyLoad>
        </div>
        <p className={styles.login_welcome_text}>
          Welcome to Atarev Revenue Suite
        </p>
        <div className={styles.form_wraper}>
          <div className={styles.input_wrapper}>
            <p>E-mail/UserID:</p>
            <input
              type="email"
              name=""
              id="mail"
              placeholder="Please enter your email"
              className={styles.input}
              data-theme={theme}
              value={mail}
              onChange={(e) => {
                setMail(e.target.value); // set mail to value of input
              }}
              data-active={inputError}
              autoComplete={"off"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e); // handle submit if enter key is pressed
                }
              }}
            />
            <Tooltip title={errorMessage}>
              <img
                src={images.info}
                alt=""
                className={styles.error_info}
                data-active={inputError}
              />
            </Tooltip>
          </div>
          <div className={styles.input_wrapper}>
            <p>Password:</p>
            <input
              type={visible ? "password" : "text"}
              name=""
              id="password"
              placeholder="Please enter your Password"
              className={styles.input}
              data-theme={theme}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value); // set password to value of input
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e); // handle submit if key is enter
                }
              }}
            />

            <img
              src={images.eye}
              alt=""
              className={styles.password_visible}
              data-theme={theme}
              onClick={() => {
                setVisible(!visible); // set visible to opposite value
              }}
            />
          </div>
          <div>
            <button
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e); // handle submit if key is enter
                }
              }}
              className={styles.login_button}
              data-active={isFull && !progress}
              disabled={!isFull || progress}
              data-theme={theme}
            >
              {progress ? (
                <div className={styles.loader}>
                  <CircularProgress color="inherit" size={24} />
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
