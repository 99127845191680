import images from "constans/images";
import { FilterContext } from "context-api/FilterContext";
import { useContext } from "react";
import styles from "./DynamicFilterClosed.module.css";
import { Tooltip } from "components/common/Tooltip";

interface IDynamicFilterClosed {
  setIsOpen: any;
  filterNumber: number;
  dynamicFilters: any;
  setDynamicFilters: any;
}

export default function DynamicFilterClosed({
  setIsOpen,
  filterNumber,
  dynamicFilters,
  setDynamicFilters,
}: IDynamicFilterClosed) {
  const { filterList } = useContext(FilterContext);

  const handleremove = () => {
    if (dynamicFilters.length > 1) {
      dynamicFilters.filter((_el, index) => index !== filterNumber);
      setDynamicFilters(dynamicFilters);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <span className={styles.filter_number} style={{ color: "#da7706" }}>
            {filterNumber + 1}
          </span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title}>Region</span>
              <img
                src={images.navigation_maps}
                alt=""
                className={styles.card_icon}
              />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origRegion.join()} to ${filterList.destRegion.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.origRegion.join()}`}
                    </span>
                  </div>
                  <span className={styles.to}>to</span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.destRegion.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title}>Country</span>
              <img src={images.flag} alt="" className={styles.card_icon} />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origCountry.join()} to ${filterList.destCountry.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.origCountry.join()}`}
                    </span>
                  </div>
                  <span className={styles.to}>to</span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.destCountry.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title}>City / Airport</span>
              <img
                src={images.travel_plane_take_off}
                alt=""
                className={styles.card_icon}
              />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origCityAirport.join()} to ${filterList.destCityAirport.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.origCityAirport.join()}`}
                    </span>
                  </div>
                  <span className={styles.to}>to</span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text}>
                      {`${filterList.destCityAirport.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title}>Point of Sale</span>
            </div>
            <div className={styles.card_bottom}>
              <Tooltip title={`${filterList.pos.join()}`}>
                <div className={styles.from_to_params}>
                  <span className={styles.card_text}>
                    {`${filterList.pos.join()}`}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title}>Competitors</span>
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${[
                  ...filterList.mainCompetitor,
                  ...filterList.selectedCompetitors,
                ].join()}`}
              >
                <div className={styles.from_to_params}>
                  <span className={styles.card_text}>
                    {`${[
                      ...filterList.mainCompetitor,
                      ...filterList.selectedCompetitors,
                    ].join()}`}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={styles.setting_button}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <img src={images.setting_button} alt="" />
          </div>
          <div className={styles.remove_filter} onClick={handleremove}>
            <img src={images.cancel} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.card_footer}>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title}>Sales Channel:</span>
          <Tooltip title={filterList.salesChannel.join()}>
            <span className={styles.footer_content}>
              {filterList.salesChannel.join()}
            </span>
          </Tooltip>
        </div>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title}>Cabin:</span>
          <Tooltip title={filterList.cabin.join()}>
            <span className={styles.footer_content}>
              {filterList.cabin.join()}
            </span>
          </Tooltip>
        </div>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title}>Pax Type:</span>
          <Tooltip title={filterList.paxType.join()}>
            <span className={styles.footer_content}>
              {filterList.paxType.join()}
            </span>
          </Tooltip>
        </div>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title}>Direction:</span>
          <span className={styles.footer_content}>Directional</span>
        </div>
      </div>
    </div>
  );
}
