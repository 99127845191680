import { ThemeContext } from "context-api/ThemeContext";
import { DetailedHTMLProps, InputHTMLAttributes, useContext } from "react";
import styles from "./styles.module.css";

const Input = ({
  ...rest
}: DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  const { theme } = useContext(ThemeContext);
  return <input className={styles.input} data-theme={theme} {...rest} />;
};
export default Input;