/* eslint-disable react-hooks/exhaustive-deps */
import Dropdown from "components/common/Dropdown/Dropdown";
import Input from "components/common/Input/input";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { StoreOptionsClient } from "lib/api/holidays/StoreOptions";
import DatePickerComponent from "components/common/DatePicker/DatepickerLastest";
import Card from "components/common/Card/Card";
import { CreateEventClient } from "lib/api/holidays/CreateEvent";
import moment from "moment";
import Notification from "components/common/Notification/Notification";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { EventTableClient } from "lib/api/holidays/Table";
import { UpdateEventClient } from "lib/api/holidays/UpdateEvent";
import { CircularProgress } from "@mui/material";
import { MdDelete } from "react-icons/md";
import { DeleteEventClient } from "lib/api/holidays/DeleteEvent";

interface IEventForm {
  row: any;
  edit?: boolean;
  isDelete?: boolean;
  orig_city_airport?: string[];
  dest_city_airport?: string[];
}

const EventForm = ({
  row,
  edit,
  isDelete,
  orig_city_airport,
  dest_city_airport,
}: IEventForm) => {
  const [dropdownData, setDropdownData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [cityCode, setCityCode] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string[]>([]);
  const [selectedCityCode, setSelectedCityCode] = useState<string[]>([]);

  const { date } = row;
  const [startDate, setStartDate] = useState(new Date(date));
  const [endDate, setEndDate] = useState(new Date(date));
  const [eventName, setEventName] = useState("");
  const [id, setId] = useState<null | string>(null);
  const [error, setError] = useState({
    category: false,
    subCategory: false,
    countryCode: false,
    cityCode: false,
    eventName: false,
  });
  const [formError, setFormError] = useState(false);
  const [tableData, setTableData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });

  const [loading, setLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {
    if (edit || isDelete) {
      setDataLoader(true);
      const params = {
        orig_city_airport,
        dest_city_airport,
        date,
      };
      EventTableClient.fetchEventTable({ params })
        .then((res: any) => {
          setTableData({
            data: res?.data ?? [],
            columns: getColumns(res?.labels),
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setDataLoader(false));
    }
  }, [orig_city_airport, dest_city_airport, date, edit]);

  const getColumns = (labels) => {
    const array: {
      name: string;
      field: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
    }[] = [];
    for (const [field, name] of Object.entries({ openMenu: "", ...labels })) {
      if (field !== "openMenu") {
        const obj: any = {
          name,
          field,
          cell: (row) => {
            return <span className={"rdb-custom-cell"}>{row[field]}</span>;
          },
          width: 200,
        };
        array.push(obj);
      } else {
        const obj: any = {
          name,
          field,
          cell: (row) => {
            return (
              <span className={"rdb-custom-cell"}>
                <MdDelete
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDelete(row.id);
                  }}
                />
              </span>
            );
          },
          width: 50,
          disabledHeader: true,
        };
        array.push(obj);
      }
    }
    return array;
  };

  // Alfabetik sıralama işlevi
  const compareFunction = (a, b) => {
    return a.localeCompare(b);
  };

  // Opsiyonları dolduran servis
  useEffect(() => {
    StoreOptionsClient.fetchStoreOptions()
      .then((res: any) => {
        const categories = res?.categories
          ?.map((el) => el.value)
          .sort(compareFunction);
        const cityCodes = res?.cities
          ?.map((el) => el.value)
          .sort(compareFunction);
        const countryCodes = res?.countries
          ?.map((el) => el.value)
          .sort(compareFunction);
        setCategoryOptions(categories);
        setCountryCode(countryCodes);
        setCityCode(cityCodes);
        setDropdownData(res?.categories);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const subCategory: any = dropdownData.find((el: any) =>
      selectedCategory.includes(el?.value)
    );
    setSubCategoryOptions(
      subCategory?.sub_categories?.map((el) => el.value).sort(compareFunction)
    );
  }, [selectedCategory, dropdownData]);

  useEffect(() => {
    setFormError(false);
  }, [selectedCategory, selectedSubCategory, selectedCityCode, eventName]);

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectedCategory.length < 1 || eventName === "") {
      setFormError(true);
      setError({
        category: selectedCategory.length < 1,
        subCategory: selectedSubCategory.length < 1,
        countryCode: selectedCountryCode.length < 1,
        cityCode: selectedCityCode.length < 1,
        eventName: eventName === "",
      });
      setLoading(false);
      return;
    }
    const data = {
      start_date: date,
      end_date: moment(endDate).format("YYYY-MM-DD"),
      name: eventName,
      category: selectedCategory.join(),
      ...(selectedSubCategory.length > 0 && {
        sub_category: selectedSubCategory.join(),
      }),
      ...(selectedCityCode.length > 0 && {
        city_code: selectedCityCode.join(),
      }),
      ...(selectedCountryCode.length > 0 && {
        country_code: selectedCountryCode.join(),
      }),
      ...(edit && { id }),
    };
    if (!loading) {
      if (edit) {
        if (!id) {
          Notification({
            type: "error",
            message: "Please select an event to organize!",
          });
          return;
        }
        UpdateEventClient.fetchUpdateEvent(data)
          .then((res: any) => {
            Notification({ type: "success", message: "Success" });
          })
          .catch((err) => {
            Notification({ type: "error", message: "Error" });
          })
          .finally(() => setLoading(false));
      } else {
        CreateEventClient.fetchCreateEvent(data)
          .then((res: any) => {
            Notification({ type: "success", message: "Success" });
          })
          .catch((err) => {
            console.log(err);
            Notification({ type: "error", message: "Error" });
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const handleDelete = (id) => {
    DeleteEventClient.fetchDeleteEvent({ id })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const handleRowClick = (row: any) => {
    setId(row?.id ?? null);
    setSelectedCategory([row?.category]);
    setSelectedSubCategory(row?.sub_category ? [row?.sub_category] : []);
    setSelectedCountryCode(row?.country_code ? [row?.country_code] : []);
    setSelectedCityCode(row?.city_code ? [row?.city_code] : []);
    setEventName(row?.event_name ?? "");
    setStartDate(new Date(row?.start_date));
    setEndDate(new Date(row?.end_date));
  };
  return (
    <div>
      <Card variant="secondary">
        {(edit || isDelete) && (
          <div className={styles.table_wrapper}>
            <CustomAtarevTable
              data={tableData.data}
              columns={tableData.columns}
              onRowClick={handleRowClick}
              pagination
              loader={dataLoader}
            />
          </div>
        )}
        {!isDelete && (
          <form className={styles.container} onSubmit={handleSave}>
            <div>
              <span>Event Category*</span>
              <Dropdown
                data={categoryOptions}
                added={selectedCategory}
                setAdded={(item) => setSelectedCategory(item)}
                singleSelected
                required={formError && error.category}
              />
            </div>
            <div>
              <span>Event Sub-type</span>
              <Dropdown
                data={subCategoryOptions}
                added={selectedSubCategory}
                setAdded={(item) => setSelectedSubCategory(item)}
                disabled={selectedCategory.length === 0}
                singleSelected
                required={formError && error.subCategory}
              />
            </div>
            <div>
              <span>Country Code</span>
              <Dropdown
                data={countryCode}
                added={selectedCountryCode}
                setAdded={(item) => {
                  setSelectedCountryCode(item);
                }}
                required={formError && error.countryCode}
                disabled={selectedCityCode.length > 0}
              />
            </div>
            <div>
              <span>City Code</span>
              <Dropdown
                data={cityCode}
                added={selectedCityCode}
                setAdded={(item) => {
                  setSelectedCityCode(item);
                }}
                required={formError && error.cityCode}
                disabled={selectedCountryCode.length > 0}
              />
            </div>
            <div>
              <span>Event Name*</span>
              <Input
                placeholder="Event Name*"
                required
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className={styles.datepicker_wrapper}>
              <Card variant="primary" width={"50%"}>
                <p>Start Date</p>
                {!edit ? (
                  <DatePickerComponent
                    value={new Date(date)}
                    minDate={new Date(date)}
                    maxDate={new Date(date)}
                  />
                ) : (
                  <DatePickerComponent
                    value={startDate}
                    onChangeDate={(date) => {
                      setStartDate(date);
                    }}
                  />
                )}
              </Card>
              <Card variant="primary" width={"50%"}>
                <p>End Date</p>
                <DatePickerComponent
                  value={endDate}
                  onChangeDate={(date) => {
                    setEndDate(date);
                  }}
                  minDate={new Date(date)}
                />
              </Card>
            </div>
            <div className={styles.button_wrapper}>
              <button
                className={styles.save_button}
                data-active={
                  selectedCategory.length > 0 && eventName.length > 0
                }
              >
                {loading ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        )}
      </Card>
    </div>
  );
};

export default EventForm;
