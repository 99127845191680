import { createTheme } from "@mui/material/styles";

function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}

const MuiTheme = createTheme({
  palette: {
    // Renk paletini burada özelleştirin
  },
  // Diğer tema ayarlarını buraya ekleyin
  components: {
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "var(--material-theme)",
          color: "var(--mui-text-color)",
        },
      },
    },
  },
});

export default MuiTheme;
