import { RestClient } from "lib/api/RestClient";

interface ICustomerMarketDetail {
  customer: string;
  orig: string;
  dest: string;
}
export class CustomerMarketDetailClient {
  private static _client: RestClient;

  static fetchCustomerMarketDetail({
    customer,
    orig,
    dest,
  }: ICustomerMarketDetail) {
    return CustomerMarketDetailClient.getClient().getCall(
      `/api/msdv2/admin/filter-market?customer=${customer}&orig=${orig}&dest=${dest}`
    );
  }

  static getClient() {
    if (CustomerMarketDetailClient._client == null) {
      CustomerMarketDetailClient._client = new RestClient();
    }
    return CustomerMarketDetailClient._client;
  }
}
