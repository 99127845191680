import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IStrategyActionsRecommendations {
  filterList: IFilterContext;
  recommendation_text: string;
}

export class StrategyActionsRecommendationsClient {
  private static _client: RestClient;

  static fetchStrategyActionsRecommendations({
    filterList,
    recommendation_text,
  }: IStrategyActionsRecommendations) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return StrategyActionsRecommendationsClient.getClient().getCall(
      `/api/msdv1/strategyActions_recommendations?${qp}&recommendation_text=${recommendation_text}`
    );
  }

  static getClient() {
    if (StrategyActionsRecommendationsClient._client == null) {
      StrategyActionsRecommendationsClient._client = new RestClient();
    }
    return StrategyActionsRecommendationsClient._client;
  }
}
