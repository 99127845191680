import { Popover } from "@mui/material";
import MultiSort from "../multiSort";
import { HiDotsVertical } from "react-icons/hi";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";
import { FaSortAmountDown } from "react-icons/fa";
import MenuComponent from "components/common/Menu";
import { useRef, useState } from "react";
import { TableColumn } from "../type";
import { useDrag, useDrop } from "react-dnd";
import styles from "./styles.module.css";

const DraggableHeader: React.FC<{
  allColumns: TableColumn[];
  column: TableColumn;
  index: number;

  sortKey: string | null;
  sortOrder: "asc" | "desc" | null;
  sortModel: {
    sortKey: string;
    sortOrder: "asc" | "desc";
  }[];
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  onSort: (key: string) => void;
  onMultiSort: (
    key: {
      sortKey: string;
      sortOrder: "asc" | "desc";
    }[]
  ) => void;
  setSortModel: React.Dispatch<
    React.SetStateAction<
      {
        sortKey: string;
        sortOrder: "asc" | "desc";
      }[]
    >
  >;
}> = ({
  allColumns,
  column,
  index,
  sortKey,
  sortOrder,
  sortModel,
  moveColumn,
  onSort,
  onMultiSort,
  setSortModel,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuRef: any = useRef(null);

  const [, ref] = useDrag({
    type: "COLUMN",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "COLUMN",
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  const handleSort = () => {
    if (column.sortable) {
      onSort(column.key);
      setSortModel([]);
    }
  };

  const handleClick = () => {
    let element: any = document.getElementById(column.key);
    setAnchorEl(element);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <th
      ref={(node) => ref(drop(node))}
      className={styles.tableHeaderCell}
      style={{ width: column.width }}
      id={column.key}
    >
      {column.title}
      {column.sortable && (
        <>
          {sortKey === column.key ? (
            sortOrder === "asc" ? (
              <FaSortUp
                onClick={handleSort}
                className={styles.headerCellIcons}
              />
            ) : (
              <FaSortDown
                onClick={handleSort}
                className={styles.headerCellIcons}
              />
            )
          ) : (
            <FaSort onClick={handleSort} className={styles.headerCellIcons} />
          )}
        </>
      )}
      <>
        <MenuComponent
          options={[
            {
              title: (
                <>
                  <FaSortAmountDown />
                  <span style={{ marginLeft: 12 }}>Multi Sort</span>
                </>
              ),
              handleClick: () => {
                handleClick();
                if (
                  sortModel.length < 1 ||
                  sortModel.every((sm) => sm.sortKey !== column.key)
                ) {
                  setSortModel((prevState) => [
                    ...prevState,
                    { sortKey: column.key, sortOrder: "asc" },
                  ]);
                }
              },
            },
            // {
            //   title: (
            //     <>
            //       <FaFilter />
            //       <span style={{ marginLeft: 12 }}>Export Excell</span>
            //     </>
            //   ),
            //   handleClick: () => "", // Multi filter menüsünü açacak fonksiyon eklenecek
            // },
          ]}
        >
          <HiDotsVertical className={styles.headerCellIcons} />
        </MenuComponent>
        <div>
          <Popover
            id={open ? "simple-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ borderRadius: 10 }}
          >
            <MultiSort
              ref={menuRef}
              column={column}
              allColumns={allColumns}
              sortModel={sortModel}
              setSortModel={setSortModel}
              handleMultiSort={() => onMultiSort(sortModel)}
              handleClose={handleClose}
            />
          </Popover>
        </div>
      </>
    </th>
  );
};

export default DraggableHeader;
