import { RestClient } from "lib/api/RestClient";
// import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";

export class RulesGetSimpleClient {
  private static _client: RestClient;

  static fetchRulesGetSimple() {
    return RulesGetSimpleClient.getClient().getCall(`/api/lfa/rules/simplev2`);
  }

  static getClient() {
    if (RulesGetSimpleClient._client == null) {
      RulesGetSimpleClient._client = new RestClient();
    }
    return RulesGetSimpleClient._client;
  }
}
