import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IProductOverviewProductMatrix {
  filterList: IFilterContext;
  selected_yearmonth: string;
}
export class ProductOverviewProductMatrixClient {
  private static _client: RestClient;
  static fetchProductOverviewProductMatrix({
    filterList,
    selected_yearmonth,
  }: IProductOverviewProductMatrix) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return ProductOverviewProductMatrixClient.getClient().getCall(
      `/api/msdv2/product-overview/product-matrix?${qp}&selected_yearmonth=${selected_yearmonth}`
    );
  }
  static getClient() {
    if (ProductOverviewProductMatrixClient._client == null) {
      ProductOverviewProductMatrixClient._client = new RestClient();
    }
    return ProductOverviewProductMatrixClient._client;
  }
}
