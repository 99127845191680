import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";

type Anchor = "top" | "left" | "bottom" | "right";

interface ISideDrawer {
  children: React.ReactNode;
  MenuButton: JSX.Element;
  direction?: Anchor;
  width?: string | number;
  shutdownTrigger?: () => void;
}

const SideDrawer = React.forwardRef(
  (
    {
      children,
      MenuButton,
      direction = "left",
      width = 280,
      shutdownTrigger,
    }: ISideDrawer,
    ref: React.Ref<{ closeDrawer: () => void }>
  ) => {
    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => {
      if (state[anchor] !== open) {
        setState({ ...state, [anchor]: open });
        if (shutdownTrigger && !open) {
          shutdownTrigger();
        }
      }
    };

    React.useImperativeHandle(ref, () => ({
      closeDrawer() {
        toggleDrawer(direction, false);
      },
    }));

    const list = (anchor: Anchor) => (
      <Box
        className={styles.container}
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : width,
          color: "inherit",
        }}
        role="presentation"
      >
        {children}
      </Box>
    );

    return (
      <div>
        <React.Fragment>
          <Button
            onClick={() => toggleDrawer(direction, true)}
            style={{ color: "#fff", padding: 0 }}
          >
            {MenuButton}
          </Button>
          <SwipeableDrawer
            anchor={direction}
            open={state[direction]}
            onClose={() => toggleDrawer(direction, false)}
            onOpen={() => toggleDrawer(direction, true)}
            sx={{
              ".MuiDrawer-paper": {
                bgcolor: "transparent",
              },
            }}
          >
            {list(direction)}
          </SwipeableDrawer>
        </React.Fragment>
      </div>
    );
  }
);

export default SideDrawer;
