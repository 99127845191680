/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "../../common/Card/Card";
import styles from "./styles.module.css";
import { FilterContext } from "context-api/FilterContext";
import { AgencyAnalysisAgencyTableClient } from "lib/api/msd/agencyAnalysisAgencyTable/AgencyAnalysisAgencyTable";
import { AgencyAnalysisAgencyGraphsClient } from "lib/api/msd/agencyAnalysisAgencyGraphs/AgencyAnalysisAgencyGraphs";
import RangeSlider from "../../common/RangeSlider/RangeSlider";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import images from "constans/images";
import moment from "moment";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import SelectMenu from "components/common/SelectMenu";
import { IOption } from "types/global";
import Table from "components/common/Table/newTable";

interface IAgencyPerformanceTrends {
  filterIsOpen: boolean;
  setGraphData: Dispatch<SetStateAction<{}>>;
  setSelectedYearMonth: Dispatch<SetStateAction<string>>;
  yearMonthly: boolean;
  forward: boolean;
  setForward: Dispatch<SetStateAction<boolean>>;
  historical: boolean;
  setHistorical: Dispatch<SetStateAction<boolean>>;
  setStoryText: Dispatch<
    SetStateAction<{
      bookings: {
        content: string;
        title: string;
      };
      class_rbd: {
        content: string;
        title: string;
      };
      days_to_departure: {
        content: string;
        title: string;
      };
      dow_revenue: {
        content: string;
        title: string;
      };
      fare_revenue: {
        content: string;
        title: string;
      };
      main_card: {
        content: string;
        title: string;
      };
      passenger_volume: {
        content: string;
        title: string;
      };
      ticket_type: {
        content: string;
        title: string;
      };
    }>
  >;
}
interface IRangeSlider {
  value: number | null;
  min: number;
  max: number;
  dateList: string[];
}
export default function AgencyPerformanceTrends({
  filterIsOpen,
  setGraphData,
  setSelectedYearMonth,
  yearMonthly,
  forward,
  setForward,
  historical,
  setStoryText,
}: IAgencyPerformanceTrends) {
  const [shareVolume, setShareVolume] = useState(false);
  const [rangeValue, setRangeValue] = useState<IRangeSlider>({
    value: null,
    min: 0,
    max: 11,
    dateList: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
  });
  const { theme } = useContext(ThemeContext);
  // const [storyTextPerformanceTrends, setStoryTextPerformanceTrends] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption>();

  const [tableData, setTableData] = useState<{ data: any[]; columns: any[] }>({
    data: [],
    columns: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);
  const [meta, setMeta] = useState<{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
  const [filterText, setFilterText] = useState("");
  const [search, setSearch] = useState("");
  const [selectedAgency, setSelectedAgency] = useState<any[]>([]);
  const [defaultSelectedRows, setDefaultSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { filterList } = useContext(FilterContext);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "yearly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const values = response?.values?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setOptions(values);
        setSelectedOption({
          label: moment(response.default_date).format("YYYY"),
          value: moment(response.default_date).format("YYYY"),
          disabled: false,
        });
        setRangeValue((prevState) => ({
          ...prevState,
          value: Number(moment(response.default_date).format("M")) - 1,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getViewButtonValue = () => {
    if (forward && historical) {
      return "historical-forward";
    } else if (forward && historical === false) {
      return "forward";
    } else if (forward === false && historical) {
      return "historical";
    } else {
      setForward(true);
      return "forward";
    }
  };

  useEffect(() => {
    if (
      !filterIsOpen &&
      selectedAgency.length > 0 &&
      rangeValue.value !== null
    ) {
      AgencyAnalysisAgencyGraphsClient.fetchAgencyAnalysisAgencyGraphs({
        filterList,
        selected_agency: selectedAgency?.map((el: any) => el?.agency_id).join(),
        comp_type: yearMonthly ? "month" : "year",
        time_direction: getViewButtonValue(),
        selected_yearmonth: yearMonthly
          ? `${selectedOption?.value}-${rangeValue.dateList[rangeValue.value]}`
          : selectedOption?.value ?? "",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setGraphData(response);
          setStoryText(response?.story_text);
        })
        .catch(() => {
          setGraphData({});
        });
    } else if (selectedAgency.length === 0) {
      setGraphData({});
    }
    if (rangeValue.value !== null) {
      setSelectedYearMonth(
        `${selectedOption?.value}-${rangeValue.dateList[rangeValue.value]}`
      );
    }
  }, [selectedAgency, selectedOption, historical, forward, theme]);

  useEffect(() => {
    if (!filterIsOpen && selectedOption && rangeValue.value !== null) {
      setLoading(true);
      const params = {
        comp_type: yearMonthly ? "month" : "year",
        time_direction: getViewButtonValue(),
        agg_type: shareVolume ? "share" : "volume",
        selected_yearmonth: !yearMonthly
          ? selectedOption?.value ?? ""
          : `${selectedOption?.value}-${rangeValue.dateList[rangeValue.value]}`,
        page_: paginationModel.page,
        limit_: paginationModel.pageSize,
        ...(sortModel &&
          sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
        ...(filterText && { search_: filterText }),
      };
      AgencyAnalysisAgencyTableClient.fetchAgencyAnalysisAgencyTableClient({
        params,
        filterList,
      })
        .then((response: any) => {
          const newData: any[] = response?.data.map((item: any) => ({
            ...item,
            id: item?.agency_id,
          }));
          setGraphData([]);
          setTableData({
            data: newData,
            columns: getColumns(response.labels),
          });
          setMeta(response?.meta);
          setDefaultSelectedRows([newData[0]]);
        })
        .catch(() => {
          setTableData({ data: [], columns: [] });
        })
        .finally(() => setLoading(false));
    }
  }, [
    filterIsOpen,
    yearMonthly,
    historical,
    forward,
    shareVolume,
    selectedOption,
    rangeValue.value,
    paginationModel,
    sortModel,
    filterText,
  ]);

  const getColumns = (labels) => {
    const array: {
      title: string;
      key: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
      sortable?: boolean;
    }[] = [];
    labels?.forEach((element) => {
      for (const [key, title] of Object.entries(element)) {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return <span className={"rdb-custom-cell"}>{row[key]}</span>;
          },
          sortable: true,
          width: 200,
        };
        array.push(obj);
      }
    });
    return array;
  };
  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleSearchFilter, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchFilter = () => {
    setFilterText(search);
  };
  return (
    <Fragment>
      <Card variant="secondary" height={800} zIndex={2}>
        <div className={styles.container}>
          <div className={styles.card_header}>
            <p className={styles.card_title} data-theme={theme}>
              Agency Performance Trends
              {/* <CardInfo
                title={storyTextPerformanceTrends?.main_card?.title ?? null}
                description={
                  storyTextPerformanceTrends?.main_card?.content ?? null
                }
              /> */}
            </p>
            <div className={styles.buttons_wrapper}>
              <div className={styles.search_button} data-theme={theme}>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search by Name"
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img
                  src={images.search}
                  alt=""
                  className={styles.search_icon}
                />
              </div>
              <div className={styles.share_volume_buttons} data-theme={theme}>
                <span
                  className={styles.button}
                  data-active={shareVolume}
                  onClick={() => {
                    setShareVolume(!shareVolume);
                  }}
                >
                  Share
                </span>
                <span
                  className={styles.button}
                  data-active={!shareVolume}
                  onClick={() => {
                    setShareVolume(!shareVolume);
                  }}
                >
                  Volume
                </span>
              </div>
            </div>
          </div>
          <div className={styles.table_wrapper}>
            <Table
              data={tableData.data}
              columns={tableData.columns}
              defaultSelectedRows={defaultSelectedRows}
              selectableRows
              pagination
              paginationModel={meta}
              defaultPageSize={10}
              loading={loading}
              serverMode="server"
              tableHeight="570px"
              onRowSelect={(newSelectedRows) => {
                console.log(newSelectedRows);
                setSelectedAgency(newSelectedRows);
              }}
              onColumnOrderChange={(newColumnOrder) => {
                console.log(
                  "Yeni Kolon Sıralaması:",
                  newColumnOrder.map((col) => col.key)
                );
              }}
              onPaginationModelChange={(newPaginationModel) => {
                // fetch data from server
                setPaginationModel(newPaginationModel);
              }}
              onSortModelChange={(newSortModel) => {
                // fetch data from server
                setSortModel(newSortModel);
              }}
              onFilterModelChange={(newFilterModel) => {
                // fetch data from server
              }}
            />
          </div>
          <div className={styles.range_slider_wrapper}>
            <div className={styles.range_slider} data-active={!yearMonthly}>
              <RangeSlider
                data={{
                  max: rangeValue.max,
                  min: rangeValue.min,
                  value: rangeValue.value ?? 0,
                  dateList: rangeValue.dateList,
                  setValue: setRangeValue,
                }}
                format={"MMM"}
              />
            </div>
            <SelectMenu
              options={options}
              value={selectedOption}
              onSelect={(value) => {
                setSelectedOption(value);
              }}
              placeholder=""
              style={{
                width: 90,
                minWidth: 90,
                borderRadius: 16,
                marginLeft: 32,
              }}
            />
          </div>
        </div>
      </Card>
      <div style={{ marginTop: 12 }}>
        {selectedAgency.length > 0 && (
          <Card variant="secondary" title={"Selected Agency"}>
            <div className={styles.selected_item_wrapper}>
              {selectedAgency?.map((el: any) => (
                <span className={styles.selected_item} data-theme={theme}>
                  {el?.agency_name}
                </span>
              ))}
            </div>
          </Card>
        )}
      </div>
    </Fragment>
  );
}
