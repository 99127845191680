import { RestClient } from "lib/api/RestClient";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";

export class RuleOptionsClient {
  private static _client: RestClient;

  static fetchRuleOptions(data) {
    const qp = QueryParameterStringBuilder.apply(data);
    return RuleOptionsClient.getClient().getCall(
      `/api/lfa/rules/rule-options?${qp}`
    );
  }

  static getClient() {
    if (RuleOptionsClient._client == null) {
      RuleOptionsClient._client = new RestClient();
    }
    return RuleOptionsClient._client;
  }
}
