import React, { forwardRef } from "react";
import { MdCancel } from "react-icons/md";
import { TableColumn } from "../type";
import styles from "./styles.module.css";

interface MultiSortProps {
  allColumns: TableColumn[];
  column: TableColumn;
  sortModel: {
    sortKey: string;
    sortOrder: "asc" | "desc";
  }[];
  setSortModel: React.Dispatch<
    React.SetStateAction<
      {
        sortKey: string;
        sortOrder: "asc" | "desc";
      }[]
    >
  >;
  handleMultiSort: () => void;
  handleClose: () => void;
}

const MultiSort = forwardRef<HTMLDivElement, MultiSortProps>((props, ref) => {
  const {
    allColumns,
    column,
    sortModel,
    setSortModel,
    handleMultiSort,
    handleClose,
    ...rest
  } = props;

  const handleSortKeyOnChange = (e, sm, i) => {
    let newSortModel = sortModel;
    newSortModel = newSortModel.map((item, index) => {
      if (item.sortKey === sm.sortKey && i === index) {
        return { ...item, sortKey: e.target.value };
      }
      return item;
    });
    setSortModel(newSortModel);
  };

  const handleSortValueOnChange = (e, sm, i) => {
    let newSortModel = sortModel;
    newSortModel = newSortModel.map((item, index) => {
      if (item.sortKey === sm.sortKey && i === index) {
        return { ...item, sortOrder: e.target.value };
      }
      return item;
    });
    setSortModel(newSortModel);
  };

  const handleRemoveSortModel = (i) => {
    const remove = (param) => {
      let newSortModel = sortModel.filter((_, index) => i !== index);
      setSortModel(newSortModel);
    };
    if (sortModel.length > 1) {
      remove(i);
    } else {
      remove(i);
      handleClose();
    }
  };

  return (
    <div className={styles.container} ref={ref} {...rest}>
      <div>
        {sortModel.map((sm, index) => {
          return (
            <div className={styles.content} key={index}>
              <MdCancel
                size={16}
                className={styles.sort_remove_icon}
                onClick={() => handleRemoveSortModel(index)}
              />
              <span>Column</span>
              <select
                className={styles.select_col}
                onChange={(e) => handleSortKeyOnChange(e, sm, index)}
                value={sm.sortKey}
              >
                {allColumns.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.title}
                  </option>
                ))}
              </select>
              <span>Value</span>
              <select
                value={sm.sortOrder}
                onChange={(e) => handleSortValueOnChange(e, sm, index)}
              >
                <option value="asc">ASC</option>
                <option value="desc">DESC</option>
              </select>
            </div>
          );
        })}
      </div>
      <div className={styles.buttons_wrapper}>
        <button
          style={{ marginTop: 8 }}
          onClick={() => {
            // Çoklu sıralama eklerken, eklenmiş kolonları kontrol eder ve eklenmemiş olan kolonu sıralamaya ekler.
            let nextSortItem = allColumns.find((col) =>
              sortModel.every((model) => col.key !== model.sortKey)
            );
            if (nextSortItem) {
              setSortModel([
                ...sortModel,
                { sortKey: nextSortItem.key, sortOrder: "asc" },
              ]);
            }
          }}
        >
          Add Sort
        </button>
        <button style={{ marginTop: 8 }} onClick={handleMultiSort}>
          Apply
        </button>
      </div>
    </div>
  );
});

export default MultiSort;
