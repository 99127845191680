import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};
interface ILeftMenuContext {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const contextDefaultValues: ILeftMenuContext = {
  isOpen: false,
  setIsOpen: () => {},
};
const LeftMenuContext = React.createContext<ILeftMenuContext>(
  contextDefaultValues
);
const LeftMenuConsumer = LeftMenuContext.Consumer;

const LeftMenuContextApp = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LeftMenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </LeftMenuContext.Provider>
  );
};

export { LeftMenuContext, LeftMenuConsumer, LeftMenuContextApp };
