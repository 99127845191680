import { RestClient } from "lib/api/RestClient";

export class UpdateRule {
  private static _client: RestClient;
  static fetchUpdateRule(data, id) {
    return UpdateRule.getClient().putCall(
      `/api/lfa/rules/simplev2/${id}`,
      data
    );
  }

  static getClient() {
    if (UpdateRule._client == null) {
      UpdateRule._client = new RestClient();
    }
    return UpdateRule._client;
  }
}
