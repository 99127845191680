import styles from "./LandingPage.module.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function LandingPage() {
  useEffect(() => {
    window.location.href = "/home"; // redirect to product overview
  });
  return (
    <div className={styles.main}>
      <Link to="/lfa">
        <img alt="Atarev" src={"/images/main_page_lead.png"} />
      </Link>
    </div>
  );
}
