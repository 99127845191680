import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface ICustomerSegmentationGraphs {
  filterList: IFilterContext;
  dark_theme: boolean;
  field: string;
}

export class CustomerSegmentationGraphsClient {
  private static _client: RestClient;

  static fetchCustomerSegmentationGraphs({
    filterList,
    dark_theme,
    field,
  }: ICustomerSegmentationGraphs) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return CustomerSegmentationGraphsClient.getClient().getCall(
      `/api/msdv2/customer-segmentation/segmentation-graphs?${qp}&dark_theme=${dark_theme}&field=${field}`
    );
  }

  static getClient() {
    if (CustomerSegmentationGraphsClient._client == null) {
      CustomerSegmentationGraphsClient._client = new RestClient();
    }
    return CustomerSegmentationGraphsClient._client;
  }
}
