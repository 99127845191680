import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";

interface IPassengerVolume {
  data: {
    data: never[];
    layout: never[];
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function PassengerVolume({ data, storyText }: IPassengerVolume) {
  return (
    <Card
      variant="secondary"
      title="Passenger Volume"
      width={"40%"}
      // cardInfo={{
      //   title: storyText?.passenger_volume?.title ?? null,
      //   description: storyText?.passenger_volume?.content ?? null,
      // }}
    >
      <Card variant="primary" height={364}>
        <Plotly
          data={data?.data}
          height={348}
          layout={data?.layout}
          margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
        />
      </Card>
    </Card>
  );
}
