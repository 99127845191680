interface IColumnControl {
  value: any;
  isView: boolean;
}

export default function ColumnControl({ value, isView }: IColumnControl) {
  if (isView) {
    if (typeof value === "object") {
      return value.displayVal;
    }
    return value;
  } else {
    if (typeof value === "object") {
      return value.val;
    }
    return value;
  }
}
