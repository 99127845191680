import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./styles.module.css";
import Notification from "components/common/Notification/Notification";
import Confirmation from "components/common/ConfirmationModal/ConfirmationModal";
import { ThemeContext } from "context-api/ThemeContext";
import { ScraperMarketDeleteClient } from "lib/api/admin-panel/admin/scraper-configuration/scraper-market-delete";
import { ThemeProvider, createTheme } from "@mui/material";
function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}
const fullscreenTheme = createTheme({
  components: {
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
    },
  },
});
const ITEM_HEIGHT = 48;

export default function OpenMenu({ editMarket, row, customer, tableUpdate }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = React.useState(false);
  const { theme } = React.useContext(ThemeContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteMarket = () => {
    ScraperMarketDeleteClient.fetchFilterMarketDelete({
      customer,
      orig: row?.orig,
      dest: row?.dest,
    })
      .then(() => {
        handleClose();
        tableUpdate();
        Notification({ type: "success", message: "Market Deleted" });
      })
      .catch((err) =>
        Notification({
          type: "error",
          message: `${err?.originalError?.response?.data?.message ?? "Error"}`,
        })
      );
  };

  return (
    <ThemeProvider theme={fullscreenTheme}>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: theme === "dark" ? "#fff" : "#000" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "12ch",
              backgroundColor: theme === "dark" ? "#383051" : "#fff",
              color: theme === "dark" ? "#fff" : "#000",
              borderRadius: 12,
            },
          }}
        >
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={() => {
              editMarket(row, "duplicate");
              handleClose();
            }}
          >
            Duplicate
          </MenuItem>
          <Confirmation
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            event={deleteMarket}
            title={"Market Deletion"}
            description={"Are you sure you want to delete the market?"}
          >
            <MenuItem className={styles.menu_item} data-theme={theme}>
              Delete
            </MenuItem>
          </Confirmation>
        </Menu>
      </div>
    </ThemeProvider>
  );
}
