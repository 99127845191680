import { RestClient } from "lib/api/RestClient";

export class AddNewRule {
  private static _client: RestClient;
  static fetchAddNewRule(data) {
    return AddNewRule.getClient().postCall(`/api/lfa/rules/simplev2`, data);
  }

  static getClient() {
    if (AddNewRule._client == null) {
      AddNewRule._client = new RestClient();
    }
    return AddNewRule._client;
  }
}
