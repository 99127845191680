import { RestClient } from "../RestClient";

export class DemandClient {
  private static _client: RestClient;

  static fetchDemand({ params }) {
    return DemandClient.getClient().getCall(
      `/api/msdv2/events/demand?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (DemandClient._client == null) {
      DemandClient._client = new RestClient();
    }
    return DemandClient._client;
  }
}
