import MiniCard from "components/common/MiniCard/MiniCard";
import styles from "./styles.module.css";
import images from "constans/images";

import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useState } from "react";

const MiniKpi = () => {
  return (
    <div className={styles.container}>
      {/* <MiniCard title="Musab Kara" total="test" icon={images.a_to_b} />
      <MiniCard title="Burak KEPEK" total="test" icon={images.a_to_b} /> */}

      <SortableComponent />
    </div>
  );
};

export default MiniKpi;
const SortableList = SortableContainer(({ items }) => {
  return (
    <div
      style={{ display: "flex", gap: 8, overflowX: "auto", padding: "12px 0" }}
    >
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(({ value }) => {
  return (
    <div style={{ cursor: "grab" }}>
      <MiniCard
        title={value?.title}
        total={value?.value}
        icon={value?.icon}
        iconBackgroundColor={value?.iconBackgroundColor}
        borderHoverColor={value?.borderHoverColor}
      />
    </div>
  );
});

const SortableComponent = () => {
  const [state, setState] = useState({
    items: [
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi,
        iconBackgroundColor: "#259eff20",
        borderHoverColor: "#259eff",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_purple,
        iconBackgroundColor: "#5504d920",
        borderHoverColor: "#5504d9",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_navy,
        iconBackgroundColor: "#144aff20",
        borderHoverColor: "#144aff",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_yellow,
        iconBackgroundColor: "#da770620",
        borderHoverColor: "#da7706",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_yellow,
        iconBackgroundColor: "#da770620",
        borderHoverColor: "#da7706",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_navy,
        iconBackgroundColor: "#144aff20",
        borderHoverColor: "#144aff",
      },
    ],
    // items: [
    //   {
    //     title: "XX Market Share",
    //     value: "48%",
    //     icon: images.atom_icon_kpi,
    //     iconBackgroundColor: "#259eff20",
    //     borderHoverColor: "#259eff",
    //   },
    //   {
    //     title: "YY Market Share",
    //     value: "22%",
    //     icon: images.atom_icon_kpi_magenta,
    //     iconBackgroundColor: "#9c00ed20",
    //     borderHoverColor: "#9c00ed",
    //   },
    //   {
    //     title: "XX Cur Avg Fare",
    //     value: "$ 148",
    //     icon: images.atom_icon_kpi_purple,
    //     iconBackgroundColor: "#5504d920",
    //     borderHoverColor: "#5504d9",
    //   },
    //   {
    //     title: "XX Targ Avg Fare",
    //     value: "$ 174",
    //     icon: images.atom_icon_kpi_navy,
    //     iconBackgroundColor: "#144aff20",
    //     borderHoverColor: "#144aff",
    //   },
    //   {
    //     title: "YY Avg Fare",
    //     value: "$ 452",
    //     icon: images.atom_icon_kpi_yellow,
    //     iconBackgroundColor: "#da770620",
    //     borderHoverColor: "#da7706",
    //   },
    //   {
    //     title: "XX Cur Pax",
    //     value: "1.593",
    //     icon: images.atom_icon_kpi_magenta,
    //     iconBackgroundColor: "#9c00ed20",
    //     borderHoverColor: "#9c00ed",
    //   },
    //   {
    //     title: "XX Targ Pax",
    //     value: "3.176",
    //     icon: images.atom_icon_kpi_magenta,
    //     iconBackgroundColor: "#9c00ed20",
    //     borderHoverColor: "#9c00ed",
    //   },
    //   {
    //     title: "XX Cur Revenue",
    //     value: "$ 236K",
    //     icon: images.atom_icon_kpi_yellow,
    //     iconBackgroundColor: "#da770620",
    //     borderHoverColor: "#da7706",
    //   },
    //   {
    //     title: "XX Bud Revenue",
    //     value: "$ 553K",
    //     icon: images.atom_icon_kpi_navy,
    //     iconBackgroundColor: "#144aff20",
    //     borderHoverColor: "#144aff",
    //   },
    // ],
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  return (
    <SortableList
      helperClass={"SortableHelperWithOverride"}
      items={state.items}
      onSortEnd={onSortEnd}
      axis="xy"
      distance={1}
    />
  );
};
