/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Replay } from "@mui/icons-material";
import Button from "components/common/Button/Button";
import Card from "components/common/Card/Card";
import DatePicker2 from "components/common/DatePicker/DatePicker2";
import Dropdown from "components/common/Dropdown/Dropdown";
import DropdownCityAirport from "components/common/Dropdown/DropdownCityAirport";
import DropdownText from "components/common/Dropdown/DropdownText";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import moment from "moment";
import { Dispatch, SetStateAction, useContext } from "react";
import { IOptions } from "types/rule-options";
import ColoredIcon from "utils/ColoredIcon";
import styles from "./styles.module.css";
import { Tooltip } from "components/common/Tooltip";

interface IRulesConditions {
  data: {
    region: string;
    origin: string[];
    destination: string[];
    flight_number: string[];
    effective_time: string[];
    departure_date: string[];
    cabin: string;
    equipment: string[] | never[];
    competitor: string;
    competitor_range: number[];
    dtd: number[];
    dow: never[];
  };
  setData: Dispatch<
    SetStateAction<{
      region: string;
      origin: string[];
      destination: string[];
      flight_number: string[];
      effective_time: string[];
      departure_date: string[];
      cabin: string;
      equipment: string[] | never[];
      competitor: string;
      competitor_range: number[];
      dtd: number[];
      dow: never[];
    }>
  >;
  formError: boolean;
  options: IOptions | null;
  timeRange: {
    value: number[];
    min: number;
    max: number;
    dateList: string[];
  };
  setTimeRange: Dispatch<
    SetStateAction<{
      value: number[];
      min: number;
      max: number;
      dateList: string[];
    }>
  >;
  flightNumberOptions: string[];
  setFlightNumberSearch: Dispatch<SetStateAction<string>>;
  setOrigCityAirportLookup: Dispatch<SetStateAction<string>>;
  setDestCityAirportLookup: Dispatch<SetStateAction<string>>;
}
export default function RulesConditions({
  data,
  setData,
  formError,
  options,
  timeRange,
  setTimeRange,
  flightNumberOptions,
  setFlightNumberSearch,
  setOrigCityAirportLookup,
  setDestCityAirportLookup,
}: IRulesConditions) {
  const negativeOptions = [
    "0 Hour",
    "-1 Hour",
    "-2 Hours",
    "-3 Hours",
    "-4 Hours",
    "-5 Hours",
    "-6 Hours",
    "-7 Hours",
    "-8 Hours",
    "-9 Hours",
    "-10 Hours",
    "-11 Hours",
    "-12 Hours",
    "-13 Hours",
    "-14 Hours",
    "-15 Hours",
    "-16 Hours",
    "-17 Hours",
    "-18 Hours",
    "-19 Hours",
    "-20 Hours",
    "-21 Hours",
    "-22 Hours",
    "-23 Hours",
    "-24 Hours",
  ];
  const positiveOptions = [
    "0 Hour",
    "1 Hour",
    "2 Hours",
    "3 Hours",
    "4 Hours",
    "5 Hours",
    "6 Hours",
    "7 Hours",
    "8 Hours",
    "9 Hours",
    "10 Hours",
    "11 Hours",
    "12 Hours",
    "13 Hours",
    "14 Hours",
    "15 Hours",
    "16 Hours",
    "17 Hours",
    "18 Hours",
    "19 Hours",
    "20 Hours",
    "21 Hours",
    "22 Hours",
    "23 Hours",
    "24 Hours",
  ];
  const [isForever, setIsForever] = useState(false);
  const { theme } = useContext(ThemeContext);

  const parsedArrayToString = (item: number[]) => {
    const strArr = item?.map(String) ?? [];
    return strArr;
  };
  const parsedArrayToNumber = (item) => {
    const numberArr = item?.map(Number) ?? [];
    return numberArr;
  };
  useEffect(() => {
    if (data?.departure_date.length === 1) setIsForever(true);
    else if (data?.departure_date.length === 2) setIsForever(false);
  }, [data]);

  return (
    <div className={styles.container}>
      <Card variant="secondary" title="Rules Conditions">
        <div className={styles.card_body}>
          <div className={styles.in_card_wrapper}>
            <Card variant="primary" zIndex={3}>
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    Region
                  </span>
                  <ColoredIcon
                    imgPath={images.navigation_maps}
                    backgroundColor={"#144aff"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={options?.regions?.map((el) => el.value) ?? []}
                    added={data?.region === "" ? [] : [data?.region]}
                    setAdded={(item) => {
                      setData((state) => ({
                        ...state,
                        region: item[0] ?? "",
                      }));
                    }}
                    singleSelected
                    disabled={data?.origin.length > 0}
                  />
                </div>
              </div>
            </Card>
            <Card variant="primary" zIndex={2}>
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    Competitor
                  </span>
                  <ColoredIcon
                    imgPath={images.clock_watch}
                    backgroundColor={"#d77cbb"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={options?.compatitors?.map((el) => el.value) ?? []}
                    added={data?.competitor === "" ? [] : [data?.competitor]}
                    setAdded={(item) => {
                      setData((state) => ({
                        ...state,
                        competitor: item[0] ?? "",
                      }));
                    }}
                    singleSelected
                    required={
                      formError
                        ? data.competitor.length === 0
                          ? true
                          : false
                        : false
                    }
                  />
                </div>
              </div>
            </Card>
            <Card variant="primary" zIndex={1}>
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    Flight Number
                  </span>
                  <ColoredIcon
                    imgPath={images.travel_plane_take_off}
                    backgroundColor={"#5504d9"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={flightNumberOptions}
                    added={data?.flight_number}
                    setAdded={(item: any) => {
                      setData((state) => ({ ...state, flight_number: item }));
                    }}
                    setSearchFilter={setFlightNumberSearch}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.in_card_wrapper}>
            <Card variant="primary" zIndex={4}>
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    City / Airport Pair
                  </span>
                  <ColoredIcon
                    imgPath={images.flag}
                    backgroundColor={"#9c00ed"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <div className={styles.dropdown_wrapper_in}>
                    <div style={{ width: 135 }}>
                      <DropdownCityAirport
                        data={options?.origins ?? []}
                        added={data?.origin}
                        setAdded={(item: any) => {
                          setData((state) => ({
                            ...state,
                            origin: [...item],
                          }));
                        }}
                        setSearchFilter={setOrigCityAirportLookup}
                        required={
                          formError
                            ? data.origin.length === 0
                              ? true
                              : false
                            : false
                        }
                      />
                    </div>
                    <div
                      onClick={() => {
                        setData((state) => ({
                          ...state,
                          origin: data?.destination ?? [],
                          destination: data?.origin ?? [],
                        }));
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={images.arrow_change}
                        alt=""
                        width={24}
                        height={24}
                        className={styles.arrow_change}
                      />
                    </div>
                    <div style={{ width: 130 }}>
                      <DropdownCityAirport
                        data={options?.destinations ?? []}
                        added={data?.destination}
                        setAdded={(item: any) => {
                          setData((state) => ({
                            ...state,
                            destination: [...item],
                          }));
                        }}
                        setSearchFilter={setDestCityAirportLookup}
                        disabled={data.origin.length === 0}
                        required={
                          formError
                            ? data.destination.length === 0
                              ? true
                              : false
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Card variant="primary" zIndex={3}>
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    Equipment
                  </span>
                  <ColoredIcon
                    imgPath={images.travel_plane_take_off}
                    backgroundColor={"#5504d9"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={
                      options?.equipments?.map((el) => String(el.value)) ?? []
                    }
                    added={
                      data?.equipment.length === 0 ? [] : [data?.equipment[0]]
                    }
                    setAdded={(item: any) => {
                      setData((state) => ({
                        ...state,
                        equipment: item?.length > 0 ? [...item] : [],
                      }));
                    }}
                    singleSelected
                  />
                </div>
              </div>
            </Card>
            <Card variant="primary">
              <div className={styles.in_card}>
                <div className={styles.header}>
                  <span className={styles.title} data-theme={theme}>
                    Days to Departure
                  </span>
                  <ColoredIcon
                    imgPath={images.travel_plane_take_off}
                    backgroundColor={"#5504d9"}
                  />
                </div>
                <div className={styles.dropdown_wrapper}>
                  <div className={styles.form_element}>
                    <input
                      className={styles.input}
                      data-theme={theme}
                      name="time-start"
                      value={data.dtd[0]}
                      onChange={(e) =>
                        setData((state) => ({
                          ...state,
                          dtd: [Number(e.target.value), data.dtd[1]],
                        }))
                      }
                    />
                    <img
                      src={images.arrow_right}
                      className={styles.arrow_right}
                      alt=""
                    />
                    <input
                      className={styles.input}
                      data-theme={theme}
                      type="text"
                      name="time-end"
                      value={data.dtd[1]}
                      onChange={(e) =>
                        setData((state) => ({
                          ...state,
                          dtd: [data.dtd[0], Number(e.target.value)],
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.datepicler_wrapper} data-theme={theme}>
            <div className={styles.in_card}>
              <div className={styles.header} style={{ marginBottom: 16 }}>
                <span className={styles.title} data-theme={theme}>
                  Departure Date
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{ marginRight: 8, cursor: "pointer" }}
                    onClick={() => {
                      setData((state) => ({ ...state, departure_date: [] }));
                    }}
                  >
                    <Tooltip title="Clear Date">
                      <Replay />
                    </Tooltip>
                  </span>
                  <ColoredIcon
                    imgPath={images.calendar}
                    backgroundColor={"#da7706"}
                  />
                </div>
              </div>
              <div className={styles.forever_wrapper} data-theme={theme}>
                <div className={styles.buttons_wrapper}>
                  <span
                    className={styles.button_forever}
                    data-active={!isForever}
                    onClick={() => {
                      setIsForever(!isForever);
                      setData((state) => ({ ...state, departure_date: [] }));
                    }}
                  >
                    Range
                  </span>
                  <span
                    className={styles.button_forever}
                    data-active={isForever}
                    onClick={() => {
                      setIsForever(!isForever);
                      setData((state) => ({ ...state, departure_date: [] }));
                    }}
                  >
                    Date <span className={styles.forever_text}>∞</span>
                  </span>
                </div>
              </div>
              <DatePicker2
                minDate={new Date()}
                isSingle={isForever}
                defaultDate={{
                  startDate:
                    data.departure_date.length > 0
                      ? new Date(
                          moment(data.departure_date[0], "YYYY-MM-DD").format()
                        )
                      : null,
                  endDate:
                    data.departure_date.length > 0
                      ? new Date(
                          moment(data.departure_date[1], "YYYY-MM-DD").format()
                        )
                      : null,
                }}
                onChange={(date) => {
                  if (isForever) {
                    if (date?.startDate) {
                      setData((state) => ({
                        ...state,
                        departure_date: [
                          moment(date?.startDate).format("YYYY-MM-DD"),
                        ],
                      }));
                    }
                  } else {
                    if (date?.startDate && date?.endDate) {
                      setData((state) => ({
                        ...state,
                        departure_date: [
                          moment(date?.startDate).format("YYYY-MM-DD"),
                          moment(date?.endDate).format("YYYY-MM-DD"),
                        ],
                      }));
                    }
                  }
                }}
              />
              <div className={styles.divider} data-theme={theme}>
                <span></span>
              </div>

              <div className={styles.header}>
                <span className={styles.title} data-theme={theme}>
                  Days of Week
                </span>
              </div>
              <div className={styles.dow_wrapper}>
                {[0, 1, 2, 3, 4, 5, 6].map((el, index) => (
                  <Button
                    key={index}
                    data={el.toString()}
                    added={parsedArrayToString(data.dow)}
                    setAdded={(item) => {
                      setData((state) => ({
                        ...state,
                        dow: parsedArrayToNumber(item),
                      }));
                    }}
                    width={68}
                    text={"center"}
                  >
                    {options?.dow[el]?.label ?? ""}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Card variant="primary" width={"calc(50% - 4px)"}>
            <div className={styles.in_card}>
              <div className={styles.header}>
                <span className={styles.title} data-theme={theme}>
                  Cabin
                </span>
                <ColoredIcon
                  imgPath={images.boarding_pass_white}
                  backgroundColor={"#ec4252"}
                />
              </div>
              <div
                className={`${styles.cabin_wrapper} ${
                  formError ? (data.cabin.length === 0 ? styles.error : "") : ""
                }`}
              >
                {options?.cabins
                  ?.map((el) => el.value)
                  ?.map((el, index) => (
                    <div key={index} className={styles.cabin}>
                      <Button
                        data={el}
                        added={data.cabin === "" ? [] : [data.cabin]}
                        setAdded={(item) => {
                          setData((state) => ({
                            ...state,
                            cabin: item[0] ?? "",
                          }));
                        }}
                        text={"center"}
                        singleSelected
                      >
                        {el}
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
          </Card>
          <Card variant="primary" width={"calc(50% - 4px)"} zIndex={2}>
            <div className={styles.in_card}>
              <div className={styles.header}>
                <span className={styles.title} data-theme={theme}>
                  Competitor Departure Time Range
                </span>
                <ColoredIcon
                  imgPath={images.calendar_checkmark}
                  backgroundColor={"#259eff"}
                />
              </div>
              <div className={styles.dropdown_wrapper}>
                <div className={styles.form_element}>
                  <DropdownText
                    disabled={false}
                    options={negativeOptions}
                    handleSelectedOption={(item) => {
                      setData((state) => ({
                        ...state,
                        competitor_range: [
                          negativeOptions.indexOf(item),
                          state.competitor_range[1],
                        ],
                      }));
                    }}
                    defaultSelection={
                      negativeOptions[Math.abs(data.competitor_range[0])]
                    }
                    border
                    height={40}
                  />
                  <img
                    src={images.arrow_right}
                    className={styles.arrow_right}
                    alt=""
                  />
                  <DropdownText
                    disabled={false}
                    options={positiveOptions}
                    handleSelectedOption={(item) => {
                      setData((state) => ({
                        ...state,
                        competitor_range: [
                          state.competitor_range[0],
                          positiveOptions.indexOf(item),
                        ],
                      }));
                    }}
                    defaultSelection={positiveOptions[data.competitor_range[1]]}
                    border
                    height={40}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div style={{ marginTop: 8 }}>
          <Card variant="primary" zIndex={1}>
            <div className={styles.in_card}>
              <div className={styles.header}>
                <span className={styles.title} data-theme={theme}>
                  Departure Time Range
                </span>
                <ColoredIcon
                  imgPath={images.clock_watch}
                  backgroundColor={"#d77cbb"}
                />
              </div>
              <div className={styles.range_slider}>
                <RangeSlider
                  disabled={false}
                  data={{
                    max: timeRange.max,
                    min: timeRange.min,
                    value: timeRange.value,
                    dateList: timeRange.dateList,
                    setValue: setTimeRange,
                  }}
                  format={"HH:mm"}
                />
              </div>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );
}
