import { RestClient } from '../RestClient';

export class CarriersClient {
  private static _client: RestClient;

  static fetchCarrioerOptions() {
    return CarriersClient.getClient().getCall(`/api/msdv1/msd_getCarriers?`);
  }

  static getClient() {
    if (CarriersClient._client == null) {
      CarriersClient._client = new RestClient();
    }
    return CarriersClient._client;
  }
}
