import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareRevenueRbdElasticities {
  filterList: IFilterContext;
  date_range_start: string;
  date_range_end: string;
  dark_theme: boolean;
}

export class FareRevenueRbdElasticitiesClient {
  private static _client: RestClient;

  static fetchFareRevenueRbdElasticities({
    filterList,
    date_range_start,
    date_range_end,
    dark_theme,
  }: IFareRevenueRbdElasticities) {
    const qp = QueryParameterStringBuilder.apply(filterList);

    return FareRevenueRbdElasticitiesClient.getClient().getCall(
      `/api/msdv2/fare-revenue/rbd-elasticities?date_range_start=${date_range_start}&date_range_end=${date_range_end}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (FareRevenueRbdElasticitiesClient._client == null) {
      FareRevenueRbdElasticitiesClient._client = new RestClient();
    }
    return FareRevenueRbdElasticitiesClient._client;
  }
}
