import { useState } from "react";
import FareStructureFiltersClosed from "./FareStructureFiltersClosed";
import FareStructureFiltersOpened from "./FareStructureFiltersOpened";

export default function FareStructureFilters() {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div>
      {isOpen ? (
        <FareStructureFiltersOpened setIsOpen={setIsOpen} />
      ) : (
        <FareStructureFiltersClosed setIsOpen={setIsOpen} />
      )}
    </div>
  );
}
