import { useState } from "react";
import RecommendationsTable from "components/lfa/RecommendationsTable";
import Layout from "components/common/Layout/Layout";

export default function BRERESULTS() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Recommendations"}
    >
      <RecommendationsTable filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
