import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IKpi {
  filterList: IFilterContext;
  kpi_type: string;
}
export class KpiClient {
  private static _client: RestClient;

  static fetchKpi({ filterList, kpi_type }: IKpi) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return KpiClient.getClient().getCall(
      `/api/msdv2/kpi?kpi_type=${kpi_type}&${qp}`
    );
  }

  static getClient() {
    if (KpiClient._client == null) {
      KpiClient._client = new RestClient();
    }
    return KpiClient._client;
  }
}
