import { useContext, useEffect, useRef, useState } from "react";
import Card from "../Card/Card";
import styles from "./Carriers.module.css";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";

interface ICarriers {
  data: string[];
  added: string[];
  setAdded: any;
  singleSelected?: boolean;
  carriersJson: any;
}

export default function Carriers({
  data,
  added,
  setAdded,
  singleSelected = false,
  carriersJson,
}: ICarriers) {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [host, setHost] = useState({
    host: "",
    name: "",
    color: "",
  });
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  const handleAdded = (item: string) => {
    if (added.length > 0) {
      const bool = added.find((str) => (str === item ? true : false));
      if (!bool) {
        if (singleSelected) {
          setAdded(() => [item]);
        } else setAdded(() => [...added, item]);
      }
    } else {
      setAdded(() => [...added, item]);
    }
  };

  const handleRemove = (item: string) => {
    if (added.length > 0) {
      const array = added.filter((str) => str !== item);
      setAdded(array);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    setHost({
      host: user ? JSON.parse(user).clientCode : "",
      name: user ? JSON.parse(user).clientCode : "",
      color: user ? JSON.parse(user).clientCodeColor : null,
    });
  }, [carriersJson]);

  const getColor = (item: string) => {
    const color = "transparent";
    if (carriersJson) {
      if (carriersJson[item] !== undefined) {
        return carriersJson[item];
      }
      return color;
    }
  };

  return (
    <Card variant="primary" width={"100%"} height={48} zIndex={2}>
      <div className={styles.container} ref={wrapperRef}>
        <img
          src={images.travel_plane_take_off}
          alt=""
          className={styles.flight_icon}
          style={{ marginLeft: 24 }}
          data-theme={theme}
        />
        <span className={styles.carriers_text} data-theme={theme}>
          Carriers:
        </span>
        <div className={styles.added_items}>
          <div className={styles.add_wrapper}>
            <div
              className={styles.add_button}
              onClick={() => setIsOpen(!isOpen)}
              data-theme={theme}
            >
              <img src={images.plus} alt="" className={styles.plus_icon} />
              <span className={styles.add_button_text} data-theme={theme}>
                Add
              </span>
            </div>
            <div
              className={styles.open_menu}
              data-theme={theme}
              data-active={isOpen}
            >
              {data?.map((item, index) => {
                return (
                  <div
                    key={`item${index}`}
                    className={styles.open_menu_items}
                    onClick={() => handleAdded(item)}
                    data-theme={theme}
                  >
                    <span
                      style={{ borderColor: getColor(item) }}
                      className={styles.border_color}
                    />
                    <span
                      className={styles.open_menu_item_text}
                      data-theme={theme}
                    >
                      {item}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className={styles.add_button} data-theme={theme}>
              <span
                style={{ borderColor: host.color }}
                className={styles.rounded_icon}
              >
                <span style={{ backgroundColor: host.color }} />
              </span>
              <span className={styles.add_item_text} data-theme={theme}>
                {host.host}
              </span>
            </div>
            {added?.map((item, index) => {
              return (
                <div
                  key={`item${index}`}
                  className={styles.add_button}
                  onClick={() => handleRemove(item)}
                  data-theme={theme}
                >
                  <span
                    style={{ borderColor: getColor(item) }}
                    className={styles.rounded_icon}
                  >
                    <span style={{ backgroundColor: getColor(item) }} />
                  </span>
                  <span className={styles.add_item_text} data-theme={theme}>
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
