import { RestClient } from "lib/api/RestClient";

interface IFilterMarketDelete {
  customer: string;
  orig: string;
  dest: string;
}
export class FilterMarketDeleteClient {
  private static _client: RestClient;

  static fetchFilterMarketDelete({
    customer,
    orig,
    dest,
  }: IFilterMarketDelete) {
    return FilterMarketDeleteClient.getClient().deleteCall(
      `/api/msdv2/admin/filter-market?customer=${customer}&orig=${orig}&dest=${dest}`
    );
  }

  static getClient() {
    if (FilterMarketDeleteClient._client == null) {
      FilterMarketDeleteClient._client = new RestClient();
    }
    return FilterMarketDeleteClient._client;
  }
}
