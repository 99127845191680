import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IProductOverviewCosBreakdown {
  filterList: IFilterContext;
  agg_view: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}

export class ProductOverviewCosBreakdownClient {
  private static _client: RestClient;

  static fetchProductOverviewCosBreakdown({
    filterList,
    agg_view,
    selected_yearmonth,
    dark_theme,
  }: IProductOverviewCosBreakdown) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return ProductOverviewCosBreakdownClient.getClient().getCall(
      `/api/msdv2/product-overview/cos-breakdown?${qp}&agg_view=${agg_view}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (ProductOverviewCosBreakdownClient._client == null) {
      ProductOverviewCosBreakdownClient._client = new RestClient();
    }
    return ProductOverviewCosBreakdownClient._client;
  }
}
