import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Button from "components/common/Button/Button";
import Card from "components/common/Card/Card";
import images from "constans/images";
import { AvaibilityTrendsFilterContext } from "context-api/AvaibilityTrendsFilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import moment from "moment";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import styles from "./AvaibilityTrendsFiltersClose.module.css";
import { Tooltip } from "components/common/Tooltip";

interface IAvaibilityTrendsFiltersClose {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPriceEvo?: boolean;
}
export default function AvaibilityTrendsFiltersClose({
  setIsOpen,
  isPriceEvo,
}: IAvaibilityTrendsFiltersClose) {
  const { avaibilityTrendsFilterList, setAvaibilityTrendsFilterList } =
    useContext(AvaibilityTrendsFilterContext);
  const { theme } = useContext(ThemeContext);
  const connetion = ["DIRECT", "1 STOP", "2 STOPS"];
  const [daysOfWeek] = useState(["M", "T", "W", "T", "F", "S", "S"]);

  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          {isPriceEvo ? "Price Evolution Filter" : "Availability Trends Filters"}
        </span>
        <div className={styles.button_wrapper}>
          {theme === "dark" && (
            <div className={styles.tick_button} onClick={() => setIsOpen(true)}>
              <img src={images.setting} alt="" />
            </div>
          )}
          {theme === "light" && (
            <img
              src={images.setting_button_light}
              alt=""
              onClick={() => setIsOpen(true)}
              style={{ width: 40, height: 40 }}
            />
          )}
        </div>
      </div>
      <div className={styles.in_card_wrapper}>
        <Card variant="primary" width={"100%"} height={164}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Travel Date Range
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{
                backgroundColor: theme === "dark" ? "#d77cbb" : "#d77cbbe6",
              }}
            >
              <img src={images.travel_earth_arrow} alt="" />
            </span>
          </div>
          <div
            className={styles.text_wrapper}
            style={{ justifyContent: isPriceEvo ? "center" : "space-between" }}
          >
            <span className={styles.left_text} data-theme={theme}>
              {isPriceEvo ? (
                <div>
                  <KeyboardArrowLeft
                    className={styles.date_button}
                    onClick={() => {
                      const today = moment().add(1, "d");
                      if (
                        !moment(
                          avaibilityTrendsFilterList.date_range_start
                        ).isBefore(today, "day")
                      ) {
                        setAvaibilityTrendsFilterList((prevState) => ({
                          ...prevState,
                          date_range_start: moment(
                            avaibilityTrendsFilterList.date_range_start
                          )
                            .add(-1, "d")
                            .format("YYYY-MM-DD"),
                        }));
                      }
                    }}
                  />
                  <span className={styles.date_text}>
                    {moment(avaibilityTrendsFilterList.date_range_start).format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <KeyboardArrowRight
                    className={styles.date_button}
                    onClick={() => {
                      setAvaibilityTrendsFilterList((prevState) => ({
                        ...prevState,
                        date_range_start: moment(
                          avaibilityTrendsFilterList.date_range_start
                        )
                          .add(1, "d")
                          .format("YYYY-MM-DD"),
                      }));
                    }}
                  />
                </div>
              ) : (
                <Tooltip
                  title={`${moment(
                    avaibilityTrendsFilterList.date_range_start
                  ).format("DD MMM YYYY")} / ${moment(
                    avaibilityTrendsFilterList.date_range_end
                  ).format("DD MMM YYYY")}`}
                >
                  <span>
                    {`${
                      moment(
                        avaibilityTrendsFilterList.date_range_start
                      ).format("DD MMM YYYY") +
                      "/ " +
                      moment(avaibilityTrendsFilterList.date_range_end).format(
                        "DD MMM YYYY"
                      )
                    }`}
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
          <hr className={styles.hr} data-theme={theme} />
        </Card>
        <Card variant="primary" width={"100%"} height={164}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Days of Week
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{
                backgroundColor: theme === "dark" ? "#144aff" : "#144affe6",
              }}
            >
              <img src={images.clock_time_timer} alt="" />
            </span>
          </div>
          <div className={styles.text_wrapper}>
            <span className={styles.days_wrapper} data-theme={theme}>
              {["0", "1", "2", "3", "4", "5", "6"].map((item) => (
                <Button
                  key={item}
                  added={avaibilityTrendsFilterList.weekdays}
                  setAdded={(item: any) => {
                    setAvaibilityTrendsFilterList((prevState) => ({
                      ...prevState,
                      weekdays: [...item],
                    }));
                  }}
                  data={item}
                  width={25}
                  height={30}
                  text={"center"}
                  fontSize={11}
                  disabled={isPriceEvo}
                >
                  {daysOfWeek[item]}
                </Button>
              ))}
            </span>
          </div>
          <hr className={styles.hr} data-theme={theme} />
          <div className={styles.text_wrapper}></div>
        </Card>
        <Card variant="primary" width={"100%"} height={164}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Connection
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{
                backgroundColor: theme === "dark" ? "#ec4252" : "#ec4252e6",
              }}
            >
              <img src={images.a_to_b} alt="" />
            </span>
          </div>
          <div className={styles.text_wrapper}>
            <Tooltip
              title={avaibilityTrendsFilterList?.connection?.map(
                (el) => `${connetion[el]}, `
              )}
            >
              <span className={styles.left_text} data-theme={theme}>
                {avaibilityTrendsFilterList?.connection?.map(
                  (el) => `${connetion[el]}, `
                )}
              </span>
            </Tooltip>
          </div>
          <hr className={styles.hr} data-theme={theme} />
          <div className={styles.text_wrapper}></div>
        </Card>
        <Card variant="primary" width={"100%"} height={164}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Departure Time Range
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{
                backgroundColor: theme === "dark" ? "#5504d9" : "#5504d9e6",
              }}
            >
              <img src={images.travel_earth_arrow} alt="" />
            </span>
          </div>
          <div className={styles.text_wrapper}>
            <Tooltip
              title={`${avaibilityTrendsFilterList.time_range_start} - ${avaibilityTrendsFilterList.time_range_end}`}
            >
              <span className={styles.left_text} data-theme={theme}>
                {`${avaibilityTrendsFilterList.time_range_start} - ${avaibilityTrendsFilterList.time_range_end}`}
              </span>
            </Tooltip>
          </div>
          <hr className={styles.hr} data-theme={theme} />
          <div className={styles.text_wrapper}></div>
        </Card>
        <Card variant="primary" width={"100%"} height={164}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Duration
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{
                backgroundColor: theme === "dark" ? "#da7706" : "#da7706e6",
              }}
            >
              <img src={images.clock_time_timer2} alt="" />
            </span>
          </div>
          <div className={styles.text_wrapper}>
            <Tooltip title={avaibilityTrendsFilterList.duration}>
              <span className={styles.left_text} data-theme={theme}>
                {avaibilityTrendsFilterList.duration}
              </span>
            </Tooltip>
          </div>
          <hr className={styles.hr} data-theme={theme} />
          <div className={styles.text_wrapper}></div>
        </Card>
      </div>
    </Card>
  );
}
