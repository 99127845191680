import * as React from "react";
import { ThemeContext } from "context-api/ThemeContext";
import styles from "./styles.module.css";
import StatisticTooltip from "./Tooltip";

interface IData {
  max_fare: string;
  min_fare: string;
  avg_fare: string;
  count: string;
  period: string;
  carrier: {
    color: string;
    value: string;
  };
  hoverText: {
    max_fares: {
      lf_max: {
        weekday: string;
        date: string;
        time: string;
        class: string;
        lf: string;
        flight_num: number;
      };
      lf_min: {
        weekday: string;
        date: string;
        time: string;
        class: string;
        lf: string;
        flight_num: number;
      };
    };
    min_fares: {
      lf_max: {
        weekday: string;
        date: string;
        time: string;
        class: string;
        lf: string;
        flight_num: number;
      };
      lf_min: {
        weekday: string;
        date: string;
        time: string;
        class: string;
        lf: string;
        flight_num: number;
      };
    };
  };
}
interface Props {
  data: IData[];
  isPriceEvo?: boolean;
}
const StatisticsComponent: React.FC<Props> = ({ data, isPriceEvo = false }) => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <div className={styles.container}>
      <div className={styles.fields} data-theme={theme}>
        <span className={styles.field} data-theme={theme}>
          Carrier
        </span>
        <span className={styles.field} data-theme={theme}>
          Max Fare
        </span>
        <span className={styles.field} data-theme={theme}>
          MIN Fare
        </span>
        <span className={styles.field} data-theme={theme}>
          Count
        </span>
        <span className={styles.field} data-theme={theme}>
          AVG Fare
        </span>
      </div>
      {data?.map((el, index) => (
        <div key={index} className={styles.row}>
          <span className={styles.field} data-theme={theme}>
            <span
              className={styles.carrier}
              style={{
                backgroundColor: el?.carrier?.color ?? "#fff",
              }}
            >
              {el?.carrier?.value ?? "-"}
            </span>
          </span>
          <StatisticTooltip
            max={el?.hoverText?.max_fares?.lf_max}
            min={el?.hoverText?.max_fares?.lf_min}
            carrierColor={el?.carrier?.color ?? ""}
            isPriceEvo={isPriceEvo}
          >
            <span className={styles.field} data-theme={theme}>
              {el?.max_fare ?? "-"}
            </span>
          </StatisticTooltip>
          <StatisticTooltip
            max={el?.hoverText?.min_fares?.lf_max}
            min={el?.hoverText?.min_fares?.lf_min}
            carrierColor={el?.carrier?.color ?? ""}
            isPriceEvo={isPriceEvo}
          >
            <span className={styles.field} data-theme={theme}>
              {el?.min_fare ?? "-"}
            </span>
          </StatisticTooltip>

          <span className={styles.field} data-theme={theme}>
            {el?.count ?? "-"}
          </span>
          <span className={styles.field} data-theme={theme}>
            {el?.avg_fare ?? "-"}
          </span>
        </div>
      ))}
    </div>
  );
};
export default StatisticsComponent;
