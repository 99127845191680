import { RestClient } from "../RestClient";

export class StoreOptionsClient {
  private static _client: RestClient;

  static fetchStoreOptions() {
    return StoreOptionsClient.getClient().getCall(
      `/api/msdv2/events/options`
    );
  }

  static getClient() {
    if (StoreOptionsClient._client == null) {
      StoreOptionsClient._client = new RestClient();
    }
    return StoreOptionsClient._client;
  }
}
