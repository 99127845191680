import { RestClient } from "lib/api/RestClient";

export class FareTrendsTableClient {
  private static _client: RestClient;
  static fetchFareTable(params) {
    return FareTrendsTableClient.getClient().getCall(
      `/api/msdv2/fare-structure/price-evolution-table?${new URLSearchParams(
        params
      )}`
    );
  }
  static getClient() {
    if (FareTrendsTableClient._client == null) {
      FareTrendsTableClient._client = new RestClient();
    }
    return FareTrendsTableClient._client;
  }
}
