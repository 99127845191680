import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ThemeContext } from "context-api/ThemeContext";
import { HolidaysClient } from "lib/api/holidays/Holidays";
import CalendarCard from "./CalendarCard";
import {
  CalendarMonth,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import moment from "moment";
import HolidaysDetailTable from "components/holidays/Calendar/CustomTableHolidays";

import { HolidaysTableClient } from "lib/api/holidays/Events";
import { HolidaysFieldsClient } from "lib/api/holidays/Fields";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useHistory } from "react-router-dom";
import { EAFilterContext } from "context-api/FilterContextEA";
import DatePicker from "components/common/DatePicker/DatepickerLastest";
import ModalComponent from "components/common/Modal/ModalComponent";
import EventForm from "./EventModalContent/EventForm";
import RightMenu from "./RightMenu";

interface IProps {
  date: moment.Moment;
  setDate: Dispatch<SetStateAction<moment.Moment>>;
  detailDate: moment.Moment;
  setDetailDate: Dispatch<SetStateAction<moment.Moment>>;
  isSummaryDetail: boolean;
  setIsSummaryDetail: Dispatch<SetStateAction<boolean>>;
}
const Holidays = ({
  date,
  setDate,
  detailDate,
  setDetailDate,
  isSummaryDetail,
  setIsSummaryDetail,
}: IProps) => {
  const [activeStepSummary, setActiveStepSummary] = useState("lf");
  const [activeStepDetail, setActiveStepDetail] = useState(196);
  const { theme } = useContext(ThemeContext);
  const [data, setData] = useState(null);
  const position = { lf: 28, pax: 152, favg: 263, rask: 350 };
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  // lf | pax | favg | rask

  const [tableData, setTableData] = useState<{
    data: any[];
    columns: {
      name: string;
      field: string;
      cell: (row: any) => JSX.Element;
    }[];
  }>({ data: [], columns: [] });
  const [tableFields, setTableFields] = useState<
    {
      label: string;
      value: string;
      enabled: boolean;
      fields: {
        label: string;
        value: string;
        enabled: boolean;
        selected: boolean;
      }[];
    }[]
  >([]);
  const [tableFieldsInitial, setTableFieldsInitial] = useState<
    {
      label: string;
      value: string;
      enabled: boolean;
      fields: {
        label: string;
        value: string;
        enabled: boolean;
        selected: boolean;
      }[];
    }[]
  >([]);
  const [selectedTableFields, setSelectedTableFields] = useState<string[]>([]);
  const [selectedCalendarFields, setSelectedCalendarFields] = useState<
    string[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(tableFields);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const { eaFilterList } = useContext(EAFilterContext);

  let history = useHistory();

  const ref = useRef(null);
  useOutsideClick(ref, () => setCalendarIsOpen(false));

  useEffect(() => {
    const params = {
      target: isSummaryDetail ? "table" : "calendar",
    };
    HolidaysFieldsClient.fetchHolidaysFields({ params })
      .then((res: any) => {
        setTableFields(res);
        setTableFieldsInitial(res);
      })
      .catch((err) => console.log(err));
  }, [isSummaryDetail]);

  // Takvim verisini günceller
  useEffect(() => {
    if (
      eaFilterList.origin_city.length > 0 &&
      // eaFilterList.destination.length > 0 &&
      selectedCalendarFields.length > 0 &&
      !isSummaryDetail
    ) {
      const params = {
        orig_city_airport: eaFilterList.origin_city,
        dest_city_airport: eaFilterList.destination,
        field: activeStepSummary,
        fields: selectedCalendarFields,
        date_range_start: moment(date).format("YYYY-MM-DD"),
        date_range_end: moment(date).add(1, "y").format("YYYY-MM-DD"),
      };
      HolidaysClient.fetchHolidays(params)
        .then((response: any) => {
          setData(response?.data);
        })
        .catch((err) => {
          setData(null);
          console.log(err);
        });
    }
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    eaFilterList.updated,
    activeStepSummary,
    selectedCalendarFields,
    isSummaryDetail,
    date,
  ]);

  // Tablo verisini günceller
  useEffect(() => {
    if (
      eaFilterList.origin_city.length > 0 &&
      // eaFilterList.destination.length > 0 &&
      selectedTableFields.length > 0 &&
      isSummaryDetail
    ) {
      const params = {
        orig_city_airport: eaFilterList.origin_city,
        dest_city_airport: eaFilterList.destination,
        fields: selectedTableFields,
        cabin:
          activeStepDetail === 0
            ? "All"
            : activeStepDetail === 60
            ? "FIRST"
            : activeStepDetail === 128
            ? "BUS"
            : "ECO",
        year: moment(detailDate).format("YYYY"),
        month: detailDate.month() + 1,
      };
      HolidaysTableClient.fetchHolidaysTable(params)
        .then((res: any) => {
          setTableData({
            data: res?.data,
            columns: getColumns(res?.labels),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    eaFilterList.updated,
    selectedTableFields,
    activeStepDetail,
    detailDate,
    isSummaryDetail,
  ]);

  const getColumns = (labels) => {
    const array: {
      name: string;
      field: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
    }[] = [];
    for (const [field, name] of Object.entries(labels)) {
      const obj: any = {
        name,
        field,
        cell: (row) => {
          return <span className={"rdb-custom-cell"}>{row[field]}</span>;
        },
        width: 200,
      };
      array.push(obj);
    }
    return array;
  };

  const handleYearChange = (str, bool) => {
    const today = moment();
    if (str === "prev") {
      if (bool) {
        setDetailDate(moment(detailDate).subtract(1, "months"));
        return;
      }
      if (
        Number(moment(date).format("YYYY")) >
        Number(moment(today).format("YYYY")) - 1
      ) {
        setDate(moment(date).subtract(1, "y"));
      }
    }
    if (str === "next") {
      if (bool) {
        setDetailDate(moment(detailDate).add(1, "months"));
        return;
      }
      if (
        Number(moment(date).format("YYYY")) <
        Number(moment(today).format("YYYY")) + 1
      ) {
        setDate(moment(date).add(1, "y"));
      }
    }
  };

  useEffect(() => {
    let filteredData = tableFields;
    if (searchTerm.length > 0) {
      filteredData = tableFields.filter(
        (item) =>
          item.fields.some(
            (subCat) =>
              subCat.label.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
          ) ||
          item?.label?.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
      );
      filteredData = filteredData.map((item) => ({
        ...item,
        fields: item.fields.filter(
          (subCat) =>
            subCat.label.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
        ),
      }));
    }
    setFilteredData(filteredData);
  }, [tableFields, searchTerm]);

  const handleSelectAll = () => {
    const newData = tableFields.map((item) => ({
      ...item,
      fields: item.fields.map((field) => ({
        ...field,
        selected: true,
      })),
    }));
    setTableFields(newData);
  };

  const handleClearAll = () => {
    const newData = tableFields.map((item) => ({
      ...item,
      fields: item.fields.map((field) => ({
        ...field,
        selected: false,
      })),
    }));
    setTableFields(newData);
  };

  const handleReset = () => {
    setTableFields(tableFieldsInitial);
  };

  // Bu ayı içeren ve 12 elemanlı bir dizi oluştur
  function getThisAndNextMonths() {
    const currentMonth = moment(date);
    const months: { month: number; year: number }[] = [];
    for (let i = 0; i < 12; i++) {
      const nextMonth = currentMonth.clone().add(i, "months");
      months.push({ month: nextMonth.month(), year: nextMonth.year() }); // Ayın index numarasını ve yıl bilgisini al, nesne olarak diziye ekle
    }

    return months;
  }

  const twelveMonthsArray = getThisAndNextMonths();

  return (
    <Card variant="secondary">
      <div className={styles.header}>
        <div className={styles.switch_date_wrapper}>
          <div className={styles.switch_wrapper}>
            <span
              className={styles.switch_button}
              data-active={!isSummaryDetail}
              onClick={() => setIsSummaryDetail(!isSummaryDetail)}
            >
              Calendar
            </span>
            <span
              className={styles.switch_button}
              data-active={isSummaryDetail}
              onClick={() => setIsSummaryDetail(!isSummaryDetail)}
            >
              Detail
            </span>
          </div>
          <div className={styles.date_wrapper}>
            {isSummaryDetail && (
              <div className={styles.calendar_wrapper} ref={ref}>
                <span
                  className={styles.calendar_icon}
                  onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                >
                  <CalendarMonth />
                </span>
                <div
                  className={styles.date_picker_modal}
                  data-active={calendarIsOpen}
                >
                  <Card variant="secondary">
                    <Card variant="primary">
                      <DatePicker
                        value={detailDate.toDate()}
                        onChangeDate={(value) => setDetailDate(moment(value))}
                      />
                    </Card>
                  </Card>
                </div>
              </div>
            )}

            <span
              className={styles.date_prev_button}
              onClick={() => handleYearChange("prev", isSummaryDetail)}
            >
              <KeyboardArrowLeft />
            </span>
            <span
              className={styles.date_next_button}
              onClick={() => handleYearChange("next", isSummaryDetail)}
            >
              <KeyboardArrowRight />
            </span>
            <span className={styles.date_year_text}>
              {moment(isSummaryDetail ? detailDate : date).format("MMM YYYY")}
            </span>
          </div>
          <RightMenu
            isSummaryDetail={isSummaryDetail}
            handleReset={handleReset}
            handleClearAll={handleClearAll}
            handleSelectAll={handleSelectAll}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filteredData={filteredData}
            selectedTableFields={selectedTableFields}
            setSelectedTableFields={setSelectedTableFields}
            selectedCalendarFields={selectedCalendarFields}
            setSelectedCalendarFields={setSelectedCalendarFields}
          />
        </div>
        <div className={styles.select_wrapper} data-active={isSummaryDetail}>
          <span
            className={styles.select_button}
            onClick={() => setActiveStepDetail(0)}
            data-active={activeStepDetail === 0}
          >
            All
          </span>
          <span
            className={styles.select_button}
            onClick={() => setActiveStepDetail(60)}
            data-active={activeStepDetail === 60}
          >
            First
          </span>
          <span
            className={styles.select_button}
            onClick={() => setActiveStepDetail(128)}
            data-active={activeStepDetail === 128}
          >
            Bus
          </span>
          <span
            className={styles.select_button}
            onClick={() => setActiveStepDetail(196)}
            data-active={activeStepDetail === 196}
          >
            Eco
          </span>
          <span
            className={styles.active_button}
            style={{ left: activeStepDetail }}
          />
        </div>
        <div className={styles.select_wrapper} data-active={!isSummaryDetail}>
          <span
            className={styles.select_button}
            onClick={() => setActiveStepSummary("lf")}
            data-active={activeStepSummary === "lf"}
            data-theme={theme}
          >
            Load Factor
          </span>
          <span
            className={styles.select_button}
            // onClick={() => setActiveStepSummary("pax")}
            data-active={activeStepSummary === "pax"}
            data-theme={theme}
          >
            Passengers
          </span>
          <span
            className={styles.select_button}
            // onClick={() => setActiveStepSummary("favg")}
            data-active={activeStepSummary === "favg"}
            data-theme={theme}
          >
            Avg fare
          </span>
          <span
            className={styles.select_button}
            // onClick={() => setActiveStepSummary("rask")}
            data-active={activeStepSummary === "rask"}
            data-theme={theme}
          >
            Rask
          </span>
          <span
            className={styles.active_button}
            style={{ left: position[activeStepSummary] }}
          />
        </div>
      </div>
      <div className={styles.body}>
        {!isSummaryDetail ? (
          <>
            {[...twelveMonthsArray].map((item, index) => (
              <CalendarCard
                key={index}
                data={data}
                year={item.year}
                month={item.month}
                field={activeStepSummary}
                onClick={(month) => {
                  history.push(
                    `/settings?calendarDate=${moment(date).format(
                      "YYYY-MM-DD"
                    )}&selectMonth=${month}&field=${activeStepSummary}`
                  );
                }}
              />
            ))}
          </>
        ) : (
          <HolidaysDetailTable
            data={tableData.data}
            columns={tableData.columns}
            date={detailDate}
            expand
            addEvent={(row) => {
              setSelectedRow(row);
              setAddModal(true);
            }}
            editEvent={(row) => {
              setSelectedRow(row);
              setEditModal(true);
            }}
            deleteEvent={(row) => {
              setSelectedRow(row);
              setDeleteModal(true);
            }}
            height={700}
          />
        )}
      </div>
      <div>
        <ModalComponent open={addModal} setOpen={setAddModal}>
          <EventForm row={selectedRow} />
        </ModalComponent>
        <ModalComponent open={editModal} setOpen={setEditModal}>
          <EventForm
            row={selectedRow}
            edit
            orig_city_airport={eaFilterList.origin_city}
            dest_city_airport={eaFilterList.destination}
          />
        </ModalComponent>
        <ModalComponent open={deleteModal} setOpen={setDeleteModal}>
          <EventForm
            row={selectedRow}
            isDelete
            orig_city_airport={eaFilterList.origin_city}
            dest_city_airport={eaFilterList.destination}
          />
        </ModalComponent>
      </div>
    </Card>
  );
};

export default Holidays;
