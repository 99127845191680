import { useEffect } from "react";
import HomeSelect from "components/common/HomeSelect/HomeSelect";

export default function HOME() {
  useEffect(() => {
    document.title = "Home";
  }, []);

  return <HomeSelect />;
}
