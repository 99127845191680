import React, { useContext, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ThemeContext } from "context-api/ThemeContext";
import { IconType } from "react-icons";

interface IMenu {
  children: React.ReactNode;
  options: {
    title: string | IconType | JSX.Element;
    handleClick: (event: any) => void;
  }[];
}

const MenuComponent = ({ children, options }: IMenu) => {
  const { theme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const onHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span onClick={onHandleClick}>{children}</span>
      <Menu
        id={`menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            bgcolor:
              theme === "dark" ? "var(--card-third-color) !important" : "",
            color: theme === "dark" ? "#fff !important" : "",
          },
        }}
      >
        {options.map((item, index) => {
          const { title, handleClick } = item;
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
                handleClose();
              }}
            >
              {title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuComponent;
