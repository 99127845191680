/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./styles.module.css";
import { Avatar } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { LeftMenuContext } from "context-api/LeftMenu";
import images from "constans/images";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import RouteList from "RouterList";
import { IRoute } from "types/route";
import { ThemeContext } from "context-api/ThemeContext";
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardDoubleArrowLeft,
  Logout,
} from "@mui/icons-material";
import { UserProfileContext } from "context-api/UserProfile";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Add, HorizontalRule } from "@mui/icons-material";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useLocation } from "react-router-dom";
import { Tooltip } from "../Tooltip";

export default function LeftMenu() {
  const [logo, setLogo] = useState<any>();
  const { isOpen, setIsOpen } = useContext(LeftMenuContext);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const wrapperRef: any = useRef(null);
  const [_cookies, setCookie] = useCookies(["token"]);
  const [routes, setRoutes] = useState({});
  const { theme, setTheme } = useContext(ThemeContext);
  const { user } = useContext(UserProfileContext);
  const [expand, setExpand] = useState<string | false>(false);
  const location = useLocation();

  useOutsideClick(wrapperRef, () => setIsOpenLogout(false));

  useEffect(() => {
    setLogo(theme === "dark" ? images.logo : images.logo_light);
  }, [theme]);

  useEffect(() => {
    if (user) {
      // Moduller kontrol ediliyor
      if (user.enabledModules) {
        let backendRoutes = {};
        // Backend üzerinden gelen modüller ve modüllere ait sayfalar erişilebilirlik durumuna göre yan menüye ekleniyor.
        Object.keys(user.enabledModules)?.forEach((module) => {
          if (user.enabledModules[module].enabled) {
            backendRoutes = {
              ...backendRoutes,
              [module]:
                RouteList[module] &&
                RouteList[module]?.map((el: IRoute) => ({
                  ...el,
                  disabled:
                    !user.enabledModules[module].pages.includes(el.path) ||
                    el.path === "/comparative-analysis", // Ön uçtan bir module erişime kapatılıyor
                })),
            };
          }
        });
        setRoutes(backendRoutes);

        // Aktif olan sayfaya göre accordion yapısı açılıyor
        Object.keys(backendRoutes).forEach((el, index) => {
          if (
            backendRoutes[el] &&
            backendRoutes[el].some(
              (route) => route.path === window.location.pathname
            )
          ) {
            setExpand(`panel${index}`);
          }
        });
      }
    }
  }, [user]);

  const onChangeLogo = () => {
    setLogo(
      isOpen
        ? images.logo_arrow
        : theme === "dark"
        ? images.logo
        : images.logo_light
    );
  };

  useEffect(() => {
    setTimeout(() => {
      onChangeLogo();
    }, 700);
  }, [isOpen]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    setCookie("token", "");
  };

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpand(isExpanded ? panel : false);
    };

  return (
    <div className={styles.container} data-active={isOpen}>
      <div
        className={styles.menu_content}
        data-active={isOpen}
        data-theme={theme}
      >
        <div className={styles.logo_wrapper} data-active={isOpen}>
          <a href="/home">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className={styles.route_wrapper}>
          {Object.keys(routes)?.map((el, index) => (
            <Accordion
              className={styles.accordion}
              expanded={expand === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              key={index}
              data-theme={theme}
              disabled={!user?.enabledModules[el]?.enabled}
            >
              <AccordionSummary
                expandIcon={
                  expand === `panel${index}` ? (
                    <KeyboardArrowUp
                      className={styles.icon}
                      data-theme={theme}
                    />
                  ) : (
                    <KeyboardArrowRight
                      className={styles.icon}
                      data-theme={theme}
                    />
                  )
                }
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                className={styles.accordion_summary}
              >
                <span className={styles.summary_text} data-theme={theme}>
                  {el?.replace("_", " ")}
                </span>
              </AccordionSummary>
              <div className={styles.accordion_details_wrapper}>
                {routes[el] &&
                  routes[el]?.map((element, inIndex) => (
                    <AccordionDetails
                      className={styles.accordion_details}
                      onClick={() => {}}
                      data-theme={theme}
                      key={inIndex}
                    >
                      <Link
                        to={element?.disabled ? "#" : element?.path}
                        key={element?.id}
                      >
                        <div
                          className={styles.hr}
                          data-active={location.pathname === element?.path}
                        ></div>
                        <div
                          className={styles.route}
                          data-active={location.pathname === element?.path}
                          data-theme={theme}
                        >
                          <Tooltip title={isOpen ? element?.name : ""}>
                            <img
                              src={element?.icon}
                              alt=""
                              className={styles.route_icon}
                            />
                          </Tooltip>

                          <span
                            className={element?.disabled ? styles.disabled : ""}
                            data-active={isOpen}
                            data-theme={theme}
                          >
                            {element?.name}
                          </span>
                        </div>
                      </Link>
                    </AccordionDetails>
                  ))}
              </div>
            </Accordion>
          ))}
        </div>
        <div
          className={styles.user_wrapper}
          data-active={isOpen}
          ref={wrapperRef}
          onClick={() => setIsOpenLogout(!isOpenLogout)}
          data-theme={theme}
        >
          <div
            className={styles.open_menu}
            data-active={isOpenLogout}
            data-theme={theme}
          >
            <div className={styles.theme_button_wrapper} data-theme={theme}>
              <span
                className={styles.dark_button}
                data-active={theme === "dark"}
                onClick={() => {
                  setTheme("dark");
                  localStorage.setItem("theme", "dark");
                  document.body.className = "dark";
                }}
                data-theme={theme}
              >
                Dark
              </span>
              <span
                className={styles.light_button}
                data-active={theme === "light"}
                onClick={() => {
                  setTheme("light");
                  localStorage.setItem("theme", "light");
                  document.body.className = "light";
                }}
                data-theme={theme}
              >
                Light
              </span>
            </div>
            <div
              className={styles.user_logout_wrapper}
              onClick={handleLogout}
              data-theme={theme}
            >
              <Logout className={styles.logout_icon} data-theme={theme} />
              <span>Logout</span>
            </div>
          </div>
          <Avatar style={{ width: 32, height: 32 }} />
          <div
            className={styles.user_info_wrapper}
            style={{ overflow: "hidden" }}
          >
            <div className={styles.user_info}>
              <span className={styles.user_name} data-theme={theme}>
                {user?.displayName}
              </span>
              <span className={styles.user_msd} data-theme={theme}>
                {user?.clientName}
              </span>
            </div>
            <span className={styles.arrow} data-theme={theme} />
          </div>
        </div>
        <div className={styles.menu_open_button_wrapper} data-active={isOpen}>
          <KeyboardDoubleArrowLeft
            className={styles.menu_open_button}
            onClick={() => setIsOpen(!isOpen)}
            data-theme={theme}
          />
        </div>
      </div>
    </div>
  );
}
