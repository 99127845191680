import { RestClient } from "lib/api/RestClient";
// import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";

export class RulesGetSimpleByIdClient {
  private static _client: RestClient;

  static fetchRulesGetSimple(id) {
    return RulesGetSimpleByIdClient.getClient().getCall(
      `/api/lfa/rules/simplev2/${id}`
    );
  }

  static getClient() {
    if (RulesGetSimpleByIdClient._client == null) {
      RulesGetSimpleByIdClient._client = new RestClient();
    }
    return RulesGetSimpleByIdClient._client;
  }
}
