/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/common/Button/Button";
import Card from "components/common/Card/Card";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import images from "constans/images";
import { AvaibilityTrendsFilterContext } from "context-api/AvaibilityTrendsFilterContext";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./AvaibilityTrendsFiltersOpen.module.css";
import moment from "moment";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";
import DatePickerComponent from "components/common/DatePicker/DatepickerLastest";

interface IAvaibilityTrendsFiltersOpen {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPriceEvo?: boolean;
  isSingleDate: boolean;
}

export default function AvaibilityTrendsFiltersOpen({
  setIsOpen,
  isPriceEvo,
  isSingleDate,
}: IAvaibilityTrendsFiltersOpen) {
  const { avaibilityTrendsFilterList, setAvaibilityTrendsFilterList, maxDate } =
    useContext(AvaibilityTrendsFilterContext);
  const { theme } = useContext(ThemeContext);
  const [daysOfWeek] = useState(["M", "T", "W", "T", "F", "S", "S"]);
  const [connection] = useState(["DIRECT", "1 STOP", "2 STOPS"]);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<string[]>(
    avaibilityTrendsFilterList.weekdays
  );
  const [selectedConnection, setSelectedConnection] = useState<string[]>(
    avaibilityTrendsFilterList.connection
  );
  const [duration, setDuration] = useState({
    value: Number(avaibilityTrendsFilterList.duration) || 0,
    min: 0,
    max: 24,
    dateList: [],
  });
  const [departureTimeRange, setDepartureTimeRange] = useState({
    value: [0, 23],
    min: 0,
    max: 23,
    dateList: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:59",
    ],
  });

  const [datePicker, setDatePicker] = useState({
    startDate: new Date(
      moment(avaibilityTrendsFilterList.date_range_start, "YYYY-MM-DD").format()
    ),
    endDate: new Date(
      moment(avaibilityTrendsFilterList.date_range_end, "YYYY-MM-DD").format()
    ),
  });
  const [datePickerUpdated, setDatePickerUpdated] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [datePicker]);

  const handleClickFilterAccept = () => {
    const data = {
      date_range_start: moment(datePicker.startDate).format("YYYY-MM-DD"),
      date_range_end: moment(datePicker.endDate).format("YYYY-MM-DD"),
      time_range_start:
        departureTimeRange.dateList[departureTimeRange.value[0]],
      time_range_end: departureTimeRange.dateList[departureTimeRange.value[1]],
      duration: duration.value,
      weekdays: selectedDaysOfWeek,
      connection: selectedConnection,
    };

    if (datePicker.startDate !== null && datePicker.endDate !== null) {
      setAvaibilityTrendsFilterList(data);
      setIsOpen(false);
    } else setError(true);
  };

  useEffect(() => {
    if (
      departureTimeRange.dateList.indexOf(
        avaibilityTrendsFilterList.time_range_start
      ) !== -1 &&
      departureTimeRange.dateList.indexOf(
        avaibilityTrendsFilterList.time_range_end
      ) !== -1
    ) {
      setDepartureTimeRange((state) => ({
        ...state,
        value: [
          departureTimeRange.dateList.indexOf(
            avaibilityTrendsFilterList.time_range_start
          ),
          departureTimeRange.dateList.indexOf(
            avaibilityTrendsFilterList.time_range_end
          ),
        ],
      }));
    } else setDepartureTimeRange((state) => ({ ...state, value: [0, 23] }));
  }, []);

  const handleClear = () => {
    setDatePicker({
      startDate: new Date(),
      endDate: new Date(moment(new Date()).add(100, "d").format()),
    });
    setDatePickerUpdated(!datePickerUpdated);
    setSelectedDaysOfWeek([]);
    setSelectedConnection([]);
    setDuration((state) => ({ ...state, value: 0 }));
    setDepartureTimeRange((state) => ({ ...state, value: [0, 23] }));
    setAvaibilityTrendsFilterList({
      date_range_start: new Date(),
      date_range_end: new Date(moment(new Date()).add(100, "d").format()),
      time_range_start: "",
      time_range_end: "",
      duration: "0",
      weekdays: [],
      connection: [],
    });
  };
  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          {isPriceEvo
            ? "Price Evolution Filter"
            : "Availability Trends Filters"}
        </span>
        <div className={styles.button_wrapper}>
          <div
            className={styles.clear_button}
            data-theme={theme}
            onClick={handleClear}
          >
            Clear
          </div>
          <Tooltip title={"Please select at least 2 days range!"} open={error}>
            <div
              className={styles.tick_button}
              onClick={() => handleClickFilterAccept()}
              data-active={error}
            >
              <img src={error ? images.cancel_red : images.tick_green} alt="" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.body}>
        <Card variant={"primary"} width={"100%"} height={505}>
          <div className={styles.travel_date_range_wrapper}>
            <span className={styles.travel_date_range_title} data-theme={theme}>
              Travel Date Range
            </span>
            <span
              className={styles.items_icon_wrapper}
              style={{ backgroundColor: "#d77cbb" }}
            >
              <img src={images.travel_earth_arrow} alt="" />
            </span>
          </div>
          <div
            className={styles.travel_date_range_date_picker}
            data-active={error}
          >
            {isSingleDate ? (
              <DatePickerComponent
                value={datePicker.startDate}
                onChange={(date) => {
                  setDatePicker((prevState) => ({
                    ...prevState,
                    startDate: date,
                  }));
                }}
                minDate={new Date()}
                maxDate={maxDate}
              />
            ) : (
              <DatePickerComponent
                selectsRange
                value={datePicker.startDate}
                startDate={datePicker.startDate}
                endDate={datePicker.endDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setDatePicker({
                    startDate: start,
                    endDate: end,
                  });
                }}
                minDate={new Date()}
                maxDate={maxDate}
              />
            )}
          </div>
          <hr className={styles.hr} data-theme={theme} />
          <div className={styles.days_of_week_wrapper}>
            <p className={styles.title} data-theme={theme}>
              Days of Week
            </p>
            <div className={styles.button_wrapper}>
              {["0", "1", "2", "3", "4", "5", "6"].map((item) => (
                <Button
                  key={item}
                  added={selectedDaysOfWeek}
                  setAdded={(item: any) => {
                    setSelectedDaysOfWeek(() => [...item]);
                  }}
                  data={item}
                  width={47}
                  height={32}
                  text={"center"}
                  disabled={isPriceEvo}
                >
                  {daysOfWeek[item]}
                </Button>
              ))}
            </div>
          </div>
        </Card>
        <div className={styles.right_cards_wrapper}>
          <Card variant={"primary"} width={"100%"} height={163}>
            <div className={styles.travel_date_range_wrapper}>
              <span
                className={styles.travel_date_range_title}
                data-theme={theme}
              >
                Connection
              </span>
              <span
                className={styles.items_icon_wrapper}
                style={{ backgroundColor: "#ec4252" }}
              >
                <img src={images.a_to_b} alt="" />
              </span>
            </div>
            <div className={styles.connection_button_wrapper}>
              {["0", "1", "2"].map((item) => (
                <Button
                  key={item}
                  added={selectedConnection}
                  setAdded={(item: any) => {
                    setSelectedConnection([...item]);
                  }}
                  data={item}
                  width={108}
                  height={32}
                  text={"center"}
                >
                  {connection[item]}
                </Button>
              ))}
            </div>
          </Card>
          <Card variant={"primary"} width={"100%"} height={163}>
            <div className={styles.travel_date_range_wrapper}>
              <span
                className={styles.travel_date_range_title}
                data-theme={theme}
              >
                Departure Time Range
              </span>
              <span
                className={styles.items_icon_wrapper}
                style={{ backgroundColor: "#5504d9" }}
              >
                <img src={images.travel_earth_arrow} alt="" />
              </span>
            </div>
            <div className={styles.range_slider}>
              <RangeSlider
                data={{
                  max: departureTimeRange.max,
                  min: departureTimeRange.min,
                  value: departureTimeRange.value,
                  dateList: departureTimeRange.dateList,
                  setValue: setDepartureTimeRange,
                }}
                format={"HH:mm"}
              />
            </div>
          </Card>
          <Card variant={"primary"} width={"100%"} height={163}>
            <div className={styles.travel_date_range_wrapper}>
              <span
                className={styles.travel_date_range_title}
                data-theme={theme}
              >
                Duration (Hr)
              </span>
              <span
                className={styles.items_icon_wrapper}
                style={{ backgroundColor: "#da7706" }}
              >
                <img src={images.clock_time_timer2} alt="" />
              </span>
            </div>
            <div className={styles.range_slider}>
              <RangeSlider
                data={{
                  max: duration.max,
                  min: duration.min,
                  value: duration.value,
                  dateList: duration.dateList,
                  setValue: setDuration,
                }}
              />
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}
