/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ThemeContext } from "context-api/ThemeContext";
import moment from "moment";
import { memo, useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";
import styles from "./styles.module.css";

interface IDatePickerComponent {
  isSingle?: boolean;
  onChange: any;
  defaultDate?: {
    startDate: Date | null;
    endDate: Date | null;
  };
  minDate?: Date | null;
  maxDate?: Date | null;
}

function DatePickerComponent({
  isSingle = false,
  onChange,
  defaultDate = { startDate: new Date(), endDate: new Date() },
  minDate = null,
  maxDate = null,
}: IDatePickerComponent) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { theme } = useContext(ThemeContext);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (isSingle) {
      onChange({ startDate: start, endDate: null });
    } else onChange({ startDate: start, endDate: end });
  };

  useEffect(() => {
    if (isSingle) {
      setStartDate(defaultDate?.startDate);
    } else {
      setStartDate(defaultDate?.startDate);
      setEndDate(defaultDate?.endDate);
    }
  }, [defaultDate]);

  const CustomDatePicker = ({
    decreaseMonth,
    increaseMonth,
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
  }) => {
    return (
      <div className={styles.custom_date_picker}>
        <span
          className={styles.prev_year}
          onClick={() => {
            changeYear(Number(moment(date).subtract(1, "year").format("YYYY")));
          }}
        >
          {moment(date).subtract(1, "year").format("YYYY")}
        </span>
        <div className={styles.buttons_wrapper}>
          <button
            type="button"
            className={styles.arrow_left}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            data-theme={theme}
          >
            <KeyboardArrowLeft />
          </button>
          <span className={styles.date_now}>
            {moment(date).format("MMM - YYYY")}
          </span>
          <button
            type="button"
            className={styles.arrow_right}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            data-theme={theme}
          >
            <KeyboardArrowRight />
          </button>
        </div>
        <span
          className={styles.next_year}
          onClick={() => {
            changeYear(Number(moment(date).add(1, "year").format("YYYY")));
          }}
        >
          {moment(date).add(1, "year").format("YYYY")}
        </span>
      </div>
    );
  };
  return isSingle ? (
    <DatePicker
      selected={startDate}
      onChange={onChangeDate}
      selectsRange
      inline
      renderCustomHeader={CustomDatePicker}
      calendarStartDay={1}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      minDate={minDate}
      maxDate={maxDate}
      showDisabledMonthNavigation
    />
  ) : (
    <DatePicker
      selected={startDate}
      onChange={onChangeDate}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
      renderCustomHeader={CustomDatePicker}
      calendarStartDay={1}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      minDate={minDate}
      maxDate={maxDate}
      showDisabledMonthNavigation
    />
  );
}

export default memo(DatePickerComponent);
