import { Dispatch, SetStateAction } from "react";
import MainFilterClosed from "./MainFilterClosed";
import MainFilterOpened from "./MainFilterOpened";
import styles from "./MainFilter.module.css";

interface IMainFilter {
  filterMenu: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
}
export default function MainFilter({ filterMenu }: IMainFilter) {
  return (
    <div className={styles.container}>
      <div className={styles.filter} data-active={filterMenu.isOpen}>
        <MainFilterOpened setIsOpen={filterMenu.setIsOpen} />
      </div>
      <div className={styles.filter} data-active={!filterMenu.isOpen}>
        <MainFilterClosed
          isOpen={filterMenu.isOpen}
          setIsOpen={filterMenu.setIsOpen}
        />
      </div>
    </div>
  );
}
