import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import styles from "./styles.module.css";
interface ITooltip {
  data: {
    hoverTexts: {
      label: string;
      value: string;
    }[];
    lastUpdated: string;
  };
}

export default function PriceTooltip({ data }: ITooltip) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.content}>
        {data?.hoverTexts?.map((el, index) => (
          <div key={index}>
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  {el?.label}
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {el?.value}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div>
          <div className={styles.text_wrapper}>
            <div>
              <span className={styles.text_left} data-theme={theme}>
                Last Updated
              </span>
            </div>
            <div>
              <span className={styles.text_right} data-theme={theme}>
                {data?.lastUpdated}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
