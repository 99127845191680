/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./styles.module.css";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "components/common/Card/Card";
import PlotlyComponent from "components/common/Charts/Plotly";
import { DemandClient } from "lib/api/holidays/Demand";
import { IoIosArrowForward } from "react-icons/io";
import { HolidaysClient } from "lib/api/holidays/Holidays";
import { ThemeContext } from "context-api/ThemeContext";
import { useQuery } from "hooks/useQuery";
import { EAFilterContext } from "context-api/FilterContextEA";
import DropdownText from "components/common/Dropdown/DropdownText";
import CalendarCard from "./CalendarCard";

interface IProps {
  currentDate: moment.Moment;
  setCurrentDate: Dispatch<SetStateAction<moment.Moment>>;
  prevDate: moment.Moment;
  setPrevDate: Dispatch<SetStateAction<moment.Moment>>;
}

const ComparisonViewComponent = ({
  currentDate,
  setCurrentDate,
  prevDate,
  setPrevDate,
}: IProps) => {
  const [data, setData] = useState<null | any[]>(null);

  const [activeStepSummary, setActiveStepSummary] = useState("lf");
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);

  const [demandDataCurrent, setDemandDataCurrent] = useState([]);
  const [demandLayoutCurrent, setDemandLayoutCurrent] = useState([]);
  const [demandDataPrev, setDemandDataPrev] = useState([]);
  const [demandLayoutPrev, setDemandLayoutPrev] = useState([]);
  const { eaFilterList } = useContext(EAFilterContext);
  const { theme } = useContext(ThemeContext);
  const position = { lf: 28, pax: 152, favg: 263, rask: 350 };
  let query = useQuery();

  useEffect(() => {
    const queryCalendarDate = query.get("calendarDate");
    const querySelectMonth = query.get("selectMonth");
    const queryField = query.get("field");
    if (queryCalendarDate && querySelectMonth && queryField) {
      setActiveStepSummary(queryField);
      setCurrentDate(moment(queryCalendarDate, "YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    const params = {
      orig_city_airport: eaFilterList.origin_city,
      dest_city_airport: eaFilterList.destination,
      field: activeStepSummary,
    };
    if (
      eaFilterList.origin_city.length > 0 &&
      eaFilterList.destination.length > 0
    ) {
      DemandClient.fetchDemand({ params })
        .then((res: any) => {
          setDemandDataCurrent(res?.current?.fig?.data);
          setDemandLayoutCurrent(res?.current?.fig?.layout);
          setDemandDataPrev(res?.prev?.fig?.data);
          setDemandLayoutPrev(res?.prev?.fig?.layout);
        })
        .catch((err) => {
          console.log(err);
          setDemandDataCurrent([]);
          setDemandLayoutCurrent([]);
          setDemandDataPrev([]);
          setDemandLayoutPrev([]);
        });
    }
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    eaFilterList.updated,
    activeStepSummary,
  ]);

  useEffect(() => {
    const params = {
      orig_city_airport: eaFilterList.origin_city,
      dest_city_airport: eaFilterList.destination,
      field: activeStepSummary,
      current_date: moment(currentDate).format("YYYY-MM"),
      prev_date: moment(prevDate).format("YYYY-MM"),
    };
    if (
      eaFilterList.origin_city.length > 0 &&
      eaFilterList.destination.length > 0
    ) {
      HolidaysClient.fetchHolidays(params)
        .then((response: any) => {
          setData(response?.data);
        })
        .catch((err) => {
          console.log(err);
          setData([]);
        });
    }
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    eaFilterList.updated,
    activeStepSummary,
    currentDate,
    prevDate,
  ]);

  const colorGenerate = (color, value) => {
    if (value > 0) {
      const newValue = value === 1 ? value + 0.5 : value;
      // Renk kanallarını artırmak için bir oran belirleyin
      const increaseFactor = 0.05; // İstenilen faktörü ayarlayın

      // Renk kanallarını artırın
      const newR = Math.min(255, color.r + newValue * increaseFactor);
      const newG = Math.min(255, color.g + newValue * increaseFactor);
      const newB = Math.min(255, color.b + newValue * increaseFactor);

      const alpha = 1 - 1 / newValue;
      const newColor = `rgba(${newR},${newG},${newB},${alpha})`;
      return newColor;
    }
    return "transparent";
  };

  return (
    <>
      <Card variant="secondary">
        <div className={styles.header}>
          <h3 className={styles.title}>Comparison View</h3>
          <div className={styles.select_wrapper}>
            <span
              className={styles.select_button}
              onClick={() => setActiveStepSummary("lf")}
              data-active={activeStepSummary === "lf"}
              data-theme={theme}
            >
              Load Factor
            </span>
            <span
              className={styles.select_button}
              // onClick={() => setActiveStepSummary("pax")}
              data-active={activeStepSummary === "pax"}
              data-theme={theme}
            >
              Passengers
            </span>
            <span
              className={styles.select_button}
              // onClick={() => setActiveStepSummary("favg")}
              data-active={activeStepSummary === "favg"}
              data-theme={theme}
            >
              Avg fare
            </span>
            <span
              className={styles.select_button}
              // onClick={() => setActiveStepSummary("rask")}
              data-active={activeStepSummary === "rask"}
              data-theme={theme}
            >
              Rask
            </span>
            <span
              className={styles.active_button}
              style={{ left: position[activeStepSummary] }}
            />
          </div>
        </div>
        <div className={styles.body}>
          <CalendarCard
            data={data}
            year={Number(moment(currentDate).format("YYYY"))}
            month={Number(moment(currentDate).format("MM")) - 1}
            field={activeStepSummary}
            isMonthChange
            onChangeDate={(newDate) => {
              setCurrentDate(newDate);
            }}
          />
          <CalendarCard
            data={data}
            year={Number(moment(prevDate).format("YYYY"))}
            month={Number(moment(prevDate).format("MM")) - 1}
            field={activeStepSummary}
            isMonthChange
            onChangeDate={(newDate) => {
              setPrevDate(newDate);
            }}
          />
        </div>
      </Card>

      <div style={{ marginBottom: "1em" }}>
        <Card variant="secondary">
          <div className={styles.header}>
            <h3 className={styles.title}>Demand by Booking Period</h3>
            <div className={styles.select_wrapper}>
              <span
                className={styles.select_button}
                onClick={() => setActiveStepSummary("lf")}
                data-active={activeStepSummary === "lf"}
                data-theme={theme}
              >
                Pickup - Pax and Avg Fare
              </span>
              <span
                className={styles.select_button}
                // onClick={() => setActiveStepSummary("pax")}
                data-active={activeStepSummary === "pax"}
                data-theme={theme}
              >
                Cumulative - Pax and Avg Fare
              </span>
              <span
                className={styles.select_button}
                // onClick={() => setActiveStepSummary("favg")}
                data-active={activeStepSummary === "favg"}
                data-theme={theme}
              >
                Cumulative - Revenue & Load Factor
              </span>
              <span
                className={styles.active_button}
                style={{ left: position[activeStepSummary] }}
              />
            </div>
          </div>
          <div className={styles.chart_wrapper}>
            <Card variant="primary" width={"50%"} height={450}>
              <PlotlyComponent
                data={demandDataCurrent}
                layout={demandLayoutCurrent}
              />
            </Card>
            <Card variant="primary" width={"50%"} height={450}>
              <PlotlyComponent
                data={demandDataPrev}
                layout={demandLayoutPrev}
              />
            </Card>
          </div>
        </Card>
      </div>
      <Card variant="secondary">
        <div className={styles.card_header}>
          <span className={styles.card_title}>
            Demand by Booking Period Data Table
          </span>
          <div className={styles.select_wrapper} data-active={""}>
            <span
              className={styles.select_button}
              onClick={() => {}}
              data-active={false}
            >
              Pax
            </span>
            <span
              className={styles.select_button}
              onClick={() => {}}
              data-active={false}
            >
              Avg Fare
            </span>
            <span
              className={styles.select_button}
              onClick={() => {}}
              data-active={false}
            >
              Revenue
            </span>
            <span
              className={styles.select_button}
              onClick={() => {}}
              data-active={false}
            >
              Load Factor
            </span>
            <span className={styles.active_button} style={{ left: 0 }} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.body_content}>
            <Card variant="primary">
              <div className={styles.demand_dropdowns_wrapper}>
                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="BKD MON ST"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="BKD MON END"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="DEPARTURE YEAR"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="DEPARTURE EVENT"
                  border
                />
              </div>
              <div className={styles.demad_table_head}>
                <div className={styles.row}>
                  <span className={styles.th}>Clusters</span>
                  <span className={styles.th}>TTL ACT</span>
                  <span className={styles.th}>Cabin</span>
                  <span className={styles.th}>Class</span>
                  <span className={styles.th} />
                </div>
                <div className={styles.row}>
                  <span className={styles.td}>
                    <span className={styles.button}>BKD MON</span>
                  </span>
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.chart_th_wrapper}>
                    <div className={styles.chart_th}>
                      {[
                        "JAN",
                        "FEB",
                        "MAR",
                        "APR",
                        "MAY",
                        "JUN",
                        "JUL",
                        "AUG",
                        "SEP",
                        "OCT",
                        "NOW",
                        "DEC",
                        "TOTAL",
                      ].map((el, index) => (
                        <span key={index} className={styles.chart_th_item}>
                          {el}
                        </span>
                      ))}
                    </div>
                  </span>
                </div>
                <div className={styles.row}>
                  <span className={styles.td}>
                    <IoIosArrowForward
                      size={24}
                      style={{
                        transform: first ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "ease-in-out 300ms",
                      }}
                      onClick={() => {
                        if (second && first) {
                          setFirst(false);
                          setSecond(false);
                          return;
                        }
                        setFirst(!first);
                      }}
                    />
                  </span>
                  <span className={styles.td}>
                    <span className={styles.button}>TTL ACT</span>
                  </span>
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.chart_td_wrapper}>
                    {[
                      { value: 0 },
                      { value: 0 },
                      { value: 0 },
                      { value: 1 },
                      { value: 0 },
                      { value: 0 },
                      { value: 3 },
                      { value: 19 },
                      { value: 34 },
                      { value: 235 },
                      { value: 495 },
                      { value: 281 },
                      { value: 1068 },
                    ].map((el, index) => (
                      <span
                        key={index}
                        className={styles.chart_td_item}
                        style={{
                          backgroundColor: colorGenerate(
                            { r: 80, g: 0, b: 180 },
                            el.value
                          ),
                          visibility: el.value === 0 ? "hidden" : "visible",
                        }}
                      >
                        {el.value}
                      </span>
                    ))}
                  </span>
                </div>
                {first && (
                  <>
                    <div className={styles.row}>
                      <span className={styles.td}>
                        <span className={styles.button}>BKD MON</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.chart_th_wrapper}>
                        <div className={styles.chart_th}>
                          {[
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAY",
                            "JUN",
                            "JUL",
                            "AUG",
                            "SEP",
                            "OCT",
                            "NOW",
                            "DEC",
                            "TOTAL",
                          ].map((el, index) => (
                            <span key={index} className={styles.chart_th_item}>
                              {el}
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <IoIosArrowForward
                          size={24}
                          style={{
                            transform: second
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                            transition: "ease-in-out 300ms",
                          }}
                          onClick={() => setSecond(!second)}
                        />
                      </span>
                      <span className={styles.td}>
                        <span className={styles.button}>C</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 6 },
                          { value: 17 },
                          { value: 28 },
                          { value: 55 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 16, g: 0, b: 72 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Y</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 1 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 19 },
                          { value: 30 },
                          { value: 229 },
                          { value: 478 },
                          { value: 253 },
                          { value: 1013 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 16, g: 0, b: 72 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                  </>
                )}
                {second && (
                  <>
                    <div className={styles.row}>
                      <span className={styles.td}>
                        <span className={styles.button}>BKD MON</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.chart_th_wrapper}>
                        <div className={styles.chart_th}>
                          {[
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAY",
                            "JUN",
                            "JUL",
                            "AUG",
                            "SEP",
                            "OCT",
                            "NOW",
                            "DEC",
                            "TOTAL",
                          ].map((el, index) => (
                            <span key={index} className={styles.chart_th_item}>
                              {el}
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>J</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 0 },
                          { value: 2 },
                          { value: 8 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>C</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 10 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>D</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 14 },
                          { value: 20 },
                          { value: 34 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>I</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 0 },
                          { value: 3 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Y</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 2 },
                          { value: 2 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>K</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 13 },
                          { value: 13 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>M</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 10 },
                          { value: 15 },
                          { value: 25 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>L</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 2 },
                          { value: 13 },
                          { value: 13 },
                          { value: 28 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>V</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 9 },
                          { value: 10 },
                          { value: 0 },
                          { value: 22 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>T</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 8 },
                          { value: 11 },
                          { value: 16 },
                          { value: 0 },
                          { value: 41 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>R</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 9 },
                          { value: 5 },
                          { value: 40 },
                          { value: 76 },
                          { value: 28 },
                          { value: 158 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>S</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 7 },
                          { value: 64 },
                          { value: 15 },
                          { value: 11 },
                          { value: 97 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>N</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 5 },
                          { value: 16 },
                          { value: 21 },
                          { value: 45 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Q</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 4 },
                          { value: 7 },
                          { value: 6 },
                          { value: 21 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>O</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 4 },
                          { value: 0 },
                          { value: 11 },
                          { value: 43 },
                          { value: 42 },
                          { value: 103 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>W</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 1 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 83 },
                          { value: 272 },
                          { value: 102 },
                          { value: 458 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Card>
          </div>
          <div className={styles.body_content}>
            <Card variant="primary">
              <div className={styles.demand_dropdowns_wrapper}>
                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="BKD MON ST"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="BKD MON END"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="DEPARTURE YEAR"
                  border
                />

                <DropdownText
                  options={[
                    "BKD MON ST",
                    "BKD MON END",
                    "DEPARTURE YEAR",
                    "DEPARTURE EVENT",
                  ]}
                  handleSelectedOption={() => {}}
                  defaultSelection="DEPARTURE EVENT"
                  border
                />
              </div>
              <div className={styles.demad_table_head}>
                <div className={styles.row}>
                  <span className={styles.th}>Clusters</span>
                  <span className={styles.th}>TTL ACT</span>
                  <span className={styles.th}>Cabin</span>
                  <span className={styles.th}>Class</span>
                  <span className={styles.th} />
                </div>
                <div className={styles.row}>
                  <span className={styles.td}>
                    <span className={styles.button}>BKD MON</span>
                  </span>
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.chart_th_wrapper}>
                    <div className={styles.chart_th}>
                      {[
                        "JAN",
                        "FEB",
                        "MAR",
                        "APR",
                        "MAY",
                        "JUN",
                        "JUL",
                        "AUG",
                        "SEP",
                        "OCT",
                        "NOW",
                        "DEC",
                        "TOTAL",
                      ].map((el, index) => (
                        <span key={index} className={styles.chart_th_item}>
                          {el}
                        </span>
                      ))}
                    </div>
                  </span>
                </div>
                <div className={styles.row}>
                  <span className={styles.td}>
                    <IoIosArrowForward
                      size={24}
                      style={{
                        transform: first ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "ease-in-out 300ms",
                      }}
                      onClick={() => {
                        if (second && first) {
                          setFirst(false);
                          setSecond(false);
                          return;
                        }
                        setFirst(!first);
                      }}
                    />
                  </span>
                  <span className={styles.td}>
                    <span className={styles.button}>TTL ACT</span>
                  </span>
                  <span className={styles.td} />
                  <span className={styles.td} />
                  <span className={styles.chart_td_wrapper}>
                    {[
                      { value: 0 },
                      { value: 0 },
                      { value: 0 },
                      { value: 1 },
                      { value: 0 },
                      { value: 0 },
                      { value: 3 },
                      { value: 19 },
                      { value: 34 },
                      { value: 235 },
                      { value: 495 },
                      { value: 281 },
                      { value: 1068 },
                    ].map((el, index) => (
                      <span
                        key={index}
                        className={styles.chart_td_item}
                        style={{
                          backgroundColor: colorGenerate(
                            { r: 80, g: 0, b: 180 },
                            el.value
                          ),
                          visibility: el.value === 0 ? "hidden" : "visible",
                        }}
                      >
                        {el.value}
                      </span>
                    ))}
                  </span>
                </div>
                {first && (
                  <>
                    <div className={styles.row}>
                      <span className={styles.td}>
                        <span className={styles.button}>BKD MON</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.chart_th_wrapper}>
                        <div className={styles.chart_th}>
                          {[
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAY",
                            "JUN",
                            "JUL",
                            "AUG",
                            "SEP",
                            "OCT",
                            "NOW",
                            "DEC",
                            "TOTAL",
                          ].map((el, index) => (
                            <span key={index} className={styles.chart_th_item}>
                              {el}
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <IoIosArrowForward
                          size={24}
                          style={{
                            transform: second
                              ? "rotate(90deg)"
                              : "rotate(0deg)",
                            transition: "ease-in-out 300ms",
                          }}
                          onClick={() => setSecond(!second)}
                        />
                      </span>
                      <span className={styles.td}>
                        <span className={styles.button}>C</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 6 },
                          { value: 17 },
                          { value: 28 },
                          { value: 55 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 16, g: 0, b: 72 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Y</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 1 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 19 },
                          { value: 30 },
                          { value: 229 },
                          { value: 478 },
                          { value: 253 },
                          { value: 1013 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 16, g: 0, b: 72 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                  </>
                )}
                {second && (
                  <>
                    <div className={styles.row}>
                      <span className={styles.td}>
                        <span className={styles.button}>BKD MON</span>
                      </span>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.chart_th_wrapper}>
                        <div className={styles.chart_th}>
                          {[
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAY",
                            "JUN",
                            "JUL",
                            "AUG",
                            "SEP",
                            "OCT",
                            "NOW",
                            "DEC",
                            "TOTAL",
                          ].map((el, index) => (
                            <span key={index} className={styles.chart_th_item}>
                              {el}
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>J</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 0 },
                          { value: 2 },
                          { value: 8 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>C</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 10 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>D</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 14 },
                          { value: 20 },
                          { value: 34 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>I</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 0 },
                          { value: 3 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Y</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 2 },
                          { value: 2 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>K</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 13 },
                          { value: 13 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>M</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 10 },
                          { value: 15 },
                          { value: 25 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>L</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 2 },
                          { value: 13 },
                          { value: 13 },
                          { value: 28 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>V</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 9 },
                          { value: 10 },
                          { value: 0 },
                          { value: 22 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>T</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 6 },
                          { value: 8 },
                          { value: 11 },
                          { value: 16 },
                          { value: 0 },
                          { value: 41 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>R</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 9 },
                          { value: 5 },
                          { value: 40 },
                          { value: 76 },
                          { value: 28 },
                          { value: 158 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>S</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 7 },
                          { value: 64 },
                          { value: 15 },
                          { value: 11 },
                          { value: 97 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>N</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 5 },
                          { value: 16 },
                          { value: 21 },
                          { value: 45 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>Q</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 4 },
                          { value: 4 },
                          { value: 7 },
                          { value: 6 },
                          { value: 21 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>O</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 3 },
                          { value: 4 },
                          { value: 0 },
                          { value: 11 },
                          { value: 43 },
                          { value: 42 },
                          { value: 103 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td} />
                      <span className={styles.td}>
                        <span className={styles.button}>W</span>
                      </span>
                      <span className={styles.chart_td_wrapper}>
                        {[
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 1 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 0 },
                          { value: 83 },
                          { value: 272 },
                          { value: 102 },
                          { value: 458 },
                        ].map((el, index) => (
                          <span
                            key={index}
                            className={styles.chart_td_item}
                            style={{
                              backgroundColor: colorGenerate(
                                { r: 102, g: 10, b: 63 },
                                el.value
                              ),
                              visibility: el.value === 0 ? "hidden" : "visible",
                            }}
                          >
                            {el.value}
                          </span>
                        ))}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ComparisonViewComponent;
