import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  jwt,
  component: Component,
  setPathname,
  ...rest
}) => {
  useEffect(() => {
    setPathname(window.location.pathname);
  });
  return (
    <Route
      {...rest}
      render={(props) => {
        if (jwt) {
          return <Component {...props} />; // render component
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location, // this is the path that the user was trying to access before they were redirected
                },
              }}
            />
          );
        }
      }}
    />
  );
};
