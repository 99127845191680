/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useContext } from "react";
import { OpenWith } from "@mui/icons-material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChartjs,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Tooltip } from "../Tooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipChartjs,
  Legend,
  Filler
);

interface ILineChart {
  data: any;
  tension?: number;
  chartTitle: string;
  radius?: number;
  id: string;
  width?: number | string;
  height?: number | string;
}
interface IDataSet {
  label: string;
  data: any;
  fill: boolean;
  backgroundColor: any;
  borderColor: string;
  radius: number;
  tension: number;
}
export default function LineChart({
  data,
  tension = 0.5,
  chartTitle,
  radius = 1,
  id,
  width = "100%",
  height = "100%",
}: ILineChart) {
  const [dataSet, setDataSet] = useState<IDataSet[]>([]);
  const chartRef = useRef();
  const { theme } = useContext(ThemeContext);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        position: "top",
        align: "start",
        text: chartTitle,
        padding: 20,
        color: "rgba(255, 255, 255, 0.6)",
        font: {
          size: 10,
          family: "Fabriga",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          color: "#fff",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        grid: {
          color: theme === "light" ? "rgba(0, 0, 0,0.1)" : "rgb(31, 28, 46)",
        },
        beginAtZero: true,
      },
      x: {
        grid: {
          display: false,
          color: "red",
          lineWidth: 2,
        },

        ticks: {
          font: {
            size: 10,
          },
          align: "center",
        },
      },
    },
  };

  useEffect(() => {
    let x: any = [];
    data.forEach((element, index) => {
      let datas: any = [];
      element.y.forEach((yAxis, index) => {
        datas.push({
          y: yAxis,
          x: element.x[index],
        });
      });
      const obj = {
        label: element.name,
        data: datas,
        fill: true,
        backgroundColor: element.line
          ? getGradient(
              element.line.color.indexOf("#") > -1
                ? element.line.color
                : "#ef4351"
            )
          : "#ef4351",
        borderColor: element.line ? element.line.color : "#ef4351",
        radius: radius,
        pointBackgroundColor: element.line ? element.line.color : "#ef4351",
        tension: tension,
      };
      x[index] = obj;
    });
    setDataSet(x);
  }, [data, isFullScreen]);

  const getGradient = (lineColor) => {
    const chart: any = chartRef.current;
    if (!chart) {
      return;
    }
    return createGradient(chart.ctx, lineColor);
  };

  const createGradient = (ctx, color) => {
    const gradient = ctx.createLinearGradient(
      0,
      -350,
      0,
      isFullScreen ? 1080 : 350
    );
    let opacity = `${color}00`;
    gradient.addColorStop(0, color);
    gradient.addColorStop(0.8, opacity);
    return gradient;
  };

  const array = {
    datasets: dataSet,
  };
  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.custom-id-${id}`);
      selectedElement?.requestFullscreen();
    }
  }

  return (
    <div
      className={`${styles.container} custom-id-${id}`}
      data-theme={theme}
      data-fullscreen={isFullScreen}
      style={{ width, height }}
    >
      <div className={styles.fullscreen_button}>
        <Tooltip title="Full Screen">
          <OpenWith
            onClick={() => {
              setIsFullScreen(!isFullScreen);
              toggleFullScreen();
            }}
          />
        </Tooltip>
      </div>
      <Line ref={chartRef} options={options} data={array} />
    </div>
  );
}
