import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IMarketShareVsAverageFare {
  filterList: IFilterContext;
  graphCarriers: string;
  time_direction: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}

export class MarketShareVsAverageFareClient {
  private static _client: RestClient;
  static fetchMarketShareVsAverageFare(params: IMarketShareVsAverageFare) {
    const {
      time_direction,
      filterList,
      selected_yearmonth,
      dark_theme,
      graphCarriers,
    } = params;
    const qp = QueryParameterStringBuilder.apply(filterList);
    return MarketShareVsAverageFareClient.getClient().getCall(
      `/api/msdv2/market-share/share-vs-fare?${qp}&time_direction=${time_direction}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}&graph_carriers=${graphCarriers}`
    );
  }
  static getClient() {
    if (!MarketShareVsAverageFareClient._client) {
      MarketShareVsAverageFareClient._client = new RestClient();
    }
    return MarketShareVsAverageFareClient._client;
  }
}
