import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

const contextDefaultValues: ContextState = {
  date: new Date(),
  setDate: () => {},
};
const DailyFlightsOverviewContext =
  React.createContext<ContextState>(contextDefaultValues);
const DailyFlightsOverviewConsumer = DailyFlightsOverviewContext.Consumer;

const DailyFlightsOverviewContextApp = ({ children }: Props) => {
  const [date, setDate] = useState<Date | null>(new Date());
  return (
    <DailyFlightsOverviewContext.Provider
      value={{
        date,
        setDate,
      }}
    >
      {children}
    </DailyFlightsOverviewContext.Provider>
  );
};

export {
  DailyFlightsOverviewContext,
  DailyFlightsOverviewConsumer,
  DailyFlightsOverviewContextApp,
};
