import LeftMenu from "components/common/LeftMenu/LeftMenu";
import { useState } from "react";
import AddFilter from "./AddFilter/AddFilter";
import DynamicFilter from "./DynamicFilter/DynamicFilter";

// interface IComparativeAnalysis {}

export default function COMPARATIVEANALYSIS() {
  const [dynamicFilters, setDynamicFilters] = useState([
    {
      origRegion: [],
      destRegion: [],
      origCountry: [],
      destCountry: [],
      origCityAirport: [],
      destCityAirport: [],
      pos: [],
      mainCompetitor: [],
      selectedCompetitors: [],
      salesChannel: [],
      cabin: [],
      paxType: [],
      currency: [],
    },
  ]);

  return (
    <div style={{ display: "flex" }}>
      <LeftMenu />
      <div style={{ padding: "16px 0" }}>
        {dynamicFilters?.map((el, index) => (
          <DynamicFilter
            key={index}
            filterElement={el}
            filterNumber={index}
            setDynamicFilters={setDynamicFilters}
            dynamicFilters={dynamicFilters}
          />
        ))}
        <div style={{ margin: "16px 0" }}>
          <AddFilter
            dynamicFilters={dynamicFilters}
            setDynamicFilters={setDynamicFilters}
          />
        </div>
      </div>
    </div>
  );
}
