import { RestClient } from "lib/api/RestClient";
// import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";

export class RulesDeleteSimpleByIdClient {
  private static _client: RestClient;

  static fetchRulesDeleteSimple(id) {
    return RulesDeleteSimpleByIdClient.getClient().deleteCall(
      `/api/lfa/rules/simplev2/${id}`
    );
  }

  static getClient() {
    if (RulesDeleteSimpleByIdClient._client == null) {
      RulesDeleteSimpleByIdClient._client = new RestClient();
    }
    return RulesDeleteSimpleByIdClient._client;
  }
}
