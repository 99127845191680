import React, { useState } from "react";

export interface State {
    lastUpdatedHistoricalDate: string;
}

export interface State {
    lastUpdatedHistoricalDate: string;
    lastUpdatedForwordDate: string;
}
export interface Context {
    configs: State;
    set: (key: string, value: string | number | boolean) => void;
}
const ConfigContext = React.createContext<Context>({
    configs: {
        lastUpdatedHistoricalDate: "",
        lastUpdatedForwordDate: "",
    },
    set: () => null,
});

const ConfigContextProvider = ({ children }) => {
    const [state, setState] = useState<State>({
        lastUpdatedHistoricalDate: "",
        lastUpdatedForwordDate: "",
    });

    const set = (key: string, value: string | number | boolean) => {
        setState(prevState => ({ ...prevState, [key]: value }));
    };

    return (
        <ConfigContext.Provider value={{ configs: state, set }}>{children}</ConfigContext.Provider>
    );
};

export { ConfigContext, ConfigContextProvider };
