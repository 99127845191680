import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { useContext } from "react";
import { UserProfileContext } from "context-api/UserProfile";

interface IData {
  generalInfo: {
    all_gap_max: string;
    all_gap_min: string;
    all_avg_gap: string;
    all_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        all_gap_max_weekday: string;
        all_gap_max_date: string;
        all_gap_max_time: string;
      };
      gap_min: {
        all_gap_min_weekday: string;
        all_gap_min_date: string;
        all_gap_min_time: string;
      };
    };
  };
  positiveInfo: {
    pos_gap_max: string;
    pos_gap_min: string;
    pos_avg_gap: string;
    pos_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        pos_gap_max_weekday: string;
        pos_gap_max_date: string;
        pos_gap_max_time: string;
      };
      gap_min: {
        pos_gap_min_weekday: string;
        pos_gap_min_date: string;
        pos_gap_min_time: string;
      };
    };
  };
  negativeInfo: {
    neg_gap_max: string;
    neg_gap_min: string;
    neg_avg_gap: string;
    neg_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        neg_gap_max_weekday: string;
        neg_gap_max_date: string;
        neg_gap_max_time: string;
      };
      gap_min: {
        neg_gap_min_weekday: string;
        neg_gap_min_date: string;
        neg_gap_min_time: string;
      };
    };
  };
  overview: {
    pos: {
      days: string;
      limit: string;
      avg: number;
    };
    neg: {
      days: string;
      limit: string;
      avg: number;
    };
  };
}
interface Props {
  data: IData[];
  title?: string;
}
export default function Highlights({ data, title = "Highlights" }: Props) {
  const { user } = useContext(UserProfileContext);
  return (
    <Card variant="secondary" title={title}>
      {data?.map((el, index) => (
        <div key={index} className={styles.meaningful_element}>
          <Card variant="primary">
            <div>
              <p
                className={styles.carrier}
                style={{
                  backgroundColor: el?.generalInfo?.carrier?.color ?? "",
                }}
              >
                {el?.generalInfo?.carrier?.value ?? ""}
              </p>
              <div className={styles.content}>
                <p
                  className={styles.pos}
                  data-active={el?.overview?.pos?.days ? true : false}
                >
                  On
                  <span className={styles.card}>
                    <span
                      className={styles.card_icon}
                      style={{
                        backgroundColor: el?.generalInfo?.carrier?.color,
                      }}
                    >
                      <img src={images.a_to_b} alt="" />
                    </span>
                    <span className={styles.card_text}>
                      {el?.overview?.pos?.days ?? "-"}
                    </span>
                  </span>
                  <span className={styles.card}>
                    <span
                      className={styles.card_icon}
                      style={{
                        backgroundColor: el?.generalInfo?.carrier?.color,
                      }}
                    >
                      <img src={images.alarm_clock_time} alt="" />
                    </span>
                    <span className={styles.card_text}>
                      {el?.overview?.pos?.limit}
                    </span>
                  </span>
                  of the time,{" "}
                  <span
                    className={styles.carrier}
                    style={{
                      backgroundColor: el?.generalInfo?.carrier?.color ?? "",
                    }}
                  >
                    {el?.generalInfo?.carrier?.value ?? ""}
                  </span>{" "}
                  prices are on average {el?.overview?.pos?.avg ?? "-"} more
                  expensive
                  <span className={styles.card}>{`>`}</span>
                  than
                  <span
                    className={styles.carrier}
                    style={{ backgroundColor: user?.clientCodeColor ?? "" }}
                  >
                    {user?.clientCode ?? ""}
                  </span>
                </p>
                <span
                  className={styles.hr}
                  data-active={
                    el?.overview?.pos?.days && el?.overview?.neg?.days
                      ? true
                      : false
                  }
                ></span>
                <p
                  className={styles.neg}
                  data-active={el?.overview?.neg?.days ? true : false}
                >
                  On
                  <span className={styles.card}>
                    <span
                      className={styles.card_icon}
                      style={{
                        backgroundColor: el?.generalInfo?.carrier?.color,
                      }}
                    >
                      <img src={images.a_to_b} alt="" />
                    </span>
                    <span className={styles.card_text}>
                      {el?.overview?.neg?.days ?? "-"}
                    </span>
                  </span>
                  <span className={styles.card}>
                    <span
                      className={styles.card_icon}
                      style={{
                        backgroundColor: el?.generalInfo?.carrier?.color,
                      }}
                    >
                      <img src={images.alarm_clock_time} alt="" />
                    </span>
                    <span className={styles.card_text}>
                      {el?.overview?.neg?.limit}
                    </span>
                  </span>
                  of the time,
                  <span
                    className={styles.carrier}
                    style={{
                      backgroundColor: el?.generalInfo?.carrier?.color ?? "",
                    }}
                  >
                    {el?.generalInfo?.carrier?.value ?? ""}
                  </span>{" "}
                  prices are on average {el?.overview?.neg?.avg ?? "-"} cheaper{" "}
                  <span className={styles.card}>{`<`}</span> than
                  <span
                    className={styles.carrier}
                    style={{ backgroundColor: user?.clientCodeColor ?? "" }}
                  >
                    {user?.clientCode ?? ""}
                  </span>
                </p>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </Card>
  );
}
