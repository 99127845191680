import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import CustomAtarevTable from "components/common/Table/CustomTable";

interface INetworkTable {
  filterIsOpen: boolean;
  data: { data: any[]; columns: any[] };
  storyText: {
    network_connectivity: {
      content: string;
      title: string;
    };
    network_table: {
      content: string;
      title: string;
    };
  };
}

export default function NetworkTable({ data, storyText }: INetworkTable) {
  return (
    <div className={styles.container}>
      <Card
        title="Network Table"
        variant="secondary"
        height={550}
        // cardInfo={{
        //   title: storyText?.network_table?.title ?? null,
        //   description: storyText?.network_table?.content ?? null,
        // }}
      >
        <CustomAtarevTable
          data={data.data}
          columns={data.columns}
          pagination
          isShowing={false}
          height={400}
        />
      </Card>
    </div>
  );
}
