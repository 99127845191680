import DailyFlightsOverview from "components/lfa/DailyFlightOverview";
import Layout from "../../../components/common/Layout/Layout";
import { useState } from "react";

function DAILYFLIGHTSOVERVIEW() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Daily Flights Overview"
      filterHidden
    >
      <DailyFlightsOverview />
    </Layout>
  );
}

export default DAILYFLIGHTSOVERVIEW;
