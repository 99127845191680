import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareRevenueRevenueTrends {
  filterList: IFilterContext;
  graphCarriers: string;
  date_range_start?: string;
  date_range_end?: string;
  dark_theme: boolean;
}

export class FareRevenueRevenueTrendsClient {
  private static _client: RestClient;

  static fetchFareRevenueRevenueTrends({
    filterList,
    graphCarriers,
    date_range_start = "2019-01",
    date_range_end = "2021-12",
    dark_theme,
  }: IFareRevenueRevenueTrends) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return FareRevenueRevenueTrendsClient.getClient().getCall(
      `/api/msdv2/fare-revenue/revenue-trends?date_range_start=${date_range_start}&date_range_end=${date_range_end}&${qp}&dark_theme=${dark_theme}&graph_carriers=${graphCarriers}`
    );
  }

  static getClient() {
    if (FareRevenueRevenueTrendsClient._client == null) {
      FareRevenueRevenueTrendsClient._client = new RestClient();
    }
    return FareRevenueRevenueTrendsClient._client;
  }
}
