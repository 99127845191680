import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  isProgress: boolean;
  setIsProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const contextDefaultValues: ContextState = {
  isProgress: false,
  setIsProgress: () => {},
};
const AtarevProgressLinearContext =
  React.createContext<ContextState>(contextDefaultValues);
const AtarevProgressLinearConsumer = AtarevProgressLinearContext.Consumer;

const AtarevProgressLinearContextApp = ({ children }: Props) => {
  const [isProgress, setIsProgress] = useState<boolean>(true);
  return (
    <AtarevProgressLinearContext.Provider
      value={{
        isProgress,
        setIsProgress,
      }}
    >
      {children}
    </AtarevProgressLinearContext.Provider>
  );
};

export {
  AtarevProgressLinearContext,
  AtarevProgressLinearConsumer,
  AtarevProgressLinearContextApp,
};
