import { CiStar } from "react-icons/ci";
import { FaArrowRightLong } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { HolidaysFilterClient } from "lib/api/holidays/GetFilterOptions";
import Card from "components/common/Card/Card";
import CustomAtarevTable from "components/common/Table/CustomTable";
import Dropdown from "components/common/Dropdown/Dropdown";
import Input from "components/common/Input/input";
import ColoredIcon from "utils/ColoredIcon";
import DatePickerComponent from "components/common/DatePicker/DatepickerLastest";
import SideDrawer from "components/common/SideDrawer";
import Button from "components/common/Button/Button";
import images from "constans/images";
import styles from "./styles.module.css";
import MenuItem from "./FilterMenu";

const AlertRules = () => {
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<
    {
      value: string;
      sub_categories: {
        value: string;
        enabled: boolean;
        selected: boolean;
      }[];
    }[]
  >([]);
  const [categoriesInitial, setCategoriesInitial] = useState<
    {
      value: string;
      sub_categories: {
        value: string;
        enabled: boolean;
        selected: boolean;
      }[];
    }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(categories);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    const params = {
      target: "ea",
    };
    setLoading(true);
    HolidaysFilterClient.fetchFilterOptions(params)
      .then((res: any) => {
        const data = res?.categories?.map((el) => ({
          ...el,
          sub_categories: el?.sub_categories?.map((item) => ({
            ...item,
            selected: false,
            enabled: true,
          })),
        }));
        setCategories(data);
        setCategoriesInitial(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let filteredData = categories;
    if (searchTerm.length > 0) {
      filteredData = categories.filter(
        (item) =>
          item.sub_categories.some(
            (subCat) =>
              subCat.value.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
          ) ||
          item?.value?.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
      );
      filteredData = filteredData.map((item) => ({
        ...item,
        sub_categories: item.sub_categories.filter(
          (subCat) =>
            subCat.value.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0
        ),
      }));
    }
    setFilteredData(filteredData);
  }, [categories, searchTerm]);

  const handleSelectAll = () => {
    const newData = categories.map((item) => ({
      ...item,
      sub_categories: item?.sub_categories?.map((field) => ({
        ...field,
        selected: true,
      })),
    }));
    setCategories(newData);
  };

  const handleClearAll = () => {
    const newData = categories?.map((item) => ({
      ...item,
      sub_categories: item?.sub_categories?.map((field) => ({
        ...field,
        selected: false,
      })),
    }));
    setCategories(newData);
  };

  const handleReset = () => {
    setCategories(categoriesInitial);
  };
  return (
    <div className={styles.container}>
      <div className={styles.rules_table_wrapper}>
        <Card variant="secondary" title="Rules Table">
          <Card variant="primary">
            <CustomAtarevTable
              data={[]}
              columns={[]}
              pagination
              loader={loading}
            />
          </Card>
        </Card>
      </div>
      <div className={styles.rules_creator}>
        <Card variant="secondary" title="Rules Creator">
          <div className={styles.rules_creator_body}>
            <Card variant="primary" width={"100%"}>
              <div className={styles.card_header}>
                <span>General Information</span>
                <ColoredIcon
                  imgPath={images.line_pen}
                  backgroundColor={"#5504d9"}
                />
              </div>
              <div className={styles.card_body_test}>
                <div className={styles.card_body_rules}>
                  <span className={styles.rule_name}>Rule Name</span>
                  <Input
                    type="text"
                    value={""}
                    onChange={(e) => {}}
                    placeholder="Rule Name"
                  />
                </div>
                <div className={styles.card_body_rules}>
                  <div className={styles.rules_creator_description}>
                    <div className={styles.description}>
                      <span>Description</span>
                      <textarea id="description" />
                    </div>
                    <div className={styles.description}>
                      <span>Notes</span>
                      <textarea id="notes" />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </div>
      <div className={styles.rules_criteria}>
        <Card variant="secondary" title="Rules Criteria">
          <div className={styles.rules_criteria_body}>
            <div className={styles.left}>
              <div className={styles.left_wrapper}>
                <Card variant="primary" zIndex={2}>
                  <div className={styles.card_header}>
                    <span>Market Group</span>
                    <ColoredIcon
                      imgPath={images.navigation_maps}
                      backgroundColor={"#144aff"}
                    />
                  </div>
                  <div className={styles.card_body}>
                    <Dropdown data={[]} added={[]} setAdded={(item) => {}} />
                  </div>
                </Card>
                <Card variant="primary" zIndex={1}>
                  <div className={styles.card_header}>
                    <span>Competitor</span>
                    <ColoredIcon
                      imgPath={images.clock_watch}
                      backgroundColor={"#d77cbb"}
                    />
                  </div>
                  <div className={styles.card_body}>
                    <Dropdown data={[]} added={[]} setAdded={(item) => {}} />
                  </div>
                </Card>
              </div>
              <div className={styles.left_wrapper}>
                <Card variant="primary" zIndex={2}>
                  <div className={styles.card_header}>
                    <span>Country / City</span>
                    <ColoredIcon
                      imgPath={images.flag}
                      backgroundColor={"#9c00ed"}
                    />
                  </div>
                  <div className={styles.card_body}>
                    <Dropdown data={[]} added={[]} setAdded={(item) => {}} />
                  </div>
                </Card>
                <Card variant="primary">
                  <div className={styles.card_header}>
                    <span>Days to Event</span>
                    <ColoredIcon
                      imgPath={images.travel_plane_take_off}
                      backgroundColor={"#5504d9"}
                    />
                  </div>
                  <div className={styles.card_body}>
                    <div className={styles.dtd_wrapper}>
                      <Input />
                      <FaArrowRightLong size={32} />
                      <Input />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className={styles.right}>
              <Card variant="primary" width={"100%"}>
                <div className={styles.card_body}>
                  <DatePickerComponent
                    value={new Date()}
                    onChangeDate={(date) => {}}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className={styles.rules_criteria_footer}>
            <Card variant="primary" width={"100%"}>
              <div className={styles.card_header}>
                <span>Cabin</span>
                <ColoredIcon
                  imgPath={images.boarding_pass_white}
                  backgroundColor={"#ec4252"}
                />
              </div>
              <div className={styles.card_body}>
                <div className={styles.events_wrapper}>
                  {["FIRST", "BUS", "ECO"].map((el, index) => (
                    <Button
                      key={index}
                      data={el}
                      added={[]}
                      setAdded={() => {}}
                      width={64}
                      text="center"
                    >
                      {el}
                    </Button>
                  ))}
                </div>
              </div>
            </Card>
            <Card variant="primary" width={"100%"}>
              <div className={styles.card_header}>
                <span>Events</span>
                <ColoredIcon
                  imgPath={images.a_to_b}
                  backgroundColor={"#5504d9"}
                />
              </div>
              <div className={styles.card_body}>
                <div className={styles.events_wrapper}>
                  <SideDrawer
                    MenuButton={
                      <span className={styles.setting_button}>
                        <CiStar size={24} />
                      </span>
                    }
                    direction="right"
                    width={450}
                  >
                    <div className={styles.menu_wrapper}>
                      <h3 className={styles.menu_title}>Event Categories</h3>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={handleClearAll}>Clear All</button>
                      <button onClick={handleSelectAll}>Select All</button>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {filteredData.map((item, index) => (
                        <MenuItem
                          key={index}
                          item={item}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                        />
                      ))}
                    </div>
                  </SideDrawer>
                  <div className={styles.selected_categories_wrapper}>
                    {selectedCategories.map((el, index) => (
                      <span className={styles.selected_categories} key={index}>
                        {el}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </div>
      <div className={styles.condition}>
        <Card variant="secondary" title="Conditions">
          <div className={styles.card_wrapper}>
            <Card variant="primary" width={"100%"}>
              <div className={styles.card_header}>
                <span>Setup</span>
                <ColoredIcon
                  imgPath={images.programming_code_checkmark}
                  backgroundColor={"#d77cbb"}
                />
              </div>
              <div className={styles.card_body}>
                <div className={styles.table_header}>
                  {["", "Input", "Operator", "Value", ""].map((el, index) => (
                    <span key={index} className={styles.table_field}>
                      {el}
                    </span>
                  ))}
                </div>
                <div className={styles.table_body}>
                  {[1, 2, 3, 4].map((el, index) => (
                    <div key={index} className={styles.table_tr}>
                      <span className={styles.table_td}>{index + 1}.</span>
                      <span className={styles.table_td}>
                        <Dropdown data={[]} added={[]} setAdded={() => {}} />
                      </span>
                      <span className={styles.table_td}>
                        <Dropdown data={[]} added={[]} setAdded={() => {}} />
                      </span>
                      <span className={styles.table_td}>
                        <Input placeholder="Value" />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
            <Card variant="primary" width={"100%"}>
              <div className={styles.card_header}>
                <span>Actions</span>
                <ColoredIcon
                  imgPath={images.programming_code_checkmark}
                  backgroundColor={"#5504d9"}
                />
              </div>
              <div className={styles.card_body}>
                <div className={styles.table_header}>
                  {["", "Category", ""].map((el, index) => (
                    <span key={index} className={styles.table_field}>
                      {el}
                    </span>
                  ))}
                </div>
                <div className={styles.table_body}>
                  {[1, 2, 3, 4].map((el, index) => (
                    <div key={index} className={styles.table_tr}>
                      <span className={styles.table_td}>{index + 1}.</span>
                      <span className={styles.table_td}>
                        <Dropdown data={[]} added={[]} setAdded={() => {}} />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AlertRules;
