import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./styles.module.css";
import { RulesDeleteSimpleByIdClient } from "lib/api/lfa/rules/RulesDeleteSimpleById";
import Notification from "components/common/Notification/Notification";
import Confirmation from "components/common/ConfirmationModal/ConfirmationModal";
import { ThemeContext } from "context-api/ThemeContext";
import { ThemeProvider, createTheme } from "@mui/material";
function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}
const fullscreenTheme = createTheme({
  components: {
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;

export default function OpenMenu({ editRule, id, setCreatedNewRule }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let newWindow;
  const [isOpen, setIsOpen] = React.useState(false);
  const { theme } = React.useContext(ThemeContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const deleteRule = (e) => {
    e.stopPropagation();
    RulesDeleteSimpleByIdClient.fetchRulesDeleteSimple(id)
      .then(() => {
        handleClose(e);
        setCreatedNewRule((state: boolean) => !state);
        Notification({ type: "success", message: "Rule Deleted" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openPriorityWindow = () => {
    if (newWindow && !newWindow.closed) {
      newWindow.close();
    }
    newWindow = window.open(
      `/rules-priority?id=${id}`,
      "",
      "width=1080,height=400,left=200,top=200"
    );
  };

  return (
    <ThemeProvider theme={fullscreenTheme}>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: theme === "dark" ? "#fff" : "#000" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "12ch",
              backgroundColor: theme === "dark" ? "#383051" : "#fff",
              color: theme === "dark" ? "#fff" : "#000",
              borderRadius: 12,
            },
          }}
        >
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={(e) => {
              editRule(id, "duplicate");
              handleClose(e);
            }}
          >
            Duplicate
          </MenuItem>
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={(e) => {
              openPriorityWindow();
              handleClose(e);
            }}
          >
            Priority
          </MenuItem>
          <Confirmation
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            event={deleteRule}
            title={"Rule Deletion"}
            description={"Are you sure you want to delete the rule?"}
          >
            <MenuItem className={styles.menu_item} data-theme={theme}>
              Delete
            </MenuItem>
          </Confirmation>
        </Menu>
      </div>
    </ThemeProvider>
  );
}
