/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import Card from "components/common/Card/Card";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./styles.module.css";
import { MarketShareTrendsClient } from "../../../lib/api/msd/marketShareTrends/MarketShareTrendsClient";
import Carriers from "components/common/Carriers/Carriers";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x.webp";
import { FilterContext } from "context-api/FilterContext";
import LineChart from "components/common/Charts/Line";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import images from "constans/images";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";

interface IMarketTrends {
  filterIsOpen: boolean;
}

export default function MarketTrends({ filterIsOpen }: IMarketTrends) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [rangeValue, setRangeValue] = useState({
    value: [0, 11],
    min: 0,
    max: 20,
    dateList: [],
  });
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [fareTrendsData, setFareTrendsData] = useState([]);
  const [shareTrendsData, setShareTrendsData] = useState([]);
  const { filterList } = useContext(FilterContext);
  const [viewIsActiveHistorical, setViewIsActiveHistorical] = useState(true);
  const [viewIsActiveForward, setViewIsActiveForward] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [storyText, setStoryText] = useState({
    fare_trends: {
      content: "",
      title: "",
    },
    main_card: {
      content: "",
      title: "",
    },
    share_trends: {
      content: "",
      title: "",
    },
  });
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      time_direction: getViewButtonValue(),
      agg_type: "monthly",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response?.values?.length ? response?.values?.length - 1 : 0,
          dateList: response?.values,
          value: [response?.start_idx, response?.end_idx],
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, [viewIsActiveForward, viewIsActiveHistorical]);

  useEffect(() => {
    if (filterIsOpen === false && rangeValue.dateList.length > 0) {
      MarketShareTrendsClient.fetchMarketShareTrends({
        filterList,
        graphCarriers: selectedCarriers.join(),
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        time_direction: getViewButtonValue(),
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setFareTrendsData(response.fig_fareTrends.data);
          setShareTrendsData(response.fig_shareTrends.data);
          setCarriersJson(response?.carriers);
          setStoryText(response?.story_text);
        })
        .catch(() => {
          setFareTrendsData([]);
          setShareTrendsData([]);
        });
    }
  }, [filterIsOpen, filterList, selectedCarriers, rangeValue.value, theme]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  const getViewButtonValue = () => {
    if (viewIsActiveForward && viewIsActiveHistorical) {
      return "historical-forward";
    } else if (viewIsActiveForward && viewIsActiveHistorical === false) {
      return "forward";
    } else if (viewIsActiveForward === false && viewIsActiveHistorical) {
      return "historical";
    } else {
      setViewIsActiveForward(true);
      return "forward";
    }
  };
  return (
    <Card
      variant="secondary"
      title="Market Trends"
      cardInfo={{
        title: storyText?.main_card?.title ?? null,
        description: storyText?.main_card?.content ?? null,
      }}
    >
      <div className={styles.card_header}>
        <div className={styles.carriers_wrapper}>
          <Carriers
            data={GetCarriers()}
            added={selectedCarriers}
            setAdded={setSelectedCarriers}
            carriersJson={carriersJson}
          />
        </div>
        <div className={styles.open_menu_wrapper} ref={wrapperRef}>
          <img
            src={theme === "dark" ? settingIcon : images.setting_button_light}
            alt=""
            className={styles.setting_button}
            onClick={() => setIsOpen(!isOpen)}
          />
          <div
            className={styles.open_menu}
            data-active={isOpen}
            data-theme={theme}
          >
            <div className={styles.top} data-theme={theme}>
              <div className={styles.top_text_wrapper}>
                <span className={styles.open_menu_view_text} data-theme={theme}>
                  View
                </span>
                <img
                  src={images.eye}
                  alt=""
                  className={styles.view_icon}
                  data-theme={theme}
                />
              </div>
              <div className={styles.top_buttons_wrapper}>
                <div
                  className={styles.open_menu_buttons}
                  data-active={viewIsActiveForward}
                  onClick={() => setViewIsActiveForward(!viewIsActiveForward)}
                  data-theme={theme}
                >
                  Forward
                </div>
                <div
                  className={styles.open_menu_buttons}
                  data-active={viewIsActiveHistorical}
                  onClick={() =>
                    setViewIsActiveHistorical(!viewIsActiveHistorical)
                  }
                  data-theme={theme}
                >
                  Historical
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chart_wrapper}>
        <Card
          variant="primary"
          title="Share Trends"
          titlePosition="center"
          width={"calc(50% - 4px)"}
          height={364}
          // cardInfo={{
          //   title: storyText?.share_trends?.title ?? null,
          //   description: storyText?.share_trends?.content ?? null,
          // }}
        >
          <div className={styles.chart}>
            <LineChart
              data={shareTrendsData}
              chartTitle={"Market Share (%)"}
              id="1"
              height={288}
            />
          </div>
        </Card>
        <Card
          variant="primary"
          title="Fare Trends"
          titlePosition="center"
          width={"calc(50% - 4px)"}
          height={364}
          // cardInfo={{
          //   title: storyText?.fare_trends?.title ?? null,
          //   description: storyText?.fare_trends?.content ?? null,
          // }}
        >
          <div className={styles.chart}>
            <LineChart
              data={fareTrendsData}
              chartTitle={"Average Fare ($)"}
              id={"2"}
              height={288}
            />
          </div>
        </Card>
      </div>
      <div className={styles.range_slider_wrapper}>
        <RangeSlider
          data={{
            max: rangeValue.max,
            min: rangeValue.min,
            value: rangeValue.value,
            dateList: rangeValue.dateList,
            setValue: setRangeValue,
          }}
        />
      </div>
    </Card>
  );
}
