export class QueryParameterStringBuilder {
  /**
   * Converts a simple object (not nested and values are only string, number or primitive type's array) to query parameter string
   * @param object simple object (not nested and values are only string, number or primitive type's array)
   * @param toSnakeCase should "anyKey" become "any_key"? (defaultly true)
   * @returns
   */
  public static apply(object: Object | null, toSnakeCase = true): string {
    if (object == null) return "";
    let holder: string[] = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const value = object[key];
        let finalKey = key,
          finalValue = "";

        if (toSnakeCase) {
          finalKey = finalKey.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`);
        }

        if (value) {
          let flag = false;
          if (!(value instanceof Array)) {
            finalValue = value.toString();
            flag = true;
          } else if (value.length > 0) {
            finalValue = value.join(",");
            flag = true;
          }
          if (flag) holder.push(`${finalKey}=${finalValue}`);
        }
      }
    }
    const str = holder.join("&");
    return str;
  }
}
