import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface ICustomerSegmentationTable {
  filterList: IFilterContext;
}

export class CustomerSegmentationTableClient {
  private static _client: RestClient;

  static fetchCustomerSegmentationTable({
    filterList,
  }: ICustomerSegmentationTable) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return CustomerSegmentationTableClient.getClient().getCall(
      `/api/msdv2/customer-segmentation/segmentation-table?${qp}`
    );
  }

  static getClient() {
    if (CustomerSegmentationTableClient._client == null) {
      CustomerSegmentationTableClient._client = new RestClient();
    }
    return CustomerSegmentationTableClient._client;
  }
}
