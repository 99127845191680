import { RestClient } from "lib/api/RestClient";

export class FlightsClient {
  private static _client: RestClient;
  static fetchFlights(params: any) {
    const queryString = new URLSearchParams(params).toString();
    return FlightsClient.getClient().getCall(
      `/api/msdv2/fare-structure/flights?${queryString}`
    );
  }
  static getClient() {
    if (FlightsClient._client == null) {
      FlightsClient._client = new RestClient();
    }
    return FlightsClient._client;
  }
}
