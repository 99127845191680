import { RestClient } from "lib/api/RestClient";

export class CustomersClient {
  private static _client: RestClient;
  static fetchCustomers() {
    return CustomersClient.getClient().getCall(
      `/api/msdv2/configuration/customers`
    );
  }
  static getClient() {
    if (CustomersClient._client == null) {
      CustomersClient._client = new RestClient();
    }
    return CustomersClient._client;
  }
}
