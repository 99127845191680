export default function upperCaseLetters(str) {
  let result = "";

  // String'deki her karakteri tek tek kontrol edelim
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);

    // Eğer karakter bir harf ise, büyük harfe çevirip result değişkenine ekleyelim
    if (/[a-zA-Z]/.test(char)) {
      result += char.toUpperCase();
    } else {
      result += char; // Harf değilse, karakteri değiştirmeden result değişkenine ekleyelim
    }
  }

  return result;
}
