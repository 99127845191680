import { ReactNode, useContext } from "react";
import styles from "./Card.module.css";
import { StyleSheet, css } from "aphrodite";
import CardInfo from "../CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";

interface ICard {
  children?: ReactNode;
  width?: string | number;
  height?: string | number;
  title?: string;
  titlePosition?: "left" | "center" | "right";
  titleMargin?: string;
  icon?: any;
  iconSize?: "sm" | "lg";
  iconBackgroundColor?: string;
  variant: "primary" | "secondary" | "tertiary";
  border?: boolean;
  borderHoverColor?: string;
  borderColor?: string;
  borderRadius?: string | number;
  zIndex?: number;
  fontSize?: string | number;
  cardInfo?: {
    title: string | null;
    description: string | null;
    visible?: boolean;
  };
}

export default function Card({
  children,
  width,
  height,
  title,
  titlePosition = "left",
  titleMargin = "20px 16px",
  icon,
  iconSize = "lg",
  iconBackgroundColor,
  variant,
  border,
  borderColor,
  borderHoverColor,
  borderRadius,
  zIndex,
  cardInfo,
  fontSize = 20,
}: ICard) {
  const custommStyles = StyleSheet.create({
    width: {
      width: width,
    },
    height: {
      height: height,
    },
    borderHoverColored: {
      ":hover": {
        backdropFilter: "blur(5px)",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
        borderStyle: "solid",
        borderWidth: 1,
        borderImageSource: `linear-gradient(244deg,${borderHoverColor} , rgba(19, 74, 239, 0) 70%)`,
        borderImageSlice: 1,
        backgroundImage: `linear-gradient(236deg, ${borderHoverColor}00 218%, rgba(20, 74, 255, 0) 79%), linear-gradient(to bottom, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), linear-gradient(244deg, ${borderHoverColor}33, transparent 30%)`,
        backgroundOrigin: "border-box",
        backgroundClip: "border-box, border-box, border-box",
      },
    },
    borderColored: {
      backdropFilter: "blur(5px)",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
      borderStyle: "solid",
      borderWidth: 1,
      borderImageSource: `linear-gradient(244deg,${borderColor} , rgba(19, 74, 239, 0) 70%)`,
      borderImageSlice: 1,
      backgroundImage: `linear-gradient(236deg, ${borderColor}00 218%, rgba(20, 74, 255, 0) 79%), linear-gradient(to bottom, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), linear-gradient(244deg, ${borderColor}33, transparent 30%)`,
      backgroundOrigin: "border-box",
      backgroundClip: "border-box, border-box, border-box",
    },
    borderRadiused: {
      borderRadius: borderRadius,
      backdropFilter: "blur(5px)",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
      borderStyle: "solid",
      borderWidth: 1,
      borderImageSource: `linear-gradient(244deg,${borderColor} , rgba(19, 74, 239, 0) 70%)`,
      borderImageSlice: 1,
      backgroundImage: `linear-gradient(236deg, ${borderColor}00 218%, rgba(20, 74, 255, 0) 79%), linear-gradient(to bottom, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), linear-gradient(244deg, ${borderColor}33, transparent 30%)`,
      backgroundOrigin: "border-box",
      backgroundClip: "border-box, border-box, border-box",
    },
    zIndex: {
      zIndex: zIndex,
    },
    titleMargin: {
      margin: titleMargin,
    },
  });
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={`${borderHoverColor && css(custommStyles.borderHoverColored)}
      ${width && css(custommStyles.width)}
      ${height && css(custommStyles.height)}
      ${borderColor && css(custommStyles.borderColored)}
      ${borderRadius && css(custommStyles.borderRadiused)}
      ${zIndex && css(custommStyles.zIndex)}
      ${styles.container} ${styles[variant]} ${styles[border ? "border" : ""]}`}
      data-theme={theme}
    >
      {title && (
        <div
          className={`${styles.title_wrapper}
        ${titleMargin && css(custommStyles.titleMargin)}
        `}
          style={{ fontSize }}
        >
          <span
            className={`${styles.title} ${styles[titlePosition]}`}
            data-theme={theme}
          >
            {title}
            {cardInfo && (
              <CardInfo
                title={cardInfo?.title ?? null}
                description={cardInfo?.description ?? null}
                visible={cardInfo?.visible}
              />
            )}
          </span>
          {icon ? (
            iconBackgroundColor ? (
              <span
                className={styles.icon_wrapper}
                style={{
                  backgroundColor: iconBackgroundColor,
                  width: iconSize === "sm" ? 24 : 48,
                  height: iconSize === "sm" ? 24 : 48,
                }}
              >
                <img src={icon} alt="" />
              </span>
            ) : (
              <img
                src={icon}
                alt={""}
                className={styles.only_icon}
                style={{
                  width: iconSize === "sm" ? 24 : 48,
                  height: iconSize === "sm" ? 24 : 48,
                }}
                data-theme={theme}
              />
            )
          ) : (
            ""
          )}
        </div>
      )}
      <div className={styles.children}>{children}</div>
    </div>
  );
}
