enum LogLevel{
    ERROR,
    WARN,
    INFO,
    DEBUG,
    TRACE
}

/**
 * Logging utility with logging levels
 * It should be used for developer logging, not client facing errors though
 *
 * For client facing logging (e.g. failures notifications, errors, ) use ToastNotificationsWrapper
 * TODO - integrate e.g. with winston
 */
export class Logger {
    public static trace(...message: any[]):void{
        Logger.logMessage(LogLevel.TRACE, message);
    }
    public static debug(...message: any[]):void{
        Logger.logMessage(LogLevel.DEBUG, message);
    }
    public static info(...message: any[]):void{
        Logger.logMessage(LogLevel.INFO, message);
    }
    public static warn(...message: any[]):void{
        Logger.logMessage(LogLevel.WARN, message);
    }
    public static error(...message: any[]):void{
        Logger.logMessage(LogLevel.ERROR, message);
    }

    private static logMessage(level:LogLevel,...message: any[]){
        console.log(`logMessage,  message: ${message}, level: ${level}`);
        //TODO add logic to handle different levels
        //TODO integrate with tools like winston
        /*switch(level){
            case LogLevel.TRACE:
                this.log.trace(message);
                break;
            case LogLevel.DEBUG:
                this.log.debug(message);
                break;
            case LogLevel.INFO:
                this.log.info(message);
                break;
            case LogLevel.WARN:
                this.log.warn(message);
                break;
            case LogLevel.ERROR:
                this.log.error(message);
                break;
        }*/
    }
}
