import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";

interface IRecommendedStrategy {
  data: { title: string; value: string[] };
}
export default function RecommendedStrategy({ data }: IRecommendedStrategy) {
  const { theme } = useContext(ThemeContext);

  return (
    <Card
      variant="secondary"
      width={"100%"}
      height={144}
      borderRadius="15px 50px 15px 15px"
      borderColor="#00c1ac"
    >
      <div className={styles.container} data-theme={theme}>
        <span className={styles.icon_wrapper}>
          <img src={images.chess} alt="" />
        </span>
        <div className={styles.title_content_wrapper} data-theme={theme}>
          <span>Recommended Strategy</span>
          <p>{data.value[0]}</p>
          <p>{data.value[1]}</p>
        </div>
      </div>
    </Card>
  );
}
