import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import Table from "components/common/Table/newTable";
import { Tooltip } from "components/common/Tooltip";

const FareTrendsTable = (props) => {
  const {
    handleExport,
    columns,
    data,
    meta,
    isLoading,
    setPaginationModel,
    setSortModel,
  } = props;
  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title}>Fare Trends Table</span>
        <Tooltip title={"Export Excel"}>
          <span className={styles.export_button} onClick={handleExport}>
            <img src={images.excel} alt="" />
          </span>
        </Tooltip>
      </div>
      <Card variant="primary">
        <Table
          columns={columns}
          data={data}
          pagination
          paginationModel={meta}
          loading={isLoading}
          serverMode="server"
          defaultPageSize={10}
          tableHeight="500px"
          onColumnOrderChange={(newColumnOrder) => {
            console.log(
              "Yeni Kolon Sıralaması:",
              newColumnOrder.map((col) => col.key)
            );
          }}
          onPaginationModelChange={(newPaginationModel) => {
            // fetch data from server
            setPaginationModel(newPaginationModel);
          }}
          onSortModelChange={(newSortModel) => {
            // fetch data from server
            setSortModel(newSortModel);
          }}
          onFilterModelChange={(newFilterModel) => {
            // fetch data from server
          }}
        />
      </Card>
    </Card>
  );
};

export default FareTrendsTable;
