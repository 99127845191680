/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface IMenu {
  item: {
    label: string;
    value: string;
    enabled: boolean;
    fields: {
      label: string;
      value: string;
      enabled: boolean;
      selected: boolean;
    }[];
  };
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}
const MenuItem = ({ item, selectedItems, setSelectedItems }: IMenu) => {
  const [expand, setExpand] = useState(true);
  const [fieldsCheckboxChecked, setFieldsCheckboxChecked] = useState({});
  const [groupChecked, setGroupChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    const initialStates = {};
    item.fields.forEach((field) => {
      initialStates[field.value] = field.selected;
    });
    setFieldsCheckboxChecked(initialStates);
  }, [item]);

  useEffect(() => {
    // Seçilen field sayısını verir
    const count = Object.values(fieldsCheckboxChecked).filter(
      (value) => value === true
    ).length;
    setSelectedCount(count);

    // Objenin field içerip içermediğini kontrol eder ve içeriyorsa ve hepsi true ise grubu true olarak günceller
    const hasAnyKey = Object.keys(fieldsCheckboxChecked).length > 0;
    if (hasAnyKey) {
      const allTrue = Object.values(fieldsCheckboxChecked).every(
        (value) => value === true
      );
      if (allTrue) {
        setGroupChecked(true);
      } else setGroupChecked(false);

      // Object içindeki anahtarları dön
      Object.keys(fieldsCheckboxChecked).forEach((key) => {
        // Anahtarın değeri true ise ve selectedItems içinde bulunmuyorsa ekle
        if (
          fieldsCheckboxChecked[key] === true &&
          !selectedItems.includes(key)
        ) {
          setSelectedItems((prevState) => [...prevState, key]);
        } else if (
          fieldsCheckboxChecked[key] === false &&
          selectedItems.includes(key)
        ) {
          // Anahtarın değeri false ise ve selectedItems içinde bulunuyorsa sil
          setSelectedItems((prevState) =>
            prevState.filter((item) => item !== key)
          );
        }
      });
    }
  }, [fieldsCheckboxChecked]);

  const handleAllSelect = (e) => {
    const initialStates = {};
    item.fields.forEach((field) => {
      initialStates[field.value] = e.target.checked;
    });
    setFieldsCheckboxChecked(initialStates);
    setGroupChecked(e.target.checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title_wrapper}>
        <span>
          <input
            type="checkbox"
            name={item.value}
            id={item.value}
            checked={groupChecked}
            onChange={handleAllSelect}
          />
          <label htmlFor={item.value}>
            {item.label}
            {selectedCount > 0 && (
              <span className={styles.selected_count}>{selectedCount}</span>
            )}
          </label>
        </span>
        <span onClick={() => setExpand(!expand)}>
          {!expand ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </span>
      </div>
      {item?.fields?.map((el, index) => {
        return (
          <div key={index} className={styles.expand_panel} data-active={expand}>
            <input
              type="checkbox"
              checked={fieldsCheckboxChecked[el.value] ?? false}
              disabled={!el.enabled}
              name={el.value}
              id={el.value}
              onChange={() => {
                setFieldsCheckboxChecked((prevState) => ({
                  ...prevState,
                  [el.value]: !fieldsCheckboxChecked[el.value],
                }));
              }}
            />
            <label htmlFor={el.value}>{el.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default MenuItem;
