import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import styles from "./styles.module.css";
import { Divider } from "@mui/material";
interface ITooltip {
  data: {
    hoverTexts: {
      label: string;
      value: string;
    }[];
    lastUpdated: string;
  };
  legs?: {
    destination: string;
    duration: string;
    flightKey: string;
    origin: string;
    opAlCode: string;
    mkAlCode: string;
    legArrivalTime: string;
  }[];
  totalTime?: string;
}

export default function LowestMountPriceTooltip({
  data,
  legs,
  totalTime,
}: ITooltip) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.content}>
        {data?.hoverTexts?.map((el, index) => (
          <div key={index}>
            <div className={styles.text_wrapper}>
              {el?.value && (
                <>
                  <div>
                    <span className={styles.text_left} data-theme={theme}>
                      {el?.label}
                    </span>
                  </div>
                  <div>
                    <span className={styles.text_right} data-theme={theme}>
                      {el?.value}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        {legs?.map((el, index) => (
          <>
            <div>
              {el?.origin && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Origin
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.origin}
                  </span>
                </div>
              )}

              {el?.destination && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Destination
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.destination}
                  </span>
                </div>
              )}
              {el?.legArrivalTime && index > 0 && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Arr Time
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.legArrivalTime}
                  </span>
                </div>
              )}
              {el?.flightKey && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Flight Number
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.flightKey}
                  </span>
                </div>
              )}
              {el?.duration && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Duration
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.duration}
                  </span>
                </div>
              )}
              {el?.opAlCode && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Op Al Code
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.opAlCode}
                  </span>
                </div>
              )}
              {el?.mkAlCode && (
                <div className={styles.text_wrapper}>
                  <span className={styles.text_left} data-theme={theme}>
                    Mk Al Code
                  </span>
                  <span className={styles.text_right} data-theme={theme}>
                    {el?.mkAlCode}
                  </span>
                </div>
              )}
            </div>
            <div>
              {legs?.length > 0 && legs?.length > index + 1 && (
                <Divider sx={{ margin: "4px 0" }} />
              )}
            </div>
          </>
        ))}
        {totalTime && (
          <p>
            <span className={styles.text_left} data-theme={theme}>
              Total Time
            </span>
            <span className={styles.text_right} data-theme={theme}>
              {totalTime}
            </span>
          </p>
        )}
        <div>
          {data?.lastUpdated && (
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  Last Updated
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {data?.lastUpdated}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
