import { RestClient } from "../RestClient";
interface IData {
  id?: string | null | undefined;
  country_code?: string | undefined;
  city_code?: string | undefined;
  sub_category?: string | undefined;
  start_date: any;
  end_date: string;
  name: string;
  category: string;
}
export class UpdateEventClient {
  private static _client: RestClient;

  static fetchUpdateEvent(data: IData) {
    return UpdateEventClient.getClient().putCall(
      `/api/msdv2/events/store`,
      data
    );
  }

  static getClient() {
    if (UpdateEventClient._client == null) {
      UpdateEventClient._client = new RestClient();
    }
    return UpdateEventClient._client;
  }
}
