/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  useState,
} from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  compareAsc,
} from "date-fns";
import styles from "./styles.module.css";
import images from "constans/images";
import moment from "moment";
import { FilterContext } from "context-api/FilterContext";
import { LowestFareCalendarClient } from "lib/api/lfa/lowestFareCalendar/LowestFareCalendar";
import LowestMountHolidaysTooltip from "../HolidaysTooltip";
import { ThemeContext } from "context-api/ThemeContext";
import LowestMountPriceTooltip from "../PriceTooltip";
import { TriggerTooltip } from "components/common/Tooltip";
import { BasicFilterContext } from "context-api/BasicFilter";

interface ICalendar {
  selectedDate: Date;
  filterIsOpen: boolean;
  cabin_code: string;
  selectedCarrier: string[];
  setDropdownOptions: Dispatch<SetStateAction<string[]>>;
  selectedFilter: string | null;
  owRtSwitch: "ow" | "rt" | null;
  setSelectedOption: Dispatch<SetStateAction<"Day" | "Week" | "Month">>;
  setDate: Dispatch<SetStateAction<Date>>;
}

const Calendar: React.FC<ICalendar> = ({
  selectedDate,
  filterIsOpen,
  cabin_code,
  selectedCarrier,
  setDropdownOptions,
  selectedFilter,
  owRtSwitch,
  setSelectedOption,
  setDate,
}) => {
  const { filterList } = useContext(FilterContext);
  const { basicFilter } = useContext(BasicFilterContext);

  const startWeek = startOfWeek(startOfMonth(selectedDate), {
    weekStartsOn: 1,
  });
  const endWeek = endOfWeek(endOfMonth(selectedDate), { weekStartsOn: 1 });
  const daysInMonth = eachDayOfInterval({ start: startWeek, end: endWeek });

  const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const getMonth = selectedDate.getMonth(); // getMonth parametresi ile seçilmiş tarihte hangi ayda olduğumuzu buluyorum
  const getFullYear = selectedDate.getFullYear(); // getFullYear parametresi ile seçilmiş tarihte hangi yılda olduğumuzu buluyorum
  const dayOfMonth = new Date(getFullYear, getMonth + 1, 0).getDate();
  const firstDayCurrentMonth = new Date(getFullYear, getMonth, 1); // seçilmiş tarihin ilk gününü buluyorum. 04.11.2022 tarihinin ilk günü 01.11.2022 gibi
  const endDayCurrentMonth = new Date(getFullYear, getMonth, dayOfMonth); // seçilmiş tarihin son gününü buluyorum. 04.11.2022 tarihinin son günü 30.11.2022 gibi

  const [data, setData] = useState<any>({});
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!filterIsOpen && owRtSwitch) {
      const params = {
        start_date: moment(new Date(getFullYear, getMonth, 1)).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(new Date(getFullYear, getMonth, dayOfMonth)).format(
          "YYYY-MM-DD"
        ),
        source: filterList.origCityAirport?.join(),
        destination: filterList.destCityAirport?.join(),
        competitors: filterList.selectedCompetitors?.join(),
        main_competitor: filterList.mainCompetitor?.join(),
        cabin: cabin_code,
        agg_type: "month",
        display_date: moment(selectedDate).format("YYYY-MM-DD"),
        graph_carriers: selectedCarrier?.join(),
        currency: filterList.currency,
        type: owRtSwitch.toUpperCase(),
        fare_family: basicFilter,
        ...(selectedFilter && { seeking: selectedFilter }),
      };
      LowestFareCalendarClient.fetchLowestFareCalendar({ data: params })
        .then((res: any) => {
          setData(res?.fares);
          setDropdownOptions(Object.keys(res?.carriers));
        })
        .catch(() => {
          setData([]);
        });
    }
  }, [
    selectedDate,
    filterList,
    basicFilter,
    filterIsOpen,
    cabin_code,
    selectedCarrier,
    selectedFilter,
    owRtSwitch,
  ]);

  const getOpacity = (value) => {
    if (value && typeof value === "number") {
      if (value > 0 && value <= 25) {
        return 0.25;
      } else if (value <= 50) {
        return 0.5;
      } else if (value > 50 && value <= 75) {
        return 0.75;
      } else return 1;
    }
    return 1;
  };

  return (
    <div>
      <div className={styles.calendar}>
        {days?.map((el, index) => (
          <div key={index} className={styles.weekdays} data-theme={theme}>
            {el}
          </div>
        ))}

        {daysInMonth?.map((date, index) => {
          if (
            compareAsc(
              new Date(format(date, "yyyy-MM-dd")),
              new Date(format(endDayCurrentMonth, "yyyy-MM-dd"))
            ) < 1
          )
            return (
              <div
                className={styles.day_card}
                data-theme={theme}
                key={index}
                data-isholiday={
                  data && data[format(date, "yyyy-MM-dd")]?.is_holiday
                }
              >
                <div
                  className={styles.day_card_content}
                  data-visible={
                    compareAsc(
                      new Date(format(date, "yyyy-MM-dd")),
                      new Date(format(firstDayCurrentMonth, "yyyy-MM-dd"))
                    ) === -1
                  }
                >
                  <div className={styles.day} data-theme={theme}>
                    <span
                      onClick={() => {
                        setDate(new Date(moment(date, "YYYY-MM-DD").format()));
                        setSelectedOption("Day");
                        localStorage.setItem(
                          "calendar_type",
                          JSON.stringify("Day")
                        );
                      }}
                    >
                      {format(date, "d")}
                    </span>
                    {data && data[format(date, "yyyy-MM-dd")]?.is_holiday && (
                      <span className={styles.info_icon}>
                        <TriggerTooltip
                          title={
                            <LowestMountHolidaysTooltip
                              data={
                                (data &&
                                  data[format(date, "yyyy-MM-dd")]?.holidays) ??
                                []
                              }
                            />
                          }
                        >
                          <img
                            src={
                              theme === "dark"
                                ? images.lowest_info
                                : images.lowest_info_light
                            }
                            alt=""
                          />
                        </TriggerTooltip>
                      </span>
                    )}
                    <TriggerTooltip
                      title={
                        <span style={{ fontSize: 14 }}>
                          <p style={{ margin: 0 }}>
                            {`Load Factor : %${
                              data &&
                              data[format(date, "yyyy-MM-dd")]?.loadFactor
                            }`}
                          </p>
                          <p style={{ margin: 0 }}>
                            {`${
                              data &&
                              data[format(date, "yyyy-MM-dd")]?.LFLastupdatedAt
                            }`}
                          </p>
                        </span>
                      }
                      placement="top"
                    >
                      <div
                        className={styles.bar_wrapper}
                        style={{
                          display:
                            data && data[format(date, "yyyy-MM-dd")]?.loadFactor
                              ? "block"
                              : "none",
                        }}
                      >
                        <span
                          className={styles.bar}
                          style={{
                            width:
                              data &&
                              (data[format(date, "yyyy-MM-dd")]?.loadFactor ??
                                0) * 0.32,
                            opacity: getOpacity(
                              data &&
                                data[format(date, "yyyy-MM-dd")]?.loadFactor
                            ),
                            backgroundColor: "#00ad92",
                          }}
                        />
                      </div>
                    </TriggerTooltip>
                  </div>
                  {data &&
                    data[format(date, "yyyy-MM-dd")]?.data?.map((el, index) => (
                      <div
                        key={index}
                        className={styles.carrier}
                        data-theme={theme}
                        data-first={index === 0}
                      >
                        <TriggerTooltip
                          title={
                            el?.isConnecting && (
                              <LowestMountPriceTooltip
                                data={{
                                  hoverTexts: el?.hoverTexts,
                                  lastUpdated: el?.lastUpdated,
                                }}
                                legs={el?.legs ?? []}
                                totalTime={el?.totalTime}
                              />
                            )
                          }
                        >
                          <span
                            className={styles.carrier_code}
                            data-theme={theme}
                          >
                            {el?.carrierCode ?? ""}
                            {el?.isConnecting && (
                              <span className={styles.is_connecting_dot} />
                            )}
                          </span>
                        </TriggerTooltip>
                        <span className={styles.class_code} data-theme={theme}>
                          {el?.classCode ?? ""}
                        </span>
                        <TriggerTooltip
                          title={
                            <LowestMountPriceTooltip
                              data={{
                                hoverTexts: el?.hoverTexts,
                                lastUpdated: el?.lastUpdated,
                              }}
                              legs={el?.legs ?? []}
                              totalTime={el?.totalTime}
                            />
                          }
                        >
                          <span
                            className={styles.price_wrapper}
                            data-theme={theme}
                          >
                            <span className={styles.currency}>
                              {el?.currency ?? ""}
                            </span>
                            <span className={styles.price}>
                              {el?.fare ?? ""}
                            </span>
                            <span>
                              <img
                                src={
                                  el?.seeking === "down"
                                    ? images.arrow_down
                                    : el?.seeking === "up"
                                    ? images.arrow_up
                                    : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                }
                                alt=""
                                className={styles.arrow}
                              />
                            </span>
                          </span>
                        </TriggerTooltip>
                      </div>
                    ))}
                </div>
              </div>
            );
          else return null;
        })}
      </div>
    </div>
  );
};

export default Calendar;
