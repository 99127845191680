import Layout from "components/common/Layout/Layout";
import Alerts from "components/holidays/Alerts";
// import HolidaysFilter from "components/holidays/HolidayFilter";
import { useState } from "react";

const ALERTSDASHBOARD = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Alerts"}
      filterHidden
    >
      <Alerts />
    </Layout>
  );
};

export default ALERTSDASHBOARD;
