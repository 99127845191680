import { LinearProgress } from "@mui/material";
import images from "constans/images";
import { AtarevProgressLinearContext } from "context-api/AtarevProgressLinear";
import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import styles from "./styles.module.css";

const ProgressLinear = () => {
  const { theme } = useContext(ThemeContext);
  const { isProgress } = useContext(AtarevProgressLinearContext);

  return (
    <div
      className={styles.atarev_progress_line_wrapper}
      data-active={isProgress}
      style={{ zIndex: 20 }}
      data-theme={theme}
    >
      <div>
        <img src={theme === "dark" ? images.logo : images.logo_light} alt="" />
        <LinearProgress color="inherit" />
      </div>
    </div>
  );
};
export default ProgressLinear;
