import { RestClient } from "lib/api/RestClient";

interface IFilterMarketOptions {
  customer: string;
  orig_country?: string | null;
  dest_country?: string | null;
}
export class FilterMarketOptionsClient {
  private static _client: RestClient;

  static fetchFilterMarketOptions({
    customer,
    orig_country,
    dest_country,
  }: IFilterMarketOptions) {
    const params = {
      customer,
      ...(orig_country && { orig_country }),
      ...(dest_country && { dest_country }),
    };
    return FilterMarketOptionsClient.getClient().getCall(
      `/api/msdv2/admin/filter-market-options?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (FilterMarketOptionsClient._client == null) {
      FilterMarketOptionsClient._client = new RestClient();
    }
    return FilterMarketOptionsClient._client;
  }
}
