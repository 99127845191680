interface ICustomIcon {
  icon: any;
  size?: "sm" | "lg";
  color: string;
}

export default function CustomIcon({ icon, size = "sm", color }: ICustomIcon) {
  const customStyle = {
    width: size === "sm" ? 40 : 48,
    height: size === "sm" ? 40 : 48,
    backgroundColor: color,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
  };
  return (
    <div style={customStyle}>
      <img src={icon} alt="" style={{ width: 24, height: 24 }} />
    </div>
  );
}
