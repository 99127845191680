import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};
interface IThemeContext {
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
}
const contextDefaultValues: IThemeContext = {
  theme: localStorage.getItem("theme") ?? "dark",
  setTheme: () => {},
};
const ThemeContext = React.createContext<IThemeContext>(contextDefaultValues);
const ThemeConsumer = ThemeContext.Consumer;

const ThemeContextApp = ({ children }: Props) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "dark");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeConsumer, ThemeContextApp };
