import KPIMini from "components/msd/KPIMini/KPIMini";
import LowComp from "components/msd/LowComp/LowComp";
import LowHost from "components/msd/LowHost/LowHost";
import { useState } from "react";
import FareStructureFilters from "components/msd/FareStructureFilters/FareStructureFilters";
import Layout from "components/common/Layout/Layout";
import { UserProfileContext } from "context-api/UserProfile";
import { useContext } from "react";
import Ancillaries from "components/msd/Ancillaries";

export default function FARESTRUCTURE() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const { user } = useContext(UserProfileContext);
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Fare Structure"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <div style={{ opacity: "0.3", pointerEvents: "none" }}>
        {true && <FareStructureFilters />}
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        <LowHost filterIsOpen={filterIsOpen} />
        {user?.enabledModules &&
          user.enabledModules["MSD"] &&
          !user.enabledModules["MSD"]?.lite && (
            <LowComp filterIsOpen={filterIsOpen} />
          )}
      </div>
      <div style={{ opacity: "0.3", pointerEvents: "none" }}>
        {true && <Ancillaries />}
      </div>
    </Layout>
  );
}
