import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./react-datepicker.css";
import styles from "./styles.module.css";

function DatePickerComponent({ ...props }) {
  const { value, onChangeDate } = props;
  const { theme } = useContext(ThemeContext);
  const CustomDatePicker = ({
    decreaseMonth,
    increaseMonth,
    date,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
  }) => {
    return (
      <div className={styles.custom_date_picker}>
        <span
          className={styles.prev_year}
          onClick={() => {
            changeYear(Number(moment(date).subtract(1, "year").format("YYYY")));
          }}
        >
          {moment(date).subtract(1, "year").format("YYYY")}
        </span>
        <div className={styles.buttons_wrapper}>
          <button
            type="button"
            className={styles.arrow_left}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            data-theme={theme}
          >
            <KeyboardArrowLeft />
          </button>
          <span className={styles.date_now}>
            {moment(date).format("MMM - YYYY")}
          </span>
          <button
            type="button"
            className={styles.arrow_right}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            data-theme={theme}
          >
            <KeyboardArrowRight />
          </button>
        </div>
        <span
          className={styles.next_year}
          onClick={() => {
            changeYear(Number(moment(date).add(1, "year").format("YYYY")));
          }}
        >
          {moment(date).add(1, "year").format("YYYY")}
        </span>
      </div>
    );
  };

  return (
    <DatePicker
      selected={value} // Seçilen günü verir.
      onChange={(value) => {
        onChangeDate && onChangeDate(value);
      }} // Seçilen günü güncelleyen fonksiyon
      inline // Takvimi açık durumda gösterir.
      calendarStartDay={1} // Haftanın hangi gününden başlayacağını belirtir.
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)} // Haftanın günlerini kaç harf ile gösterileceğini belirtir.
      renderCustomHeader={CustomDatePicker} // Takvim başlığını özelleştirir.
      showDisabledMonthNavigation
      {...props}
    />
  );
}

export default DatePickerComponent;
