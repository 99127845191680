import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};
interface IKpiContext {
  kpiList: {
    items: {
      kpiName: string;
      kpiType: string;
      requestedDate: string;
      metrics: never[];
      graphData: never[];
    }[];
  };
  setKpiList: React.Dispatch<
    React.SetStateAction<{
      items: {
        kpiName: string;
        kpiType: string;
        requestedDate: string;
        metrics: never[];
        graphData: never[];
      }[];
    }>
  >;
}
const contextDefaultValues: IKpiContext = {
  kpiList: {
    items: [],
  },
  setKpiList: () => {},
};
const KPIContext = React.createContext<IKpiContext>(contextDefaultValues);
const KPIConsumer = KPIContext.Consumer;

const KPIContextApp = ({ children }: Props) => {
  const [kpiList, setKpiList] = useState<{
    items: {
      kpiName: string;
      kpiType: string;
      requestedDate: string;
      metrics: never[];
      graphData: never[];
    }[];
  }>({
    items: [],
  });

  return (
    <KPIContext.Provider value={{ kpiList, setKpiList }}>
      {children}
    </KPIContext.Provider>
  );
};

export { KPIContext, KPIConsumer, KPIContextApp };
