import images from "constans/images";
import styles from "./AddFilter.module.css";

type Props = {
  dynamicFilters: {
    origRegion: never[];
    destRegion: never[];
    origCountry: never[];
    destCountry: never[];
    origCityAirport: never[];
    destCityAirport: never[];
    pos: never[];
    mainCompetitor: never[];
    selectedCompetitors: never[];
    salesChannel: never[];
    cabin: never[];
    paxType: never[];
    currency: never[];
  }[];
  setDynamicFilters: any;
};

export default function AddFilter({
  dynamicFilters,
  setDynamicFilters,
}: Props) {
  const filter = {
    origRegion: [],
    destRegion: [],
    origCountry: [],
    destCountry: [],
    origCityAirport: [],
    destCityAirport: [],
    pos: [],
    mainCompetitor: [],
    selectedCompetitors: [],
    salesChannel: [],
    cabin: [],
    paxType: [],
    currency: [],
  };
  const handleAddedFilter = () => {
    if (dynamicFilters.length < 4) {
      setDynamicFilters((state) => [...state, filter]);
      return;
    }
    alert("Maksimum filtre sayısına ulaşıldı");
  };
  return (
    <div className={styles.container} onClick={handleAddedFilter}>
      <img className={styles.plus_icon} src={images.plus} alt="" />
      AddFilter
    </div>
  );
}
