/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import images from "constans/images";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import OpenMenu from "./OpenMenu";
import MuiTable from "components/common/Table/MuiTable";
import { ThemeContext } from "context-api/ThemeContext";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Tooltip } from "components/common/Tooltip";

interface IListOfMarkets {
  customer: string | null;
  editMarket: (row, type) => void;
  data: {
    orig: string;
    dest: string;
  }[];
  labels: {
    [key: string]: string;
  }[];
  getTableData: () => void;
}
export default function ListOfMarkets({
  customer,
  editMarket,
  data,
  labels,
  getTableData,
}: IListOfMarkets) {
  const [columns, setColumns] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { theme } = useContext(ThemeContext);

  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );
  useEffect(() => {
    getColumns(labels);
  }, [labels]);

  const getColumns = (labels) => {
    const array: any = [];
    [{ openMenu: "" }, ...labels].forEach((element) => {
      for (const [key, value] of Object.entries(element)) {
        if (key !== "openMenu") {
          const obj = {
            headerName: value,
            field: key,
            renderCell: ({ row }) => {
              return (
                <Tooltip title={row[key] ?? ""}>
                  <span className={"rdb-custom-cell"}>{row[key]}</span>
                </Tooltip>
              );
            },
            width: 134,
          };
          array.push(obj);
        } else if (key === "openMenu") {
          const obj = {
            headerName: value,
            field: key,
            renderCell: ({ row }) => (
              <OpenMenu
                editMarket={editMarket}
                row={row}
                customer={customer}
                tableUpdate={getTableData}
              />
            ),
            width: 50,
          };
          array.push(obj);
        }
      }
    });
    setColumns(array);
  };

  const handleRowClick = (row: any) => {
    editMarket(row, "edit");
  };

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.custom-id-table`);
      selectedElement?.requestFullscreen();
    }
  }

  return (
    <div className={styles.container}>
      <Card variant="secondary">
        <div className={styles.card_header}>
          <span className={styles.card_title} data-theme={theme}>
            List of Markets
          </span>
          <div
            className={styles.add_button}
            // onClick={() => clearForm()}
            data-theme={theme}
          >
            <img src={images.plus} alt="" />
            <span>Add</span>
          </div>
        </div>
        <div
          className={`${styles.card_body} custom-id-table`}
          style={{
            backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.fullscreen_button_wrapper}>
            <span
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                toggleFullScreen();
              }}
              className={styles.fullscreen_button}
            >
              <Tooltip title="Full Screen">
                {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
              </Tooltip>
            </span>
          </div>
          <MuiTable data={data} columns={columns} onRowClick={handleRowClick} />
        </div>
      </Card>
    </div>
  );
}
