import { ArrowForwardIos } from "@mui/icons-material";
import { useContext, useState } from "react";
import styles from "./styles.module.css";
import { Dispatch, SetStateAction } from "react";
import { CircularProgress } from "@mui/material";
import { ThemeContext } from "context-api/ThemeContext";

interface IStepByStep {
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  scrollSmooth: (index: any) => void;
  loading: boolean;
  saveIsActive: boolean;
}
export default function StepByStep({
  scrollSmooth,
  isActive,
  setIsActive,
  loading,
  saveIsActive,
}: IStepByStep) {
  const [step, setStep] = useState(0);
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.container}>
      <div className={styles.card} data-theme={theme}>
        <div
          className={styles.active_deactive_button_wrapper}
          data-theme={theme}
        >
          <div
            className={styles.button}
            data-active={!isActive}
            onClick={() => setIsActive(!isActive)}
            data-theme={theme}
          >
            <span className={styles.dot} data-active={!isActive}></span>
            <span>Deactive</span>
          </div>
          <div
            className={styles.button}
            data-active={isActive}
            onClick={() => setIsActive(!isActive)}
            data-theme={theme}
          >
            <span className={styles.dot} data-active={isActive}></span>
            <span>Active</span>
          </div>
        </div>
        <div className={styles.step_wrapper}>
          <div
            className={styles.steps}
            onClick={() => {
              setStep(0);
              scrollSmooth(2);
            }}
          >
            <span
              className={styles.step_name}
              data-active={step === 0}
              data-theme={theme}
            >
              General Info
            </span>
            <span
              className={styles.step}
              data-active={step === 0}
              data-theme={theme}
            ></span>
          </div>

          <div
            className={styles.steps}
            onClick={() => {
              setStep(1);
              scrollSmooth(3);
            }}
          >
            <span
              className={styles.step_name}
              data-active={step === 1}
              data-theme={theme}
            >
              Conditions
            </span>
            <span
              className={styles.step}
              data-active={step === 1}
              data-theme={theme}
            ></span>
          </div>

          <div
            className={styles.steps}
            onClick={() => {
              setStep(2);
              scrollSmooth(4);
            }}
          >
            <span
              className={styles.step_name}
              data-active={step === 2}
              data-theme={theme}
            >
              If & Then Actions
            </span>
            <span
              className={styles.step}
              data-active={step === 2}
              data-theme={theme}
            ></span>
          </div>
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          }}
          disabled={loading}
        >
          <div
            className={styles.next_button_wrapper}
            data-active={saveIsActive}
            data-theme={theme}
          >
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <>
                <span>Save</span>
                <ArrowForwardIos className={styles.next_button_icon} />
              </>
            )}
          </div>
        </button>
      </div>
    </div>
  );
}
