import { RestClient } from "lib/api/RestClient";

interface IScraperMarketDelete {
  customer: string;
  orig: string;
  dest: string;
}
export class ScraperMarketDeleteClient {
  private static _client: RestClient;

  static fetchFilterMarketDelete({
    customer,
    orig,
    dest,
  }: IScraperMarketDelete) {
    return ScraperMarketDeleteClient.getClient().deleteCall(
      `/api/msdv2/admin/config-market?customer=${customer}&orig=${orig}&dest=${dest}`
    );
  }

  static getClient() {
    if (ScraperMarketDeleteClient._client == null) {
      ScraperMarketDeleteClient._client = new RestClient();
    }
    return ScraperMarketDeleteClient._client;
  }
}
