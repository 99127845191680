/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { FareStructureFareStructureTablesClient } from "lib/api/msd/fareStructureFareStructureTables/FareStructureFareStructureTables";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import ColumnControl from "utils/ColumnControl";
import { UserProfileContext } from "context-api/UserProfile";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Tooltip } from "components/common/Tooltip";

interface ILowHost {
  filterIsOpen: boolean;
}
export default function LowHost({ filterIsOpen }: ILowHost) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);
  const { filterList } = useContext(FilterContext);
  const { user } = useContext(UserProfileContext);

  useEffect(() => {
    if (!filterIsOpen) {
      FareStructureFareStructureTablesClient.fetchFareStructureFareStructureTables(
        { filterList, host: user?.clientCode ?? "" }
      )
        .then((response: any) => {
          setData(response.host_table.data ?? []);
          setColumns(getColumns(response.labels ?? []));
          setTitle(response.host_table.title);
        })
        .catch(() => {
          setData([]);
        });
    }
  }, [filterIsOpen]);

  const getColumns = (labels) => {
    const array: any = [];
    for (const [field, name] of Object.entries(labels)) {
      if (field !== "buyup_ratio" && field !== "pax_ratio") {
        const obj = {
          name,
          field,
          cell: (row) => (
            <Tooltip title={ColumnControl({ value: row[field], isView: true })}>
              <span className={"rdb-custom-cell"}>
                {ColumnControl({ value: row[field], isView: true })}
              </span>
            </Tooltip>
          ),
          valueGetter: (row) =>
            ColumnControl({ value: row[field], isView: false }),
        };
        array.push(obj);
      } else if (field === "buyup_ratio") {
        const obj = {
          name,
          field,
          cell: (row) => (
            <Tooltip title={row[field].text}>
              <div className={styles.custom_table_row}>
                <span
                  className={styles.row}
                  style={{ width: row[field].ratio * 32 }}
                  data-active={"blue"}
                />
              </div>
            </Tooltip>
          ),
          valueGetter: (row) => row[field].text,
        };
        array.push(obj);
      } else if (field === "pax_ratio") {
        const obj = {
          name,
          field,
          cell: (row) => (
            <Tooltip title={row[field].text}>
              <div className={styles.custom_table_row}>
                <span
                  className={styles.row}
                  style={{ width: row[field].ratio * 32 }}
                />
              </div>
            </Tooltip>
          ),
          valueGetter: (row) => row[field].text,
        };
        array.push(obj);
      }
    }
    return array;
  };

  return (
    <Card
      variant="secondary"
      width={"50%"}
      // width={
      //   user?.enabledModules &&
      //   user.enabledModules["MSD"] &&
      //   !user.enabledModules["MSD"]?.lite
      //     ? 490
      //     : 1080
      // }
      height={648}
    >
      <p className={styles.card_title}>{title}</p>
      <CustomAtarevTable
        data={data}
        columns={columns}
        pagination
        isShowing={false}
        height={504}
      />
    </Card>
  );
}
