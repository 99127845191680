import { RestClient } from "../RestClient";

interface IRangeSlider {
  agg_type?: string;
  time_direction?: string;
}
export class RangeSliderClient {
  private static _client: RestClient;

  static fetchRangeSlider({ agg_type, time_direction }: IRangeSlider) {
    return RangeSliderClient.getClient().getCall(
      `/api/msdv2/product-overview/get-range-slider-values?agg_type=${agg_type}&time_direction=${time_direction}`
    );
  }

  static getClient() {
    if (RangeSliderClient._client == null) {
      RangeSliderClient._client = new RestClient();
    }
    return RangeSliderClient._client;
  }
}
