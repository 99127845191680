/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { LowestFareCalendarClient } from "lib/api/lfa/lowestFareCalendar/LowestFareCalendar";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.css";
import LowestMountPriceTooltip from "../PriceTooltip";
import { UserProfileContext } from "context-api/UserProfile";
import { ThemeContext } from "context-api/ThemeContext";
import { useHistory } from "react-router-dom";
import OpenMenu from "./OpenMenu";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Tooltip } from "components/common/Tooltip";
import { BasicFilterContext } from "context-api/BasicFilter";

interface IDay {
  selectedDate: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  cabin_code: string;
  setFares: any;
  filterIsOpen: boolean;
  owRtSwitch: "ow" | "rt" | null;
}

export default function DayComponent({
  selectedDate,
  cabin_code,
  setFares,
  filterIsOpen,
  owRtSwitch,
}: IDay) {
  const { filterList, setFilterList } = useContext(FilterContext);
  const { basicFilter } = useContext(BasicFilterContext);

  const { user } = useContext(UserProfileContext);
  const [data, setData] = useState({
    table1: { data: [], columns: [] },
    table2: { data: [], columns: [] },
    table3: { data: [], columns: [] },
    table4: { data: [], columns: [] },
  });
  const { theme } = useContext(ThemeContext);
  // const [loading, setLoading] = useState(false);

  let history = useHistory();

  const conditionalRowStyles = [
    {
      when: (row) => row?.carrier,
      style: (row) => ({
        border:
          row?.carrier?.value === user?.clientCode
            ? `1px solid ${row?.carrier?.color}`
            : "none",
      }),
    },
  ];

  useEffect(() => {
    if (!filterIsOpen && owRtSwitch) {
      const data = {
        start_date: moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay() + 6
              : selectedDate.getDay() - 1,
            "days"
          )
          .format("YYYY-MM-DD"),
        end_date: moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay()
              : selectedDate.getDay() - 7,
            "days"
          )
          .format("YYYY-MM-DD"),
        source: filterList.origCityAirport,
        destination: filterList.destCityAirport,
        competitors: filterList.selectedCompetitors?.join(),
        main_competitor: filterList.mainCompetitor,
        cabin: cabin_code,
        agg_type: "day",
        display_date: moment(selectedDate).format("YYYY-MM-DD"),
        graph_carriers: "All",
        currency: filterList.currency,
        type: owRtSwitch.toUpperCase(),
        fare_family: basicFilter,
      };
      LowestFareCalendarClient.fetchLowestFareCalendar({ data })
        .then((res: any) => {
          setFares(res?.fares);
          const array1: any = []; // 00:01-06:00 aralığındaki öğeler
          const array2: any = []; // 06:01-12:00 aralığındaki öğeler
          const array3: any = []; // 12:01-18:00 aralığındaki öğeler
          const array4: any = []; // 18:01-00:00 aralığındaki öğeler
          res?.table?.data?.forEach((item) => {
            const time = item.depTime; // depTime'dan saat değerini alır
            if (time >= "00:00" && time <= "06:00") {
              array1.push(item);
            } else if (time >= "06:01" && time <= "12:00") {
              array2.push(item);
            } else if (time >= "12:01" && time <= "18:00") {
              array3.push(item);
            } else {
              array4.push(item);
            }
          });
          setData({
            table1: {
              data: array1,
              columns: getColumns(res?.table?.labels),
            },
            table2: {
              data: array2,
              columns: getColumns(res?.table?.labels),
            },
            table3: {
              data: array3,
              columns: getColumns(res?.table?.labels),
            },
            table4: {
              data: array4,
              columns: getColumns(res?.table?.labels),
            },
          });
        })
        .catch(() => {
          setData({
            table1: { data: [], columns: [] },
            table2: { data: [], columns: [] },
            table3: { data: [], columns: [] },
            table4: { data: [], columns: [] },
          });
        });
    }
  }, [
    filterList,
    basicFilter,
    selectedDate,
    cabin_code,
    filterIsOpen,
    owRtSwitch,
  ]);

  const getColumns = (labels) => {
    const array: any = [];
    [{ openMenu: "" }, ...labels].forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (
          field !== "carrier" &&
          field !== "maf" &&
          field !== "openMenu" &&
          field !== "loadFactor"
        ) {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip title={row[field]}>
                <span className={"rdb-custom-cell"}>{row[field]}</span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "openMenu") {
          const obj = {
            name,
            field,
            cell: (row) => <OpenMenu row={row} selectMenu={onRowClick} />,
            disabledHeader: true,
          };
          array.push(obj);
        } else if (field === "maf") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip
                title={
                  <LowestMountPriceTooltip
                    data={{
                      hoverTexts: row?.hoverTexts,
                      lastUpdated: row?.lastUpdated,
                    }}
                    legs={row?.legs ?? []}
                    totalTime={row?.totalTime}
                  />
                }
              >
                <span className={"rdb-custom-cell"}>{row[field]}</span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "carrier") {
          const obj = {
            name,
            field,
            valueGetter: (row) => row[field].value,
            cell: (row) => (
              <div
                className={styles.custom_table_row}
                style={{
                  backgroundColor: row[field].color || "rgba(255,0,0,0.2)",
                }}
              >
                <Tooltip
                  title={
                    row?.isConnecting && (
                      <LowestMountPriceTooltip
                        data={{
                          hoverTexts: row?.hoverTexts,
                          lastUpdated: row?.lastUpdated,
                        }}
                        legs={row?.legs ?? []}
                        totalTime={row?.totalTime}
                      />
                    )
                  }
                >
                  <span className={styles.row}>{row[field].value}</span>
                </Tooltip>
              </div>
            ),
          };
          array.push(obj);
        } else if (field === "loadFactor") {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <Tooltip title={row?.LFLastupdatedAt ?? "-"}>
                  <span
                    className={"rdb-custom-cell"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 8,
                    }}
                  >
                    {row[field] && (
                      <span style={{ width: 30 }}>{`%${
                        row[field] ?? "-"
                      }`}</span>
                    )}
                    <span className={styles.bar_wrapper}>
                      <span
                        className={styles.bar}
                        style={{
                          backgroundColor: getColor(row[field]),
                          width:
                            row[field] &&
                            typeof row[field] === "number" &&
                            row[field] * 0.6,
                        }}
                      />
                    </span>
                  </span>
                </Tooltip>
              );
            },
          };
          array.push(obj);
        }
      }
    });
    return array;
  };

  const getColor = (value) => {
    const today = moment();
    const selected = moment(selectedDate);
    const daysDifference = selected.diff(today, "days");
    if (value && typeof value === "number") {
      if (daysDifference + 1 > 30) {
        return "#9c00ed";
      } else if (daysDifference + 1 < 30 && value < 50) {
        return "#ec4252"; //Kırmızı
      } else if (daysDifference + 1 < 30 && value >= 50) {
        return "#00ad92"; //Yeşil
      }
    }
    return "#9c00ed";
  };
  const onRowClick = (row, location = null) => {
    let empty = filterList;
    Object.keys(empty).forEach((key) => {
      empty[key] = [];
    });
    setFilterList((prevState) => ({ ...prevState, ...empty }));
    setTimeout(() => {
      setFilterList((prevState) => ({
        ...prevState,
        origCityAirport: row?.origin ? [row?.origin] : [],
        destCityAirport: row?.destination ? [row?.destination] : [],
        mainCompetitor: row?.competitor ? [row?.competitor] : [],
        selectedCompetitors: ["All"],
        pos: ["All"],
        salesChannel: ["All"],
        cabin: ["All"],
        paxType: ["All"],
      }));
    }, 2000);

    if (location === "at") {
      setTimeout(() => {
        history.push(
          `/availability-trends?cabin=${row?.cabinCode ?? "All"}&date=${moment(
            selectedDate
          ).format("YYYY-MM-DD")}`
        );
      }, 3000);
      return;
    }
    if (location === "pe") {
      setTimeout(() => {
        history.push(
          `/price-evolution?cabin=${row?.cabinCode ?? "All"}&date=${moment(
            selectedDate
          ).format("YYYY-MM-DD")}`
        );
      }, 3000);
      return;
    }
  };
  return (
    <div>
      <div className={styles.table_wrapper}>
        <span className={styles.dep_time} data-theme={theme}>
          00:00 - 06:00
        </span>
        <CustomAtarevTable
          data={data["table1"].data}
          columns={data["table1"].columns}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      <div className={styles.table_wrapper}>
        <span className={styles.dep_time} data-theme={theme}>
          06:01 - 12:00
        </span>
        <CustomAtarevTable
          data={data["table2"].data}
          columns={data["table2"].columns}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      <div className={styles.table_wrapper}>
        <span className={styles.dep_time} data-theme={theme}>
          12:01 - 18:00
        </span>
        <CustomAtarevTable
          data={data["table3"].data}
          columns={data["table3"].columns}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>{" "}
      <div className={styles.table_wrapper}>
        <span className={styles.dep_time} data-theme={theme}>
          18:01 - 23:59
        </span>
        <CustomAtarevTable
          data={data["table4"].data}
          columns={data["table4"].columns}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </div>
  );
}
