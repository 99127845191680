import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";

interface IFareRevenue {
  data: {
    data: never[];
    layout: never[];
  };
  yearMonthly;
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function FareRevenue({
  data,
  yearMonthly,
  storyText,
}: IFareRevenue) {
  return (
    <Card
      variant="secondary"
      title="Fare Revenue"
      width={"100%"}
      // cardInfo={{
      //   title: storyText?.fare_revenue?.title ?? null,
      //   description: storyText?.fare_revenue?.content ?? null,
      // }}
    >
      <div className={styles.container}>
        <div className={styles.chart_wrapper}>
          <Card variant="primary" height={358}>
            <div className={styles.chart}>
              <p className={styles.chart_title}>Average Fare</p>
              <Plotly
                data={data?.data}
                height={276}
                layout={data?.layout}
                margin={{ t: 20, b: 20, l: 30, r: 10, p: 4 }}
              />
              <p className={styles.chart_x_title}>
                {!yearMonthly ? "Months" : "Departure Dates"}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}
