/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface IMenu {
  item: {
    sub_categories: { value: string; selected: boolean; enabled: boolean }[];
    value: string;
  };
  selectedItems: string[];
  setSelectedItems: (item) => void;
  selectedCategories: string[];
  setSelectedCategories: (item) => void;
}
const MenuItem = ({
  item,
  selectedItems,
  setSelectedItems,
  selectedCategories,
  setSelectedCategories,
}: IMenu) => {
  const [expand, setExpand] = useState(false);
  const [fieldsCheckboxChecked, setFieldsCheckboxChecked] = useState({});
  const [groupChecked, setGroupChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    const initialStates = {};
    item?.sub_categories?.forEach((field) => {
      initialStates[field.value] = field.selected;
    });
    setFieldsCheckboxChecked(initialStates);
  }, [item]);

  useEffect(() => {
    // Seçilen field sayısını verir
    const count = Object.values(fieldsCheckboxChecked).filter(
      (value) => value === true
    ).length;
    setSelectedCount(count);

    // Objenin field içerip içermediğini kontrol eder ve içeriyorsa ve hepsi true ise grubu true olarak günceller
    const hasAnyKey = Object.keys(fieldsCheckboxChecked).length > 0;
    if (hasAnyKey) {
      const allTrue = Object.values(fieldsCheckboxChecked).every(
        (value) => value === true
      );
      if (allTrue) {
        setGroupChecked(true);
        if (!selectedCategories.includes(item.value)) {
          setSelectedCategories((prevState) => [...prevState, item.value]);
        }
      } else {
        setGroupChecked(false);
        const newArray = selectedCategories.filter((el) => item?.value !== el);
        setSelectedCategories(newArray);
      }

      // Object içindeki anahtarları dön
      Object.keys(fieldsCheckboxChecked).forEach((key) => {
        // Anahtarın değeri true ise ve selectedItems içinde bulunmuyorsa ekle
        if (
          fieldsCheckboxChecked[key] === true &&
          !selectedItems.includes(key)
        ) {
          setSelectedItems((prevState) => [...prevState, key]);
        } else if (
          fieldsCheckboxChecked[key] === false &&
          selectedItems.includes(key)
        ) {
          // Anahtarın değeri false ise ve selectedItems içinde bulunuyorsa sil
          setSelectedItems((prevState) =>
            prevState.filter((item) => item !== key)
          );
        }
      });
    }
  }, [fieldsCheckboxChecked]);

  const handleAllSelect = (e) => {
    const initialStates = {};
    item?.sub_categories?.forEach((field) => {
      initialStates[field.value] = e.target.checked;
    });
    setFieldsCheckboxChecked(initialStates);
    setGroupChecked(e.target.checked);
    if (e.target.checked && !selectedCategories.includes(item.value)) {
      setSelectedCategories((prevState) => [...prevState, item.value]);
    } else {
      const newArray = selectedCategories.filter((el) => item?.value !== el);
      setSelectedCategories(newArray);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title_wrapper}>
        <span>
          <input
            type="checkbox"
            name={item?.value}
            id={item?.value}
            checked={groupChecked}
            onChange={handleAllSelect}
          />
          <label htmlFor={item?.value}>
            {item?.value}
            {selectedCount > 0 && (
              <span className={styles.selected_count}>{selectedCount}</span>
            )}
          </label>
        </span>
        <span onClick={() => setExpand(!expand)}>
          {!expand ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </span>
      </div>
      {item?.sub_categories?.map((el, index) => {
        return (
          <div key={index} className={styles.expand_panel} data-active={expand}>
            <input
              type="checkbox"
              checked={fieldsCheckboxChecked[el.value] ?? false}
              disabled={!el.enabled}
              name={el.value}
              id={el.value}
              onChange={() => {
                setFieldsCheckboxChecked((prevState) => ({
                  ...prevState,
                  [el.value]: !fieldsCheckboxChecked[el.value],
                }));
              }}
            />
            <label htmlFor={el.value}>{el.value}</label>
          </div>
        );
      })}
    </div>
  );
};

export default MenuItem;
