import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IBookingTrendsHolidays {
  filterList: IFilterContext;
}

export class BookingTrendsHolidaysClient {
  private static _client: RestClient;

  static fetchBookingTrendsHolidays({ filterList }: IBookingTrendsHolidays) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return BookingTrendsHolidaysClient.getClient().getCall(
      `/api/msdv2/booking-trends/holiday-country-options?${qp}`
    );
  }

  static getClient() {
    if (BookingTrendsHolidaysClient._client == null) {
      BookingTrendsHolidaysClient._client = new RestClient();
    }
    return BookingTrendsHolidaysClient._client;
  }
}
