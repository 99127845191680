import { ReactNode, useContext } from "react";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";

type Data = {
  max_weekday: string;
  max_date: string;
  max_time: string;
  max_lf?: string;
  class: string;
  comp_fare: string;
  comp_flight_num: number;
  host_fare: string;
  host_flight_num: number;
  host_lf: string;
};

type Props = {
  children: ReactNode;
  data: Data;
  isPriceEvo?: boolean;
  compCarrier: {
    value: string;
    color: string;
  };
  hostCarrier: {
    color: string;
    value: string;
  };
};

export default function StatisticTooltip({
  children,
  data,
  isPriceEvo = false,
  compCarrier,
  hostCarrier,
}: Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title={htmlElement(data, compCarrier, hostCarrier, isPriceEvo)}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
          },
        },
      }}
    >
      <span className={styles.children} data-theme={theme}>
        {children}
      </span>
    </Tooltip>
  );
}
const htmlElement = (data: Data, compCarrier, hostCarrier, isPriceEvo) => {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: compCarrier?.color ?? "#3f365a" }}
    >
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {isPriceEvo ? "Days to Departure" : "Departure Date"}
        </span>
        :<span className={styles.description}>{data?.max_date ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Departure Time</span>:
        <span className={styles.description}>{data?.max_time ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Days of Week</span>:
        <span className={styles.description}>{data?.max_weekday ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Class</span>:
        <span className={styles.description}>{data?.class ?? "-"}</span>
      </span>
      {data?.max_lf && (
        <span className={styles.wrapper}>
          <span className={styles.title}>Load Factor</span>:
          <span className={styles.description}>{data?.max_lf ?? "-"}</span>
        </span>
      )}
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {compCarrier?.value ?? "Comp"} Fare
        </span>
        :<span className={styles.description}>{data?.comp_fare ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {" "}
          {compCarrier?.value ?? "Comp"} Flight Number
        </span>
        :
        <span className={styles.description}>
          {data?.comp_flight_num ?? "-"}
        </span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {hostCarrier?.value ? hostCarrier.value : "Host"} Fare
        </span>
        :<span className={styles.description}>{data?.host_fare ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {hostCarrier?.value ? hostCarrier.value : "Host"} Flight Number
        </span>
        :
        <span className={styles.description}>
          {data?.host_flight_num ?? "-"}
        </span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {hostCarrier?.value ? hostCarrier.value : "Host"} Load Factor
        </span>
        :<span className={styles.description}>{data?.host_lf ?? "-"}</span>
      </span>
    </div>
  );
};
