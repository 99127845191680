/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Tooltip } from "@mui/material";
import { FilterContext } from "context-api/FilterContext";
import { StrategyActionsRecommendationsClient } from "lib/api/msd/strategyActionsRecommendations/StrategyActionsRecommendations";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import ColumnControl from "utils/ColumnControl";
import { StarBorder } from "@mui/icons-material";
import RecomendedTable from "components/common/Table/RecomendedTable";
import images from "constans/images";
import ColoredIcon from "utils/ColoredIcon";
import { ThemeContext } from "context-api/ThemeContext";
import { UserProfileContext } from "context-api/UserProfile";

interface IParams {
  recommendationText: string;
}
export default function PricingSummary({ recommendationText }: IParams) {
  const [progress, setProgress] = useState(true);
  const [data, setData] = useState([
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1899",
    },
    {
      comp1: "2169",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2179",
    },
    {
      comp1: "",
      fare_basis: "NLRTSAS4",
      host: "2277",
      reco: "",
    },
    {
      comp1: "2448",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2459",
    },
    {
      comp1: "2728",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2739",
    },
    {
      comp1: "",
      fare_basis: "TLRTSAS4",
      host: "2818",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "QLRTSAS4",
      host: "3057",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "LLRTSAS4",
      host: "3286",
      reco: "",
    },
    {
      comp1: "3319",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "3329",
    },
    {
      comp1: "",
      fare_basis: "HLRTSAS4",
      host: "3747",
      reco: "",
    },
    {
      comp1: "3972",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "4625",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "5279",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "7606",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "9933",
      fare_basis: "",
      host: "",
      reco: "",
    },
  ]);
  const [columns, setColumns] = useState([]);
  const { filterList } = useContext(FilterContext);
  const [category, setCategory] = useState("");
  const { theme } = useContext(ThemeContext);
  const [recomendedCards, setRecomendedCards] = useState<any>([]);
  const { user } = useContext(UserProfileContext);

  const etFakeData: any = [
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1093",
    },
    {
      comp1: "",
      fare_basis: "KEEGH",
      host: " 1093",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1096",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1163",
    },
    {
      comp1: "",
      fare_basis: "MEEGH",
      host: "1213",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1243",
    },
    {
      comp1: "1247",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1343",
    },
    {
      comp1: "1345",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "BEEGH",
      host: "1362",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1453",
    },
    {
      comp1: "1454",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "SRTGH",
      host: "1511",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1573",
    },
    {
      comp1: "1577",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "GRTGH",
      host: "1660",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1713",
    },
    {
      comp1: "1720",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "GRTGH",
      host: "1660",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1713",
    },
    {
      comp1: "1720",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1883",
    },
    {
      comp1: "1889",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "YRTGH",
      host: "1957",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2083",
    },
    {
      comp1: "2094",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "D7RTGH",
      host: "2275",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2333",
    },
    {
      comp1: "2361",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2375",
    },
    {
      comp1: "",
      fare_basis: "D5RTGH",
      host: "2395",
      reco: "",
    },
    {
      comp1: "2475",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "2475",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2495",
    },
    {
      comp1: "",
      fare_basis: "D3RTGH",
      host: "2575",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2615",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2633",
    },
    {
      comp1: "2736",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "2797",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "JRTGH",
      host: "2833",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "3200",
    },
    {
      comp1: "3212",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "3233",
    },
    {
      comp1: "3377",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "CRTGH",
      host: "3605",
      reco: "",
    },
    {
      comp1: "3797",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "4200",
    },
    {
      comp1: "5397",
      fare_basis: "",
      host: "",
      reco: "",
    },
  ];

  const svFakeData: any = [
    {
      comp1: "1894",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "1899",
    },
    {
      comp1: "2169",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2179",
    },
    {
      comp1: "",
      fare_basis: "NLRTSAS4",
      host: "2277",
      reco: "",
    },
    {
      comp1: "2448",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2459",
    },
    {
      comp1: "2728",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "2739",
    },
    {
      comp1: "",
      fare_basis: "TLRTSAS4",
      host: "2818",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "QLRTSAS4",
      host: "3057",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "LLRTSAS4",
      host: "3286",
      reco: "",
    },
    {
      comp1: "3319",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "3329",
    },
    {
      comp1: "",
      fare_basis: "HLRTSAS4",
      host: "3747",
      reco: "",
    },
    {
      comp1: "3972",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "3979",
    },
    {
      comp1: "4625",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "4629",
    },
    {
      comp1: "5279",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "5279",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "7579",
    },
    {
      comp1: "7606",
      fare_basis: "",
      host: "",
      reco: "",
    },
    {
      comp1: "",
      fare_basis: "",
      host: "",
      reco: "9879",
    },
    {
      comp1: "9933",
      fare_basis: "",
      host: "",
      reco: "",
    },
  ];

  useEffect(() => {
    StrategyActionsRecommendationsClient.fetchStrategyActionsRecommendations({
      filterList,
      recommendation_text: recommendationText,
    })
      .then((response: any) => {
        if (response.category === "table") {
          // Geçici Süre Kapatıldı
          if (false) {
            setData(response.data);
          }
          if (user?.username === "ethiopia") {
            setData(etFakeData);
          } else setData(svFakeData);
          getColumns(response.labels);
          setCategory("table");
          const labels = response?.labels?.length > 0 ? response.labels : null;
          setRecomendedCards([
            {
              title: labels !== null ? labels[0]?.fare_basis : "",
              summary: "",
              icon: images.marker_pin,
              color: "#5504d9",
            },
            {
              title: labels !== null ? labels[1]?.host : "",
              summary: "",
              icon: images.chess,
              color: "#ec4252",
            },
            {
              title: labels !== null ? labels[2]?.reco : "",
              summary: "",

              icon: images.star_favorite,
              color: "#00ad92",
            },
            {
              title: labels !== null ? labels[3]?.comp1 : "",
              summary: "",
              icon: images.chess,
              color: "#259eff",
            },
          ]);
        }
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setProgress(false);
      });
  }, [recommendationText]);

  const getColumns = (labels) => {
    const array: any = [];
    labels.forEach((element) => {
      for (const [key, value] of Object.entries(element)) {
        if (key === "reco") {
          const obj = {
            name: value,
            selector: (row) =>
              ColumnControl({ value: row[key], isView: false }),
            cell: (row) => (
              <div
                className={`${styles.items} ${styles.reco}`}
                data-none={
                  ColumnControl({ value: row[key], isView: true }) === ""
                }
                data-theme={theme}
              >
                <Tooltip
                  title={ColumnControl({ value: row[key], isView: true })}
                >
                  <span className={`${"rdb-custom-cell"} ${styles.row_center}`}>
                    {row[key] !== "" && <StarBorder />}
                    {ColumnControl({ value: row[key], isView: true })}
                  </span>
                </Tooltip>
              </div>
            ),
            sortable: false,
            width: "342px",
          };
          array.push(obj);
        } else {
          const obj = {
            name: value,
            selector: (row) =>
              ColumnControl({ value: row[key], isView: false }),
            cell: (row) => (
              <div
                className={styles.items}
                data-none={
                  ColumnControl({ value: row[key], isView: true }) === ""
                }
                data-theme={theme}
              >
                <Tooltip
                  title={ColumnControl({ value: row[key], isView: true })}
                >
                  <span className={`${"rdb-custom-cell"} ${styles.row_center}`}>
                    {ColumnControl({ value: row[key], isView: true })}
                  </span>
                </Tooltip>
              </div>
            ),
            sortable: false,
            width: "342px",
          };
          array.push(obj);
        }
      }
    });
    setColumns(array);
  };

  return (
    <Card variant={"secondary"} title={"Pricing Summary"}>
      {progress ? (
        <div className={styles.progress}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        category === "table" && (
          <Card variant={"primary"}>
            <div className={styles.container} data-theme={theme}>
              <div className={styles.table_titles_wrapper}>
                {recomendedCards?.map((el) => (
                  <div className={styles.table_titles}>
                    <ColoredIcon
                      imgPath={el?.icon}
                      backgroundColor={el?.color}
                    />
                    <div className={styles.content_wrapper}>
                      <span className={styles.content_title}>{el?.title}</span>
                      <span
                        className={styles.content_content}
                        data-theme={theme}
                      >
                        {el?.summary}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.table_wrapper}>
                <RecomendedTable data={data} columns={columns} />
              </div>
            </div>
          </Card>
        )
      )}
    </Card>
  );
}
