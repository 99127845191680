import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css"; // Bu satırda SelectMenu bileşenimizin CSS modülünü içe aktarıyoruz.
import { Clear, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"; // Material UI'nin ikonlarını kullanmak için gerekli import işlemi yapıyoruz.
import { useOutsideClick } from "hooks/useOutsideClick";

interface IOption {
  label: string;
  value: string;
  disabled: boolean;
}

type SelectMenuProps = {
  options: IOption[]; // SelectMenu seçeneklerini tutan options prop'unu belirliyoruz.
  onSelect: (selectedValue: IOption | undefined) => void; // Seçim yaptığımızda çağrılacak callback fonksiyonunu belirliyoruz.
  value?: IOption | undefined | null; // Varsayılan olarak belirlediğimiz seçeneği belirtiyoruz.
  error?: boolean; //SelectMenu componentinde bir hata oluştuğunda, bu hatayı göstermek için kullanılan opsiyonel 'error' prop'u.
  placeholder?: string;
  clearButton?: boolean;
  style?: React.CSSProperties; // Dışarıdan stil özelliğini alıyoruz.
};
const SelectMenu: React.FC<SelectMenuProps> = ({
  options,
  onSelect,
  value,
  error,
  placeholder = "Select a option",
  clearButton = false,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false); // SelectMenu'un açık/kapalı durumunu saklayacak state'i oluşturuyoruz.
  const [selectedValue, setSelectedValue] = useState<
    IOption | undefined | null
  >(
    value // Seçilen değeri saklayacak state'i oluşturuyoruz ve varsayılan olarak defaultValue'u atıyoruz.
  );
  const menuRef = useRef<HTMLDivElement>(null); // Harici hook fonksiyonu için ref'i oluşturuyoruz.
  useOutsideClick(menuRef, () => setIsOpen(false)); // SelectMenu dışında bir yere tıklandığında isOpen state'ini false yapacak olan harici hook fonksiyonunu çağırıyoruz.

  useEffect(() => {
    setSelectedValue(value); // props'tan gelen defaultValue değeri değiştiğinde selectedValue state'ini güncelliyoruz.
  }, [value]);

  // SelectMenu'un açılıp kapanmasını kontrol etmek için kullanılan fonksiyon
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Seçilen değeri güncelleyen ve onSelect callback fonksiyonunu çağıran fonksiyon
  const handleSelect = (option: IOption) => {
    setSelectedValue(option); // Seçilen değeri saklayan state'i güncelliyoruz.
    setIsOpen(false); // SelectMenu'u kapalı hale getiriyoruz.
    onSelect(option); // onSelect callback fonksiyonunu çağırıyoruz.
  };

  // Seçilen değeri güncelleyen ve onSelect callback fonksiyonunu çağıran fonksiyon
  const handleSelectRemove = () => {
    setSelectedValue(undefined); // Seçilen değeri saklayan state'i güncelliyoruz.
    onSelect(undefined); // onSelect callback fonksiyonunu çağırıyoruz.
  };

  return (
    <div
      className={styles.container}
      ref={menuRef}
      style={style} // Dışarıdan gelen stil özelliklerini belirliyoruz.
    >
      <div
        className={`${styles.selectedOption} ${error ? styles.error : ""}`} // Her bir seçeneğin CSS class'ını belirliyoruz.
        onClick={handleToggle}
        role="button" // Klavye ile seçilebilir olması için role prop'unu belirliyoruz.
        tabIndex={0} // Tab tuşuyla seçilebilir olması için tabIndex prop'unu belirliyoruz.
      >
        {selectedValue ? (
          <span className={styles.selection}>
            {
              options?.find((option) => option.value === selectedValue.value)
                ?.label // Seçilen değerin label'ını gösteriyoruz.
            }
          </span>
        ) : (
          <span className={styles.placeholder}>{placeholder}</span> // Değer yoksa placeholder gösterecek bir span tag'i ekliyoruz.
        )}
        <span className={styles.icon_wrapper}>
          {/* SelectMenu'un seçilmiş opsiyonunu temizleyen X icon'u */}
          {selectedValue && clearButton ? (
            <Clear className={styles.clear} onClick={handleSelectRemove} />
          ) : (
            ""
          )}
          {/* SelectMenu'un açılıp kapandığını belirleyen ok icon'u */}
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </span>
      </div>
      {isOpen && ( // isOpen state'i true olduğunda SelectMenu seçenekleri listesi görüntülenir.
        <ul className={styles.optionsList}>
          {options?.map((option) => (
            <li
              key={option.value} // Her bir seçeneğin unique bir key değeri olması için value prop'unu kullanıyoruz.
              className={`${styles.option} ${
                option.disabled ? styles.disabled : ""
              }`} // Her bir seçeneğin CSS class'ını belirliyoruz.
              data-active={option.value === selectedValue?.value} // Eğer seçili olan seçenek bu seçenekse active olarak işaretleyen data attribute'u ekliyoruz.
              onClick={() => !option.disabled && handleSelect(option)} // Tıklama event'i için handleSelect fonksiyonunu belirliyoruz.
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectMenu;
