import KPIMini from "components/msd/KPIMini/KPIMini";
import { useState } from "react";
import MarketTrends from "../../../components/msd/MarketTrends/MarketTrends";
import MarketShareVsAverageFare from "../../../components/msd/MarketShareVsAverageFare/MarketShareVsAverageFare";
import Layout from "components/common/Layout/Layout";

export default function MARKETSHARE() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Market Share"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <MarketTrends filterIsOpen={filterIsOpen} />
      <MarketShareVsAverageFare filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
