import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

export class AgencyAnalysisAgencyTableClient {
  private static _client: RestClient;

  static fetchAgencyAnalysisAgencyTableClient({ params, filterList }) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return AgencyAnalysisAgencyTableClient.getClient().getCall(
      `/api/msdv2/agency-analysis/agency-table?${new URLSearchParams(params)}&${qp}`
    );
  }

  static getClient() {
    if (AgencyAnalysisAgencyTableClient._client == null) {
      AgencyAnalysisAgencyTableClient._client = new RestClient();
    }
    return AgencyAnalysisAgencyTableClient._client;
  }
}
