import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";
import { useContext, useState } from "react";
import { ThemeContext } from "context-api/ThemeContext";

interface IClassRBDBreakdown {
  data: {
    competitors: {
      carrier: string;
      fig: {
        data: never[];
        layout: never[];
      };
    }[];
    host: {
      carrier: string;
      fig: {
        data: never[];
        layout: never[];
      };
    }[];
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function ClassRBDBreakdown({
  data,
  storyText,
}: IClassRBDBreakdown) {
  const [activeStep, setActiveStep] = useState(0);
  const { theme } = useContext(ThemeContext);

  return (
    <Card
      variant="secondary"
      // cardInfo={{
      //   title: storyText?.class_rbd?.title ?? null,
      //   description: storyText?.class_rbd?.content ?? null,
      // }}
    >
      <div className={styles.card_header}>
        <span className={styles.card_title}>Class Mix</span>
        <div className={styles.button_wrapper}>
          <div className={styles.slider}>
            <span
              className={styles.prev_arrow}
              onClick={() => {
                if (activeStep > 0) {
                  setActiveStep(activeStep - 1);
                } else {
                  setActiveStep(data.competitors.length - 1);
                }
              }}
              data-theme={theme}
            >
              <span className={styles.arrow_left} data-theme={theme}></span>
            </span>
            <span className={styles.slider_title}>
              {`${data?.competitors[activeStep]?.carrier ?? "-"}`}
            </span>
            <span
              className={styles.next_arrow}
              onClick={() => {
                if (activeStep < data?.competitors.length - 1) {
                  setActiveStep(activeStep + 1);
                } else {
                  setActiveStep(0);
                }
              }}
              data-theme={theme}
            >
              <span className={styles.arrow_right} data-theme={theme}></span>
            </span>
          </div>
          <div className={styles.active_wrapper}>
            {data?.competitors?.map((_, index) => (
              <span
                className={styles.active_slide}
                data-active={activeStep === index}
                data-theme={theme}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.chart_wrapper}>
        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={400}
        >
          <Plotly
            data={data?.host[0]?.fig?.data ?? []}
            height={378}
            layout={data?.host[0]?.fig?.layout ?? []}
            margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
          />
        </Card>

        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={400}
        >
          <Plotly
            data={data?.competitors[activeStep]?.fig?.data ?? []}
            height={378}
            layout={data?.competitors[activeStep]?.fig?.layout ?? []}
            margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
          />
        </Card>
      </div>
    </Card>
  );
}
