/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, useContext } from "react";
import Notification from "components/common/Notification/Notification";
import { AddNewRule } from "lib/api/lfa/rules/AddNewRule";
import { RuleOptionsClient } from "lib/api/lfa/rules/RuleOptions";
import { RulesGetSimpleByIdClient } from "lib/api/lfa/rules/RulesGetSimpleById";
import { UpdateRule } from "lib/api/lfa/rules/UpdateRule";
import { IOptions } from "types/rule-options";
import BusinessRules from "./BusinessRules";
import RulesConditions from "./RulesConditions";
import RulesCreator from "./RulesCreator";
import StepByStep from "./StepByStep";
import Strategy from "./Strategy";
import styles from "./styles.module.css";
import { FlightsClient } from "lib/api/lfa/flights/Flights";
import upperCaseLetters from "utils/upperCaseLetters";
import { FareTrendsClient } from "lib/api/msd/fareTrends/FareTrends";
import moment from "moment";
import Highlights from "../Highlights";
import { ThemeContext } from "context-api/ThemeContext";
import { FilterContext } from "context-api/FilterContext";

interface IRulesCreator {
  name: string;
  description: string;
  note: string;
  auto: boolean;
  effective_date: never[] | string[];
}
interface IRulesConditions {
  region: string;
  origin: string[];
  destination: string[];
  flight_number: string[];
  effective_time: string[];
  departure_date: string[];
  cabin: string;
  equipment: string[] | never[];
  competitor: string;
  competitor_range: number[];
  dtd: number[];
  dow: never[];
}

interface IInventory {
  apply: string;
  operator: string;
  value: number[];
}
interface IActions {
  action: string;
  params: {
    class_rank: string;
    set_avail: number;
  };
}
export default function RulesComponent() {
  const rulesCreatorRef = useRef<any>(null);
  const rulesConditionsRef = useRef<any>(null);
  const strategyRef = useRef<any>(null);
  const businessRulesRef = useRef<any>(null);
  let newWindow;

  const [timeRange, setTimeRange] = useState({
    value: [0, 23],
    min: 0,
    max: 23,
    dateList: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:59",
    ],
  });
  const [ruleOptions, setRuleOptions] = useState<IOptions | null>(null);
  const [ruleId, setRuleId] = useState("");
  const [rulesCreatorData, setRulesCreatorData] = useState<IRulesCreator>({
    name: "",
    description: "",
    note: "",
    auto: false,
    effective_date: [],
  });
  const [rulesConditions, setRulesConditions] = useState<IRulesConditions>({
    region: "",
    origin: [],
    destination: [],
    flight_number: [],
    effective_time: [],
    departure_date: [],
    cabin: "ECONOMY",
    equipment: [],
    competitor: "",
    competitor_range: [1, 1],
    dtd: [0, 365],
    dow: [],
  });
  const [inventory, setInventory] = useState<IInventory[]>([
    {
      apply: "",
      operator: "",
      value: [],
    },
  ]);
  const [actions, setActions] = useState<IActions[]>([
    {
      action: "",
      params: { class_rank: "", set_avail: 0 },
    },
  ]);
  const [isActive, setIsActive] = useState(true);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveIsActive, setSaveIsActive] = useState(false);
  const [createdNewRule, setCreatedNewRule] = useState(false);
  const [inventoryError, setInventoryError] = useState<boolean[]>([]);
  const [actionsError, setActionsError] = useState<boolean[]>([]);
  const [ruleName, setRuleName] = useState("");
  const [flightNumberOptions, setFlightNumberOptions] = useState<string[]>([]);

  const [flightNumberSearch, setFlightNumberSearch] = useState<string>("");
  const [origCityAirportLookup, setOrigCityAirportLookup] = useState("");
  const [destCityAirportLookup, setDestCityAirportLookup] = useState("");
  const { filterList } = useContext(FilterContext);
  const [stats, setStats] = useState([]);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setRulesConditions((state) => ({
      ...state,
      effective_time: [
        timeRange.dateList[timeRange.value[0]],
        timeRange.dateList[timeRange.value[1]],
      ],
    }));
  }, [timeRange]);

  useEffect(() => {
    const data = {
      ...rulesConditions,
      orig_city_airport_lookup: upperCaseLetters(origCityAirportLookup),
      dest_city_airport_lookup: upperCaseLetters(destCityAirportLookup),
    };
    if (rulesConditions.effective_time.length > 0) {
      RuleOptionsClient.fetchRuleOptions(data)
        .then((res: any) => {
          setRuleOptions(res);
        })
        .catch((err) => {
          console.log("Error");
        });
    }
    const params = {
      orig_city_airport: rulesConditions.origin,
      dest_city_airport: rulesConditions.destination,
      selected: rulesConditions.flight_number,
      lookup: flightNumberSearch,
    };
    if (
      rulesConditions.origin.length > 0 &&
      rulesConditions.destination.length > 0
    ) {
      FlightsClient.fetchFlights(params)
        .then((response: any) => {
          setFlightNumberOptions(response?.flights);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setStats([]);
    if (
      rulesConditions.origin.length > 0 &&
      rulesConditions.destination.length > 0
    ) {
      const fareTrendsBody = {
        orig_city_airport: rulesConditions.origin,
        dest_city_airport: rulesConditions.destination,
        cabin: rulesConditions.cabin ? rulesConditions.cabin : "ECO",
        date_range_start: moment(new Date()).format("YYYY-MM-DD"),
        date_range_end: moment(new Date()).add(100, "d").format("YYYY-MM-DD"),
        time_range_start: "00:00",
        time_range_end: "23:59",
        duration: "0",
        weekdays: [],
        connection: [],
        graph_carriers: rulesConditions.competitor
          ? rulesConditions.competitor
          : "All",
        flight: rulesConditions.flight_number,
        overview: "1",
        currency: filterList.currency,
        dark_theme: theme === "dark",
      };
      FareTrendsClient.fetchFareTrends({ data: fareTrendsBody })
        .then((res: any) => {
          setStats(res?.stats?.data ?? []);
        })
        .catch((err) => console.log(err));
    }
  }, [
    rulesConditions,
    flightNumberSearch,
    origCityAirportLookup,
    destCityAirportLookup,
  ]);

  useEffect(() => {
    const bool =
      rulesCreatorData.name !== "" &&
      rulesConditions.origin.length > 0 &&
      rulesConditions.destination.length > 0 &&
      rulesConditions.cabin.length > 0 &&
      rulesConditions.competitor.length > 0;
    if (bool) setSaveIsActive(true);
    else setSaveIsActive(false);
  }, [rulesCreatorData, rulesConditions]);

  useEffect(() => {
    setInventoryError([]);
    setActionsError([]);
  }, [inventory, actions]);

  const clearInventoryAndActions = async () => {
    setInventory([]);
    setActions([]);
  };

  const clearForm = () => {
    setRuleId("");
    setTimeRange((state) => ({
      ...state,
      value: [0, 23],
    }));
    setRulesConditions({
      region: "",
      origin: [],
      destination: [],
      flight_number: [],
      effective_time: [],
      departure_date: [],
      cabin: "ECONOMY",
      equipment: [],
      competitor: "",
      competitor_range: [1, 1],
      dtd: [0, 365],
      dow: [],
    });
    setRulesCreatorData({
      name: "",
      description: "",
      note: "",
      auto: false,
      effective_date: [],
    });
    clearInventoryAndActions().then(() => {
      setInventory([
        {
          apply: "",
          operator: "",
          value: [],
        },
      ]);
      setActions([
        {
          action: "",
          params: { class_rank: "", set_avail: 0 },
        },
      ]);
    });
  };

  const postService = () => {
    setLoading(true);
    let data = {
      name: rulesCreatorData.name.toUpperCase(), //required
      description: rulesCreatorData.description,
      note: rulesCreatorData.note,
      effective_date: rulesCreatorData.effective_date, //required
      effective_time: rulesConditions.effective_time, //required
      origin: rulesConditions.origin, //required
      destination: rulesConditions.destination, //required
      cabin: rulesConditions.cabin, //required
      flight_number: rulesConditions.flight_number,
      departure_date: rulesConditions.departure_date,
      priority: 1,
      is_active: isActive,
      is_auto: true,
      region: rulesConditions.region,
      equipment: rulesConditions.equipment,
      dow: rulesConditions.dow,
      dtd: rulesConditions.dtd,
      competitor_range: rulesConditions.competitor_range,
      competitor: rulesConditions.competitor,
      analysis: [...inventory],
      event: actions[0],
    };
    let newData = {};
    for (let key in data) {
      if (data[key] && Array.isArray(data[key]) && data[key].length > 0) {
        newData[key] = data[key];
      } else if (!Array.isArray(data[key])) {
        if (typeof data[key] === "string" && data[key] !== "") {
          newData[key] = data[key];
        } else if (typeof data[key] !== "string") newData[key] = data[key];
      }
    }
    if (ruleId === "") {
      AddNewRule.fetchAddNewRule({ ...newData })
        .then((res: any) => {
          setCreatedNewRule(!createdNewRule);
          clearForm();
          Notification({ type: "success", message: "Rule Created" });
          if (res.overlapping.length > 0) {
            if (newWindow && !newWindow.closed) {
              newWindow.close();
            }
            newWindow = window.open(
              `/rules-priority?id=${res?.id}`,
              "",
              "width=1080,height=400,left=200,top=200"
            );
          }
        })
        .catch((err) =>
          Notification({
            type: "error",
            message: `${
              err?.originalError?.response?.data?.message ?? "Error"
            }`,
          })
        )
        .finally(() => setLoading(false));
    } else {
      UpdateRule.fetchUpdateRule({ ...newData }, ruleId)
        .then((res: any) => {
          setCreatedNewRule(!createdNewRule);
          clearForm();
          Notification({ type: "success", message: "Rule Updated" });
          if (res.overlapping.length > 0) {
            if (newWindow && !newWindow.closed) {
              newWindow.close();
            }
            newWindow = window.open(
              `/rules-priority?id=${res?.id}`,
              "",
              "width=1080,height=400,left=200,top=200"
            );
          }
        })
        .catch((err) =>
          Notification({
            type: "error",
            message: `${
              err?.originalError?.response?.data?.message ?? "Error"
            }`,
          })
        )
        .finally(() => setLoading(false));
    }
  };

  const handleOpenWindow = (e) => {
    e.preventDefault();
    const inventoryBool = inventory?.map((el) => {
      if (el.apply === "" || el.operator === "") {
        return true;
      }
      return false;
    });
    setInventoryError([...inventoryBool]);
    const actionsBool = actions?.map((el) => {
      if (el.action === "" || el?.params?.class_rank === "") {
        return true;
      }
      return false;
    });
    setActionsError([...actionsBool]);
    const bool =
      rulesCreatorData.name !== "" &&
      rulesConditions.origin.length > 0 &&
      rulesConditions.destination.length > 0 &&
      rulesConditions.cabin.length > 0 &&
      rulesConditions.competitor.length > 0;
    if (ruleName !== rulesCreatorData.name) {
      if (bool) {
        postService();
        setFormError(false);
      } else {
        Notification({
          type: "error",
          message: "Fill in the mandatory fields",
        });
        setFormError(true);
      }
    } else {
      Notification({
        type: "error",
        message: "Rule name already exists",
      });
    }
  };

  const handleScrollSmooth = (index: number) => {
    switch (index) {
      case 1:
        businessRulesRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        rulesCreatorRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        rulesConditionsRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        strategyRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;

      default:
        rulesCreatorRef?.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  const editRule = (id, type) => {
    RulesGetSimpleByIdClient.fetchRulesGetSimple(id).then((res: any) => {
      handleScrollSmooth(2);
      if (type === "edit") {
        setRuleId(res.id ?? "");
        setRuleName("");
      } else if (type === "duplicate") {
        setRuleId("");
        setRuleName(res.name);
      }
      setIsActive(res.is_active ?? true);

      setTimeRange((state) => ({
        ...state,
        value: [
          timeRange.dateList.indexOf(
            res?.conditions?.effective_time?.length > 0
              ? res?.conditions?.effective_time[0]
              : "00:00"
          ),
          timeRange.dateList.indexOf(
            res?.conditions?.effective_time?.length > 0
              ? res?.conditions?.effective_time[1]
              : "23:59"
          ),
        ],
      }));
      const creator = {
        name: type === "edit" ? res.name : res.name + " - DUPLICATE" ?? "",
        description: res.description ?? "",
        note: res.note ?? "",
        auto: false,
        effective_date: res.effecitve_date ?? [],
      };
      setRulesCreatorData(creator);
      const conditions = {
        region: res.conditions.region ?? "",
        origin: res.conditions.origin ?? [],
        destination: res.conditions.destination ?? [],
        flight_number: res.conditions.flight_number ?? [],
        effective_time: res.conditions.effective_time ?? [],
        departure_date: res.conditions.departure_date ?? [],
        cabin: res.conditions.cabin ?? "",
        equipment: res.conditions.equipment ?? [],
        competitor: res.conditions.competitor ?? "",
        competitor_range: res.conditions.competitor_range ?? [1, 1],
        dtd: res.conditions.dtd ?? [1, 2],
        dow: res.conditions.dow ?? [],
      };
      setRulesConditions(conditions);
      clearInventoryAndActions().then(() => {
        setInventory(res.conditions.analysis);
        setActions([res.event]);
      });
    });
  };
  return (
    <form onSubmit={handleOpenWindow}>
      <div className={styles.container}>
        <div className={styles.rules_priority}>
          <StepByStep
            scrollSmooth={handleScrollSmooth}
            isActive={isActive}
            setIsActive={setIsActive}
            loading={loading}
            saveIsActive={saveIsActive}
          />
        </div>
        <div ref={businessRulesRef}>
          <BusinessRules
            scrollSmooth={handleScrollSmooth}
            editRule={editRule}
            createdNewRule={createdNewRule}
            setCreatedNewRule={setCreatedNewRule}
            clearForm={clearForm}
          />
        </div>
        <div ref={rulesCreatorRef}>
          <RulesCreator
            data={rulesCreatorData}
            setData={setRulesCreatorData}
            formError={formError}
          />
        </div>
        <div
          ref={rulesConditionsRef}
          style={{ position: "relative", zIndex: 2 }}
        >
          <RulesConditions
            data={rulesConditions}
            setData={setRulesConditions}
            formError={formError}
            options={ruleOptions}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            flightNumberOptions={flightNumberOptions}
            setFlightNumberSearch={setFlightNumberSearch}
            setOrigCityAirportLookup={setOrigCityAirportLookup}
            setDestCityAirportLookup={setDestCityAirportLookup}
          />
        </div>
        <div style={{ marginBottom: 24 }}>
          {stats.length > 0 && (
            <Highlights data={stats} title="Strategic Tips" />
          )}
        </div>
        <div ref={strategyRef} style={{ marginBottom: 220 }}>
          <Strategy
            inventory={inventory}
            setInventory={setInventory}
            actions={actions}
            setActions={setActions}
            options={ruleOptions}
            inventoryError={inventoryError}
            actionsError={actionsError}
          />
        </div>
      </div>
    </form>
  );
}
