import * as React from "react";
import { Tooltip as TooltipComponent, TooltipProps } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ThemeProvider, createTheme } from "@mui/material";
import styles from "./styles.module.css";

function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}
const theme = createTheme({
  components: {
    // Name of the component
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
      styleOverrides: {
        // Name of the slot
        tooltip: {
          // Some CSS
          backgroundColor: "var(--material-theme)",
          color: "var(--mui-text-color)",
          // Add these styles to dynamically adjust width
          width: "auto",
          maxWidth: "none",
          whiteSpace: "nowrap",
        },
      },
    },
  },
});

function TriggerTooltip({ ...props }: TooltipProps) {
  const [open, setOpen] = React.useState(false);
  const { children } = props;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <TooltipComponent onClose={handleTooltipClose} open={open} {...props}>
          <span className={styles.children} onClick={handleTooltipOpen}>
            {children}
          </span>
        </TooltipComponent>
      </ClickAwayListener>
    </ThemeProvider>
  );
}

function Tooltip({ ...props }: TooltipProps) {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <TooltipComponent {...props}>{children}</TooltipComponent>
    </ThemeProvider>
  );
}

export { TriggerTooltip, Tooltip };
