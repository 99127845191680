import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ThemeContext } from "context-api/ThemeContext";
import styles from "./Modal.module.css";
import { ArrowBackIos } from "@mui/icons-material";

export default function ModalComponent({ children, open, setOpen }) {
  const { theme } = React.useContext(ThemeContext);
  const handleClose = () => setOpen(false);
  const style = {
    widht: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    bgcolor:
      theme === "dark"
        ? "var(--bg-color-primary-dark)"
        : "var(--bg-color-white)",
    boxShadow: 24,
    p: 2,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ bgcolor: "#fff" }}
      >
        <Box sx={style} className={styles.container} data-theme={theme}>
          <div className={styles.button_wrapper}>
            <span
              className={styles.back_button}
              onClick={handleClose}
              data-theme={theme}
            >
              <ArrowBackIos className={styles.arrow_icon} /> Back
            </span>
          </div>
          <div className={styles.children}>{children}</div>
        </Box>
      </Modal>
    </div>
  );
}
