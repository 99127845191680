export default function CompareFilterObjects(prev, current) {
  // Eğer aynı referansa sahiplerse eşittirler
  if (prev === current) {
    return true;
  }

  // Eğer ikisi de obje değilse veya biri undefined ise eşit değillerdir
  if (
    typeof prev !== "object" ||
    typeof current !== "object" ||
    prev === null ||
    current === null
  ) {
    return false;
  }

  // Anahtarları karşılaştır
  const keys1 = Object.keys(prev);
  const keys2 = Object.keys(current);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Tüm anahtarlar ve alt değerler eşit mi kontrol et
  for (const key of keys1) {
    if (
      !keys2.includes(key) ||
      !CompareFilterObjects(prev[key], current[key])
    ) {
      return false;
    }
  }

  return true;

  // for (const key in prev) {
  //   // Eğer prev'in key'i current'nin de aynı bir key'e sahip değilse, eşitlik false dönecek.
  //   if (!current.hasOwnProperty(key)) {
  //     return false;
  //   }

  //   // Eğer key aynı değilse veya değerler aynı değilse, eşitlik false dönecek.
  //   if (prev[key].toString() !== current[key].toString()) {
  //     return false;
  //   }
  // }

  // // Tüm key-value çiftleri eşitse, eşitlik true dönecek.
  // return true;
}
