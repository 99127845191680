import { RestClient } from "lib/api/RestClient";

export class FaresTableClient {
  private static _client: RestClient;

  static fetchFaresTable(params) {
    return FaresTableClient.getClient().getCall(
      `/api/msdv2/fare-analyzer/fares?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (FaresTableClient._client == null) {
      FaresTableClient._client = new RestClient();
    }
    return FaresTableClient._client;
  }
}
