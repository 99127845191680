import { Add, Close } from "@mui/icons-material";
import Card from "components/common/Card/Card";
import DropdownText from "components/common/Dropdown/DropdownText";
import images from "constans/images";
import ColoredIcon from "utils/ColoredIcon";
import styles from "./styles.module.css";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Input from "components/common/Input";
import { IOptions } from "types/rule-options";
import { ThemeContext } from "context-api/ThemeContext";

interface IStrategy {
  inventory: {
    apply: string;
    operator: string;
    value: number[];
  }[];
  setInventory: Dispatch<
    SetStateAction<
      {
        apply: string;
        operator: string;
        value: number[];
      }[]
    >
  >;
  actions: {
    action: string;
    params: {
      class_rank: string;
      set_avail: number;
    };
  }[];
  setActions: Dispatch<
    SetStateAction<
      {
        action: string;
        params: {
          class_rank: string;
          set_avail: number;
        };
      }[]
    >
  >;
  options: IOptions | null;
  inventoryError: boolean[];
  actionsError: boolean[];
}
export default function Strategy({
  inventory,
  actions,
  setInventory,
  setActions,
  options,
  inventoryError,
  actionsError,
}: IStrategy) {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {}, [inventory, actions]);
  const handleInventoryAdd = () => {
    setInventory((state) => [...state, { apply: "", operator: "", value: [] }]);
  };

  const handleActionsAdd = () => {
    if (actions.length < 1)
      setActions((state) => [
        ...state,
        { action: "", params: { class_rank: "", set_avail: 0 } },
      ]);
  };
  const handleInventoryRemove = (index) => {
    const array = inventory.filter((_el, i) => i !== index);
    setInventory(array);
  };
  const handleActionsRemove = (index) => {
    const array = actions.filter((_el, i) => i !== index);
    setActions(array);
  };

  const tooltipTextFound = (index) => {
    const array = options?.operators.filter(
      (el: any) => el?.value === inventory[index].operator
    );
    const tooltipText = array ? array[0]?.readable : "";
    return tooltipText;
  };

  const defaultInventory = (index, type) => {
    switch (type) {
      case "apply":
        const apply = options?.analysis.filter(
          (el) => el.value === inventory[index].apply
        );
        return Array.isArray(apply) ? apply[0]?.label ?? "" : "";
      case "operator":
        const operator = options?.operators.filter(
          (el) => el.value === inventory[index].operator
        );
        return Array.isArray(operator) ? operator[0]?.label ?? "" : "";
      case "action":
        const action = options?.actions.filter(
          (el) => el.value === actions[index].action
        );
        return Array.isArray(action) ? action[0]?.label ?? "" : "";
      case "class_rank":
        const classRank = options?.ranks.filter(
          (el) => el.value === actions[index]?.params?.class_rank
        );
        return Array.isArray(classRank) ? classRank[0]?.label ?? "" : "";

      default:
        return "";
    }
  };

  return (
    <div className={styles.container}>
      <Card variant="secondary">
        <div className={styles.body}>
          <div className={styles.card_wrapper}>
            <div className={styles.card_header} data-theme={theme}>
              Strategy If Conditions
            </div>
            <Card variant="primary">
              <div className={styles.in_card_header}>
                <span>Inventory</span>
                <div>
                  <span
                    className={styles.add_button}
                    onClick={handleInventoryAdd}
                    data-theme={theme}
                  >
                    <Add style={{ fontSize: 12 }} /> Add New
                  </span>
                  <ColoredIcon
                    imgPath={images.programming_code_checkmark}
                    backgroundColor={"#d77cbb"}
                  />
                </div>
              </div>
              <div className={styles.in_card_body}>
                <div className={styles.table_head} data-theme={theme}>
                  <span className={styles.table_head_input}>INPUT</span>
                  <span className={styles.table_head_operator}>OPERATOR</span>
                  <span className={styles.table_head_value}>VALUE</span>
                </div>
                <div className={styles.table_body}>
                  {inventory?.map((el, index) => (
                    <div
                      key={index}
                      className={styles.td}
                      data-active={inventoryError[index]}
                      data-theme={theme}
                    >
                      <span className={styles.td_count}>{index + 1}.</span>
                      <div className={styles.input_dropdown_wrapper}>
                        <DropdownText
                          options={
                            options?.analysis?.map((el) => el.label) ?? []
                          }
                          handleSelectedOption={(value) => {
                            const newValue: any = options?.analysis?.filter(
                              (el: any) => el?.label === value
                            );
                            let array = inventory;
                            array[index].apply = newValue[0]?.value;
                            setInventory([...array]);
                          }}
                          defaultSelection={defaultInventory(index, "apply")}
                          tooltip={true}
                        />
                      </div>
                      <div className={styles.operator_dropdown_wrapper}>
                        <DropdownText
                          options={
                            options?.operators?.map((el) => el.label) ?? []
                          }
                          handleSelectedOption={(value) => {
                            const newValue: any = options?.operators?.filter(
                              (el: any) => el?.label === value
                            );
                            let array = inventory;
                            array[index].operator = newValue[0]?.value;
                            if (value !== "< >") {
                              array[index].value = [
                                Array.isArray(array[index].value)
                                  ? array[index].value[0]
                                  : 0,
                              ];
                            }
                            setInventory([...array]);
                          }}
                          tooltip={tooltipTextFound(index)}
                          tooltipOptions={options?.operators ?? []}
                          description
                          defaultSelection={defaultInventory(index, "operator")}
                        />
                      </div>
                      <div
                        className={styles.value_dropdown_wrapper}
                        data-theme={theme}
                      >
                        <Input
                          type="number"
                          defaultValue={
                            Array.isArray(inventory[index].value)
                              ? inventory[index].value[0]
                              : inventory[index].value
                          }
                          onChange={(value) => {
                            let array = inventory;
                            array[index].value[0] = Number(value);
                            setInventory([...array]);
                          }}
                        />
                        <div style={{ width: 88 }}>
                          {el.operator === "between" && (
                            <Input
                              type="number"
                              defaultValue={inventory[index].value[1]}
                              onChange={(value) => {
                                let array = inventory;
                                array[index].value[1] = Number(value);
                                setInventory([...array]);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.cancel_icon_wrapper}
                        onClick={() => handleInventoryRemove(index)}
                      >
                        <Close className={styles.cancel_icon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.card_wrapper}>
            <div className={styles.card_header} data-theme={theme}>
              Strategy Then Actions
            </div>
            <Card variant="primary">
              <div className={styles.in_card_header}>
                <span>Actions</span>
                <div>
                  <span
                    className={styles.add_button}
                    onClick={handleActionsAdd}
                    data-theme={theme}
                  >
                    <Add style={{ fontSize: 12 }} /> Add New
                  </span>
                  <ColoredIcon
                    imgPath={images.programming_code_checkmark}
                    backgroundColor={"#5504d9"}
                  />
                </div>
              </div>
              <div className={styles.in_card_body}>
                <div className={styles.table_head} data-theme={theme}>
                  <span className={styles.table_head_category}>CATEGORY</span>
                  <span className={styles.table_head_action}>ACTION</span>
                  <span className={styles.table_head_set_avail}>SET AVAIL</span>
                </div>
                <div className={styles.table_body}>
                  {actions?.map((_el, index) => (
                    <div
                      key={index}
                      className={styles.td}
                      data-active={actionsError[index]}
                      data-theme={theme}
                    >
                      <span className={styles.td_count}>{index + 1}.</span>
                      <div className={styles.category_dropdown_wrapper}>
                        <DropdownText
                          options={
                            options?.actions?.map((el) => el.label) ?? []
                          }
                          handleSelectedOption={(value) => {
                            const newValue: any = options?.actions?.filter(
                              (el: any) => el.label === value
                            );
                            let array = actions;
                            array[index].action = newValue[0]?.value;
                            setActions([...array]);
                          }}
                          defaultSelection={defaultInventory(index, "action")}
                          tooltipOptions={options?.actions ?? []}
                        />
                      </div>
                      <div className={styles.action_dropdown_wrapper}>
                        <DropdownText
                          options={options?.ranks?.map((el) => el.label) ?? []}
                          handleSelectedOption={(value) => {
                            const newValue: any = options?.ranks?.filter(
                              (el: any) => el.label === value
                            );
                            let array = actions;
                            array[index].params.class_rank = newValue[0]?.value;
                            setActions([...array]);
                          }}
                          defaultSelection={defaultInventory(
                            index,
                            "class_rank"
                          )}
                        />
                      </div>
                      <div
                        className={styles.value_dropdown_wrapper}
                        data-theme={theme}
                      >
                        <Input
                          type="number"
                          defaultValue={actions[index]?.params?.set_avail}
                          onChange={(value) => {
                            let array = actions;
                            array[index].params.set_avail = Number(value);
                            setActions([...array]);
                          }}
                        />
                        <div style={{ width: 88 }}></div>
                      </div>
                      <div
                        className={styles.cancel_icon_wrapper}
                        onClick={() => handleActionsRemove(index)}
                      >
                        <Close className={styles.cancel_icon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
}
