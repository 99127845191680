/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import Plotly from "components/common/Charts/Plotly";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { AvaibilityTrendsFilterContext } from "context-api/AvaibilityTrendsFilterContext";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { PriceEvolutionClient } from "lib/api/lfa/priceEvolution/PriceEvolution";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import styles from "./styles.module.css";
import { FlightsClient } from "lib/api/lfa/flights/Flights";
import Dropdown from "components/common/Dropdown/Dropdown";
import { useQuery } from "hooks/useQuery";
import StatisticsComponent from "../Statistics";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";
import { FareTrendsTableClient } from "lib/api/lfa/fareTrendsTable/FareTrendsTablePriceEvo";
import { Tooltip } from "components/common/Tooltip";
import StatisticsComponentHost from "../StatisticsHost";
import { BasicFilterContext } from "context-api/BasicFilter";
import BasicFilter from "../BasicFilter";

interface IPriceEvolution {
  filterIsOpen: boolean;
  isOpen: boolean;
  setData: Dispatch<
    SetStateAction<{
      data: any[];
      columns: any[];
    }>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  setMeta: Dispatch<
    SetStateAction<{
      currentPage: number;
      itemsPerPage: number;
      totalItems: number;
      totalPages: number;
    }>
  >;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  selectedCarriers: string[];
  setSelectedCarriers: Dispatch<SetStateAction<string[]>>;
  selectedFlightNumbers: string[];
  setSelectedFlightNumbers: Dispatch<SetStateAction<string[]>>;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  sortModel: {
    sortKey: string;
    sortOrder: "asc" | "desc";
  }[];
}
export default function PriceEvolution({
  filterIsOpen,
  isOpen,
  setData,
  setLoading,
  meta,
  setMeta,
  activeStep,
  setActiveStep,
  selectedCarriers,
  setSelectedCarriers,
  selectedFlightNumbers,
  setSelectedFlightNumbers,
  paginationModel,
  sortModel,
}: IPriceEvolution) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const { avaibilityTrendsFilterList, setAvaibilityTrendsFilterList } =
    useContext(AvaibilityTrendsFilterContext);
  const { basicFilter } = useContext(BasicFilterContext);

  const [dataFig, setDataFig] = useState([]);
  const [layout, setLayout] = useState([]);
  const [dataHost, setDataHost] = useState<{
    data: any;
    columns: any[];
  }>({
    data: null,
    columns: [],
  });

  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);
  const [flightNumberOptions, setFlightNumberOptions] = useState<string[]>([]);

  const [flightNumberLookup, setFlightNumberLookup] = useState("");
  const [statsData, setStatsData] = useState<{
    data: any[];
    labels: any[];
  } | null>(null);

  const { flightTypeSwitch, allFlightType, owRtSwitch, setOwRtSwitch } =
    useContext(FlightTypeSwitchContext);
  let query = useQuery();

  useEffect(() => {
    const queryDate = query.get("date");
    const queryCabin = query.get("cabin");

    if (queryDate && queryCabin) {
      setAvaibilityTrendsFilterList((prevState) => ({
        ...prevState,
        date_range_start: queryDate,
      }));
      if (queryCabin === "Economy") {
        setActiveStep(169);
      }
      if (queryCabin === "Business") {
        setActiveStep(85);
      }
      if (queryCabin === "FIRST") {
        setActiveStep(0);
      }
    }
  }, []);

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      const params = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport.join(),
        dest_city_airport: filterList.destCityAirport.join(),
        outbound_date: moment(
          avaibilityTrendsFilterList.date_range_start
        ).format("YYYY-MM-DD"),
        graph_carriers: selectedCarriers.join(),
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        flight: selectedFlightNumbers,
        currency: filterList.currency,
        dark_theme: theme === "dark",
        type: owRtSwitch.toUpperCase(),
        fare_family: basicFilter,
      };
      PriceEvolutionClient.fetchPriceEvolution({ data: params })
        .then((res: any) => {
          setDataFig(res?.fig?.data);
          setLayout(res?.fig?.layout);
          setCarriersJson(res?.carriers);
          setStatsData(res?.stats);
          setDataHost({
            data: res?.host_stats?.data ?? null,
            columns: getColumns(res?.host_stats?.labels),
          });
        })
        .catch((err) => console.log(err));
    }
  }, [
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    filterList.currency,
    selectedCarriers,
    activeStep,
    selectedFlightNumbers,
    theme,
    owRtSwitch,
  ]);

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      const params = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        lookup: flightNumberLookup,
        selected: selectedFlightNumbers,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        historical: "1",
        outbound_date: moment(
          avaibilityTrendsFilterList.date_range_start
        ).format("YYYY-MM-DD"),
        type: owRtSwitch.toUpperCase(),
        graph_carriers: selectedCarriers?.join(),
        fare_family: basicFilter,
      };
      FlightsClient.fetchFlights(params)
        .then((res: any) => {
          setFlightNumberOptions(res?.flights ?? []);
        })
        .catch((err) => console.log(err));
    }
  }, [
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    flightNumberLookup,
    selectedFlightNumbers,
    owRtSwitch,
    selectedCarriers,
    activeStep,
    filterIsOpen,
    isOpen,
  ]);

  useEffect(() => {
    if (!filterIsOpen && !isOpen && owRtSwitch) {
      setLoading(true);
      const params = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        outbound_date: moment(
          avaibilityTrendsFilterList.date_range_start
        ).format("YYYY-MM-DD"),
        graph_carriers: selectedCarriers?.join(),
        flight: selectedFlightNumbers,
        type: owRtSwitch.toUpperCase(),
        dark_theme: theme === "dark",
        currency: filterList.currency,
        fare_family: basicFilter,
        page_: paginationModel.page,
        limit_: paginationModel.pageSize,
        ...(sortModel &&
          sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
      };
      FareTrendsTableClient.fetchFareTable(params)
        .then((res: any) => {
          setData({
            data: res?.data ?? [],
            columns: getColumns(res?.labels),
          });
          setMeta(res?.meta);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [
    avaibilityTrendsFilterList,
    basicFilter,
    filterList.origCityAirport,
    filterList.destCityAirport,
    filterList.currency,
    owRtSwitch,
    theme,
    activeStep,
    selectedCarriers,
    selectedFlightNumbers,
    paginationModel,
    sortModel,
  ]);
  const getColor = (value) => {
    if (value && typeof value === "number") {
      if (value < 50) {
        return "#ec4252"; //Kırmızı
      } else if (value >= 50) {
        return "#00ad92"; //Yeşil
      }
    }
    return "#9c00ed";
  };
  const getColumns = (labels) => {
    const array: {
      title: string;
      key: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
      sortable?: boolean;
    }[] = [];
    for (const [key, title] of Object.entries({
      carrier: "CARRIER",
      ...labels,
    })) {
      if (key !== "carrier" && key !== "fare" && key !== "lf") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return <span className={"rdb-custom-cell"}>{row[key]}</span>;
          },
          width: 200,
          sortable: true,
        };
        array.push(obj);
      } else if (key === "lf") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <Tooltip title={row?.LFLastupdatedAt ?? "-"}>
                <span
                  className={"rdb-custom-cell"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 8,
                  }}
                >
                  {row[key] && (
                    <span style={{ width: 30 }}>{`%${row[key] ?? "-"}`}</span>
                  )}
                  <span className={styles.bar_wrapper}>
                    <span
                      className={styles.bar}
                      style={{
                        backgroundColor: getColor(row[key]),
                        width:
                          row[key] &&
                          typeof row[key] === "number" &&
                          row[key] * 0.6,
                      }}
                    />
                  </span>
                </span>
              </Tooltip>
            );
          },
          sortable: true,
        };
        array.push(obj);
      } else if (key === "fare") {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <span className={"rdb-custom-cell"}>
                {`${row[key]?.currency} ${row[key]?.value}`}
              </span>
            );
          },

          width: 200,
          sortable: true,
        };
        array.push(obj);
      } else {
        const obj: any = {
          title,
          key,
          cell: (row) => {
            return (
              <div
                className={styles.custom_table_row}
                style={{
                  backgroundColor: row[key]?.color || "rgba(255,0,0,0.2)",
                }}
              >
                <span className={"rdb-custom-cell"}>{row[key]?.value}</span>
              </div>
            );
          },

          width: 200,
          sortable: true,
        };
        array.push(obj);
      }
    }
    return array;
  };

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          Price Evolution
        </span>
        <div className={styles.card_header_buttons}>
          <div className={styles.rt_ow_switch_wrapper}>
            <span
              data-active={owRtSwitch === "rt"}
              onClick={() => {
                flightTypeSwitch && setOwRtSwitch("rt");
              }}
              data-select={allFlightType && allFlightType !== "rt"}
            >
              RT
            </span>
            <span
              data-active={owRtSwitch === "ow"}
              onClick={() => {
                flightTypeSwitch && setOwRtSwitch("ow");
              }}
              data-select={allFlightType && allFlightType !== "ow"}
            >
              OW
            </span>
          </div>
          <span>Flight Number :</span>
          <div className={styles.dropdown_wrapper}>
            <Dropdown
              data={flightNumberOptions}
              added={selectedFlightNumbers}
              setAdded={(newValue) => {
                setSelectedFlightNumbers(newValue);
              }}
              setSearchFilter={(str) => {
                setFlightNumberLookup(str);
              }}
              width={120}
            />
          </div>
          <div className={styles.header_button_wrapper} data-theme={theme}>
            <span
              className={styles.button}
              // onClick={() => setActiveStep(0)}
              data-active={activeStep === 0}
              data-theme={theme}
            >
              First
            </span>
            <span
              className={styles.button}
              onClick={() => setActiveStep(85)}
              data-active={activeStep === 85}
              data-theme={theme}
            >
              Bus
            </span>
            <span
              className={styles.button}
              onClick={() => setActiveStep(169)}
              data-active={activeStep === 169}
              data-theme={theme}
            >
              Eco
            </span>
            <span
              className={styles.active_button}
              style={{ left: activeStep }}
            ></span>
          </div>
        </div>
      </div>
      <div>
        <BasicFilter />
      </div>
      <div className={styles.carriers_wrapper}>
        <Carriers
          data={GetCarriers()}
          added={selectedCarriers}
          setAdded={setSelectedCarriers}
          carriersJson={carriersJson}
        />
      </div>
      <Card variant="primary">
        <Plotly
          data={dataFig}
          layout={layout}
          height={439}
          minHeight={439}
          margin={{ l: 30, r: 30, t: 30, b: 30, p: 4 }}
        />
      </Card>
      {dataHost.data && (
        <div className={styles.statistics_wrapper}>
          <StatisticsComponentHost data={[dataHost.data]} isPriceEvo />
        </div>
      )}
      {statsData && (
        <div className={styles.statistics_wrapper}>
          <StatisticsComponent
            data={statsData.data}
            isPriceEvo
            hostCarrier={dataHost?.data?.carrier}
          />
        </div>
      )}
    </Card>
  );
}
