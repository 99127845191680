/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ThemeContext } from "context-api/ThemeContext";
import { useContext, useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js";
import { useFullScreenListener } from "hooks/useFullScreenListener";

interface IPlotly {
  width?: string | number | null;
  minWidth?: string | number | null;
  height?: string | number | null;
  minHeight?: string | number | null;
  data?: any;
  layout?: any;
  margin?:
    | {
        t: number;
        b: number;
        l: number;
        r: number;
        p: number;
      }
    | boolean;
  displayModeBar?: boolean | "hover";
}
export default function PlotlyComponent({
  data,
  width = null,
  minWidth = null,
  height = null,
  minHeight = null,
  layout,
  margin = false,
  displayModeBar = "hover",
}: IPlotly) {
  const { theme } = useContext(ThemeContext);
  const layoutObj = {
    ...layout,
    paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    showlegend: false,
    autosize: true,
    margin: margin ? margin : layout?.margin,
    font: {
      ...layout?.font,
      color: theme === "dark" ? "#fff" : "#383874",
      family: "var(--font-family-normal)",
    },
    annotations: layout?.annotations?.map((el) => ({
      ...el,
      font: { family: "var(--font-family-normal)" },
    })),
    xaxis: {
      ...layout?.xaxis,
      gridcolor: "rgba(255, 255, 255, 0.05)",
      tickfont: {
        color: "none",
        family: "var(--font-family-normal)",
        size: 13,
      },
      title: {
        ...layout?.xaxis?.title,
        font: {
          family: "var(--font-family-normal)",
        },
      },
    },
    yaxis: {
      ...layout?.yaxis,
      gridcolor: "rgba(255, 255, 255, 0.05)",
      tickfont: {
        color: "none",
        family: "var(--font-family-normal)",
        size: 13,
      },
      title: {
        ...layout?.yaxis?.title,
        font: {
          family: "var(--font-family-normal)",
        },
      },
    },
    xaxis2: {
      ...layout?.xaxis2,
      gridcolor: "rgba(255, 255, 255, 0.05)",
      tickfont: {
        color: "none",
        family: "var(--font-family-normal)",
        size: 13,
      },
      title: {
        ...layout?.xaxis2?.title,
        font: {
          family: "var(--font-family-normal)",
        },
      },
    },
    yaxis2: {
      ...layout?.yaxis2,
      gridcolor: "rgba(255, 255, 255, 0.05)",
      tickfont: {
        color: "none",
        family: "var(--font-family-normal)",
        size: 13,
      },
      title: {
        ...layout?.yaxis2?.title,

        font: {
          family: "var(--font-family-normal)",
        },
      },
    },
    geo: {
      showland: true,
      showcountries: true,
      showocean: true,
      showlakes: false,
      showrivers: false,
      oceancolor: "transparent",
      landcolor: "transparent",
      resolution: 110,
      showframe: false,
      framewidth: 0,
      countrywidth: 1,
      countrycolor: "rgba(0, 255, 233, 0.75)",
      coastlinewidth: 1,
      showcoastlines: false,
      fitbounds: "locations",
      bgcolor: "transparent",
    },
    modebar: {
      remove: ["toimage"],
    },
    title: {
      ...layout?.title,
      font: {
        ...layout?.title?.font,
        color: "none",
        family: "var(--font-family-normal)",
      },
    },
  };
  const [isFullScreen, setIsFullScreen] = useState(false);
  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const plotElement = document.querySelector(
        `.js-plotly-plot-custom-${layout?.id}`
      );
      plotElement?.requestFullscreen();
    }
  }

  function downloadImage(format) {
    const plotElement = document.querySelector(
      `.js-plotly-plot-custom-${layout?.id}`
    );
    Plotly.toImage(plotElement, { format: format, height: 800, width: 1200 })
      .then(function (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `plotly-chart.${format}`;
        link.click();
      })
      .catch((err) => console.error(err));
  }

  const config = {
    responsive: true,
    displayModeBar,
    displaylogo: false,
    modeBarButtonsToAdd: [
      {
        name: "Full Screen",
        icon: {
          width: 24,
          height: 24,
          path: "M16 3h6v6h-2V5h-4V3zM2 3h6v2H4v4H2V3zm18 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z",
        },

        click: function (gd) {
          if (layout?.id) {
            setIsFullScreen(!isFullScreen);
            toggleFullScreen();
            Plotly.Plots.resize(gd);
          }
        },
      },
      {
        name: "Download as PNG",
        icon: Plotly.Icons.camera,
        click: function (gd) {
          downloadImage("png");
        },
      },
    ],
    /* Kapatılabilir Butonlar
    “zoom2d”, “pan2d”, “select2d”, “lasso2d”, “zoomIn2d”, “zoomOut2d”, “autoScale2d”, “resetScale2d”, “hoverClosestCartesian”, “hoverCompareCartesian”, “zoom3d”, “pan3d”, “resetCameraDefault3d”, “resetCameraLastSave3d”, “hoverClosest3d”, “orbitRotation”, “tableRotation”, “zoomInGeo”, “zoomOutGeo”, “resetGeo”, “hoverClosestGeo”, “toImage”, “sendDataToCloud”, “hoverClosestGl2d”, “hoverClosestPie”, “toggleHover”, “resetViews”, “toggleSpikelines”, “resetViewMapbox”
    */
    modeBarButtonsToRemove: ["select2d", "lasso2d"],
  };
  return (
    <Plot
      data={data}
      layout={{
        ...layoutObj,
        width: isFullScreen ? null : width,
        height: isFullScreen ? null : height ? height : layout?.height,
      }}
      config={config}
      className={`js-plotly-plot-custom-${layout?.id}`}
      style={{
        minHeight,
        minWidth,
        backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    />
  );
}
