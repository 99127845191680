import { RestClient } from "lib/api/RestClient";

export class RuleOverlappingClient {
  private static _client: RestClient;

  static fetchRuleOverlapping(id) {
    return RuleOverlappingClient.getClient().getCall(
      `/api/lfa/rules/overlapping/${id}`
    );
  }

  static getClient() {
    if (RuleOverlappingClient._client == null) {
      RuleOverlappingClient._client = new RestClient();
    }
    return RuleOverlappingClient._client;
  }
}
