import { RestClient } from "lib/api/RestClient";

interface IData {
  data: {
    cabin: string;
    agg_type: string;
    display_date: string;
    currency: string[];
  };
}
export class DailyFlightsOverviewClient {
  private static _client: RestClient;
  static fetchDailyFlightsOverview({ data }: IData) {
    const params = {
      cabin: data?.cabin,
      agg_type: data?.agg_type,
      display_date: data?.display_date,
      ...(data?.currency && { currency: data.currency.join() }),
    };
    return DailyFlightsOverviewClient.getClient().getCall(
      `/api/lfa/fares2/availability-calendar?${new URLSearchParams(params)}`
    );
  }
  static getClient() {
    if (DailyFlightsOverviewClient._client == null) {
      DailyFlightsOverviewClient._client = new RestClient();
    }
    return DailyFlightsOverviewClient._client;
  }
}
