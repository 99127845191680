/**
 * All errors related to REST API retrieval
 */
export class FetchException extends Error {
    public originalError: unknown;
    public url: string;
    constructor(message: string, url: string, originalError: unknown) {
        super(message);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchException);
        }
        this.name = 'FetchException';
        this.url = url;
        this.originalError = originalError;
    }
}
