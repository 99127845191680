/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export function useFullScreenListener(callback: () => void) {
  useEffect(() => {
    document.addEventListener("fullscreenchange", callback);
    document.addEventListener("webkitfullscreenchange", callback);
    document.addEventListener("mozfullscreenchange", callback);
    document.addEventListener("MSFullscreenChange", callback);

    return () => {
      document.removeEventListener("fullscreenchange", callback);
      document.removeEventListener("webkitfullscreenchange", callback);
      document.removeEventListener("mozfullscreenchange", callback);
      document.removeEventListener("MSFullscreenChange", callback);
    };
  }, []);
}
