import { FilterContext } from "context-api/FilterContext";
import { useContext } from "react";

export default function GetCarriers() {
  const { filterList } = useContext(FilterContext);
  const mainComp = localStorage.getItem("allCompetitors");
  if (mainComp) {
    const find = filterList.selectedCompetitors.find((item) => item === "All");
    if (find === "All") {
      return JSON.parse(mainComp);
    } else
      return [...filterList.mainCompetitor, ...filterList.selectedCompetitors];
  } else
    return [...filterList.mainCompetitor, ...filterList.selectedCompetitors];
}
