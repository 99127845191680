import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  basicFilter: string;
  setBasicFilter: React.Dispatch<React.SetStateAction<string>>;
}

const contextDefaultValues: ContextState = {
  basicFilter: "",
  setBasicFilter: () => {},
};
const BasicFilterContext =
  React.createContext<ContextState>(contextDefaultValues);
const BasicFilterConsumer = BasicFilterContext.Consumer;

const BasicFilterContextApp = ({ children }: Props) => {
  const [basicFilter, setBasicFilter] = useState<string>("");
  return (
    <BasicFilterContext.Provider
      value={{
        basicFilter,
        setBasicFilter,
      }}
    >
      {children}
    </BasicFilterContext.Provider>
  );
};

export { BasicFilterContext, BasicFilterConsumer, BasicFilterContextApp };
