import { RestClient } from "../../RestClient";

export class FareTrendsClient {
  private static _client: RestClient;
  static fetchFareTrends({ data }) {
    return FareTrendsClient.getClient().getCall(
      `/api/msdv2/fare-structure/fare-trends?${new URLSearchParams(data)}`
    );
  }
  static getClient() {
    if (FareTrendsClient._client == null) {
      FareTrendsClient._client = new RestClient();
    }
    return FareTrendsClient._client;
  }
}
