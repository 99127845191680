import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import LazyLoad from "react-lazyload";

type Props = {
  imgPath: any;
  backgroundColor: string | null;
  width?: string | number;
  height?: string | number;

  wrapper?: string | number;
};

export default function ColoredIcon({
  imgPath,
  backgroundColor = null,
  wrapper = 40,
  width = 24,
  height = 24,
}: Props) {
  const { theme } = useContext(ThemeContext);
  const styles = {
    iconWrapper: {
      width: wrapper,
      minWidth: 40,
      height: wrapper,
      minHeight: 40,
      borderRadius: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor:
        theme === "dark"
          ? backgroundColor ?? "#ec4252"
          : `${backgroundColor ?? "#ec4252"}e6`,
    },
    image: {
      width,
      height,
    },
  };

  return (
    <span style={styles.iconWrapper}>
      <LazyLoad height={200} offset={100}>
        <img style={styles.image} src={imgPath} alt="" loading="lazy" />
      </LazyLoad>
    </span>
  );
}
