import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../RestClient";

export class FilterClient {
  private static _client: RestClient;

  static fetchFilterOptions(filterList: IFilterContext) {
    const queryParams = QueryParameterStringBuilder.apply(filterList);
    return FilterClient.getClient().getCall(
      `/api/msdv2/filters/msd-filter-options?${queryParams}`
    );
  }

  static getClient() {
    if (FilterClient._client == null) {
      FilterClient._client = new RestClient();
    }
    return FilterClient._client;
  }
}
