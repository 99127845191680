import { RestClient } from "../RestClient";

export class HolidaysFilterClient {
  private static _client: RestClient;
  static fetchFilterOptions(params) {
    return HolidaysFilterClient.getClient().getCall(
      `/api/msdv2/filters/msd-filter-options?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (HolidaysFilterClient._client == null) {
      HolidaysFilterClient._client = new RestClient();
    }
    return HolidaysFilterClient._client;
  }
}
