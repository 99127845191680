import Card from "components/common/Card/Card";
import styles from "./RBDs.module.css";
import { useContext, useEffect, useState } from "react";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";

interface IRBDs {
  title: string;
  data?: any;
}
export default function RBDs({ title, data }: IRBDs) {
  const [dataSet, setDataSet] = useState<any>([]);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (data !== undefined) {
      const array: any = [];
      data.forEach((element) => {
        if (element.type === "bar") {
          array.push(element);
        }
      });
      setDataSet(array);
    }
  }, [data]);
  return (
    <Card variant="primary" width={"50%"} height={48}>
      <div className={styles.container}>
        <img
          src={images.clock_watch}
          alt="icon"
          className={styles.icon}
          data-theme={theme}
        />
        <span className={styles.title_text} data-theme={theme}>
          {title}
        </span>
        <div className={styles.content_wrapper} data-theme={theme}>
          {dataSet?.map((element, index) => {
            return (
              <div
                key={index}
                style={{
                  marginRight: (index + 1) % 3 === 0 ? 18 : 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className={styles.dot_text}>{element.text}</span>
                <span
                  className={styles.dot}
                  style={{
                    backgroundColor: element.marker.color,
                    borderColor: element.marker.color,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
}
