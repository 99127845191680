import { RestClient } from "lib/api/RestClient";
export class RecommendationsClient {
  private static _client: RestClient;
  static fetchRecommendations({ params }) {
    return RecommendationsClient.getClient().getCall(
      `/api/msdv2/rules/recommendations?${new URLSearchParams(params)}`
    );
  }
  static getClient() {
    if (RecommendationsClient._client == null) {
      RecommendationsClient._client = new RestClient();
    }
    return RecommendationsClient._client;
  }
}
