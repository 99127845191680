import { RestClient } from "../RestClient";
export class HolidaysClient {
  private static _client: RestClient;

  static fetchHolidays(params) {
    return HolidaysClient.getClient().getCall(
      `/api/msdv2/events/calendar?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (HolidaysClient._client == null) {
      HolidaysClient._client = new RestClient();
    }
    return HolidaysClient._client;
  }
}
