/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Replay } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import Card from "components/common/Card/Card";
// import DatePicker from "components/common/DatePicker/DatePicker";
import DatePicker2 from "components/common/DatePicker/DatePicker2";
import Dropdown from "components/common/Dropdown/Dropdown";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import moment from "moment";
import { Dispatch, SetStateAction, useContext } from "react";
import ColoredIcon from "utils/ColoredIcon";
import styles from "./styles.module.css";

interface IRulesCreator {
  data: {
    name: string;
    description: string;
    note: string;
    auto: boolean;
    effective_date: string[] | never[];
  };
  setData: Dispatch<
    SetStateAction<{
      name: string;
      description: string;
      note: string;
      auto: boolean;
      effective_date: string[] | never[];
    }>
  >;
  formError: boolean;
}

export default function RulesCreator({
  data,
  setData,
  formError,
}: IRulesCreator) {
  const { theme } = useContext(ThemeContext);
  const handleChange = (e) => {
    setData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };
  const [isForever, setIsForever] = useState(false);

  useEffect(() => {
    if (data?.effective_date.length === 1) setIsForever(true);
    else if (data?.effective_date.length === 2) setIsForever(false);
  }, [data]);

  return (
    <div className={styles.container}>
      <Card variant="secondary" width={"100%"} title={"Rules Creator"}>
        <div className={styles.card_body}>
          <Card variant="primary" width={"60%"}>
            <div className={styles.rules_creator_card_header}>
              <span className={styles.rules_creator_card_title}>
                General Information
              </span>
              <ColoredIcon
                imgPath={images.line_pen}
                backgroundColor={"#5504d9"}
              />
            </div>
            <div className={styles.rules_creator_body}>
              <div className={styles.rules_form}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className={styles.form_element}>
                    <label className={styles.form_labels} data-theme={theme}>
                      Rule Name:
                    </label>
                    <input
                      className={`${styles.rule_name} ${
                        formError ? (data.name === "" ? styles.error : "") : ""
                      }`}
                      type="text"
                      name="name"
                      onChange={handleChange}
                      value={data?.name}
                      maxLength={30}
                      data-theme={theme}
                    />
                  </div>
                  <div className={styles.form_element}>
                    <label className={styles.form_labels} data-theme={theme}>
                      Rule Auto:
                    </label>
                    <div style={{ width: 300 }}>
                      <Dropdown
                        data={["Yes", "No"]}
                        added={["No"]}
                        setAdded={() => {}}
                        singleSelected
                        width={300}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider className={styles.divider} />
              <div className={styles.rules_form}>
                <div className={styles.form_element}>
                  <label className={styles.form_labels} data-theme={theme}>
                    Description:
                  </label>
                  <textarea
                    className={styles.description}
                    name="description"
                    onChange={handleChange}
                    value={data?.description}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.form_element}>
                  <label className={styles.form_labels} data-theme={theme}>
                    Notes:
                  </label>
                  <textarea
                    className={styles.notes}
                    name="note"
                    onChange={handleChange}
                    value={data?.note}
                    data-theme={theme}
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card variant="primary" width={"40%"}>
            <div className={styles.rules_creator_card_header}>
              <span className={styles.rules_creator_card_title}>
                Effective Dates
              </span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginRight: 8, cursor: "pointer" }}
                  onClick={() => {
                    setData((state) => ({ ...state, effective_date: [] }));
                  }}
                >
                  <Tooltip title="Clear Date">
                    <Replay />
                  </Tooltip>
                </span>
                <ColoredIcon
                  imgPath={images.line_pen}
                  backgroundColor={"#5504d9"}
                />
              </div>
            </div>
            <div className={styles.forever_wrapper} data-theme={theme}>
              <div className={styles.buttons_wrapper}>
                <span
                  className={styles.button_forever}
                  data-active={!isForever}
                  onClick={() => {
                    setIsForever(!isForever);
                    setData((state) => ({ ...state, effective_date: [] }));
                  }}
                >
                  Range
                </span>
                <span
                  className={styles.button_forever}
                  data-active={isForever}
                  onClick={() => {
                    setIsForever(!isForever);
                    setData((state) => ({ ...state, effective_date: [] }));
                  }}
                >
                  Date <span className={styles.forever_text}>∞</span>
                </span>
              </div>
            </div>
            <div className={styles.datepicker_wrapper}>
              <DatePicker2
                minDate={new Date()}
                isSingle={isForever}
                defaultDate={{
                  startDate:
                    data.effective_date.length > 0
                      ? new Date(
                          moment(data.effective_date[0], "YYYY-MM-DD").format()
                        )
                      : null,
                  endDate:
                    data.effective_date.length > 0
                      ? new Date(
                          moment(data.effective_date[1], "YYYY-MM-DD").format()
                        )
                      : null,
                }}
                onChange={(date) => {
                  if (isForever) {
                    if (date?.startDate) {
                      setData((state) => ({
                        ...state,
                        effective_date: [
                          moment(date?.startDate).format("YYYY-MM-DD"),
                        ],
                      }));
                    }
                  } else {
                    if (date?.startDate && date?.endDate) {
                      setData((state) => ({
                        ...state,
                        effective_date: [
                          moment(date?.startDate).format("YYYY-MM-DD"),
                          moment(date?.endDate).format("YYYY-MM-DD"),
                        ],
                      }));
                    }
                  }
                }}
              />
            </div>
          </Card>
        </div>
      </Card>
    </div>
  );
}
