import { RestClient } from "../RestClient";

export class KPISaveClient {
  private static _client: RestClient;

  static fetchKPISave({ data }) {
    return KPISaveClient.getClient().putCall(
      `/api/msdv2/users/store-value`,
      data
    );
  }

  static getClient() {
    if (KPISaveClient._client == null) {
      KPISaveClient._client = new RestClient();
    }
    return KPISaveClient._client;
  }
}
