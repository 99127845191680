/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ICustomAtarevTable } from "types/types";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import TBody from "./tbody";
import PaginationComponent from "./pagination";
import styles from "./styles.module.css";
import Thead from "./thead";
import { arrayMove } from "react-sortable-hoc";
import { CircularProgress } from "@mui/material";

const CustomAtarevTable = ({
  data,
  columns,
  colGroups,
  selectableRows,
  onRowClick,
  pagination = false,
  height = "auto",
  expand = false,
  isShowing = true,
  isShowPerPage = false,
  conditionalRowStyles = undefined,
  nthChild = false,
  defaultRowsPerPage = 10,
  defaultAllSelected = false,
  defaultRowsSelected = undefined,
  rowHeight = null,
  loader = false,
  meta = null,
  setMeta,
  isManageMenu = true,
  disableSelectedAllRows = true,
  maxSelection = undefined,
}: ICustomAtarevTable) => {
  const { theme } = useContext(ThemeContext);
  const [sortedData, setSortedData] = useState(data);
  const [multiSort, setMultiSort] = useState<
    {
      field: string;
      value: string;
    }[]
  >([]);
  const [sortStatus, setSortStatus] = useState<{ [key: string]: number }>({});
  const [selectedRows, setSelectedRows] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isSortMenuOpen, setIsSortMenuOpen] = React.useState(false);
  const [multiFilter, setMultiFilter] = useState({});
  const [filterUniqValues, setFilterUniqValues] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isResetAll, setIsResetAll] = useState(false);
  const [selectedGroupFields, setSelectedGroupFields] = useState<string[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>(Object.fromEntries(columns.map((column) => [column.field, true])));

  const [state, setState] = useState({
    items: columns.filter((column) => columnVisibility[column.field]),
  });

  const tableWrapperRef: any = useRef(null);
  const filterMenuRefs: any = useRef(columns?.map(() => createRef()));
  const openMenuRef = useRef(null);

  // Multi Sort Menu dışında tıklanan alanları yakalayıp menüyü kapatan işlev
  useOutsideClick(openMenuRef, () => {
    setIsSortMenuOpen(false);
  });

  // Default olarak gizlenmiş kolonları belirler
  useEffect(() => {
    setState({
      items: columns.filter((column) => columnVisibility[column.field]),
    });
  }, [columns, columnVisibility]);

  // Default olarak gizlenmiş kolonları belirler
  useEffect(() => {
    if (meta) {
      setCurrentPage(meta.currentPage);
      setRowsPerPage(meta.itemsPerPage);
      return;
    }
    setRowsPerPage(defaultRowsPerPage);
  }, [meta, defaultRowsPerPage]);

  // Kolonların sırasını değiştirir
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(({ items }) => ({
      items: arrayMove(
        items.filter((column) => columnVisibility[column.field]),
        oldIndex,
        newIndex
      ),
    }));
  };

  // Filtre menüsü dışında herhangi bir yer tıklandığında tüm açık filtre menülerini kapatmak için işlev
  const handleClickOutside = (event) => {
    if (
      filterMenuRefs?.current?.every(
        (ref) => ref?.current && !ref?.current?.contains(event.target)
      )
    ) {
      setMultiFilter({});
    }
  };

  // Tüm kolonların sıralama işlemlerini başlangıç haline döndiren işlev.
  useEffect(() => {
    filterMenuRefs.current = columns?.map(() => createRef());
    setSortedData(data);
    setSortStatus(
      columns.reduce((acc, column) => {
        acc[column.field] = 0;
        return acc;
      }, {})
    );
    handleCheckboxCheck();
    const initialColumnVisibility = Object.fromEntries(
      columns.map((column) => [column.field, true])
    );
    setColumnVisibility(initialColumnVisibility);
  }, [data, columns]);

  const toggleColumnVisibility = (field: string, bool: boolean) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: bool,
    }));
  };

  // Tüm kolonların varsayılan olarak seçilme işlemlerini yapan işlev.
  useEffect(() => {
    if (defaultAllSelected) {
      const newSelectAllChecked = true;
      setSelectAllChecked(newSelectAllChecked);
      const updatedSelectedRows = {};
      data.forEach((row: any) => {
        updatedSelectedRows[row["id"]] = newSelectAllChecked;
      });
      setSelectedRows(updatedSelectedRows);
      if (newSelectAllChecked) {
        const selectedRowIds = data.map((_, rowId) => rowId);
        // Tüm satırları seçilen satırlar olarak dışarı aktarın (isteğe bağlı)
        const selectedRowsData = selectedRowIds.map((rowId) => data[rowId]);
        selectableRows && selectableRows(selectedRowsData);
      } else {
        // "Select All" seçimi kaldırıldığında tüm seçimleri temizler (isteğe bağlı)
        selectableRows && selectableRows([]);
      }
    } else if (defaultRowsSelected && Array.isArray(defaultRowsSelected)) {
      const updatedSelectedRows = {};
      let updateSelectedRow: any[] = [];
      defaultRowsSelected.forEach((item: string | number) => {
        const newData = data.filter((el) => item === el?.id);
        newData.forEach((row: any) => {
          updatedSelectedRows[item] = true;
        });
        updateSelectedRow = newData;
      });
      setSelectedRows(updatedSelectedRows);
      selectableRows && selectableRows(updateSelectedRow);
    }
  }, [defaultAllSelected, defaultRowsSelected]);

  // Multi sort menü pozisyonunu scroll'a göre ayarlayan işlev
  useEffect(() => {
    const handleScroll = () => {
      if (tableWrapperRef?.current) {
        setScrollLeft(tableWrapperRef?.current?.scrollLeft);
      }
    };

    if (tableWrapperRef?.current) {
      tableWrapperRef?.current?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableWrapperRef?.current) {
        tableWrapperRef?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableWrapperRef]);

  // Filtre menüsü dışındaki alanların tıklanmasını dinleyen olay.
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Gruplar için sıralama sırasını belirleyen fonksiyon.
  const sortByIdsOrder = (a, b) => {
    if (colGroups) {
      const fieldsOrder = colGroups.reduce(
        (order: any, group) => [
          ...order,
          ...group.fields.map((field) => field.field),
        ],
        []
      );
      const indexOfA = fieldsOrder.indexOf(a.field);
      const indexOfB = fieldsOrder.indexOf(b.field);
      return indexOfA - indexOfB;
    }
    return 0;
  };

  // Columns'u sortByIdsOrder fonksiyonunu kullanarak sıralar.
  columns.sort(sortByIdsOrder);

  // Veriyi tek bir kolona göre sıralayan işlev
  const sortData = (column) => {
    const { field, valueGetter } = column;
    const currentStatus = sortStatus[field];
    const sorted = [...sortedData];
    const updatedSortStatus: { [key: string]: number } = { ...sortStatus };
    updatedSortStatus[field] =
      currentStatus === 0 ? 1 : currentStatus === 1 ? 2 : 0;
    columns.forEach((column) => {
      if (column.field !== field) {
        updatedSortStatus[column.field] = 0;
      }
    });
    setSortStatus(updatedSortStatus);
    if (sortStatus[field] === 0) {
      sorted.sort((a, b) => {
        const valueA = valueGetter
          ? valueGetter(a)?.toString()?.toLowerCase()
          : a[field]?.toString()?.toLowerCase();
        const valueB = valueGetter
          ? valueGetter(b)?.toString()?.toLowerCase()
          : b[field]?.toString()?.toLowerCase();

        if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
          // İki değer de sayısal ise, doğrudan sayıları karşılaştırın.
          return Number(valueA) - Number(valueB);
        } else {
          // En az bir değer sayısal değilse, yerel karşılaştırma kullanın.
          return valueA?.localeCompare(valueB);
        }
      });
    } else if (sortStatus[field] === 1) {
      sorted.reverse();
    } else {
      setSortedData(data);
      return;
    }
    setSortedData(sorted);
  };

  // Veriyi çoklu kolona göre sıralayan işlev
  const handleMultiSort = () => {
    const sorted = [...sortedData];
    const updatedSortStatus: { [key: string]: number } = { ...sortStatus };
    columns.forEach((column) => {
      updatedSortStatus[column.field] = 0;
    });
    setSortStatus(updatedSortStatus);
    sorted.sort((a, b) => {
      for (const sort of multiSort) {
        const column = columns.find((col) => col.field === sort.field);
        const valueA = column?.valueGetter
          ? column.valueGetter(a)
          : a[sort.field];
        const valueB = column?.valueGetter
          ? column.valueGetter(b)
          : b[sort.field];
        if (typeof valueA === "number" && typeof valueB === "number") {
          if (sort.value === "Sort by DESC") {
            if (valueA < valueB) {
              return 1;
            } else if (valueA > valueB) {
              return -1;
            }
          } else {
            if (valueA < valueB) {
              return -1;
            } else if (valueA > valueB) {
              return 1;
            }
          }
        } else {
          const stringA = String(valueA).toLowerCase();
          const stringB = String(valueB).toLowerCase();
          const comparison = stringA.localeCompare(stringB);
          if (sort.value === "Sort by DESC") {
            if (comparison !== 0) {
              return comparison * -1;
            }
          } else {
            if (comparison !== 0) {
              return comparison;
            }
          }
        }
      }
      return 0;
    });
    setSortedData(sorted);
    setIsSortMenuOpen(false);
  };

  // Sort öğesi eklemek için bir işlev
  const addMultiSort = () => {
    if (multiSort.length < columns.length) {
      // Yeni bir sort öğesi oluştur
      const newFilter = {
        field: columns[0].field, // İlk sütun değerini kullanabilirsiniz
        value: "Sort by ASC", // Varsayılan bir değer ekleyebilirsiniz
      };
      // mevcut multiSort dizisine yeni sort öğesini ekleyin
      setMultiSort([...multiSort, newFilter]);
    }
  };

  const handleCheckboxCheck = () => {
    const updatedSelectedRowIds = Object.keys(selectedRows).filter((id) =>
      data.some((el) => el.id.toString().indexOf(id.toString()) === 0)
    );
    let updatedSelectedRows = {};
    for (let key in selectedRows) {
      if (updatedSelectedRowIds.some((el) => el === key.toString())) {
        updatedSelectedRows[key] = selectedRows[key];
      }
    }
    setSelectedRows(updatedSelectedRows);

    // Seçilen satırları dışarı aktar (isteğe bağlı)
    const selectedRowsData = updatedSelectedRowIds.map((id) =>
      data.find((el) => el.id.toString().indexOf(id.toString()) === 0)
    );

    selectableRows && selectableRows(selectedRowsData);

    // Bir satır seçildiğinde "Select All" seçeneğini kontrol et
    if (updatedSelectedRowIds.length === data.length && data.length > 0) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  };

  // Satırın seçim durumunu işlemek için bir işlev
  const handleCheckboxChange = (rowId) => {
    if (maxSelection) {
      if (Object.keys(selectedRows).length < maxSelection) {
        const updatedSelectedRows = { ...selectedRows };
        updatedSelectedRows[rowId] = !updatedSelectedRows[rowId];
        setSelectedRows(updatedSelectedRows);
        const updatedSelectedRowIds = Object.keys(updatedSelectedRows)
          .filter((rowId) => updatedSelectedRows[rowId])
          .map((rowId) => parseInt(rowId));

        // Seçilen satırları dışarı aktarın (isteğe bağlı)
        const selectedRowsData = updatedSelectedRowIds.map((id) =>
          sortedData.find((el) => el.id.toString().indexOf(id.toString()) === 0)
        );
        selectableRows && selectableRows(selectedRowsData);

        // Bir satır seçildiğinde "Select All" seçeneğini kontrol et
        if (updatedSelectedRowIds.length === sortedData.length) {
          setSelectAllChecked(true);
        } else {
          setSelectAllChecked(false);
        }
      }
      return;
    }
    const updatedSelectedRows = { ...selectedRows };
    updatedSelectedRows[rowId] = !updatedSelectedRows[rowId];
    setSelectedRows(updatedSelectedRows);
    const updatedSelectedRowIds = Object.keys(updatedSelectedRows)
      .filter((rowId) => updatedSelectedRows[rowId])
      .map((rowId) => parseInt(rowId));

    // Seçilen satırları dışarı aktarın (isteğe bağlı)
    const selectedRowsData = updatedSelectedRowIds.map((id) =>
      sortedData.find((el) => el.id.toString().indexOf(id.toString()) === 0)
    );
    selectableRows && selectableRows(selectedRowsData);

    // Bir satır seçildiğinde "Select All" seçeneğini kontrol et
    if (updatedSelectedRowIds.length === sortedData.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  };

  // "Select All" değişikliğini işlemek için bir işlev
  const handleSelectAllChange = () => {
    const newSelectAllChecked = !selectAllChecked;
    setSelectAllChecked(newSelectAllChecked);
    const updatedSelectedRows = {};
    sortedData.forEach((col: any) => {
      updatedSelectedRows[col["id"]] = newSelectAllChecked;
    });
    setSelectedRows(updatedSelectedRows);
    if (newSelectAllChecked) {
      const selectedRowIds = sortedData.map((_, rowId) => rowId);
      // Tüm satırları seçilen satırlar olarak dışarı aktarın (isteğe bağlı)
      const selectedRowsData = selectedRowIds.map((rowId) => sortedData[rowId]);
      selectableRows && selectableRows(selectedRowsData);
    } else {
      // "Select All" seçimi kaldırıldığında tüm seçimleri temizler (isteğe bağlı)
      selectableRows && selectableRows([]);
    }
  };

  // Sayfa değişim işlevi
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (setMeta) {
      setMeta((prevState) => ({ ...prevState, currentPage: page }));
      return;
    }
    setCurrentPage(page);
  };

  // Filtre menüsünü açan işlev
  const handleFilterOpenMenu = (field) => {
    setIsSortMenuOpen(false);
    let updatedMenu = { ...multiFilter };
    for (const column in multiFilter) {
      updatedMenu[column] = false;
    }
    // Tıklanan sütunu göstermek için sütunun kimliğini state'i güncelleyin
    setMultiFilter({
      ...updatedMenu,
      [field]: !multiFilter[field],
    });
    getUniqValues(field);
  };

  // Her bir kolon verisini uniq olarak oluşturan işlev
  const getUniqValues = (
    field,
    search: string | undefined = undefined,
    isCleared = false
  ) => {
    let filteredData = data?.filter((item) => {
      // varsayılan olarak her öğeyi geçir
      let shouldInclude = true;

      // Filtre objesinin her alanını dolaş
      for (const filterField in isCleared ? {} : selectedFilters) {
        if (filterField !== field && selectedFilters[filterField].length > 0) {
          if (selectedFilters.hasOwnProperty(filterField)) {
            const filterValues = selectedFilters[filterField];
            const column = columns.find((col) => col.field === filterField);
            const itemValue = column?.valueGetter
              ? column.valueGetter(item)
              : item[filterField];

            // Eğer filtrenin içinde veri yoksa, geçerli öğeyi dahil et
            if (filterValues.length === 0) {
              continue;
            }

            // Eğer öğe filtrenin içinde yoksa, bu öğeyi dışla
            if (!filterValues.includes(String(itemValue))) {
              shouldInclude = false;
              break; // Filtreyi geçersiz kıl
            }
          }
        }
      }
      return shouldInclude;
    });
    const column = columns.find((col) => col.field === field);

    let values = filteredData?.map((item) =>
      column?.valueGetter ? column.valueGetter(item) : item[field]
    );
    let uniqueValues = Array.from(new Set(values));
    uniqueValues.sort((a, b) => {
      if (typeof a === "number" && typeof b === "number") {
        return a - b;
      }
      const valueA = a?.toString();
      const valueB = b?.toString();
      return valueA?.localeCompare(valueB);
    });
    if (search !== undefined) {
      uniqueValues = uniqueValues?.filter(
        (item) =>
          item?.toString()?.toLowerCase()?.indexOf(search?.toLowerCase()) === 0
      );
    }

    setFilterUniqValues({
      ...filterUniqValues,
      [field]: uniqueValues,
    });
  };

  useEffect(() => {
    setIsResetAll(false);
    setSelectedFilters({});
    filterData({});
  }, [data]);
  // Her bir kolon için filtre seçmeyi sağlayan işlev
  const handleSelectFilterChange = (field, item, isChecked) => {
    let updatedFilters = { ...selectedFilters };
    if (!updatedFilters[field]) {
      updatedFilters[field] = [];
    }
    if (isChecked) {
      updatedFilters[field].push(item);
    } else {
      const index = updatedFilters[field].indexOf(item);
      if (index !== -1) {
        updatedFilters[field].splice(index, 1);
      }
    }
    let count = 0;
    for (const item in updatedFilters) {
      if (updatedFilters[item].length > 0) {
        count = count + 1;
      }
    }
    setIsResetAll(count > 1);
    setSelectedFilters(updatedFilters);
    filterData(updatedFilters);
  };
  // Her bir kolon için filtre seçimini kaldırmayı sağlayan işlev
  const handleClearFilterChange = (field, isAll = false) => {
    let updatedFilters = isAll ? {} : { ...selectedFilters, [field]: [] };
    if (isAll) {
      setIsResetAll(!isAll);
    }
    setSelectedFilters(updatedFilters);
    filterData(updatedFilters);
  };

  // Seçilen filtreye göre datayı güncelleyen işlev
  const filterData = (updatedFilters) => {
    let filteredData = data;
    let isAnyFilterSelected = false;
    for (const field in updatedFilters) {
      const column = columns.find((col) => col.field === field);
      if (updatedFilters.hasOwnProperty(field)) {
        const selectedValues = updatedFilters[field];
        if (selectedValues.length > 0) {
          isAnyFilterSelected = true;
          filteredData = filteredData?.filter((item) =>
            selectedValues?.includes(
              column?.valueGetter
                ? column?.valueGetter(item)?.toString()
                : item[field]?.toString()
            )
          );
        }
      }
    }
    if (!isAnyFilterSelected) {
      // Hiçbir filtre seçili değilse, başlangıç verileri ile güncelle
      setSortedData(data);
    } else {
      setSortedData(filteredData);
    }
  };

  // Sayfa başına öğeleri sıralamak ve görüntülemek için bir aralık hesaplayın
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const itemsToDisplay = meta
    ? sortedData
    : sortedData.slice(startIndex, endIndex);

  let tupleDataArray = pagination ? itemsToDisplay : sortedData;

  // Verileri sıralama fonksiyonu
  function sortTupleData(tupleDataArrayData) {
    tupleDataArrayData.sort((a, b) => {
      const hasTupleA = a.data && a.data.length > 0;
      const hasTupleB = b.data && b.data.length > 0;

      if (hasTupleA && !hasTupleB) {
        return 1; // a, b'den sonra gelmeli
      } else if (!hasTupleA && hasTupleB) {
        return -1; // a, b'den önce gelmeli
      } else {
        // Sıralamada değişiklik yapma
        return 0;
      }
    });

    // Tuple içeren elemanlardaki tuple'ları sırala
    tupleDataArrayData.forEach((item) => {
      if (item.data && item.data.length > 0) {
        sortTuple(item.data);
      }
    });
  }

  // Tuple'ları sıralama fonksiyonu
  function sortTuple(tuple) {
    tuple.sort((a, b) => {
      const hasTupleA = a.data && a.data.length > 0;
      const hasTupleB = b.data && b.data.length > 0;

      if (hasTupleA && !hasTupleB) {
        return 1; // a, b'den sonra gelmeli
      } else if (!hasTupleA && hasTupleB) {
        return -1; // a, b'den önce gelmeli
      } else {
        // Sıralamada değişiklik yapma
        return 0;
      }
    });

    // Tuple içeren elemanlardaki tuple'ları sırala
    tuple.forEach((item) => {
      if (item.data && item.data.length > 0) {
        sortTuple(item.data);
      }
    });
  }

  // Verileri sırala
  sortTupleData(tupleDataArray);

  return (
    <React.Fragment>
      {loader ? (
        <div className={`${styles.container} ${styles.progress_wrapper}`}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.table_wrapper}
            style={{ height }}
            ref={tableWrapperRef}
          >
            {data.length === 0 ? (
              <div className={styles.data_not_found_text}>Data not found</div>
            ) : (
              <table className={styles.table}>
                <Thead
                  data={data}
                  columns={columns}
                  moveColumns={state.items}
                  colGroups={colGroups}
                  filterMenuRefs={filterMenuRefs}
                  openMenuRef={openMenuRef}
                  expand={expand}
                  selectedRows={selectedRows}
                  selectableRows={selectableRows}
                  disableSelectedAllRows={disableSelectedAllRows}
                  selectAllChecked={selectAllChecked}
                  handleSelectAllChange={handleSelectAllChange}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedFilters={selectedFilters}
                  sortData={sortData}
                  sortStatus={sortStatus}
                  multiFilter={multiFilter}
                  setMultiFilter={setMultiFilter}
                  handleClearFilterChange={handleClearFilterChange}
                  isResetAll={isResetAll}
                  getUniqValues={getUniqValues}
                  filterUniqValues={filterUniqValues}
                  handleSelectFilterChange={handleSelectFilterChange}
                  handleFilterOpenMenu={handleFilterOpenMenu}
                  multiSort={multiSort}
                  setMultiSort={setMultiSort}
                  setIsSortMenuOpen={setIsSortMenuOpen}
                  scrollLeft={scrollLeft}
                  isSortMenuOpen={isSortMenuOpen}
                  addMultiSort={addMultiSort}
                  handleMultiSort={handleMultiSort}
                  selectedGroupFields={selectedGroupFields}
                  setSelectedGroupFields={setSelectedGroupFields}
                  columnVisibility={columnVisibility}
                  setColumnVisibility={setColumnVisibility}
                  toggleColumnVisibility={toggleColumnVisibility}
                  onSortEnd={onSortEnd}
                  isManageMenu={isManageMenu}
                />
                <TBody
                  data={tupleDataArray}
                  columns={state.items.filter(
                    (column) => columnVisibility[column.field]
                  )}
                  colGroups={colGroups}
                  selectableRows={selectableRows}
                  selectedRows={selectedRows}
                  theme={theme}
                  handleCheckboxChange={handleCheckboxChange}
                  expand={expand}
                  onRowClick={onRowClick}
                  conditionalRowStyles={conditionalRowStyles}
                  nthChild={nthChild}
                  selectedGroupFields={selectedGroupFields}
                  rowHeight={rowHeight}
                  columnVisibility={columnVisibility}
                  maxSelection={maxSelection}
                />
              </table>
            )}
          </div>
          {pagination && (
            <PaginationComponent
              isShowing={isShowing}
              isShowPerPage={isShowPerPage}
              startIndex={startIndex}
              endIndex={endIndex}
              sortedData={sortedData}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              theme={theme}
              setRowsPerPage={setRowsPerPage}
              handlePageChange={handlePageChange}
              meta={meta}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomAtarevTable;
