import { RestClient } from "lib/api/RestClient";

interface IFilterMarketUpdate {
  data: {
    customer: string;
    orig: string;
    dest: string;
    competitors: string[];
    points_of_sale: string[];
    sales_channels: string[];
  };
}
export class FilterMarketUpdateClient {
  private static _client: RestClient;

  static fetchFilterMarketUpdate({ data }: IFilterMarketUpdate) {
    return FilterMarketUpdateClient.getClient().putCall(
      `/api/msdv2/admin/filter-market`,
      data
    );
  }

  static getClient() {
    if (FilterMarketUpdateClient._client == null) {
      FilterMarketUpdateClient._client = new RestClient();
    }
    return FilterMarketUpdateClient._client;
  }
}
