import styles from "./styles.module.css";
const cardInfoJsx = (element) => (
  <div className={styles.hover_text_contanier}>
    <div>
      <span className={styles.card_info_title}>Region</span>
      <span className={styles.card_info_des}>{element?.region ?? "-"}</span>
    </div>
    <div>
      <span className={styles.card_info_title}>Competitor</span>
      <span className={styles.card_info_des}>{element?.competitor ?? "-"}</span>
    </div>
    <div>
      <span className={styles.card_info_title}>Flight Number</span>
      <span className={styles.card_info_des}>
        {element?.flightNumber && element?.flightNumber?.lenght > 0
          ? element?.flightNumber
          : "-" ?? "-"}
      </span>
    </div>
    <div>
      <span className={styles.card_info_title}>Days to Departure</span>
      <span className={styles.card_info_des}>{`${
        (element?.dtd && element?.dtd[0] && element?.dtd[0]) ?? "-"
      } - ${
        (element?.dtd && element?.dtd[1] && element?.dtd[1]) ?? "-"
      }`}</span>
    </div>
    <div>
      <span className={styles.card_info_title}>Days of Week</span>
      <span className={styles.card_info_des}>
        {element?.dow && element?.dow?.lenght > 0
          ? element?.dow?.join()
          : "-" ?? "-"}
      </span>
    </div>
    <div>
      <span className={styles.card_info_title}>Comp Dep Time Range</span>
      <span className={styles.card_info_des}>
        {`${
          (element?.competitor_range &&
            element?.competitor_range[0] &&
            element?.competitor_range[0]) ??
          "-"
        } - ${
          (element?.competitor_range &&
            element?.competitor_range[1] &&
            element?.competitor_range[1]) ??
          "-"
        }`}
      </span>
    </div>
    <div>
      <span className={styles.card_info_title}>Departure Time Range</span>
      <span className={styles.card_info_des}>
        {`${
          (element?.effective_time &&
            element?.effective_time[0] &&
            element?.effective_time[0]) ??
          "-"
        } - ${
          (element?.effective_time &&
            element?.effective_time[1] &&
            element?.effective_time[1]) ??
          "-"
        }`}
      </span>
    </div>
    <div>
      <span className={styles.card_info_title}>Equipment</span>
      <span className={styles.card_info_des}>
        {`${
          (element?.equipment &&
            element?.equipment[0] &&
            element?.equipment[0]) ??
          "-"
        }`}
      </span>
    </div>
    {element?.analysis?.map((el, index) => (
      <div className={styles.strategy_card} key={index}>
        <p className={styles.card_info_title}>
          {index + 1}. Strategy If Conditions
        </p>
        <p className={styles.card_info_des}>{`Apply : ${el?.apply}`}</p>
        <p className={styles.card_info_des}>{`Operator : ${el?.operator}`}</p>
        <p className={styles.card_info_des}>{`Value : ${el?.value?.join()}`}</p>
      </div>
    ))}
    {[element?.event]?.map((el, index) => (
      <div className={styles.strategy_card} key={index}>
        <p className={styles.card_info_title}>
          {index + 1}. Strategy Then Actions
        </p>
        <p className={styles.card_info_des}>{`Category : ${
          el?.type ?? "-"
        }`}</p>
        <p className={styles.card_info_des}>{`Action : ${
          el?.class_rank ?? "-"
        }`}</p>
        <p className={styles.card_info_des}>{`Set Avail : ${
          el?.value ?? "-"
        }`}</p>
      </div>
    ))}
  </div>
);
export default cardInfoJsx;
