/* eslint-disable react-hooks/exhaustive-deps */
// import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./Button.module.css";

interface IButton {
  children: ReactNode;
  data: string;
  added: string[];
  setAdded: Dispatch<SetStateAction<string[]>>;
  singleSelected?: boolean;
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  text?: "left" | "center" | "right";
  fontSize?: number | string;
}

export default function Button({
  children,
  added,
  setAdded,
  data,
  singleSelected = false,
  disabled = false,
  width,
  height,
  text = "left",
  fontSize = "14px",
}: IButton) {
  const [isActive, setIsActive] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleAdded = (item: string) => {
    if (added.length > 0) {
      const bool = added.find((str) => (str === item ? true : false));
      if (!bool) {
        if (singleSelected) {
          setAdded([item]);
        } else setAdded([...added, item]);
      } else {
        handleRemove(item);
      }
    } else {
      setAdded([...added, item]);
    }
  };

  const handleRemove = (item: string) => {
    if (added.length > 0) {
      const array = added.filter((str) => str !== item);
      setAdded(array);
      setIsActive(true);
    }
  };

  useEffect(() => {
    if (added.length > 0) {
      added.indexOf(data) !== -1 ? setIsActive(true) : setIsActive(false);
    } else {
      setIsActive(false);
    }
  });

  return (
    <div
      className={`${disabled ? styles.disabled : styles.button} ${
        styles.button
      }`}
      onClick={() => {
        if (!disabled) {
          handleAdded(data);
        }
      }}
      data-active={isActive}
      style={{ width: width, height: height, fontSize: fontSize }}
      data-theme={theme}
    >
      <span style={{ width: "100%", textAlign: text }}>{children}</span>
    </div>
  );
}
