import { useState } from "react";
import Layout from "components/common/Layout/Layout";
import FilterConfig from "modules/admin-panel/FilterConfig";
import ScrapersConfiguration from "modules/admin-panel/ScrapersConfiguration";

export default function TestPage() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  return (
    <Layout
      title="Admin Panel"
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      filterHidden
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <FilterConfig />
        <ScrapersConfiguration />
      </div>
    </Layout>
  );
}
