import Pagination from "@mui/material/Pagination";
import styles from "./styles.module.css";

interface IPagination {
  sortedData: {
    [key: string]: string | number | boolean | any[];
  }[];
  startIndex: number;
  endIndex: number;
  currentPage: number;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handlePageChange: (_event: React.ChangeEvent<unknown>, page: number) => void;
  theme: string;
  isShowing: boolean;
  isShowPerPage: boolean;
}

const PaginationComponent = ({
  setRowsPerPage,
  startIndex,
  sortedData,
  endIndex,
  rowsPerPage,
  handlePageChange,
  currentPage,
  theme,
  isShowing,
  isShowPerPage,
}: IPagination) => {
  return (
    <div className={styles.pagination_container}>
      {isShowPerPage && (
        <span style={{ minWidth: 180 }}>
          Rows per page:
          <select
            value={rowsPerPage}
            className={styles.select_option}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </span>
      )}
      {isShowing && (
        <span className={styles.pagination_info}>
          {`Showing : ${Math.min(startIndex + 1, sortedData.length)}-${Math.min(
            endIndex,
            sortedData.length
          )} of ${sortedData.length}`}
        </span>
      )}
      <Pagination
        count={Math.ceil(sortedData.length / rowsPerPage)}
        onChange={handlePageChange}
        page={currentPage}
        showFirstButton
        showLastButton
        sx={{
          "*.Mui-selected": {
            bgcolor:
              theme === "dark"
                ? "rgba(255,255,255,0.1) !important"
                : "rgba(0,0,0,0.1) !important",
            color:
              theme === "dark"
                ? "#fff !important"
                : "var(--text-color-primary-light)",
          },
          "*.MuiPagination-ul *": {
            color:
              theme === "dark"
                ? "#fff !important"
                : "var(--text-color-primary-light)",
          },
        }}
      />
    </div>
  );
};

export default PaginationComponent;
