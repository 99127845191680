import images from "constans/images";
import { useState } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";

export default function Ancillaries() {
  const [isActive, setIsActive] = useState(true);
  const array = [
    {
      title: "1 Hand Bag Up to 8 Kg / 18 Ibs",
      super_eco: true,
      eco: true,
      advantage: true,
      comfort_flex: true,
    },
    {
      title: "1 Checked Bag Up to 23 Kg / 50 ",
      super_eco: false,
      eco: true,
      advantage: true,
      comfort_flex: true,
    },
    {
      title: "Seat Reservation",
      super_eco: false,
      eco: false,
      advantage: true,
      comfort_flex: true,
    },
    {
      title: "Snack & Drinks For a Charge",
      super_eco: false,
      eco: false,
      advantage: true,
      comfort_flex: true,
    },
    {
      title: "Inflight Entertainment",
      super_eco: false,
      eco: false,
      advantage: true,
      comfort_flex: true,
    },
    {
      title: "Refundable",
      super_eco: false,
      eco: false,
      advantage: false,
      comfort_flex: true,
    },
  ];
  const icons = [
    {
      icon: images.school_bag,
      color: "#9c00ed",
    },
    {
      icon: images.travel_baggage,
      color: "#144aff",
    },
    {
      icon: images.armchair,
      color: "#5504d9",
    },
    {
      icon: images.burger_drink,
      color: "#ec4252",
    },
    {
      icon: images.headphones,
      color: "#da7706",
    },
    {
      icon: images.refresh_loading_checkmark,
      color: "#259eff",
    },
  ];
  
  return (
    <Card variant="secondary" height={572}>
      <div className={styles.container}>
        <div className={styles.card_header}>
          <span className={styles.card_title}>Ancillaries</span>
          <div className={styles.header_button_wrapper}>
            <span
              className={styles.button}
              onClick={() => setIsActive(true)}
              data-active={isActive}
            >
              XX
            </span>
            <span
              className={styles.button}
              onClick={() => setIsActive(false)}
              data-active={!isActive}
            >
              YY
            </span>
            <span
              className={styles.active_button}
              style={{ left: isActive ? 0 : 120 }}
            ></span>
          </div>
        </div>
        <div className={styles.card_body}>
          <div className={styles.card_body_title_wrapper}>
            <span className={styles.title}>Super Eco</span>
            <span className={styles.title}>Eco</span>
            <span className={styles.title}>Advantage</span>
            <span className={styles.title}>Comfort Flex</span>
          </div>
          <div className={styles.card_body_content_wrapper}>
            {array?.map((item, index) => (
              <div key={index} className={styles.card_body_content}>
                <div className={styles.items_title_wrapper}>
                  <span
                    className={styles.items_icon_wrapper}
                    style={{ backgroundColor: icons[index].color }}
                  >
                    <img src={icons[index].icon} alt="" />
                  </span>
                  {item.title}
                </div>
                <div className={styles.card_body_content_items_wrapper}>
                  <div className={styles.items}>
                    <img
                      src={
                        item.super_eco
                          ? images.fs_green_tick
                          : images.cancel_red
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles.items} data-active={true}>
                    <img
                      src={item.eco ? images.fs_green_tick : images.cancel_red}
                      alt=""
                    />
                  </div>
                  <div className={styles.items}>
                    <img
                      src={
                        item.advantage
                          ? images.fs_green_tick
                          : images.cancel_red
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles.items} data-active={true}>
                    <img
                      src={
                        item.comfort_flex
                          ? images.fs_green_tick
                          : images.cancel_red
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
