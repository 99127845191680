import { RestClient } from "../RestClient";

export class EventTableClient {
  private static _client: RestClient;

  static fetchEventTable({ params }) {
    return EventTableClient.getClient().getCall(
      `/api/msdv2/events/filter-by-market?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (EventTableClient._client == null) {
      EventTableClient._client = new RestClient();
    }
    return EventTableClient._client;
  }
}
