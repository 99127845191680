import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";
interface ITopRevenueGroups {
  data: any;
}
export default function TopRevenueGroups({ data }: ITopRevenueGroups) {
  const icons = {
    people: images.group_user,
    days: images.weather_climate,
    calendar: images.calendar_schedule_mini,
    time: images.alarm_clock_time,
    location: images.travel_map_point,
    ticket: images.travel_earth,
  };
  const iconsBackgroundColors = {
    people: "#5504d9",
    days: "#da7706",
    calendar: "#d77cbb",
    time: "#ec4252",
    location: "#144aff",
    ticket: "#9c00ed",
  };
  const { theme } = useContext(ThemeContext);
  return (
    <Card variant="secondary" title="Top Revenue Groups">
      <Card variant="primary">
        <div className={styles.content}>
          {data?.map((element: any, i) => {
            if (element.object_type === "text") {
              return (
                <div
                  className={styles.not_card_text}
                  data-theme={theme}
                  key={i}
                >
                  {element.value}
                </div>
              );
            } else {
              return (
                <div className={styles.card_wrapper} key={i}>
                  <span
                    className={styles.icon_wrapper}
                    style={{
                      backgroundColor: iconsBackgroundColors[element.cart_type],
                    }}
                  >
                    <img src={icons[element.cart_type]} alt="" />
                  </span>
                  <span className={styles.card_text}>{element.value}</span>
                </div>
              );
            }
          })}
        </div>
      </Card>
    </Card>
  );
}
