import Layout from "components/common/Layout/Layout";
import KPIMini from "components/msd/KPIMini/KPIMini";
import PerformanceTrendsLoadFactorCurve from "components/msd/PerformanceTrendsLoadFactorCurve/PerformanceTrendsLoadFactorCurve";
import { useState } from "react";

export default function PERFORMANCETRENDSLOADFACTORCURVE() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Performance Trends"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <PerformanceTrendsLoadFactorCurve filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
