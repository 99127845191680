import { FilterList } from "@mui/icons-material";
import SideDrawer from "components/common/SideDrawer";
import styles from "./styles.module.css";
import MenuItem from "../CalendarFilterItem";

const RightMenu = ({
  isSummaryDetail,
  handleReset,
  handleClearAll,
  handleSelectAll,
  searchTerm,
  setSearchTerm,
  filteredData,
  selectedTableFields,
  setSelectedTableFields,
  selectedCalendarFields,
  setSelectedCalendarFields,
}) => {
  if (isSummaryDetail)
    return (
      <SideDrawer
        MenuButton={
          <FilterList
            sx={{
              color: "var(--text-color-primary)",
            }}
          />
        }
        direction="right"
        width={450}
      >
        <div className={styles.menu_wrapper}>
          <h3 className={styles.menu_title}>Field Settings</h3>
          <button onClick={handleReset}>Reset</button>
          <button onClick={handleClearAll}>Clear All</button>
          <button onClick={handleSelectAll}>Select All</button>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredData.map((item, index) => {
            return (
              <MenuItem
                key={index}
                item={item}
                selectedItems={selectedTableFields}
                setSelectedItems={setSelectedTableFields}
              />
            );
          })}
        </div>
      </SideDrawer>
    );
  return (
    <SideDrawer
      MenuButton={
        <FilterList
          sx={{
            color: "var(--text-color-primary)",
          }}
        />
      }
      direction="right"
      width={450}
    >
      <div className={styles.menu_wrapper}>
        <h3 className={styles.menu_title}>Hover Text Content</h3>
        <button onClick={handleReset}>Reset</button>
        <button onClick={handleClearAll}>Clear All</button>
        <button onClick={handleSelectAll}>Select All</button>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {filteredData.map((item, index) => {
          return (
            <MenuItem
              key={index}
              item={item}
              selectedItems={selectedCalendarFields}
              setSelectedItems={setSelectedCalendarFields}
            />
          );
        })}
      </div>
    </SideDrawer>
  );
};

export default RightMenu;
