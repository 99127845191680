import { ReactNode, useContext } from "react";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";
import { Divider } from "@mui/material";

type htmlElementProps = {
  max: {
    weekday: string;
    date: string;
    time: string;
    class: string;
    lf: string;
    flight_num: number;
  };
  min: {
    weekday: string;
    date: string;
    time: string;
    class: string;
    lf: string;
    flight_num: number;
  } | null;
  carrierColor?: string;
  isPriceEvo?: boolean;
};
type Props = {
  children: ReactNode;
  max: {
    weekday: string;
    date: string;
    time: string;
    class: string;
    lf: string;
    flight_num: number;
  };
  min: {
    weekday: string;
    date: string;
    time: string;
    class: string;
    lf: string;
    flight_num: number;
  } | null;
  carrierColor?: string;
  isPriceEvo?: boolean;
};

export default function StatisticTooltip({
  children,
  max,
  min,
  carrierColor = "#3f365a",
  isPriceEvo = false,
}: Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title={htmlElement({ max, min, carrierColor, isPriceEvo })}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
          },
        },
      }}
    >
      <span className={styles.children} data-theme={theme}>
        {children}
      </span>
    </Tooltip>
  );
}

const htmlElement = ({
  max,
  min,
  carrierColor,
  isPriceEvo,
}: htmlElementProps) => {
  return (
    <div className={styles.container} style={{ backgroundColor: carrierColor }}>
      {min ? <h6>LF MAX</h6> : <></>}
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {isPriceEvo ? "Days to Departure" : "Departure Date"}
        </span>
        :<span className={styles.description}>{max?.date ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Departure Time</span>:
        <span className={styles.description}>{max?.time ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Days of Week</span>:
        <span className={styles.description}>{max?.weekday ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Class</span>:
        <span className={styles.description}>{max?.class ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Flight Number</span>:
        <span className={styles.description}>{max?.flight_num ?? "-"}</span>
      </span>
      {max?.lf && (
        <span className={styles.wrapper}>
          <span className={styles.title}>Load Factor</span>:
          <span className={styles.description}>{max?.lf ?? "-"}</span>
        </span>
      )}
      {min && (
        <>
          <Divider sx={{ margin: "8px 0" }} />
          <h6>LF MIN</h6>
          <span className={styles.wrapper}>
            <span className={styles.title}>
              {isPriceEvo ? "Days to Departure" : "Departure Date"}
            </span>
            :<span className={styles.description}>{min?.date ?? "-"}</span>
          </span>
          <span className={styles.wrapper}>
            <span className={styles.title}>Departure Time</span>:
            <span className={styles.description}>{min?.time ?? "-"}</span>
          </span>
          <span className={styles.wrapper}>
            <span className={styles.title}>Days of Week</span>:
            <span className={styles.description}>{min?.weekday ?? "-"}</span>
          </span>
          <span className={styles.wrapper}>
            <span className={styles.title}>Class</span>:
            <span className={styles.description}>{min?.class ?? "-"}</span>
          </span>
          <span className={styles.wrapper}>
            <span className={styles.title}>Flight Number</span>:
            <span className={styles.description}>{min?.flight_num ?? "-"}</span>
          </span>
          {min?.lf && (
            <span className={styles.wrapper}>
              <span className={styles.title}>Load Factor</span>:
              <span className={styles.description}>{min?.lf ?? "-"}</span>
            </span>
          )}
        </>
      )}
    </div>
  );
};
