import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface INetworkSchedulingConnectivityMapTable {
  filterList: IFilterContext;
  selected_year: string;
  picked_airline: string;
  bound_selection: string[];
  dark_theme: boolean;
}

export class NetworkSchedulingConnectivityMapTableClient {
  private static _client: RestClient;

  static fetchNetworkSchedulingConnectivityMapTable({
    filterList,
    selected_year,
    picked_airline,
    bound_selection,
    dark_theme,
  }: INetworkSchedulingConnectivityMapTable) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return NetworkSchedulingConnectivityMapTableClient.getClient().getCall(
      `/api/msdv2/network-scheduling/connectivity-map-table?${qp}&selected_year=${selected_year}&picked_airline=${picked_airline}&bound_selection=${bound_selection}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (NetworkSchedulingConnectivityMapTableClient._client == null) {
      NetworkSchedulingConnectivityMapTableClient._client = new RestClient();
    }
    return NetworkSchedulingConnectivityMapTableClient._client;
  }
}
