import styles from "./styles.module.css";
import Card from "components/common/Card/Card";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";

interface IMarketProfile {
  data: string[];
  storyText: {
    comp_environ: {
      content: string;
      title: string;
    };
    curr_month_perf: {
      content: string;
      title: string;
    };
    market_profile: {
      content: string;
      title: string;
    };
    rec_market_actions: {
      content: string;
      title: string;
    };
  };
}
export default function MarketProfile({ data, storyText }: IMarketProfile) {
  const { theme } = useContext(ThemeContext);
  return (
    <Card
      variant="secondary"
      title="Market Profile"
      icon={images.business_products}
      iconBackgroundColor={"rgba(236, 66, 82, 0.1)"}
      width={"100%"}
      height={394}
      borderColor={"#ec4252"}
      // cardInfo={{
      //   title: storyText?.market_profile?.title ?? null,
      //   description: storyText?.market_profile?.content ?? null,
      // }}
    >
      <Card variant="primary" height={288}>
        <div className={styles.market_content} data-theme={theme}>
          {data?.map((item, index) => {
            return (
              <div key={index}>
                <span>
                  {item?.split("\n")?.map((str) => (
                    <p>{str}</p>
                  ))}
                </span>
              </div>
            );
          })}
        </div>
      </Card>
    </Card>
  );
}
