import { RestClient } from "../RestClient";

export class HolidaysFilterSelectedSaveClient {
  private static _client: RestClient;
  static fetchFilterSelectedSave(data) {
    return HolidaysFilterSelectedSaveClient.getClient().postCall(
      `/api/msdv2/filters/msd-filter-options?target=ea`,
      data
    );
  }

  static getClient() {
    if (HolidaysFilterSelectedSaveClient._client == null) {
      HolidaysFilterSelectedSaveClient._client = new RestClient();
    }
    return HolidaysFilterSelectedSaveClient._client;
  }
}
