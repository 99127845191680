import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";

interface IPassengersCabinChannelMix {
  chartData: {
    passengersMix: any;
    cabinMix: any;
    channelMix: any;
    layout: any;
  };
  storyText: any;
}

export default function PassengersCabinChannelMix({
  chartData,
  storyText,
}: IPassengersCabinChannelMix) {
  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title={"Passenger Mix"}
        // cardInfo={{
        //   title: storyText?.passenger?.main_card?.title ?? null,
        //   description: storyText?.passenger?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        <Card variant="primary" width={"100%"}>
          <div style={{ height: chartData?.layout?.height ?? 450 }}>
            <Plotly
              data={chartData.passengersMix}
              layout={chartData.layout}
              margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
            />
          </div>
        </Card>
      </Card>
      <Card
        variant="secondary"
        title={"Cabin Mix"}
        // cardInfo={{
        //   title: storyText?.cabin?.main_card?.title ?? null,
        //   description: storyText?.cabin?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        <Card variant="primary" width={"100%"}>
          <div style={{ height: chartData?.layout?.height ?? 450 }}>
            <Plotly
              data={chartData.cabinMix}
              layout={chartData.layout}
              margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
            />
          </div>
        </Card>
      </Card>
      <Card
        variant="secondary"
        title={"Channel Mix"}
        // cardInfo={{
        //   title: storyText?.channel?.main_card?.title ?? null,
        //   description: storyText?.channel?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        <Card variant="primary" width={"100%"}>
          <div style={{ height: chartData?.layout?.height ?? 450 }}>
            <Plotly
              data={chartData.channelMix}
              layout={chartData.layout}
              margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
            />
          </div>
        </Card>
      </Card>
    </div>
  );
}
