import Layout from "components/common/Layout/Layout";
import RulesComponent from "components/lfa/RulesComponents";

export default function RULES() {
  return (
    <Layout
      filterHidden
      title={"Rules"}
      filterIsOpen={false}
      setFilterIsOpen={() => {}}
    >
      <RulesComponent />
    </Layout>
  );
}
