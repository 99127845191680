import KPIMini from "components/msd/KPIMini/KPIMini";
import { useState } from "react";
import BookingTrends from "components/msd/BookingTrends/BookingTrends";
import BookingCurveAnalysis from "components/msd/BookingCurveAnalysis/BookingCurveAnalysis";
import Layout from "components/common/Layout/Layout";

export default function BOOKINGTRENDS() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Booking Trends"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <BookingTrends filterIsOpen={filterIsOpen} />
      <BookingCurveAnalysis filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
