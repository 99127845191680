import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

export class BookingTrendsTableClient {
  private static _client: RestClient;

  static fetchBookingTrendsClient({ filterList, params }) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return BookingTrendsTableClient.getClient().getCall(
      `/api/msdv2/bookings/pax-trends-events?${qp}&${new URLSearchParams(
        params
      )}`
    );
  }

  static getClient() {
    if (BookingTrendsTableClient._client == null) {
      BookingTrendsTableClient._client = new RestClient();
    }
    return BookingTrendsTableClient._client;
  }
}
