import Notification from "components/common/Notification/Notification";
import { CarriersClient } from "lib/api/carriers/MsdGetCarriers";
import { useEffect, useState } from "react";
import styles from "./DynamicFilter.module.css";
import DynamicFilterClosed from "./DynamicFilterClosed";
import DynamicFilterOpened from "./DynamicFilterOpened";

interface IDynamicFilter {
  filterNumber: number;
  filterElement: any;
  dynamicFilters: any;
  setDynamicFilters: any;
}

export default function DynamicFilter({
  filterNumber,
  dynamicFilters,
  setDynamicFilters,
}: IDynamicFilter) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    CarriersClient.fetchCarrioerOptions()
      .then((res: any) => {
        setCarriersJson(res.carrierDetails);
      })
      .catch(() => {
        Notification({
          type: "error",
          message: "Carriers data could not be loaded!",
        });
      });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.filter} data-active={isOpen}>
        <DynamicFilterOpened setIsOpen={setIsOpen} getCarriers={carriersJson} />
      </div>
      <div className={styles.filter} data-active={!isOpen}>
        <DynamicFilterClosed
          filterNumber={filterNumber}
          setIsOpen={setIsOpen}
          dynamicFilters={dynamicFilters}
          setDynamicFilters={setDynamicFilters}
        />
      </div>
    </div>
  );
}
