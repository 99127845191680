/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import styles from "./RBDPriceElasticitiesComparison.module.css";
import { useContext, useEffect, useState } from "react";
import { FareRevenueRbdElasticitiesClient } from "lib/api/msd/fareRevenueRbdElasticities/fareRevenueRbdElasticities";
import { FilterContext } from "context-api/FilterContext";
import Plotly from "components/common/Charts/Plotly";
import images from "constans/images";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import { ThemeContext } from "context-api/ThemeContext";
import { UserProfileContext } from "context-api/UserProfile";

interface IRBDPriceElasticitiesComparison {
  filterIsOpen: boolean;
}
export default function RBDPriceElasticitiesComparison({
  filterIsOpen,
}: IRBDPriceElasticitiesComparison) {
  const [rangeValue, setRangeValue] = useState({
    value: [0, 0],
    min: 0,
    max: 0,
    dateList: [],
  });
  const [hostChart, setHostChart] = useState([]);
  const [competitorChart, setCompetitorChart] = useState([]);
  const [layoutHost, setLayoutHost] = useState<any>();
  const [layoutComp, setLayoutComp] = useState<any>();
  const { filterList } = useContext(FilterContext);
  const [colorScale, setColorScale] = useState([]);
  const [legendScale, setLegendScale] = useState<number[]>([]);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  const { user } = useContext(UserProfileContext);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response?.values?.length - 1,
          dateList: response.values,
          value: [response.start_idx, response.end_idx],
        }));
        setRangeValue((prevState) => ({
          ...prevState,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      FareRevenueRbdElasticitiesClient.fetchFareRevenueRbdElasticities({
        filterList,
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setLegendScale(response?.legend_scale);
          setHostChart(response?.host?.data || []);
          setCompetitorChart(response?.comp?.data || []);
          setLayoutHost(response?.host?.layout);
          setLayoutComp(response?.comp?.layout);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setHostChart([]);
          setCompetitorChart([]);
        });
    }
  }, [filterIsOpen, filterList, rangeValue.value, theme]);

  useEffect(() => {
    const array: any = [];
    if (layoutHost?.coloraxis) {
      layoutHost.coloraxis.colorscale?.forEach((element: any) => {
        array.push(element[1]);
      });
    }
    setColorScale(array);
  }, [layoutHost]);

  const getLegendScale = (value) => {
    if (value !== undefined) {
      return value;
    }
  };

  return (
    <Card
      variant="secondary"
      title="RBD Price Elasticities Comparison"
      width={441}
      height={500}
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <Card variant="primary" height={48}>
        <div className={styles.container}>
          <img
            src={images.travel_plane_take_off}
            alt=""
            className={styles.ped_icon}
            data-theme={theme}
          />
          <span className={styles.ped_text} data-theme={theme}>
            PED
          </span>
          <div className={styles.gradient_line_wrapper}>
            <span
              className={styles.line}
              style={{
                borderImageSource: `linear-gradient(90deg, ${colorScale.join()} )`,
              }}
            />
            <span className={styles.text} data-theme={theme}>
              <span>{getLegendScale(legendScale[0])}</span>
              <span>
                {getLegendScale(
                  legendScale[Math.round(legendScale?.length / 4)]
                )}
              </span>
              <span>
                {getLegendScale(
                  legendScale[Math.round(legendScale?.length / 2)]
                )}
              </span>
              <span>{getLegendScale(legendScale[legendScale.length - 1])}</span>
            </span>
          </div>
        </div>
      </Card>

      <div className={styles.content}>
        <Card
          variant="primary"
          width={
            user?.enabledModules &&
            user?.enabledModules["MSD"] &&
            !user?.enabledModules["MSD"]?.lite
              ? 208
              : 424
          }
          height={314}
        >
          <div className={styles.chart_wrapper}>
            <Plotly
              data={hostChart}
              layout={layoutHost}
              margin={{
                b: 0,
                l: 0,
                r: 15,
                t: 25,
                p: 4,
              }}
            />
          </div>
        </Card>
        {user?.enabledModules &&
          user?.enabledModules["MSD"] &&
          !user?.enabledModules["MSD"]?.lite && (
            <Card variant="primary" width={208} height={314}>
              <div className={styles.chart_wrapper}>
                <Plotly
                  data={competitorChart}
                  layout={layoutComp}
                  margin={{
                    b: 0,
                    l: 0,
                    r: 15,
                    t: 25,
                    p: 4,
                  }}
                />
              </div>
            </Card>
          )}
      </div>
      <div className={styles.range_wrapper}>
        <RangeSlider
          data={{
            max: rangeValue.max,
            min: rangeValue.min,
            value: rangeValue.value,
            dateList: rangeValue.dateList,
            setValue: setRangeValue,
          }}
        />
      </div>
    </Card>
  );
}
