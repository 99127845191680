import Button from "components/common/Button/Button";
import Card from "components/common/Card/Card";
import DatePickerComponent from "components/common/DatePicker/DatepickerLastest";
import Dropdown from "components/common/Dropdown/Dropdown";
import images from "constans/images";
import React from "react";
import CustomIcon from "utils/CustomIcon";
import styles from "./FareStructureFiltersOpened.module.css";

interface IFareStructureFiltersOpened {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FareStructureFiltersOpened({
  setIsOpen,
}: IFareStructureFiltersOpened) {
  return (
    <Card variant="secondary" height={629}>
      <div className={styles.top}>
        <span>Fare Structure Filters</span>
        <div className={styles.buttons_wrapper}>
          <div className={styles.clear_button} onClick={() => alert("cleared")}>
            Clear
          </div>
          <div
            className={styles.tick_button_wrapper}
            onClick={() => setIsOpen(false)}
          >
            <img src={images.tick_green} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.card_wrapper}>
          <Card variant="primary" height={533}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Travel Date Range"}
                icon={images.travel_earth_2}
                iconColor={"#d77cbb"}
              />
              <div className={styles.date_picker_wrapper}>
                <DatePickerComponent onChange={() => {}} />
              </div>
              <hr className={styles.hr} />
              <p className={styles.repeated_days}>Repeated Days</p>
              <div
                className={`${styles.buttons_wrapper} ${styles.card_in_buttons_wrapper}`}
              >
                {["M", "T", "W", "T", "F", "S", "S"].map((str) => (
                  <Button
                    data={""}
                    added={[]}
                    setAdded={() => {}}
                    width={47}
                    height={32}
                    text={"center"}
                  >
                    {str}
                  </Button>
                ))}
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.card_wrapper}>
          <Card variant="primary" height={385}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Sales Date Range"}
                icon={images.money_hand_arm_coins}
                iconColor={"#5504d9"}
              />
              <div className={styles.date_picker_wrapper}>
                <DatePickerComponent onChange={() => {}} />
              </div>
            </div>
          </Card>
          <Card variant="primary" height={140}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Season"}
                icon={images.business_chart_2}
                iconColor={"#da7706"}
              />
              <div
                className={`${styles.buttons_wrapper} ${styles.card_in_buttons_wrapper}`}
              >
                {["LOW", "HIGH", "SHOULDER", "PEAK", "SEASONLESS"].map(
                  (str) => (
                    <Button data={""} added={[]} setAdded={() => {}}>
                      {str}
                    </Button>
                  )
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.card_wrapper}>
          <Card variant="primary" height={172}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Fare Family"}
                icon={images.money_coins}
                iconColor={"#ec4252"}
              />
              <div
                className={`${styles.buttons_wrapper} ${styles.card_in_buttons_wrapper} ${styles.extra_margin}`}
              >
                {["FLEX", "STAN.", "LIGHT", "NO"].map((str) => (
                  <Button data={""} added={[]} setAdded={() => {}}>
                    {str}
                  </Button>
                ))}
              </div>
            </div>
          </Card>
          <Card variant="primary" height={172} zIndex={2}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Currency"}
                icon={images.money_money}
                iconColor={"#00ad92"}
              />
              <div
                className={`${styles.card_in_buttons_wrapper} ${styles.extra_margin}`}
              >
                <Dropdown data={[]} added={[]} setAdded={() => {}} />
              </div>
            </div>
          </Card>
          <Card variant="primary" height={173}>
            <div className={styles.card_items}>
              <CardTitle
                title={"Travel Type"}
                icon={images.a_to_b}
                iconColor={"#144aff"}
              />
              <div
                className={`${styles.buttons_wrapper} ${styles.card_in_buttons_wrapper} ${styles.extra_margin}`}
              >
                {["ONE WAY", "ROUND TRIP"].map((str) => (
                  <Button data={""} added={[]} setAdded={() => {}}>
                    {str}
                  </Button>
                ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}

const CardTitle = (props) => {
  return (
    <div className={styles.card_title_wrapper}>
      <span className={styles.card_title}>{props.title}</span>
      <CustomIcon icon={props.icon} color={props.iconColor} />
    </div>
  );
};
