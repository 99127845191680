import { RestClient } from "lib/api/RestClient";

interface IScraperMarketOptions {
  customer: string;
  orig_country?: string | null;
  dest_country?: string | null;
}
export class ScraperMarketOptionsClient {
  private static _client: RestClient;

  static fetchFilterMarketOptions({
    customer,
    orig_country,
    dest_country,
  }: IScraperMarketOptions) {
    const params = {
      customer,
      ...(orig_country && { orig_country }),
      ...(dest_country && { dest_country }),
    };
    return ScraperMarketOptionsClient.getClient().getCall(
      `/api/msdv2/admin/config-market-options?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (ScraperMarketOptionsClient._client == null) {
      ScraperMarketOptionsClient._client = new RestClient();
    }
    return ScraperMarketOptionsClient._client;
  }
}
