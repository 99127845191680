import { RestClient } from "../RestClient";
interface IData {
  id?: string | null | undefined;
  country_code?: string | undefined;
  city_code?: string | undefined;
  sub_category?: string | undefined;
  start_date: any;
  end_date: string;
  name: string;
  category: string;
}
export class CreateEventClient {
  private static _client: RestClient;

  static fetchCreateEvent(data: IData) {
    return CreateEventClient.getClient().postCall(
      `/api/msdv2/events/store`,
      data
    );
  }

  static getClient() {
    if (CreateEventClient._client == null) {
      CreateEventClient._client = new RestClient();
    }
    return CreateEventClient._client;
  }
}
