/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import styles from "./styles.module.css";
import { FilterContext } from "context-api/FilterContext";
import Plotly from "components/common/Charts/Plotly";
import { MarketShareVsAverageFareClient } from "../../../lib/api/msd/marketShareVsAverageFare/MarketShareVsAverageFareClient";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import moment from "moment";
import images from "constans/images";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { ThemeContext } from "context-api/ThemeContext";

interface IMarketShareVsAvgFare {
  filterIsOpen: boolean;
}
export default function MarketShareVsAverageFare({
  filterIsOpen,
}: IMarketShareVsAvgFare) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [rangeValueMonthly, setRangeValueMonthly] = useState({
    value: 0,
    min: 0,
    max: 0,
    dateList: [],
  });
  const { filterList } = useContext(FilterContext);
  const [chartData, setChartData] = useState<any>([]);
  const [layout, setLayout] = useState();
  const [hostMetrics, setHostMetrics] = useState({
    pickedYearmo_avgFare: "",
    pickedYearmo_avgFareVar: "",
    pickedYearmo_mktShare: "",
    pickedYearmo_mktShareVar: "",
  });
  const [competitorMetrics, setCompetitorMetrics] = useState({
    pickedYearmo_avgFare: "",
    pickedYearmo_avgFareVar: "",
    pickedYearmo_mktShare: "",
    pickedYearmo_mktShareVar: "",
  });
  const [host, setHost] = useState<string>();
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setHost(JSON.parse(user).clientCode);
    }
  }, []);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValueMonthly((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: response?.start_idx ?? 0,
        }));
      })
      .catch(() => {
        console.log("Error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterIsOpen === false && rangeValueMonthly.dateList.length > 0) {
      MarketShareVsAverageFareClient.fetchMarketShareVsAverageFare({
        filterList,
        graphCarriers: selectedCarriers.join(),
        time_direction: "historical-forward",
        selected_yearmonth: moment(
          rangeValueMonthly.dateList[rangeValueMonthly.value]
        ).format("YYYY-MM"),
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setLayout(response.fig.layout);
          setChartData(response.fig.data);
          setCompetitorMetrics(response.comp1_metrics);
          setHostMetrics(response.host_metrics);
          setCarriersJson(response?.carriers);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setChartData([]);
        });
    }
  }, [
    filterIsOpen,
    filterList,
    selectedCarriers,
    rangeValueMonthly.value,
    rangeValueMonthly.dateList,
    theme,
  ]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <Card
      variant="secondary"
      title="Market Share vs Average Fare"
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <div className={styles.carriers_wrapper}>
        <Carriers
          data={GetCarriers()}
          added={selectedCarriers}
          setAdded={setSelectedCarriers}
          carriersJson={carriersJson}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.left_metrics}>
          <Card variant="primary" width={166}>
            <div className={styles.metrics_wrapper}>
              <p className={styles.metrics_card_title}>{`${host}`}</p>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"blue"}>
                  <img src={images.money_coins_blue} alt="" />
                </span>

                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare
                  </span>
                  <span className={styles.value}>
                    {hostMetrics?.pickedYearmo_avgFare}
                  </span>
                </div>
              </div>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"purple"}>
                  <img src={images.money_coins_purple} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LM
                  </span>
                  <span className={styles.value}>
                    {hostMetrics?.pickedYearmo_avgFareVar}
                  </span>
                </div>
              </div>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"green"}>
                  <img src={images.money_coins_green} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LY
                  </span>
                  <span className={styles.value}>
                    {hostMetrics?.pickedYearmo_mktShare}
                  </span>
                </div>
              </div>
            </div>
          </Card>
          <Card variant="primary" width={166}>
            <div className={styles.bottom_metrics_wrapper}>
              <p className={styles.metrics_card_title}>Industry</p>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"red"}>
                  <img src={images.money_coins_red} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LY
                  </span>
                  <span className={styles.value}>
                    {hostMetrics?.pickedYearmo_mktShareVar}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Card variant="primary" width={"100%"}>
          <div className={styles.chart_wrapper}>
            <p className={styles.chat_title}>Average Fare ($)</p>
            <Plotly
              data={chartData}
              layout={layout}
              height={400}
              margin={{ r: 20, l: 40, b: 48, t: 20, p: 4 }}
              displayModeBar={"hover"}
            />

            <div className={styles.range_slider_wrapper}>
              <RangeSlider
                data={{
                  max: rangeValueMonthly.max,
                  min: rangeValueMonthly.min,
                  value: rangeValueMonthly.value,
                  dateList: rangeValueMonthly.dateList,
                  setValue: setRangeValueMonthly,
                }}
              />
            </div>
          </div>
        </Card>
        <div className={styles.right_metrics}>
          <Card variant="primary" width={166}>
            <div className={styles.metrics_wrapper}>
              <p className={styles.metrics_card_title}>{` ${
                filterList.mainCompetitor ?? ""
              }`}</p>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"blue"}>
                  <img src={images.money_coins_blue} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare
                  </span>
                  <span className={styles.value}>
                    {competitorMetrics?.pickedYearmo_avgFare}
                  </span>
                </div>
              </div>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"purple"}>
                  <img src={images.money_coins_purple} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LM
                  </span>
                  <span className={styles.value}>
                    {competitorMetrics?.pickedYearmo_avgFareVar}
                  </span>
                </div>
              </div>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"green"}>
                  <img src={images.money_coins_green} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LY
                  </span>
                  <span className={styles.value}>
                    {competitorMetrics?.pickedYearmo_mktShare}
                  </span>
                </div>
              </div>
            </div>
          </Card>
          <Card variant="primary" width={166}>
            <div className={styles.bottom_metrics_wrapper}>
              <p className={styles.metrics_card_title}>Industry</p>
              <div className={styles.metrics}>
                <span className={styles.icon_wrapper} data-active={"red"}>
                  <img src={images.money_coins_red} alt="" />
                </span>
                <div>
                  <span className={styles.title} data-theme={theme}>
                    Avg. Fare vs LY
                  </span>
                  <span className={styles.value}>
                    {competitorMetrics?.pickedYearmo_mktShareVar}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
}
