/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import SelectMenu from "components/common/SelectMenu";
import Dropdown from "components/common/Dropdown/Dropdown";
import { useState, useEffect } from "react";
import ListOfMarkets from "./ListOfMarkets";
import { IOption } from "types/global";
import { FilterMarketCreateClient } from "lib/api/admin-panel/admin/filters-configuration/filter-market-create";
import Notification from "components/common/Notification/Notification";
import { FilterMarketOptionsClient } from "lib/api/admin-panel/admin/filters-configuration/filter-market-options";
import { CustomersClient } from "lib/api/admin-panel/admin/customers";
import { CustomerMarketsClient } from "lib/api/admin-panel/admin/filters-configuration/customer-markets";
import { CustomerMarketDetailClient } from "lib/api/admin-panel/admin/filters-configuration/customer-markets-detail";
import { FilterMarketUpdateClient } from "lib/api/admin-panel/admin/filters-configuration/filter-market-update";

export default function FilterConfig() {
  const [customers, setCustomers] = useState<IOption[]>([]);
  const [customersSelected, setCustomerSelected] =
    useState<IOption | null>(null);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [countriesSelectedOrigin, setCountriesSelectedOrigin] =
    useState<IOption | null>(null);
  const [countriesSelectedDestination, setCountriesSelectedDestination] =
    useState<IOption | null>(null);
  const [citiesOrigin, setCitiesOrigin] = useState<IOption[]>([]);
  const [citiesDestination, setCitiesDestination] = useState<IOption[]>([]);
  const [citiesSelectedOrigin, setCitiesSelectedOrigin] =
    useState<IOption | null>(null);
  const [citiesSelectedDestination, setCitiesSelectedDestination] =
    useState<IOption | null>(null);
  const [competitors, setCompetitors] = useState<string[]>([]);
  const [competitorsSelected, setCompetitorsSelected] = useState<string[]>([]);
  const [pos, setPos] = useState<string[]>([]);
  const [posSelected, setPosSelected] = useState<string[]>([]);
  const [salesChannel, setSalesChannel] = useState<string[]>([]);
  const [salesChannelSelected, setSalesChannelSelected] = useState<string[]>(
    []
  );
  const [isCreate, setIsCreate] = useState(true);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    getCustomersOptions();
  }, []);

  const getCustomersOptions = () => {
    CustomersClient.fetchCustomers()
      .then((response: any) => {
        const array: IOption[] = response?.customers?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setCustomers(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (customersSelected) {
      getFilterMarketOptions();
    }
  }, [
    customersSelected,
    countriesSelectedOrigin,
    countriesSelectedDestination,
  ]);

  const getFilterMarketOptions = () => {
    FilterMarketOptionsClient.fetchFilterMarketOptions({
      customer: customersSelected?.value ?? "",
      orig_country: countriesSelectedOrigin?.value,
      dest_country: countriesSelectedDestination?.value,
    })
      .then((res: any) => {
        const res_countries: IOption[] = res?.countries?.map((el) => ({
          label: el?.country_code,
          value: el?.country_code,
          disabled: false,
        }));
        const res_orig_cities: IOption[] = res?.orig_cities?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        const res_dest_cities: IOption[] = res?.dest_cities?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setCountries(res_countries);
        setCitiesOrigin(res_orig_cities);
        setCitiesDestination(res_dest_cities);
        setCompetitors(res?.competitors ?? []);
        setPos(res?.points_of_sale ?? []);
        setSalesChannel(res?.sales_channels ?? []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (customersSelected) {
      getTableData();
    }
  }, [customersSelected]);

  const getTableData = () => {
    if (customersSelected) {
      CustomerMarketsClient.fetchCustomerMarkets({
        customer: customersSelected?.value,
      })
        .then((res: any) => {
          const tableArray = res?.table?.data?.map((el, index) => ({
            ...el,
            id: index,
          }));
          setData(tableArray);
          setLabels(res?.table?.labels);
        })
        .catch((err) =>
          Notification({
            type: "error",
            message: `${
              err?.originalError?.response?.data?.message ?? "Error"
            }`,
          })
        );
    }
  };
  const clearForm = () => {
    setCountriesSelectedOrigin(null);
    setCountriesSelectedDestination(null);
    setCitiesSelectedOrigin(null);
    setCitiesSelectedDestination(null);
    setCompetitorsSelected([]);
    setPosSelected([]);
    setSalesChannelSelected([]);
  };

  const handleMarketCreate = () => {
    if (
      customersSelected &&
      citiesSelectedOrigin &&
      citiesSelectedDestination
    ) {
      const body = {
        customer: customersSelected?.value,
        orig: citiesSelectedOrigin?.value,
        dest: citiesSelectedDestination?.value,
        competitors: competitorsSelected,
        points_of_sale: posSelected,
        sales_channels: salesChannelSelected,
      };
      if (isCreate) {
        FilterMarketCreateClient.fetchFilterMarketCreate({ data: body })
          .then(() => {
            Notification({ type: "success", message: "Success" });
            clearForm();
            getTableData();
          })
          .catch((err) => {
            Notification({
              type: "error",
              message: err?.originalError?.response?.data?.message ?? "Error",
            });
          });
      } else {
        FilterMarketUpdateClient.fetchFilterMarketUpdate({ data: body })
          .then(() => {
            Notification({ type: "success", message: "Success" });
            clearForm();
            getTableData();
          })
          .catch((err) => {
            Notification({
              type: "error",
              message: err?.originalError?.response?.data?.message ?? "Error",
            });
          });
      }
    }
  };

  const handleEdit = (row, type) => {
    setIsCreate(type === "edit");
    clearForm();
    if (customersSelected) {
      CustomerMarketDetailClient.fetchCustomerMarketDetail({
        customer: customersSelected?.value,
        orig: row?.orig,
        dest: row?.dest,
      })
        .then((res: any) => {
          setCountriesSelectedOrigin({
            label: res?.orig_country,
            value: res?.orig_country,
            disabled: false,
          });
          setCountriesSelectedDestination({
            label: res?.dest_country,
            value: res?.dest_country,
            disabled: false,
          });
          setCitiesSelectedOrigin({
            label: res?.market_origin_city_code,
            value: res?.market_origin_city_code,
            disabled: false,
          });
          setCitiesSelectedDestination({
            label: res?.market_destination_city_code,
            value: res?.market_destination_city_code,
            disabled: false,
          });
          setCompetitorsSelected(res?.competitors ?? []);
          setPosSelected(res?.points_of_sale ?? []);
          setSalesChannelSelected(res?.sales_channels ?? []);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title="Filter Configuration"
        titlePosition="center"
        fontSize={32}
        zIndex={2}
      >
        <div className={styles.header_wrapper}>
          <span className={styles.select_menu_label}>Customer</span>
          <SelectMenu
            options={customers}
            value={customersSelected}
            onSelect={(value) => setCustomerSelected(value ?? null)}
            style={{ width: 200, marginRight: 8 }}
          />
        </div>
        <div className={styles.table_wrapper}>
          <ListOfMarkets
            customer={customersSelected?.value ?? ""}
            editMarket={handleEdit}
            data={data}
            labels={labels}
            getTableData={getTableData}
          />
        </div>
        <Card variant="primary">
          <div className={styles.filter_wrapper}>
            <div className={styles.orig_dest_wrapper}>
              <div className={styles.origin}>
                <span className={styles.select_menu_label}>Origin</span>
                <SelectMenu
                  options={countries}
                  value={countriesSelectedOrigin}
                  onSelect={(value) =>
                    setCountriesSelectedOrigin(value ?? null)
                  }
                  style={{ width: 200, marginRight: 8 }}
                />
                <SelectMenu
                  options={citiesOrigin}
                  value={citiesSelectedOrigin}
                  onSelect={(value) => setCitiesSelectedOrigin(value ?? null)}
                  style={{ width: 200, marginRight: 8 }}
                />
              </div>
              <div className={styles.destination}>
                <span className={styles.select_menu_label}>Destination</span>
                <SelectMenu
                  options={countries}
                  value={countriesSelectedDestination}
                  onSelect={(value) =>
                    setCountriesSelectedDestination(value ?? null)
                  }
                  style={{ width: 200, marginRight: 8 }}
                />
                <SelectMenu
                  options={citiesDestination}
                  value={citiesSelectedDestination}
                  onSelect={(value) =>
                    setCitiesSelectedDestination(value ?? null)
                  }
                  style={{ width: 200, marginRight: 8 }}
                />
              </div>
            </div>
            <div className={styles.cps_wrapper}>
              <div className={styles.compatitors_wrapper}>
                <span className={styles.select_menu_label}>Competitors</span>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={competitors}
                    added={competitorsSelected}
                    setAdded={setCompetitorsSelected}
                  />
                </div>
              </div>
              <div className={styles.compatitors_wrapper}>
                <span className={styles.select_menu_label}>Point of Sale</span>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={pos}
                    added={posSelected}
                    setAdded={setPosSelected}
                  />
                </div>
              </div>
              <div className={styles.compatitors_wrapper}>
                <span className={styles.select_menu_label}>Sales Channels</span>
                <div className={styles.dropdown_wrapper}>
                  <Dropdown
                    data={salesChannel}
                    added={salesChannelSelected}
                    setAdded={setSalesChannelSelected}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttons_wrapper}>
            <span className={styles.cancel_button} onClick={clearForm}>
              Cancel
            </span>
            <span
              className={styles.save_button}
              onClick={handleMarketCreate}
              data-disabled={
                !customersSelected ||
                !citiesSelectedOrigin ||
                !citiesSelectedDestination
              }
            >
              Save
            </span>
          </div>
        </Card>
      </Card>
    </div>
  );
}
