import { RestClient } from "../RestClient";

interface ILogin {
  email: string;
  password: string;
}
// username: "analystPC",
// password: "analystPC",
export class LoginClient {
  private static _client: RestClient;

  static fetchLogin({ email, password }: ILogin) {
    const headers: any = {
      "Content-Type": "application/json",
    };
    return LoginClient.getClient().postCall(
        `/api/msd/auth/login`,
      {
        username: email,
        password: password,
      },
      headers
    );
  }

  static getClient() {
    if (LoginClient._client == null) {
      LoginClient._client = new RestClient();
    }
    return LoginClient._client;
  }
}
