import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { ThemeProvider, createTheme } from "@mui/material";
function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}
const fullscreenTheme = createTheme({
  components: {
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;

export default function OpenMenu({ row, selectMenu }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { theme } = React.useContext(ThemeContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleSelectMenu = (event: React.MouseEvent<HTMLElement>, row, str) => {
    event.stopPropagation();
    selectMenu(row, str);
  };
  return (
    <ThemeProvider theme={fullscreenTheme}>
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: theme === "dark" ? "#fff" : "#000" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              backgroundColor: theme === "dark" ? "#383051" : "#fff",
              color: theme === "dark" ? "#fff" : "#000",
              borderRadius: 12,
            },
          }}
        >
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={(e) => {
              handleSelectMenu(e, row, "lf");
            }}
          >
            Monthly Calendar
          </MenuItem>
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={(e) => {
              handleSelectMenu(e, row, "at");
            }}
          >
            Availability Trends
          </MenuItem>
          <MenuItem
            className={styles.menu_item}
            data-theme={theme}
            onClick={(e) => {
              handleSelectMenu(e, row, "pe");
            }}
          >
            Price Evolution
          </MenuItem>
        </Menu>
      </div>
    </ThemeProvider>
  );
}
