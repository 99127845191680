import { RestClient } from "lib/api/RestClient";

export class FareStructureClient {
  private static _client: RestClient;
  static fetchFareStructure({ data }) {
    return FareStructureClient.getClient().getCall(
      `/api/msdv2/fare-structure/structure?${new URLSearchParams(data)}`
    );
  }
  static getClient() {
    if (FareStructureClient._client == null) {
      FareStructureClient._client = new RestClient();
    }
    return FareStructureClient._client;
  }
}
