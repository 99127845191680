import { Tooltip } from "components/common/Tooltip";
import images from "constans/images";
import styles from "./styles.module.css";
import cardInfoJsx from "./hoverText";
const arrows = {
  UP: images.arrow_up,
  DOWN: images.arrow_down,
  HORIZONTAL: images.arrow_horizontal,
  NONE: images.arrow_none,
};
const getColumns = (labels) => {
  const array: {
    title: string;
    key: string;
    cell: (row: any) => JSX.Element;
    width?: string | number;
    sortable?: boolean;
  }[] = [];
  for (const [key, title] of Object.entries({ actionArrow: "", ...labels })) {
    if (
      key !== "actionArrow" &&
      key !== "hostFare" &&
      key !== "mainCompetitorFare" &&
      key !== "lowestFare" &&
      key !== "createdAt" &&
      key !== "ruleName"
    ) {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <Tooltip title={row[key] ?? ""}>
            <span className={"rdb-custom-cell"}>{row[key]}</span>
          </Tooltip>
        ),
        width: 200,
        sortable: true,
      };
      array.push(obj);
    } else if (key === "ruleName") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <Tooltip title={cardInfoJsx(row?.hoverText)}>
            <span className={"rdb-custom-cell"}>{row.ruleName}</span>
          </Tooltip>
        ),
      };
      array.push(obj);
    } else if (key === "createdAt") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <div
            style={{
              backgroundColor: "rgba(20, 74, 255, 0.1)",
              width: "150px",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className={"rdb-custom-cell"}>{row.createdAt}</span>
          </div>
        ),
      };
      array.push(obj);
    } else if (key === "lowestFare") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <div
            style={{
              backgroundColor: "rgba(0, 173, 146, 0.1)",
              width: "100%",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row.lowestFare || ""}>
              <span className={"rdb-custom-cell"}>{row.lowestFare}</span>
            </Tooltip>
          </div>
        ),
      };
      array.push(obj);
    } else if (key === "mainCompetitorFare") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <div
            style={{
              backgroundColor: "rgba(85, 4, 217, 0.1)",
              width: "100%",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row.mainCompetitorFare || ""}>
              <span className={"rdb-custom-cell"}>
                {row.mainCompetitorFare}
              </span>
            </Tooltip>
          </div>
        ),
      };
      array.push(obj);
    } else if (key === "hostFare") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <div
            style={{
              backgroundColor: "rgba(20, 74, 255, 0.1)",
              width: "100%",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title={row.hostFare || ""}>
              <span className={"rdb-custom-cell"}>{row.hostFare}</span>
            </Tooltip>
          </div>
        ),
      };
      array.push(obj);
    } else if (key === "actionArrow") {
      const obj: any = {
        title,
        key,
        cell: (row) => (
          <div className={styles.col_arrow}>
            <img src={arrows[row.actionArrow]} alt="" />
          </div>
        ),
      };
      array.push(obj);
    }
  }
  return array;
};
export default getColumns;
