import { KPIContext } from "context-api/KPIContext";
import { useContext } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import KPICard from "../KPICard/KPICard";
import styles from "./styles.module.css";

const SortableItem = SortableElement(({ value }) => {
  return (
    <div className={styles.item}>
      <KPICard cardData={value} />
    </div>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className={styles.container}>
      {items?.map((value, index) => (
        <SortableItem key={index} index={index} value={value} />
      ))}
    </div>
  );
});

export default function KPI() {
  const { kpiList, setKpiList } = useContext(KPIContext);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setKpiList(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  return (
    <SortableList
      axis="xy"
      items={kpiList.items}
      onSortEnd={onSortEnd}
      helperClass={"SortableHelperWithOverride"}
    />
  );
}
