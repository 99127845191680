/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./Dropdown.module.css";
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import { Clear, Flight, KeyboardArrowDown, Room } from "@mui/icons-material";
import { useOutsideClick } from "hooks/useOutsideClick";

interface IDropdown {
  data: any;
  added: string[];
  setAdded: (str) => void;
  singleSelected?: boolean;
  width?: number | string;
  border?: boolean;
  disabled?: boolean;
  getCarriers?: any;
  required?: boolean;
  setSearchFilter: any;
  isCountryAndCity?: boolean;
}

export default function Dropdown({
  data,
  added,
  setAdded,
  width = 204,
  border = false,
  disabled = false,
  getCarriers = false,
  required = false,
  setSearchFilter,
  isCountryAndCity,
}: IDropdown) {
  const [cityCode, setCityCode] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);

  useOutsideClick(wrapperRef, () => {
    setIsOpen(false);
    setSearch("");
    setSearchFilter("");
  });

  const handleAdded = ({ isCity, item, code }) => {
    if (isCity) {
      const newAirports = item?.airports?.map((el) => el?.airport_code);
      setAdded([...newAirports]);
      setCityCode(code);
    } else {
      if (added.length === 0 || cityCode === "" || cityCode === code) {
        if (!added.includes(item.airport_code)) {
          const array = added.filter((el) => el !== item.airport_code);
          setAdded([...array, item.airport_code]);
          setCityCode(code);
        }
      }
    }
  };

  useEffect(() => {
    if (disabled) setAdded([]);
    else setAdded(added);
  }, [disabled]);

  useEffect(() => {
    optionsControl();
  }, [data]);

  const optionsControl = () => {
    if (data?.length > 0) {
      let airport_codes: any = [];
      let deleted: string[] = [];

      added.forEach((str: any) => {
        data?.forEach((element) => {
          element?.airports.forEach((el: any) => {
            airport_codes.push(el?.airport_code);
            if (el?.airport_code === str) setCityCode(element?.code);
          });
        });
        if (!airport_codes.includes(str)) {
          deleted.push(str);
        }
      });
      if (added.some((el) => deleted.includes(el))) {
        const array = added.filter((el) => !deleted.includes(el));
        setAdded(array);
      }
    }
  };

  const handleRemove = (item: string) => {
    if (added.length > 0) {
      const array = added.filter((str) => str !== item);
      setAdded(array);
    }
  };

  const getColor = (item: string) => {
    const color = "transparent";
    if (getCarriers) {
      if (getCarriers[item] !== undefined) {
        return getCarriers[item].color;
      }
      return color;
    }
  };

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleSearchFilter, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchFilter = () => {
    setSearchFilter(search);
  };
  const inputElement: any = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current?.focus();
    }
  }, [isOpen]);
  return (
    <div
      className={`${styles.container} ${required && styles.required}`}
      ref={wrapperRef}
      data-active={disabled}
      data-theme={theme}
    >
      <div className={styles.dropdown_input}>
        {added?.map((item, index) => {
          return (
            <div key={index} className={styles.item_wrapper} data-theme={theme}>
              {border && (
                <span
                  className={styles.dropdown_item_border}
                  style={{ borderColor: getColor(item) }}
                />
              )}
              <span
                style={{ marginLeft: border ? 0 : 8 }}
                className={styles.item_text}
                data-theme={theme}
              >
                {item}
              </span>
              <Clear
                className={styles.close_icon}
                data-active={disabled}
                onClick={() => {
                  handleRemove(item);
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        className={styles.onClick}
        onClick={() => {
          handleSearchFilter();
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <KeyboardArrowDown />
      </div>
      <div
        className={styles.open_menu}
        data-active={isOpen}
        style={{ minWidth: width }}
        data-theme={theme}
      >
        <div className={styles.open_menu_search_wrapper} data-theme={theme}>
          <input
            type="text"
            placeholder="Search"
            value={search}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            ref={inputElement}
          />
          <img src={images.search} alt="" className={styles.search_icon} />
        </div>
        <div className={styles.open_menu_scroll}>
          {data?.map((item: any, i) => {
            return (
              <React.Fragment key={`${item?.code}${Math.random()}${i}`}>
                <div
                  className={styles.open_menu_items}
                  onClick={() =>
                    handleAdded({ isCity: true, item: item, code: item.code })
                  }
                >
                  {border && (
                    <span
                      className={styles.open_menu_item_border}
                      style={{ borderColor: getColor(item.code) }}
                    />
                  )}
                  <div
                    style={{ marginLeft: border ? 0 : 24 }}
                    className={styles.city_airport_icon_wrapper}
                  >
                    <Room />
                  </div>
                  <span>{item?.code}</span>
                </div>
                <div style={{ marginLeft: 48 }}>
                  {(item?.airports?.length > 1 || isCountryAndCity) &&
                    item?.airports.map((el, index) => (
                      <div
                        key={index}
                        className={styles.open_menu_items}
                        onClick={() =>
                          handleAdded({
                            isCity: false,
                            item: el,
                            code: item?.code,
                          })
                        }
                      >
                        <div className={styles.city_airport_icon_wrapper}>
                          <Flight />
                        </div>
                        <span
                          className={styles.airport_code_text}
                          data-active={
                            item.code === cityCode || added.length < 1
                          }
                          data-theme={theme}
                        >
                          {el?.airport_code}
                        </span>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}
