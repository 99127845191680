import React, { useState } from "react";
import styles from "./styles.module.css";
import { ArrowForwardIos } from "@mui/icons-material";
import Recursive from "./recursive";

interface IBody {
  data: {
    [key: string]: string | number | boolean | any[];
  }[];
  columns: {
    name: string;
    field: string;
    cell: (row: any) => JSX.Element;
    width?: string | number | undefined;
  }[];
  selectedRows: {};
  selectableRows: ((rows: any) => void) | undefined;
  handleCheckboxChange: (rowId: any) => void;
  theme: string;
  expand: boolean;
  onRowClick: ((row: any) => void) | undefined;
  conditionalRowStyles:
    | {
        when: (row: any) => any;
        style: (row: any) => any;
      }[]
    | undefined;
  nthChild: boolean;
  selectedGroupFields: string[];
  colGroups:
    | {
        title: string;
        fields: {
          name: string;
          field: string;
          checked: boolean;
        }[];
      }[]
    | undefined;
  rowHeight: number | string;
  columnVisibility: {
    [key: string]: boolean;
  };
  maxSelection: number | undefined;
}
const TBody = ({
  data,
  columns,
  selectableRows,
  selectedRows,
  theme,
  handleCheckboxChange,
  expand,
  onRowClick,
  conditionalRowStyles,
  nthChild,
  selectedGroupFields,
  colGroups,
  rowHeight,
  columnVisibility,
  maxSelection,
}: IBody) => {
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleExpand = (index) => {
    expandedRow === index ? setExpandedRow(null) : setExpandedRow(index);
  };

  return (
    <tbody className={styles.tbody}>
      {[...data].map((item: any, rowIndex) => {
        const rowStyle = conditionalRowStyles?.reduce((style, condition) => {
          if (condition.when(item)) {
            return { ...style, ...condition.style(item) };
          }
          return style;
        }, {});
        return (
          <React.Fragment key={rowIndex}>
            <tr className={styles.tr_margin} />
            <tr
              className={styles.tr}
              data-theme={theme}
              data-nth={nthChild ? rowIndex % 2 === 0 : false}
              onClick={() => onRowClick && onRowClick(item)}
              style={{
                ...rowStyle,
                height: rowHeight,
              }}
            >
              <td></td>
              {Object.values(columnVisibility).every(
                (value) => value === false
              ) && <td></td>}
              {expand && (
                <td
                  onClick={() => {
                    toggleExpand(rowIndex);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span className={styles.expand_button}>
                    <ArrowForwardIos
                      style={{
                        transform:
                          expandedRow === rowIndex
                            ? "rotate(90deg)"
                            : "rotate(0deg)",
                      }}
                      className={styles.mui_icon}
                    />
                  </span>
                </td>
              )}
              {selectableRows && (
                <td className={styles.td} style={{ height: rowHeight }}>
                  <input
                    type="checkbox"
                    checked={!!selectedRows[item["id"]]}
                    onChange={() => handleCheckboxChange(item?.id)}
                    disabled={
                      maxSelection !== undefined &&
                      Object.keys(selectedRows).length === maxSelection &&
                      !selectedRows[item?.id]
                    }
                  />
                </td>
              )}

              {columns
                .filter((column) => columnVisibility[column.field])
                .map((column, columnIndex) => {
                  const { cell } = column;
                  return (
                    <td
                      key={columnIndex}
                      className={styles.td}
                      style={{
                        display: colGroups
                          ? selectedGroupFields.includes(column.field)
                            ? ""
                            : "none"
                          : "",
                        height: rowHeight,
                      }}
                    >
                      {cell(item)}
                    </td>
                  );
                })}
            </tr>
            {expandedRow === rowIndex && Array.isArray(item?.data) && (
              <Recursive
                data={item?.data}
                columns={columns}
                selectableRows={selectableRows}
                colGroups={colGroups}
                selectedGroupFields={selectedGroupFields}
              />
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default TBody;
