import { IUser } from "types/types";
import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};
interface IUserProfile {
  user: null | IUser;
  setUser: React.Dispatch<React.SetStateAction<null | IUser>>;
}
const contextDefaultValues: IUserProfile = {
  user: null,
  setUser: () => {},
};
const UserProfileContext =
  React.createContext<IUserProfile>(contextDefaultValues);
const UserProfileCunsomer = UserProfileContext.Consumer;

const UserProfileContextApp = ({ children }: Props) => {
  const [user, setUser] = useState<null | IUser>(null);

  return (
    <UserProfileContext.Provider value={{ user, setUser }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileContext, UserProfileCunsomer, UserProfileContextApp };
