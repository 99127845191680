/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import { AgencyAnalysisAgencyQuadrantClient } from "lib/api/msd/agencyAnalysisAgencyQuadrant/AgencyAnalysisAgencyQuadrant";
import { FilterContext } from "context-api/FilterContext";
import images from "constans/images";
import moment from "moment";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x.webp";
import styles from "./styles.module.css";

interface IAgencyPerformanceQuadrant {
  filterIsOpen: boolean;
  selectedYearmonth: string;
  forward: boolean;
  setForward: Dispatch<SetStateAction<boolean>>;
  historical: boolean;
  setHistorical: Dispatch<SetStateAction<boolean>>;
  yearMonthly: boolean;
  setYearMonthly: Dispatch<SetStateAction<boolean>>;
}
export default function AgencyPerformanceQuadrant({
  filterIsOpen,
  selectedYearmonth,
  forward,
  setForward,
  historical,
  setHistorical,
  yearMonthly,
  setYearMonthly,
}: IAgencyPerformanceQuadrant) {
  const [isOpen, setIsOpen] = useState(false);
  const { filterList } = useContext(FilterContext);
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState([]);
  const [host, setHost] = useState<string>();
  const wrapperRef: any = useRef(null);
  const [fourCorners, setFourCorners] = useState({
    bottom_left: "",
    bottom_right: "",
    top_left: "",
    top_right: "",
  });
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setHost(JSON.parse(user).clientCode);
    }
  }, []);

  const getViewButtonValue = () => {
    if (forward && historical) {
      return "historical-forward";
    } else if (forward && historical === false) {
      return "forward";
    } else if (forward === false && historical) {
      return "historical";
    } else {
      setForward(true);
      return "forward";
    }
  };

  const getComp = (key) => {
    switch (key) {
      case host:
        return "host";
      case filterList.mainCompetitor[0]:
        return "main-comp";
      case filterList.selectedCompetitors[0]:
        return "comp2";
      default:
        return "host";
    }
  };

  useEffect(() => {
    const isValidDate = moment(selectedYearmonth, "YYYY-MM", true).isValid();
    if (!filterIsOpen && isValidDate) {
      AgencyAnalysisAgencyQuadrantClient.fetchAgencyAnalysisAgencyQuadrant({
        filterList,
        selected_carrier: getComp("host"),
        comp_type: yearMonthly ? "month" : "year",
        time_direction: getViewButtonValue(),
        selected_yearmonth: yearMonthly
          ? selectedYearmonth
          : moment(selectedYearmonth, "YYYY-MM").format("YYYY"),
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setChartData(response.fig_agency_diag.data);
          setLayout(response.fig_agency_diag.layout);
          setFourCorners({
            bottom_left: response.bottom_left,
            bottom_right: response.bottom_right,
            top_left: response.top_left,
            top_right: response.top_right,
          });
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setChartData([]);
        });
    }
  }, [
    filterIsOpen,
    yearMonthly,
    historical,
    forward,
    selectedYearmonth,
    theme,
  ]);

  return (
    <Card variant="secondary" height={616}>
      <div className={styles.container}>
        <div className={styles.card_header}>
          <p className={styles.card_title} data-theme={theme}>
            Agency Performance Quadrant{" "}
            {/* <CardInfo
              title={storyText?.main_card?.title ?? null}
              description={storyText?.main_card?.content ?? null}
            /> */}
          </p>
          <div className={styles.open_menu_wrapper} ref={wrapperRef}>
            <img
              src={theme === "dark" ? settingIcon : images.setting_button_light}
              alt=""
              className={styles.setting_button}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
            <div
              className={styles.open_menu}
              data-theme={theme}
              data-active={isOpen}
            >
              <div className={styles.card_wrapper} data-theme={theme}>
                <div className={styles.lorem_text_and_icon}>
                  <span className={styles.text} data-theme={theme}>
                    View
                  </span>
                  <img
                    src={images.eye}
                    alt=""
                    className={styles.icon}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.buttons_wrapper}>
                  <span
                    style={{ width: 80 }}
                    className={styles.button}
                    data-active={forward}
                    onClick={() => {
                      setForward(!forward);
                    }}
                    data-theme={theme}
                  >
                    Forward
                  </span>
                  <span
                    style={{ width: 88 }}
                    className={styles.button}
                    data-active={historical}
                    onClick={() => {
                      setHistorical(!historical);
                    }}
                    data-theme={theme}
                  >
                    Historical
                  </span>
                </div>
              </div>
              <div className={styles.card_wrapper} data-theme={theme}>
                <div className={styles.lorem_text_and_icon}>
                  <span className={styles.text} data-theme={theme}>
                    Time Range
                  </span>
                  <img
                    src={images.calendar}
                    alt=""
                    className={styles.icon}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.buttons_wrapper}>
                  <span
                    style={{ width: 80 }}
                    className={styles.button}
                    data-active={!yearMonthly}
                    onClick={() => {
                      setYearMonthly(!yearMonthly);
                    }}
                    data-theme={theme}
                  >
                    Year
                  </span>
                  <span
                    style={{ width: 88 }}
                    className={styles.button}
                    data-active={yearMonthly}
                    onClick={() => {
                      setYearMonthly(!yearMonthly);
                    }}
                    data-theme={theme}
                  >
                    Monthly
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card variant="primary" height={526}>
          <div className={styles.chart_wrapper}>
            <span
              className={`${styles.chart_info} ${styles.top_left}`}
              data-theme={theme}
            >
              {fourCorners.top_left}
            </span>
            <span
              className={`${styles.chart_info} ${styles.top_right}`}
              data-theme={theme}
            >
              {fourCorners.top_right}
            </span>
            <span
              className={`${styles.chart_info} ${styles.bottom_left}`}
              data-theme={theme}
            >
              {fourCorners.bottom_left}
            </span>
            <span
              className={`${styles.chart_info} ${styles.bottom_right}`}
              data-theme={theme}
            >
              {fourCorners.bottom_right}
            </span>
            <span
              className={`${styles.chart_info2} ${styles.center_right}`}
              data-theme={theme}
            >
              Revenue
            </span>
            <span
              className={`${styles.chart_info2} ${styles.top_center}`}
              data-theme={theme}
            >
              Share
            </span>
            <Plotly
              data={chartData}
              height={510}
              layout={layout}
              margin={{ t: 60, b: 60, l: 60, r: 60, p: 4 }}
              displayModeBar="hover"
            />
          </div>
        </Card>
      </div>
    </Card>
  );
}
