import { RestClient } from "lib/api/RestClient";

interface ICustomerMarkets {
  customer: string;
}
export class CustomerMarketsClient {
  private static _client: RestClient;

  static fetchCustomerMarkets({ customer }: ICustomerMarkets) {
    return CustomerMarketsClient.getClient().getCall(
      `/api/msdv2/filters/customer-markets?customer=${customer}`
    );
  }

  static getClient() {
    if (CustomerMarketsClient._client == null) {
      CustomerMarketsClient._client = new RestClient();
    }
    return CustomerMarketsClient._client;
  }
}
