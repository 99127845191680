/* eslint-disable import/no-anonymous-default-export */
export default {
  logo: require("../assets/atarev_logo.webp").default,
  logo_light: require("../assets/atarev_logo_light.png").default,
  world: require("../assets/world.webp").default,
  world_light: require("../assets/world_light.webp").default,
  po_world: require("../assets/po_world.webp").default,
  po_world_light: require("../assets/po_world_light.webp").default,

  worldHexbin: require("../assets/worldHexbin.webp").default,

  line_pen: require("../assets/icons/line_pen.webp").default,
  group_dot: require("../assets/icons/group_dot.webp").default,
  lowest_info: require("../assets/icons/lowest_info.webp").default,
  lowest_info_light: require("../assets/icons/lowest_info_light.webp").default,
  arrow_change: require("../assets/icons/arrow_change.png").default,
  setting: require("../assets/icons/setting.webp").default,
  clock_time_timer2: require("../assets/icons/clock_time_timer2.webp").default,
  clock_time_timer: require("../assets/icons/clock_time_timer.webp").default,
  travel_earth_arrow: require("../assets/icons/travel_earth_arrow.webp")
    .default,
  excel: require("../assets/icons/excel.webp").default,
  arrow_horizontal: require("../assets/icons/arrow_horizontal.webp").default,
  arrow_none: require("../assets/icons/arrow_none.webp").default,
  arrow_up: require("../assets/icons/arrow_up.webp").default,
  arrow_down: require("../assets/icons/arrow_down.webp").default,
  clock_time: require("../assets/icons/clock_time.webp").default,
  eye: require("../assets/icons/eye.webp").default,
  info: require("../assets/icons/info.webp").default,
  logout_arrow: require("../assets/icons/logout_arrow.webp").default,
  calendar: require("../assets/icons/calendar.webp").default,
  search: require("../assets/icons/search.webp").default,
  business: require("../assets/icons/business.webp").default,
  clock_time_arrow: require("../assets/icons/clock_time_arrow.webp").default,
  travel_plane_boarding_pass:
    require("../assets/icons/travel_plane_boarding_pass.webp").default,
  legal_law: require("../assets/icons/legal_law.webp").default,
  smileys: require("../assets/icons/smileys.webp").default,
  plus: require("../assets/icons/plus.webp").default,
  travel_plane_take_off: require("../assets/icons/travel_plane_take_off.webp")
    .default,
  cancel: require("../assets/icons/cancel.webp").default,
  cancel_red: require("../assets/icons/cancel_red.webp").default,
  fs_green_tick: require("../assets/icons/fs_green_tick.webp").default,
  tick: require("../assets/icons/tick.webp").default,
  logout_arrow_big: require("../assets/icons/logout_arrow_big.webp").default,
  double_arrow_left: require("../assets/icons/double_arrow_left.webp").default,
  logo_arrow: require("../assets/icons/logo_arrow.webp").default,
  tick_green: require("../assets/icons/tick_green.webp").default,
  clock_watch: require("../assets/icons/clock_watch.webp").default,
  boarding_pass: require("../assets/icons/boarding_pass.webp").default,
  business_products_business_chart:
    require("../assets/icons/business_products_business_chart.webp").default,
  marker_pin: require("../assets/icons/marker_pin.webp").default,
  star_favorite: require("../assets/icons/star_favorite.webp").default,
  travel_earth_2: require("../assets/icons/travel_earth_2.webp").default,
  money_hand_arm_coins: require("../assets/icons/money_hand_arm_coins.webp")
    .default,
  money_coins: require("../assets/icons/money_coins.webp").default,
  money_money: require("../assets/icons/money_money.webp").default,
  business_chart_2: require("../assets/icons/business_chart_2.webp").default,
  diagrams_arrow: require("../assets/icons/diagrams_arrow.webp").default,

  // Filter Menu Icons
  navigation_maps: require("../assets/icons/navigation_maps.webp").default,
  flag: require("../assets/icons/flag.webp").default,
  travel_map_point: require("../assets/icons/travel_map_point.webp").default,
  chess: require("../assets/icons/chess.webp").default,
  hand_money_banknote: require("../assets/icons/hand_money_banknote.webp")
    .default,
  travel_airplane: require("../assets/icons/travel_airplane.webp").default,
  travel_airplane2: require("../assets/icons/travel_airplane2.webp").default,
  setting_button: require("../assets/icons/setting_button.webp").default,
  setting_button_light: require("../assets/icons/setting_button_light.webp")
    .default,

  //social media
  instagram: require("../assets/icons/instagram.webp").default,
  linkedin: require("../assets/icons/linkedin.webp").default,
  twitter: require("../assets/icons/twitter.webp").default,

  // MS icons
  money_coins_blue: require("../assets/icons/money_coins_blue.webp").default,
  money_coins_purple: require("../assets/icons/money_coins_purple.webp")
    .default,
  money_coins_green: require("../assets/icons/money_coins_green.webp").default,
  money_coins_red: require("../assets/icons/money_coins_red.webp").default,

  //NS
  money_coint_growth: require("../assets/icons/money_coint_growth.webp")
    .default,

  //SA
  business_products: require("../assets/icons/business_products.webp").default,
  business_products_white:
    require("../assets/icons/business_products_white.webp").default,
  money_analytics: require("../assets/icons/money_analytics.webp").default,
  arrow_right: require("../assets/icons/arrow_right.webp").default,
  business_chart: require("../assets/icons/business_chart.webp").default,
  business_chart_white: require("../assets/icons/business_chart.webp").default,
  group_user: require("../assets/icons/group_user.webp").default,
  money_coins_white: require("../assets/icons/money_coins_white.webp").default,
  target_goal: require("../assets/icons/target_goal.webp").default,
  target_goal_white: require("../assets/icons/target_goal_white.webp").default,
  programming_code_checkmark:
    require("../assets/icons/programming_code_checkmark.webp").default,

  //CS
  travel_plane_check_travel:
    require("../assets/icons/travel_plane_check_travel.webp").default,
  travel_plane_land: require("../assets/icons/travel_plane_land.webp").default,
  calendar_checkmark: require("../assets/icons/calendar_checkmark.webp")
    .default,
  calendar_schedule: require("../assets/icons/calendar_schedule.webp").default,
  user_profile: require("../assets/icons/user_profile.webp").default,
  a_to_b: require("../assets/icons/a_to_b.webp").default,
  boarding_pass_white: require("../assets/icons/boarding_pass_white.webp")
    .default,
  travel_earth: require("../assets/icons/travel_earth.webp").default,
  alarm_clock_time: require("../assets/icons/alarm_clock_time.webp").default,
  calendar_schedule_mini: require("../assets/icons/calendar_schedule_mini.webp")
    .default,
  weather_climate: require("../assets/icons/weather_climate.webp").default,
  atom_icon_kpi: require("../assets/icons/atom-icon-cpi-blue@3x.webp").default,
  atom_icon_kpi_magenta: require("../assets/icons/atom-icon-cpi-magenta@3x.webp").default,
  atom_icon_kpi_purple: require("../assets/icons/atom-icon-cpi-purple@3x.webp").default,
  atom_icon_kpi_navy: require("../assets/icons/atom-icon-cpi-navy-blue@3x.webp").default,
  atom_icon_kpi_yellow: require("../assets/icons/atom-icon-cpi-yellow.webp").default,

  /*fs*/
  refresh_loading_checkmark:
    require("../assets/icons/refresh_loading_checkmark.webp").default,
  school_bag: require("../assets/icons/school_bag.webp").default,
  travel_baggage: require("../assets/icons/travel_baggage.webp").default,
  armchair: require("../assets/icons/armchair.webp").default,
  burger_drink: require("../assets/icons/burger_drink.webp").default,
  headphones: require("../assets/icons/headphones.webp").default,
};
