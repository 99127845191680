import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import styles from "./styles.module.css";
interface ITooltip {
  data: {
    holiday_name: string;
    country_name: string;
    start_date: string;
    end_date: string;
  }[];
}

export default function LowestMountHolidaysTooltip({ data }: ITooltip) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.content}>
        {data?.map((el, index) => (
          <div key={index}>
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  Country
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {el?.country_name}
                </span>
              </div>
            </div>
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  Holiday Name
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {el?.holiday_name}
                </span>
              </div>
            </div>
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  Start Date
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {el?.start_date}
                </span>
              </div>
            </div>
            <div className={styles.text_wrapper}>
              <div>
                <span className={styles.text_left} data-theme={theme}>
                  End Date
                </span>
              </div>
              <div>
                <span className={styles.text_right} data-theme={theme}>
                  {el?.end_date}
                </span>
              </div>
            </div>
            <span
              className={styles.divider}
              data-active={data.length - 1 === index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
