import * as React from "react";
import styles from "./styles.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import images from "constans/images";
import { ThemeContext } from "context-api/ThemeContext";
import StatisticTooltip from "./Tooltip";
import { ArrowForwardIos } from "@mui/icons-material";

interface IData {
  generalInfo: {
    all_gap_max: string;
    all_gap_min: string;
    all_avg_gap: string;
    all_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        all_gap_max_weekday: string;
        all_gap_max_date: string;
        all_gap_max_dtd: string;
        all_gap_max_time: string;
        all_gap_max_lf: string;
        all_gap_max_class: string;
      };
      gap_min: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        all_gap_min_weekday: string;
        all_gap_min_date: string;
        all_gap_min_dtd: string;
        all_gap_min_time: string;
        all_gap_min_lf: string;
        all_gap_min_class: string;
      };
    };
  };
  positiveInfo: {
    pos_gap_max: string;
    pos_gap_min: string;
    pos_avg_gap: string;
    pos_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        pos_gap_max_weekday: string;
        pos_gap_max_date: string;
        pos_gap_max_dtd: string;
        pos_gap_max_time: string;
        pos_gap_max_lf: string;
        pos_gap_max_class: string;
      };
      gap_min: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        pos_gap_min_weekday: string;
        pos_gap_min_date: string;
        pos_gap_min_dtd: string;
        pos_gap_min_time: string;
        pos_gap_min_lf: string;
        pos_gap_min_class: string;
      };
    };
  };
  negativeInfo: {
    neg_gap_max: string;
    neg_gap_min: string;
    neg_avg_gap: string;
    neg_count: number;
    maf: string;
    carrier: {
      value: string;
      color: string;
    };
    period: number;
    hoverText: {
      gap_max: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        neg_gap_max_weekday: string;
        neg_gap_max_date: string;
        neg_gap_max_dtd: string;
        neg_gap_max_time: string;
        neg_gap_max_lf: string;
        neg_gap_max_class: string;
      };
      gap_min: {
        comp_fare: string;
        comp_flight_num: number;
        host_fare: string;
        host_flight_num: number;
        host_lf: string;
        neg_gap_min_weekday: string;
        neg_gap_min_date: string;
        neg_gap_min_dtd: string;
        neg_gap_min_time: string;
        neg_gap_min_lf: string;
        neg_gap_min_class: string;
      };
    };
  };
}
interface Props {
  data: IData[];
  isPriceEvo?: boolean;
  hostCarrier: {
    color: string;
    value: string;
  };
}
const StatisticsComponent: React.FC<Props> = ({
  data,
  isPriceEvo,
  hostCarrier,
}) => {
  const { theme } = React.useContext(ThemeContext);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className={styles.container}>
      <div className={styles.fields} data-theme={theme}>
        <span className={styles.field} data-theme={theme}>
          Carrier
        </span>
        <span className={styles.field} data-theme={theme}>
          Max Gap
        </span>
        <span className={styles.field} data-theme={theme}>
          MIN Gap
        </span>
        <span className={styles.field} data-theme={theme}>
          Count
        </span>
        <span className={styles.field} data-theme={theme}>
          AVG Gap
        </span>
        <span className={styles.field} data-theme={theme}>
          MAF +/- %
        </span>
        <span
          className={styles.field}
          data-theme={theme}
          data-active={isPriceEvo}
        >
          Period
        </span>

        <span className={styles.expand_icon} data-theme={theme} />
      </div>
      {data?.map((el, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            backgroundColor:
              theme === "dark"
                ? "var(--card-secondary-color)"
                : "rgba(0,0,0,0.05)",
            margin: "4px 0",
            minWidth: isPriceEvo ? 7 * 190 + 18 : 8 * 190 + 18,
          }}
        >
          <AccordionSummary
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              borderRadius: 1,
              bgcolor:
                theme === "dark" ? "var(--bg-color-primary-dark)" : "#fff",
            }}
          >
            <div className={styles.summary}>
              <span className={styles.field} data-theme={theme}>
                <span
                  className={styles.carrier}
                  style={{
                    backgroundColor: el?.generalInfo?.carrier?.color ?? "#fff",
                  }}
                >
                  {el?.generalInfo?.carrier?.value ?? "-"}
                </span>
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.generalInfo?.hoverText?.gap_max?.all_gap_max_dtd
                      : el?.generalInfo?.hoverText?.gap_max?.all_gap_max_date,
                    max_time:
                      el?.generalInfo?.hoverText?.gap_max?.all_gap_max_time,
                    max_weekday:
                      el?.generalInfo?.hoverText?.gap_max?.all_gap_max_weekday,
                    max_lf: el?.generalInfo?.hoverText?.gap_max?.all_gap_max_lf,
                    class:
                      el?.generalInfo?.hoverText?.gap_max?.all_gap_max_class,
                    comp_fare: el?.generalInfo?.hoverText?.gap_max?.comp_fare,
                    comp_flight_num:
                      el?.generalInfo?.hoverText?.gap_max?.comp_flight_num,
                    host_fare: el?.generalInfo?.hoverText?.gap_max?.host_fare,
                    host_flight_num:
                      el?.generalInfo?.hoverText?.gap_max?.host_flight_num,
                    host_lf: el?.generalInfo?.hoverText?.gap_max?.host_lf,
                  }}
                  compCarrier={el?.generalInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.generalInfo?.all_gap_max ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.generalInfo?.hoverText?.gap_min?.all_gap_min_dtd
                      : el?.generalInfo?.hoverText?.gap_min?.all_gap_min_date,
                    max_time:
                      el?.generalInfo?.hoverText?.gap_min?.all_gap_min_time,
                    max_weekday:
                      el?.generalInfo?.hoverText?.gap_min?.all_gap_min_weekday,
                    max_lf: el?.generalInfo?.hoverText?.gap_min?.all_gap_min_lf,
                    class:
                      el?.generalInfo?.hoverText?.gap_min?.all_gap_min_class,

                    comp_fare: el?.generalInfo?.hoverText?.gap_min?.comp_fare,
                    comp_flight_num:
                      el?.generalInfo?.hoverText?.gap_min?.comp_flight_num,
                    host_fare: el?.generalInfo?.hoverText?.gap_min?.host_fare,
                    host_flight_num:
                      el?.generalInfo?.hoverText?.gap_min?.host_flight_num,
                    host_lf: el?.generalInfo?.hoverText?.gap_min?.host_lf,
                  }}
                  compCarrier={el?.generalInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.generalInfo?.all_gap_min ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.generalInfo?.all_count ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.generalInfo?.all_avg_gap ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.generalInfo?.maf ?? "-"}
              </span>

              <span
                className={styles.field}
                data-theme={theme}
                data-active={isPriceEvo}
              >
                {el?.generalInfo?.period ?? "-"}
              </span>

              <span className={styles.expand_icon} data-theme={theme}>
                <ArrowForwardIos
                  style={{
                    transform:
                      expanded === `panel${index}`
                        ? `rotate(90deg)`
                        : `rotate(0deg)`,
                    transition: "ease-in-out 300ms",
                  }}
                  sx={{
                    color:
                      theme === "dark"
                        ? "#fff"
                        : "var(--text-color-primary-light)",
                  }}
                />
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "1em" }}>
            <div className={styles.summary}>
              <span className={styles.field} data-theme={theme}>
                <img src={images.arrow_up} alt="" />
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_dtd
                      : el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_date,
                    max_time:
                      el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_time,
                    max_weekday:
                      el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_weekday,
                    max_lf:
                      el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_lf,
                    class:
                      el?.positiveInfo?.hoverText?.gap_max?.pos_gap_max_class,

                    comp_fare: el?.positiveInfo?.hoverText?.gap_max?.comp_fare,
                    comp_flight_num:
                      el?.positiveInfo?.hoverText?.gap_max?.comp_flight_num,
                    host_fare: el?.positiveInfo?.hoverText?.gap_max?.host_fare,
                    host_flight_num:
                      el?.positiveInfo?.hoverText?.gap_max?.host_flight_num,
                    host_lf: el?.positiveInfo?.hoverText?.gap_max?.host_lf,
                  }}
                  compCarrier={el?.positiveInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.positiveInfo?.pos_gap_max ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_dtd
                      : el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_date,
                    max_time:
                      el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_time,
                    max_weekday:
                      el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_weekday,
                    max_lf:
                      el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_lf,
                    class:
                      el?.positiveInfo?.hoverText?.gap_min?.pos_gap_min_class,

                    comp_fare: el?.positiveInfo?.hoverText?.gap_min?.comp_fare,
                    comp_flight_num:
                      el?.positiveInfo?.hoverText?.gap_min?.comp_flight_num,
                    host_fare: el?.positiveInfo?.hoverText?.gap_min?.host_fare,
                    host_flight_num:
                      el?.positiveInfo?.hoverText?.gap_min?.host_flight_num,
                    host_lf: el?.positiveInfo?.hoverText?.gap_min?.host_lf,
                  }}
                  compCarrier={el?.positiveInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.positiveInfo?.pos_gap_min ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.positiveInfo?.pos_count ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.positiveInfo?.pos_avg_gap ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.positiveInfo?.maf ?? "-"}
              </span>
              <span
                className={styles.field}
                data-theme={theme}
                data-active={isPriceEvo}
              >
                {el?.positiveInfo?.period ?? "-"}
              </span>

              <span className={styles.expand_icon} data-theme={theme} />
            </div>
            <hr className={styles.hr} />
            <div className={styles.summary}>
              <span className={styles.field} data-theme={theme}>
                <img src={images.arrow_down} alt="" />{" "}
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_dtd
                      : el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_date,
                    max_time:
                      el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_time,
                    max_weekday:
                      el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_weekday,
                    max_lf:
                      el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_lf,
                    class:
                      el?.negativeInfo?.hoverText?.gap_max?.neg_gap_max_class,

                    comp_fare: el?.negativeInfo?.hoverText?.gap_max?.comp_fare,
                    comp_flight_num:
                      el?.negativeInfo?.hoverText?.gap_max?.comp_flight_num,
                    host_fare: el?.negativeInfo?.hoverText?.gap_max?.host_fare,
                    host_flight_num:
                      el?.negativeInfo?.hoverText?.gap_max?.host_flight_num,
                    host_lf: el?.negativeInfo?.hoverText?.gap_max?.host_lf,
                  }}
                  compCarrier={el?.negativeInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.negativeInfo?.neg_gap_max ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                <StatisticTooltip
                  data={{
                    max_date: isPriceEvo
                      ? el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_dtd
                      : el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_date,
                    max_time:
                      el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_time,
                    max_weekday:
                      el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_weekday,
                    max_lf:
                      el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_lf,
                    class:
                      el?.negativeInfo?.hoverText?.gap_min?.neg_gap_min_class,

                    comp_fare: el?.negativeInfo?.hoverText?.gap_min?.comp_fare,
                    comp_flight_num:
                      el?.negativeInfo?.hoverText?.gap_min?.comp_flight_num,
                    host_fare: el?.negativeInfo?.hoverText?.gap_min?.host_fare,
                    host_flight_num:
                      el?.negativeInfo?.hoverText?.gap_min?.host_flight_num,
                    host_lf: el?.negativeInfo?.hoverText?.gap_min?.host_lf,
                  }}
                  compCarrier={el?.negativeInfo?.carrier}
                  isPriceEvo={isPriceEvo}
                  hostCarrier={hostCarrier}
                >
                  {el?.negativeInfo?.neg_gap_min ?? "-"}
                </StatisticTooltip>
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.negativeInfo?.neg_count ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.negativeInfo?.neg_avg_gap ?? "-"}
              </span>
              <span className={styles.field} data-theme={theme}>
                {el?.negativeInfo?.maf ?? "-"}
              </span>
              <span
                className={styles.field}
                data-theme={theme}
                data-active={isPriceEvo}
              >
                {el?.negativeInfo?.period ?? "-"}
              </span>
              <span className={styles.expand_icon} data-theme={theme} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default StatisticsComponent;
