import { RestClient } from "lib/api/RestClient";
export class LowestFareCalendarClient {
  private static _client: RestClient;
  static fetchLowestFareCalendar({ data }) {
    return LowestFareCalendarClient.getClient().getCall(
      `/api/lfa/fares2/availability-calendar?${new URLSearchParams(data)}`
    );
  }
  static getClient() {
    if (LowestFareCalendarClient._client == null) {
      LowestFareCalendarClient._client = new RestClient();
    }
    return LowestFareCalendarClient._client;
  }
}
