import { RestClient } from "lib/api/RestClient";

interface IFilterMarketCreate {
  data: {
    customer: string;
    orig: string;
    dest: string;
    competitors: string[];
    points_of_sale: string[];
    sales_channels: string[];
  };
}
export class FilterMarketCreateClient {
  private static _client: RestClient;

  static fetchFilterMarketCreate({ data }: IFilterMarketCreate) {
    return FilterMarketCreateClient.getClient().postCall(
      `/api/msdv2/admin/filter-market`,
      data
    );
  }

  static getClient() {
    if (FilterMarketCreateClient._client == null) {
      FilterMarketCreateClient._client = new RestClient();
    }
    return FilterMarketCreateClient._client;
  }
}
