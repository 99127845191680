import { RestClient } from "lib/api/RestClient";

export class PriorityReorderClient {
  private static _client: RestClient;
  static fetchPriorityReorder(data) {
    return PriorityReorderClient.getClient().putCall(
      `/api/lfa/rules/priority-reorder`,
      data
    );
  }

  static getClient() {
    if (PriorityReorderClient._client == null) {
      PriorityReorderClient._client = new RestClient();
    }
    return PriorityReorderClient._client;
  }
}
