import * as React from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { ThemeContext } from "context-api/ThemeContext";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${theme === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"}`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

function CustomPagination({ theme }) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      sx={{
        ".MuiPaginationItem-root": {
          color:
            theme === "dark" ? "rgba(255, 255, 255, 0.3)" : "rgba(0,0,0,0.3)",
          border: "1px solid rgba(0, 0, 0, 0)",
        },
        ".Mui-selected": {
          color:
            theme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0,0,0,0.1)",
          border: "1px solid rgba(255, 255, 255, 0.2)",
        },
      }}
      size={"medium"}
      variant="outlined"
      shape="rounded"
      showFirstButton
      showLastButton
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(_event: React.ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
    />
  );
}

const StyledDataGrid = styled(DataGrid)(() => {
  const { theme } = React.useContext(ThemeContext);
  return {
    border: 0,
    color: theme === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
    fontFamily: ["var(--font-family-light)", "var(--font-family-normal)"].join(
      ","
    ),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      // backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      fontFamily: "var(--font-family-light)",
      color:
        theme === "dark"
          ? "rgba(255, 255, 255, 0.6)"
          : "var(--text-color-primary-light)",
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      // border: "1px solid rgba(255,255,255,0.2)",
    },
    "& .MuiDataGrid-cell": {
      color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0,0,0,0.6)",
      border: "none",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 5,
      "*": {
        color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0,0,0,0.6)",
      },
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor:
        theme === "dark"
          ? "rgba(0, 0, 0, 0.2)"
          : "var(--button-bg-color-primary-light)",
      border: "none",
      height: 48,
    },
    "& .MuiDataGrid-row": {
      backgroundColor:
        theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
      margin: "4px 0 0 0",
      borderRadius: 5,
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor:
        theme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0,0,0,0.1)",
    },
    "& .MuiDataGrid-footerContainer": {
      border: "none",
    },
    "& .MuiDataGrid-row--dynamicHeight": {
      height: 48,
    },
    "& .MuiDataGrid-sortIcon,.MuiDataGrid-menuIconButton,.MuiDataGrid-filterIcon":
      {
        color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0,0,0,0.6)",
      },
    "& .MuiDataGrid-selectedRowCount": {
      color: "transparent",
    },
    ...customCheckbox(theme),
  };
});

interface IAntDesignGrid {
  data: any;
  columns: any;
  onRowClick?: (params) => void | undefined;
  height?: number | string;
  pageSize?: number;
  loading?: boolean;
}
export default function AntDesignGrid({
  data,
  columns,
  onRowClick = undefined,
  height = undefined,
  pageSize = 10,
  loading = false,
}: IAntDesignGrid) {
  const { theme } = React.useContext(ThemeContext);

  return (
    <div
      style={{
        height: height ?? 400,
        width: "100%",
        position: "relative",
      }}
    >
      <StyledDataGrid
        checkboxSelection={false}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        components={{
          Pagination: () => CustomPagination({ theme }),
          NoRowsOverlay: () => (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Rows
            </div>
          ),
        }}
        rows={data}
        columns={columns}
        onRowClick={onRowClick ? (params) => onRowClick(params.row) : undefined}
        rowHeight={48}
        headerHeight={32}
        loading={loading}
      />
    </div>
  );
}
