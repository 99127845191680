import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Cancel, Done } from "@mui/icons-material";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 4,
  border: "1px solid rgba(255,255,255,0.1)",
  boxShadow: 24,
  p: 4,
};

export default function Confirmation({
  children,
  isOpen,
  setIsOpen,
  event,
  title = "",
  description = "",
}) {
  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  const { theme } = useContext(ThemeContext);

  return (
    <div>
      <span onClick={handleOpen}>{children}</span>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, bgcolor: theme === "dark" ? "#110c20" : "#fff" }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <span
              onClick={event}
              style={{
                cursor: "pointer",
                borderRadius: 8,
                padding: 8,
                backgroundColor:
                  theme === "dark" ? "#201b36" : "rgba(0,0,0,0.1)",
                textAlign: "center",
                display: "flex",
              }}
            >
              <Done /> Accept
            </span>
            <span
              onClick={handleClose}
              style={{
                cursor: "pointer",
                borderRadius: 8,
                padding: 8,
                backgroundColor:
                  theme === "dark" ? "#201b36" : "rgba(0,0,0,0.1)",
                textAlign: "center",
                display: "flex",
              }}
            >
              <Cancel /> Cancel
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
