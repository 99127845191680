import { RestClient } from "../../RestClient";
interface IPriceEvolution {
  data: {
    dest_city_airport: string;
    orig_city_airport: string;
    outbound_date: string;
    time_range_start: string;
    time_range_end: string;
    duration: string;
    weekdays: string[];
    connection: string[];
    graph_carriers: string;
    cabin: string;
    flight: string[];
    dark_theme: boolean;
    currency: string[];
    type: string;
  };
}
export class PriceEvolutionClient {
  private static _client: RestClient;
  static fetchPriceEvolution({ data }: IPriceEvolution) {
    const params = {
      orig_city_airport: data?.orig_city_airport,
      dest_city_airport: data?.dest_city_airport,
      outbound_date: data?.outbound_date,
      time_range_start: data?.time_range_start,
      time_range_end: data?.time_range_end,
      duration: data?.duration,
      weekdays: data?.weekdays?.join(),
      connection: data?.connection?.join(),
      graph_carriers: data?.graph_carriers,
      cabin: data?.cabin,
      flight: data?.flight?.join(),
      ...(data?.currency && { currency: data?.currency?.join() }),
      dark_theme: `${data?.dark_theme}`,
      type: data?.type,
    };
    return PriceEvolutionClient.getClient().getCall(
      `/api/msdv2/fare-structure/price-evolution?${new URLSearchParams(params)}`
    );
  }
  static getClient() {
    if (PriceEvolutionClient._client == null) {
      PriceEvolutionClient._client = new RestClient();
    }
    return PriceEvolutionClient._client;
  }
}
