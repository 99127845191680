import { Dispatch, SetStateAction } from "react";
import AvaibilityTrendsFiltersClose from "./AvaibilityTrendsFiltersClose";
import AvaibilityTrendsFiltersOpen from "./AvaibilityTrendsFiltersOpen";

interface IAvaibilityTrendsFilters {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPriceEvo?: boolean;
  isSingleDate?: boolean;
}

export default function AvaibilityTrendsFilters({
  isOpen,
  setIsOpen,
  isPriceEvo,
  isSingleDate = false,
}: IAvaibilityTrendsFilters) {
  return (
    <>
      {isOpen && (
        <AvaibilityTrendsFiltersOpen
          isPriceEvo={isPriceEvo}
          setIsOpen={setIsOpen}
          isSingleDate={isSingleDate}
        />
      )}
      {!isOpen && (
        <AvaibilityTrendsFiltersClose
          isPriceEvo={isPriceEvo}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
