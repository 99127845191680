import { RestClient } from "../RestClient";

interface IUser {
  token: string;
}
export class UserClient {
  private static _client: RestClient;

  static fetchUser({ token }: IUser) {
    const headers: any = {
      Authorization: `Bearer ${token}`,
    };
    return UserClient.getClient().getCall(
        `/api/msd/auth/userprofile`,
      headers
    );
  }

  static getClient() {
    if (UserClient._client == null) {
      UserClient._client = new RestClient();
    }
    return UserClient._client;
  }
}
