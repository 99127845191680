import { ThemeContext } from "context-api/ThemeContext";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";

interface IInput {
  type?: "text" | "number";
  defaultValue?: any;
  onChange: any;
  required?: boolean;
}

export default function Input({
  type = "text",
  defaultValue = "",
  onChange,
  required = false,
}: IInput) {
  const { theme } = useContext(ThemeContext);
  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <input
      className={styles.input}
      data-theme={theme}
      type={type}
      value={value}
      onChange={handleChange}
      required={required}
    />
  );
}
