import Card from "components/common/Card/Card";
import images from "constans/images";
import styles from "./FareStructureFiltersClosed.module.css";
import { Tooltip } from "components/common/Tooltip";

interface IFareStructureFiltersClosed {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FareStructureFiltersClosed({
  setIsOpen,
}: IFareStructureFiltersClosed) {
  const obj = {
    travelDate: {
      title: "Travel Date",
      icon: images.travel_earth_2,
      iconColor: "#d77cbb",
      value: "lorem",
    },
    salesDateRange: {
      title: "Sales Date Range",
      icon: images.money_hand_arm_coins,
      iconColor: "#5504d9",
      value: "lorem",
    },
    season: {
      title: "Season",
      icon: images.business_chart_2,
      iconColor: "#da7706",
      value: "lorem",
    },
    fareFamily: {
      title: "Fare Family",
      icon: images.money_coins,
      iconColor: "#ec4252",
      value: "lorem",
    },
    travelType: {
      title: "Travel Type",
      icon: images.a_to_b,
      iconColor: "#144aff",
      value: "loremloremloremloremloremlorem",
    },
  };
  return (
    <Card variant="secondary" height={215}>
      <div className={styles.container}>
        <div className={styles.top}>
          <span>Fare Structure Filters</span>
          <div
            className={styles.setting_button}
            onClick={() => setIsOpen(true)}
          >
            <img src={images.setting_button} alt="" />
          </div>
        </div>
        <div className={styles.bottom}>
          <SummaryCard object={obj.travelDate} />
          <SummaryCard object={obj.salesDateRange} />
          <SummaryCard object={obj.season} />
          <SummaryCard object={obj.fareFamily} />
          <SummaryCard object={obj.travelType} />
        </div>
      </div>
    </Card>
  );
}

const SummaryCard = ({ object }) => {
  return (
    <div className={styles.summary_card_container}>
      <div className={styles.summary_card_title_wrapper}>
        <span className={styles.summary_card_title}>{object.title}</span>
        <span
          className={styles.icon_wrapper}
          style={{
            backgroundColor: object.iconColor,
          }}
        >
          <img src={object.icon} alt="" />
        </span>
      </div>
      <div className={styles.values}>
        <Tooltip title={object.value}>
          <span className={styles.value}>{object.value}</span>
        </Tooltip>
      </div>
    </div>
  );
};
