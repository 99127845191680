import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";

interface IDowRevenueBreakdown {
  data: {
    data: never[];
    layout: never[];
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function DowRevenueBreakdown({
  data,
  storyText,
}: IDowRevenueBreakdown) {
  return (
    <Card
      variant="secondary"
      title="DOW Revenue Breakdown"
      width={"60%"}
      // cardInfo={{
      //   title: storyText?.dow_revenue?.title ?? null,
      //   description: storyText?.dow_revenue?.content ?? null,
      // }}
    >
      <Card variant="primary" height={364}>
        <div className={styles.axis_title_wrapper}>
          <span className={styles.chart_title}>#Passengers</span>
          <span className={styles.chart_title}>Revenue ($)</span>
        </div>
        <Plotly
          data={data?.data}
          height={276}
          layout={data?.layout}
          margin={{ t: 20, b: 20, l: 30, r: 10, p: 4 }}
        />
        <p className={styles.chart_x_title}>Days of Week</p>
      </Card>
    </Card>
  );
}
