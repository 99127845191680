import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IAgencyAnalysisAgencyGraphs {
  filterList: IFilterContext;
  selected_agency: string;
  comp_type: string;
  time_direction: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}
export class AgencyAnalysisAgencyGraphsClient {
  private static _client: RestClient;

  static fetchAgencyAnalysisAgencyGraphs({
    filterList,
    selected_agency,
    comp_type,
    time_direction,
    selected_yearmonth,
    dark_theme,
  }: IAgencyAnalysisAgencyGraphs) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return AgencyAnalysisAgencyGraphsClient.getClient().getCall(
      `/api/msdv2/agency-analysis/agency-graphs?${qp}&selected_agency=${selected_agency}&comp_type=${comp_type}&time_direction=${time_direction}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (AgencyAnalysisAgencyGraphsClient._client == null) {
      AgencyAnalysisAgencyGraphsClient._client = new RestClient();
    }
    return AgencyAnalysisAgencyGraphsClient._client;
  }
}
